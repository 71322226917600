import React, { Component } from 'react'
import partial from 'lodash/partial'
import toUpper from 'lodash/toUpper'
import uniq from 'lodash/uniq'
import chunk from 'lodash/chunk'

import Table, { Header, Row, Cell } from '../../../../components/Table'
import Loader from '../../../../components/Loader'
import API, { UNAUTHORISED_STATUS } from '../../../../lib/api'
import { getMessage } from '../../../../lib/translator'
// import getCategoryApi from './categories'

import {
  JobConfig,
  buildCategoryMapping,
  buildProductNameMap,
  buildTagMap,
  snakeToCamel,
  isEmpty as testEmpty,
} from '../BatchJobsConfig'

const BATCHING_SIZE = 300
const getIndex = (headers, header) => {
  return headers
    .map(h => h.replace(/ /g, '').toLowerCase())
    .indexOf(header.toLowerCase())
}

const getTableData = (results, jobName, categoryMap, productMap, tagMap) => {
  if (results.length === 0) {
    return []
  }
  const CSV_HEADERS = JobConfig[jobName].csvHeaders
  const fileHeaders = results[0]
  const getIndexPartial = partial(getIndex, fileHeaders)
  const skuIndex = getIndexPartial(CSV_HEADERS.SKU.header)
  const primaryCategoryIndex = getIndexPartial(
    CSV_HEADERS.PRIMARY_CATEGORY_ID.header
  )
  const secondaryCategoryIndex = getIndexPartial(
    CSV_HEADERS.SECONDARY_CATEGORY_IDS.header
  )
  const secCategoryActionIndex = getIndexPartial(
    CSV_HEADERS.SECONDARY_CATEGORY_IDS_ACTION.header
  )
  const barcodesIndex = getIndexPartial(CSV_HEADERS.BARCODES.header)
  const barcodesActionIndex = getIndexPartial(
    CSV_HEADERS.BARCODES_ACTION.header
  )
  const brandIdIndex = getIndexPartial(CSV_HEADERS.BRAND_ID.header)
  const nameIndex = getIndexPartial(CSV_HEADERS.NAME.header)
  const displayUnitIndex = getIndexPartial(CSV_HEADERS.DISPLAY_UNIT.header)
  // const imagesIndex = getIndexPartial(CSV_HEADERS.IMAGES.header)
  // const imagesActionIndex = getIndexPartial(CSV_HEADERS.IMAGES_ACTION.header)
  const keyInformationIndex = getIndexPartial(
    CSV_HEADERS.KEY_INFORMATION.header
  )
  const keyInformationActionIndex = getIndexPartial(
    CSV_HEADERS.KEY_INFORMATION_ACTION.header
  )
  const additionalInformationIndex = getIndexPartial(
    CSV_HEADERS.ADDITIONAL_INFORMATION.header
  )
  const additionalInformationActionIndex = getIndexPartial(
    CSV_HEADERS.ADDITIONAL_INFORMATION_ACTION.header
  )
  const ingredientsIndex = getIndexPartial(CSV_HEADERS.INGREDIENTS.header)
  const ingredientsActionIndex = getIndexPartial(
    CSV_HEADERS.INGREDIENTS_ACTION.header
  )
  const nutritionalDataIndex = getIndexPartial(
    CSV_HEADERS.NUTRITIONAL_DATA.header
  )
  const storageInformationIndex = getIndexPartial(
    CSV_HEADERS.STORAGE_INFORMATION.header
  )
  const globalBulkIndex = getIndexPartial(
    CSV_HEADERS.BULK_ORDER_THRESHOLD.header
  )
  const preparationIndex = getIndexPartial(CSV_HEADERS.PREPARATION.header)
  const dietaryAttributesIndex = getIndexPartial(
    CSV_HEADERS.DIETARY_ATTRIBUTES.header
  )
  const dietaryAttributesActionIndex = getIndexPartial(
    CSV_HEADERS.DIETARY_ATTRIBUTES_ACTION.header
  )
  const countryOfOriginIndex = getIndexPartial(
    CSV_HEADERS.COUNTRY_OF_ORIGIN.header
  )
  const vendorCodeIndex = getIndexPartial(CSV_HEADERS.VENDOR_CODE.header)
  const vendorCodeActionIndex = getIndexPartial(
    CSV_HEADERS.VENDOR_CODE_ACTION.header
  )
  const handlingDaysIndex = getIndexPartial(CSV_HEADERS.HANDLING_DAYS.header)
  const sellerSkuIndex = getIndexPartial(CSV_HEADERS.SELLER_SKU.header)
  const tagIdIndex = getIndexPartial(CSV_HEADERS.TAG_ID.header)
  const tagIdActionIndex = getIndexPartial(CSV_HEADERS.TAG_ID_ACTION.header)
  const statusIndex = getIndexPartial(CSV_HEADERS.STATUS.header)
  const isPreorderOnlyIndex = getIndexPartial(CSV_HEADERS.IS_PREORDER_ONLY.header)
  const packTypeIndex = getIndexPartial(CSV_HEADERS.PACK_TYPE.header)
  const packTypeActionIndex = getIndexPartial(CSV_HEADERS.PACK_TYPE_ACTION.header)
  const productTypeIndex = getIndexPartial(CSV_HEADERS.PRODUCT_TYPE.header)
  const productTypeActionIndex = getIndexPartial(CSV_HEADERS.PRODUCT_TYPE_ACTION.header)
  const productVolumeIndex = getIndexPartial(
    CSV_HEADERS.PRODUCT_VOLUME.header
  )
  const productVolumeActionIndex = getIndexPartial(
    CSV_HEADERS.PRODUCT_VOLUME_ACTION.header
  )
  const isOgNonComplaintIndex = getIndexPartial(
    CSV_HEADERS.IS_OG_NON_COMPLAINT.header
  )
  const isNotAdvertisableIndex = getIndexPartial(
    CSV_HEADERS.IS_NOT_ADVERTISABLE.header
  )
  const rows = results.slice(1)
  
  try {
    const tableData = rows.map(row => {
      const product = productMap[row[skuIndex]] || {}
      return {
        sku: { id: row[skuIndex], name: product.name },
        barcodes: row[barcodesIndex],
        barcodesAction: row[barcodesActionIndex],
        productName: { name: product.name, unit: product.displayUnit },
        sellerSku: row[sellerSkuIndex],
        name: row[nameIndex],
        displayUnit: row[displayUnitIndex],
        brandId: row[brandIdIndex],
        nutritionalData: row[nutritionalDataIndex],
        storageInformation: row[storageInformationIndex],
        preparation: row[preparationIndex],
        bulkOrderThreshold: row[globalBulkIndex],
        // images: row[imagesIndex],
        // imagesAction: row[imagesActionIndex],
        keyInformation: row[keyInformationIndex],
        keyInformationAction: row[keyInformationActionIndex],
        additionalInformation: row[additionalInformationIndex],
        additionalInformationAction: row[additionalInformationActionIndex],
        ingredients: row[ingredientsIndex],
        ingredientsAction: row[ingredientsActionIndex],
        dietaryAttributes: row[dietaryAttributesIndex],
        dietaryAttributesAction: row[dietaryAttributesActionIndex],
        countryOfOrigin: row[countryOfOriginIndex],
        vendorCode: row[vendorCodeIndex],
        vendorCodeAction: row[vendorCodeActionIndex],
        primaryCategoryId: {
          id: row[primaryCategoryIndex],
          name: categoryMap[row[primaryCategoryIndex]],
        },
        secondaryCategoryIds: row[secondaryCategoryIndex]
          ? row[secondaryCategoryIndex]
              .replace(/ /g, '')
              .split(',')
              .map(id => ({ id, name: categoryMap[id] }))
          : [],
        secondaryCategoryIdsAction: row[secCategoryActionIndex],
        handlingDays: row[handlingDaysIndex],
        tagId: { id: row[tagIdIndex], name: tagMap[row[tagIdIndex]] },
        tagIdName: tagMap[row[tagIdIndex]],
        tagIdAction: row[tagIdActionIndex],
        status: row[statusIndex],
        isPreorderOnly: row[isPreorderOnlyIndex],
        packType: row[packTypeIndex],
        packTypeAction: row[packTypeActionIndex],
        productType: row[productTypeIndex],
        productTypeAction: row[productTypeActionIndex],
        productVolume: row[productVolumeIndex],
        productVolumeAction: row[productVolumeActionIndex],
        isOgNonComplaint: row[isOgNonComplaintIndex],
        isNotAdvertisable: row[isNotAdvertisableIndex]
      }
    })
    return tableData
  } catch (e) {
    console.error('Error while generating review data', e)
    return []
  }
}

export default class StoreSpecificAttributeReview extends Component {
  constructor() {
    super()
    this.state = {
      products: {},
      categories: {},
      tags: {},
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    Promise.all([
      this.getCategories(this.props.results),
      this.getProductsBatch(this.props.results),
      this.getTags(this.props.results),
    ])
      .catch(e => {
        this.setState({
          error: e,
        })
        if (e.code === UNAUTHORISED_STATUS) {
          throw e
        }
      })
      .finally(() =>
        this.setState({
          loading: false,
        })
      )
  }

  getCategories(skus) {
    const primaryCategoryIdIndex = skus[0].indexOf('primary_category_id')
    const secondaryCategoryIdIndex = skus[0].indexOf('secondary_category_ids')
    const primaryIdsList = primaryCategoryIdIndex !== -1 ? skus.slice(1).map((row) => row[primaryCategoryIdIndex]) : []
    const secondaryIdsList = secondaryCategoryIdIndex !== -1 ? skus.slice(1).map((row) => row[secondaryCategoryIdIndex]) : []
    const combinedcategoryIds = primaryIdsList.concat(secondaryIdsList);
    const categoryIds = [ ...new Set(combinedcategoryIds)];
    if(!categoryIds.length) { return null }
    const categoryApi = new API({ url: '/catalogue-service/category' })
    return categoryApi
      .get({ paginate: 'false', hierarchy: false, id: categoryIds })
      .then(response => {
        this.setState({
          categories: buildCategoryMapping(response.data.category || []),
        })
      })
  }

  getProductsBatch(results = []) {
    const primarySkus = results.slice(1).map(row => row[0])
    const skus = uniq(primarySkus)
    if (skus.length) {
      return Promise.all(chunk(skus, BATCHING_SIZE).map(this.getProducts)).then(
        responses => {
          const products = responses.reduce((acc, response) => {
            const prds = response.data ? response.data.product || [] : []
            return [...acc, ...prds]
          }, [])
          this.setState({
            products: buildProductNameMap(products) || {},
          })
        }
      )
    }
    return Promise.resolve()
  }

  getProducts(skus) {
    const productApi = new API({
      url: `/catalogue-service/product`,
    })
    if (skus.length) {
      return productApi.get({ paginate: 'false', clientId: skus.slice() })
    }
    return Promise.resolve()
  }

  getTags(skus) {
    const tagIdIndex = skus[0].indexOf('tag_id')
    const idsList = tagIdIndex!== -1 ? skus.slice(1).map((row) => row[tagIdIndex]) : []
    const tagIds = [...new Set(idsList)];
    if(!tagIds.length) { return null }
    const tagApi = new API({ url: '/catalogue-service/tag' })
    return tagApi
      .get({ paginate: 'false', hierarchy: false, id: tagIds })
      .then(response => {
        this.setState({
          tags: buildTagMap(response.data.tag || []),
        })
      })
  }

  // getRowErrors :: Array, Object -> Boolean
  getRowErrors(tableData, csvHeaders, fileHeaders) {
    return tableData.map(row => {
      return !fileHeaders.reduce((isValid, h) => {
        const key = (h || '').toUpperCase()
        const { validate = () => true, header, isEmpty } = key
          ? csvHeaders[key]
          : {}
        const field = snakeToCamel(header)
        return (
          isValid &&
          ((isEmpty ? isEmpty(row[field]) : testEmpty(row[field])) ||
            validate(row[field], row[field.replace(/Action$/, '')]))
        )
      }, true)
    })
  }

  isValidData(csvHeaders, column, row) {
    const key = (column || '').toUpperCase()
    const { validate = () => true, header, isEmpty } = key
      ? csvHeaders[key]
      : {}
    const field = snakeToCamel(header)
    return (
      (isEmpty ? isEmpty(row[field]) : testEmpty(row[field])) ||
      validate(row[field], row[field.replace(/Action$/, '')])
    )
  }

  getSelectedHeaders(csvHeaders, fileHeaders) {
    return fileHeaders.map(h => {
      const key = (h || '').toUpperCase()
      return key ? csvHeaders[key] : {}
    })
  }

  getTableHeaders(csvHeaders, fileHeaders) {
    return this.getSelectedHeaders(csvHeaders, fileHeaders).map(obj => {
      return obj.displayHeader
    })
  }

  getColumnFields(csvHeaders, fileHeaders) {
    return this.getSelectedHeaders(csvHeaders, fileHeaders).map(obj => {
      return {
        header: obj.header,
        field: snakeToCamel(obj.header),
        render: obj.render,
      }
    })
  }

  render() {
    const { results = [], jobName, actions } = this.props
    const { error, loading, categories, products, tags } = this.state
    const tableData = getTableData(results, jobName, categories, products, tags)
    const csvHeaders = JobConfig[jobName].csvHeaders
    const uploadedHeaders = results[0] || []
    const givenHeaders = uploadedHeaders.reduce((headers, h) => {
      if (csvHeaders[toUpper(h) + '_NAME']) {
        return [...headers, h, csvHeaders[toUpper(h) + '_NAME'].header]
      }
      return [...headers, h]
    }, [])
    const fileHeaders = [
      givenHeaders[0],
      csvHeaders.PRODUCT_NAME.header,
      ...givenHeaders.slice(1),
    ]
    const headers = this.getTableHeaders(csvHeaders, fileHeaders)
    const validationErrors = this.getRowErrors(
      tableData,
      csvHeaders,
      fileHeaders
    )
    const columnFields = this.getColumnFields(csvHeaders, fileHeaders)
    const isValidDataPartial = partial(this.isValidData, csvHeaders)
    return loading ? (
      <Loader />
    ) : (
      <div className="review-table">
        {error && <div className="error">{getMessage('error.server')}</div>}
        {!loading && validationErrors.some(Boolean) && (
          <div className="error">
            {getMessage('category.bulk-upload.error')}
          </div>
        )}
        <div className="table-container">
          <Table tableDynamic={false}>
            <Header items={headers} />
            {tableData.map((row, index) => (
              <Row
                key={`${row[index]}-${index}`}
                className={
                  !loading && validationErrors[index] ? 'row-error' : ''
                }
              >
                {columnFields.map(col => {
                  const { header, field, render } = col
                  return (
                    <Cell
                      key={field}
                      className={
                        !loading && !isValidDataPartial(header, row)
                          ? `${field} cell-error`
                          : field
                      }
                    >
                      {render ? render(row[field]) : row[field]}
                    </Cell>
                  )
                })}
              </Row>
            ))}
          </Table>
        </div>
        {actions(validationErrors.some(Boolean))}
      </div>
    )
  }
}
