const data = {
  'googleMaps.zeroResults.error': 'Address not found',

  'order.filters.batch.heading': 'Batch ID',
  'order.filters.batch.placeholder': 'Enter picking queue batch ID',
  'order.filters.picklist.heading': 'Picklist ID',
  'order.filters.picklist.placeholder': 'Enter picklist ID',
  'order.filters.packlistZone.heading': 'Packlist Zone',
  'order.filters.packlistZone.placeholder': 'Select packlist zone',
  'order.filters.packlistStatus.heading': 'Packlist Status',
  'order.filters.packlistStatus.placeholder': 'Select packlist status',
  'order.filters.referenceNumber.heading': 'Order number',
  'order.filters.campaignId.heading': 'Campaign ID',
  'order.filters.referenceNumber.placeholder': 'Enter an order number here',
  'order.filters.campaignId.placeholder': 'Enter a campaign ID here',
  'order.filters.status.heading': 'Status',
  'order.filters.status.placeholder': 'Choose order status',
  'order.filters.submitText': 'Search',
  'order.filters.clearFiltersText': 'Clear All',
  'order.filters.download.summary.text': 'Download Orders CSV',
  'order.filters.download.b2b.delivery.notes': 'Download B2B Delivery Notes',
  'order.filters.download.pdf': 'Download PDF',
  'order.filters.download.b2b.noOrder': 'There is no order',

  'order.filters.preorder': 'Preorder',
  'order.emptyList.message':
    'Your orders will be shown here. Start selling now.',
  'order.helpItems.title': 'How to get orders?',
  'order.helpItems.seo.title': 'Search Engine Optimization',
  'order.helpItems.seo.description':
    'You will be able to target your customers using email and SMS after the import, which will potentially increase your revenue.',
  'order.helpItems.rcm.title': 'Repeat Customer Marketing',
  'order.helpItems.rcm.description':
    'You can create a discount coupon for your customers and promote the online purchase.',
  'order.helpItems.nca.title': 'New Customer Acquisition',
  'order.helpItems.nca.description':
    'You can put up a banner at a prominent place in your physical store. Include the promotional offer to create buzz.',
  'order.table.header.referenceNumber': 'Order no.',
  'order.table.referenceNumber.prefix': 'ORD ',
  'order.table.clientId.prefix': '#',
  'order.details.invoice.order': 'Invoice ',
  'order.table.header.customer': 'Customer',
  'order.table.header.placedTime': 'Placed time',
  'order.table.header.expectedTime': 'Order Slot',
  'order.table.header.amount': 'Amount',
  'order.table.header.status': 'Status',
  'order.table.text.fillrate': 'Fill Rate: ',
  'order.table.header.completionTime': 'Completion time',
  'order.table.header.actions': 'Actions',
  'order.table.itemCount': '{count} Items',
  'order.table.pendingAmount': '{currency}{amount} Pending',
  'order.table.refundAmount': '{currency}{amount} Refund',
  'order.gridItem.status.title': 'Order status',
  'order.status.completed': 'Completed',
  'order.status.cancelled': 'Cancelled',
  'order.status.pending': 'Pending',
  'order.status.picked': 'Picked',
  'order.status.checked': 'Checked',
  'order.status.packed': 'Packed',
  'order.status.processing': 'Processing',
  'order.status.readyToPickup': 'Ready To Pick Up',
  'order.verificationStatus.pending': 'Pending',
  'order.verificationStatus.passed': 'Passed',
  'order.verificationStatus.failed': 'Failed',
  'order.verificationStatus.unverified': 'Unverified',
  'order.action.viewDetails': 'View Details',
  'order.action.complete': 'Complete Order',
  'order.action.packed': 'Pack Order',
  'order.action.viewInvoice': 'View Invoice',
  'order.action.printInvoice': 'Print Invoice',
  'order.action.viewPayments': 'Payment Details',
  'order.action.return': 'Return Order',
  'order.status.picking': 'Picking',
  'order.status.partiallypicked': 'Partially picked',
  'order.status.checking': 'Checking',
  'order.status.packing': 'Packing',
  'order.status.dispatched': 'Dispatched',
  'order.status.returned': 'Returned',
  'order.details.packing.heading': 'Packing Details',
  'order.packing.type': 'Type',
  'order.packing.labels': 'Labels',
  'order.packing.smallBags': 'Small Bags',
  'order.packing.largeBags': 'Large Bags',
  'order.packing.frozenBoxes': 'Frozen Boxes',
  'order.packing.crates': 'Crates',
  'order.packing.smallBoxes': 'Small Boxes',
  'order.packing.mediumBoxes': 'Medium Boxes',
  'order.packing.largeBoxes': 'Large Boxes',
  'order.type': 'Order Type',
  'order.business': 'B2B',
  'order.invoice.id': 'Invoice ID',
  'order.personal': 'B2C',
  PENDING: 'Pending',
  PICKING: 'Picking',
  CHECKING: 'Checking',
  PICKED: 'Picked',
  CHECKED: 'Checked',
  PACKED: 'Packed',
  DISPATCHED: 'Dispatched',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  RETURNED: 'Returned',
  PACKING: 'Packing',
  PROCESSING: 'Processing',

  DELIVERY_ORDER_STATUS_PENDING: 'Pending',
  DELIVERY_ORDER_STATUS_PROCESSING: 'Processing',
  DELIVERY_ORDER_STATUS_PACKING: 'Packing',
  DELIVERY_ORDER_STATUS_PACKED: 'Packed',
  DELIVERY_ORDER_STATUS_DISPATCHED: 'Dispatched',
  DELIVERY_ORDER_STATUS_COMPLETED: 'Completed',
  DELIVERY_ORDER_STATUS_CANCELLED: 'Cancelled',
  DELIVERY_ORDER_STATUS_PICKING: 'Picking',
  DELIVERY_ORDER_STATUS_PARTIALLY_PICKED: 'Partially Picked',
  DELIVERY_ORDER_STATUS_PICKED: 'Picked',
  DELIVERY_ORDER_STATUS_CHECKING: 'Checking',
  DELIVERY_ORDER_STATUS_CHECKED: 'Checked',
  DELIVERY_ORDER_STATUS_RETURNED: 'Returned',
  PACKLIST_STATUS_PENDING: 'Pending',
  PACKLIST_STATUS_PICKING: 'Picking',
  PACKLIST_STATUS_PACKING: 'Packing',
  PACKLIST_STATUS_PACKED: 'Packed',
  PACKLIST_STATUS_CANCELLED: 'Cancelled',
  PACKLIST_STATUS_RECEIVED: 'Received',
  PACKLIST_STATUS_NOT_RECEIVED: 'Not Received',

  STARTED: 'Started',
  CHARGED: 'CHARGED',
  FAILED: 'Failed',
  REFUNDED: 'Refunded',
  'PARTIALLY-REFUNDED': 'Partially Refunded',
  'PENDING-REFUND': 'Pending Refund',
  ORDER_INITIATED: 'Order Initiated',
  ORDER_PLACED: 'Order Placed',
  GIFT_STATUS_COMPLETED: 'Completed',
  ORDER_SENT: 'Order Sent',
  PAY_AT_ORDER: 'Pay at order',

  'order.details.heading': 'Order',
  'order.details.invoice.buttonText': 'View invoice',
  'order.details.invoice.printButtonText': 'View/Print invoice',
  'order.details.cancel.optionText': 'Cancel order',
  'order.details.complete.optionText': 'Complete order',
  'order.details.returm.optionText': 'Return order',
  'order.details.dispute.optionText': 'File Dispute',
  'order.details.cancellation.title': 'Are you sure?',
  'order.details.cancellation.message': 'This action will cancel this order',
  'order.details.cancellation.cancelText': 'Cancel',
  'order.details.cancellation.confirmText': 'Confirm',
  'order.details.packed.title': 'Are you sure?',
  'order.details.completion.title': 'Are you sure?',
  'order.details.packed.message':
    'This action will move the order to packed status',
  'order.details.completion.message': 'This action will complete this order',
  'order.details.return.title': 'Are you sure?',
  'order.details.return.message': 'This action will return the order',
  'order.details.pending.title': 'Are you sure?',
  'order.details.pending.message':
    'This action will move the order to PENDING status',
  'order.action.moveToPending': 'Move to pending',
  'order.action.editPackedOrder': 'Edit Packed Order',
  'order.details.packed.optionText': 'Pack Order',
  'order.details.packed.cancelText': 'Cancel',
  'order.details.packed.confirmText': 'Confirm',
  'order.details.completion.cancelText': 'Cancel',
  'order.details.completion.confirmText': 'Confirm',
  'order.details.print.optionText': 'Print Totes Label',
  'order.details.print.title': 'Are you sure?',
  'order.details.print.message': 'This action will print totes label',
  'order.details.print.cancelText': 'Cancel',
  'order.details.print.confirmText': 'Confirm',
  'order.details.print.text': 'Print',
  'order.details.device.desktop': 'Desktop',
  'order.details.device.ios': 'iOS',
  'order.details.device.android': 'Android',
  'order.details.productSearch.placeholder': 'Search a product to add',
  'order.details.removeItem.title': 'Remove this item?',
  'order.details.removeItem.message':
    'Press confirm to remove this item from the order',
  'order.details.removeItem.cancelText': 'Cancel',
  'order.details.removeItem.confirmText': 'Confirm',
  'order.details.itemsTable.title': 'Items in order',
  'order.details.itemsTable.header.image': 'Image',
  'order.details.itemsTable.header.name': 'Name',
  'order.details.itemsTable.header.quantity': 'Original Quantity',
  'order.details.itemsTable.header.picked': 'Final Quantity',
  'order.details.itemsTable.header.quantity.abbreviated': 'Qty',
  'order.details.itemsTable.header.originalQuantity': 'Original Qty',
  'order.details.itemsTable.header.finalQuantity': 'Final Qty',
  'order.details.itemsTable.header.mrp': 'Price',
  'order.details.itemsTable.header.discount': 'Discount',
  'order.details.itemsTable.header.amount': 'Amount',
  'order.details.itemsTable.header.clientItemId': 'Client Item Id',
  'order.details.itemsTable.clearingMessage':
    'All items are removed. Order will be cancelled.',
  'order.details.itemsTable.emptyMessage': 'There are no items in this order.',
  'order.details.itemsTable.actions.confirmText': 'Done',
  'order.details.itemsTable.actions.cancelText': 'Cancel',
  'order.details.itemsTable.itemsInOrder.unavailabelText': 'Item unavailable',
  'order.details.errors.onSave': 'An error occurred. Click here to retry ⟳',
  'order.details.errors.onLoad':
    'Something went wrong. Click here to try again. ⟳',
  'order.details.packlist.fetch.error.title': 'Server error',
  'order.details.packlist.fetch.error.message': 'Unable to fetch packlist data',
  'order.details.edit-packed.heading': 'Packed Order Editing',
  'order.details.noPacklist': 'There are no packlist for this order.',
  'order.details.packages.multi-text.label': 'Packages',
  'order.details.packages.multi-text.placeholder': 'Enter package label',
  'order.details.edit-packed.error': 'Something went wrong',
  'order.details.edit-packed.error.closeDialog': 'close',
  'order.details.edit-packed.error.dialog.label': 'Error',
  'order.details.packlist.invalidLabel': 'Invalid Label',
  'order.details.summary.pendingAmount': 'Pending amount',
  'order.details.summary.refundAmount': 'Refund amount',
  'order.details.summary.totalAmount': 'Total amount',
  'order.details.summary.totalOriginalAmount': 'Total Original amount',
  'order.details.summary.totalFinalAmount': 'Total Final amount',
  'order.details.summary.orderAmount': 'Order amount',
  'order.details.summary.servicefee': 'Service fee',
  'order.details.summary.offers': 'Offers',
  'order.details.summary.coupondiscount': 'Vouchers',
  'order.details.summary.shippingCharge': 'Shipping charge',
  'order.details.summary.collectionCharge': 'Collection charge',
  'order.details.summary.discount': 'Discount',
  'order.details.location.delivery.pending.title': 'Delivering to',
  'order.details.location.delivery.completed.title': 'Delivered to',
  'order.details.location.pickup.pending.title': 'Pickup Details',
  'order.details.location.pickup.completed.title': 'Pickup Details',
  'order.details.location.b2b.pending.title': 'B2B Details',
  'order.details.location.b2b.completed.title': 'B2B Details',
  'order.details.location.offline.pending.title': 'Offline Details',
  'order.details.location.offline.completed.title': 'Offline Details',
  'order.details.location.rbPreorder': 'RB Preorder Details',
  'order.details.location.delivery.notSpecified.title':
    'Order type unspecified',
  'order.details.location.option.delivery': 'Delivery',
  'order.details.location.option.pickup': 'Pickup',
  'order.details.location.actions.confirmText': 'Done',
  'order.details.location.actions.cancelText': 'Cancel',
  'order.details.location.editing.text': 'Editing address and timeslot',
  'order.details.location.address.notFound': 'Address not found',
  'order.details.customFields.title': 'Custom Fields Details',
  'order.details.location.save.error': 'Error occured while saving changes',
  'order.details.invoice.title': 'Invoice',
  'order.details.invoice.taxDisclaimer': '* All prices are tax inclusive',
  'order.details.invoice.referenceNumber': 'Order #{referenceNumber}',
  'order.details.payment.heading': 'Payment Details',
  'order.details.payment.amount': 'Amount',
  'order.details.payment.mode': 'Mode',
  'order.details.payment.card': 'Card',
  'order.details.payment.status': 'Status',
  'order.details.payment.details': 'Details',
  'order.details.payment.transactionId': 'Transaction Id',
  'order.details.payment.ref': 'Refund reference',
  'order.details.preferredDate': 'Preferred date',
  'order.details.today': 'Today',
  'order.details.tomorrow': 'Tomorrow',
  'order.details.prefferdSlot': 'Preferred Slot',
  'order.details.slot.heading': 'Order Slot',
  'order.details.slot.collection': 'Collection Slot',
  'order.details.pickup.address': 'Pickup Address: ',
  'order.details.preorder': 'Pre Ordered',
  'order.details.rbpreorder': 'RB Preorder',
  'order.details.placedTime': 'Placed Time',
  'order.details.CompletionTime': 'Completion Time',
  'order.register.payments': 'Register Payments',
  'order.heading': 'Order',
  'order.paymentmode.COD': 'COD',
  'order.paymentmode.LOYALTY': 'Loyalty',
  'order.paymentmode.MSWIPE': 'Mswipe',
  'order.paymentmode.ONLINE': 'Online',
  'order.paymentmode.PENDING': 'Pending',
  'order.paymentmode.PREPAID': 'Prepaid',
  'order.register.payments.for': 'Register payments for',
  'order.payment.paymentmode': 'Payment Method',
  'order.payment.pendingamount': 'Pending Amount',
  'order.payment.amount': 'Amount',
  'order.payment.transactionid': 'Transaction Id',
  'order.payment.submit': 'Submit',

  'order.add.text': 'Create new order',
  'order.form.add.heading': 'Place New Order',
  'order.form.customer.phone.heading': 'Customer Phone Number',
  'order.form.productSearch.heading': 'Search Product/Scan Barcode',
  'order.form.productSearch.placeholder': 'Search Product/Scan Barcode',
  'order.form.paymentMode.heading': 'Payment Option',
  'order.form.paymentMode.online': 'Online',
  'order.form.paymentMode.cash': 'Cash',
  'order.form.customer.phone.placeholder': 'Enter Phone Number',
  'order.form.submit.text': 'Place Order',
  'order.form.cancel.text': 'Cancel',
  'order.form.customer.name.heading': 'Customer Name',
  'order.form.customer.name.placeholder': 'Enter Customer Name',
  'order.form.customer.email.heading': 'Customer Email Address',
  'order.form.customer.email.placeholder': 'Enter Customer Email',
  'order.form.save.error.closeText': 'Ok',
  'order.form.search.text': 'Search',
  'order.form.customerDetails.heading': 'Enter Customer Details',
  'order.form.addProduct.heading': 'Add Products',
  'order.form.orderType.heading': 'Order Type',
  'order.form.orderType.pickup': 'PICKUP',
  'order.form.paymentMode.delivery': 'DELIVERY',
  'order.form.selectOrderType.heading': 'Select Order Type',
  'order.form.pickupLocation.heading': 'Pickup location',
  'order.form.customer.address.heading': 'Address',
  'order.form.customer.landmark.heading': 'Landmark',
  'order.form.customer.city.heading': 'City',
  'order.form.customer.pincode.heading': 'Pincode',
  'order.form.customer.address.placeholder': 'Enter address',
  'order.form.customer.landmark.placeholder': 'Enter landmark',
  'order.form.customer.pincode.placeholder': 'Enter pincode',
  'order.form.customer.city.placeholder': 'Enter city',
  'order.form.save.success.title': 'Order placed successfully!',
  'order.form.save.success.closeText': 'Ok',
  'order.form.cash.tendered': 'Cash tendered',
  'order.form.balanceAmount': 'Balance',
  'order.form.addAddress.heading': 'Add Address',
  'order.form.deliveryArea.heading': 'Select Delivery Address',
  'order.metaData.heading': 'Order Custom Fields',
  'order.metaData.enter': 'Enter',
  'order.form.date.heading': 'Select Date',
  'order.form.slot.heading': 'Select Slot',
  'order.form.slots.today': 'Today',
  'order.form.slots.tomorrow': 'Tomorrow',
  'order.form.slots.error.message': 'Select a slot',
  'order.form.addressnotserved':
    'Address not served. Please select another address or store',
  'order.payment.cod': 'COD',
  'order.payment.online': 'Online',
  'order.payment.pending': 'Pending',
  'order.payment.paid': 'Paid',
  'order.payment.refund': 'Refund',
  'order.type.picking': 'Picking',
  'order.type.pickup': 'Pickup',
  'order.type.delivery': 'Delivery',
  'order.filters.payment.heading': 'Payment Mode',
  'order.filters.payment.placeholder': 'Select payment mode',
  'order.filters.paymentStatus': 'Payment Status',
  'order.filters.paymentStatus.placeholder': 'Select payment status',
  'order.filters.orderType': 'Order Type',
  'order.filters.orderType.placeholder': 'Select order type',
  'order.filters.customer': 'Customer name',
  'order.filters.customer.placholder': 'Enter customer name',
  'order.filters.clientId.heading': 'Client Id',
  'order.filters.clientId.placeholder': 'Enter client id',
  'order.filters.slot': 'Order Slot',
  'order.filters.slot.placeholder': 'Select order slot',
  'order.select': 'Select',
  'order.filters.placedOn': 'Placed On',
  'order.filter.placedOn.placeholder': 'Date',
  'order.filters.preferredDate': 'Preferred Date',
  'order.filters.productType': 'Product Type',
  'order.filters.productType.placeholder': 'Select product type',
  'order.filters.deliveryOrderType': 'Delivery Order Type',
  'order.filters.deliveryOrderType.placeholder': 'Select delivery order type',
  'order.filters.deliveryDate.label': 'Delivery Date',
  'order.filters.deliveryOrders': 'Filter Orders',
  'order.filters.unableDownloadCSV':
    'Unable to download CSV. Please try again later.',
  'order.filters.unableDownloadPDF':
    'Unable to download PDF. Please try again later.',
  'order.filters.noOrderFound': 'No orders found for selected delivery slot',

  'order.log.itemName': 'Item Name',
  'order.log.oldQuantity': 'Old Quantity',
  'order.log.newQuantity': 'New Quantity',
  'order.log.picklist.quantity': 'Picklist Quantity',
  'order.log.orderItemId': 'Order Item ID',
  'order.log.itemId': 'Packlist Item ID',
  'order.action.order.logs.for': 'Order logs for',
  'order.actions.logs': 'View logs',
  'order.actions.trips': 'View trips',
  'order.logs.emptyMeassage': 'No order logs for this order',
  'order.log.mode': 'Mode',
  'order.log.amount': 'Amount',
  'order.log.status': 'Status',
  'order.log.user': 'by',
  'order.logs.heading': 'Order Log ',
  'order.logs.status': 'Status',
  'order.logs.orderRef': 'Ref.',
  'order.log.tripId': 'Trip Id ',
  'order.cancel': 'Cancel',
  'order.submit': 'Submit',
  'order.enter': 'Enter',
  'order.log.reason': 'Reason',
  'order.log.placedfrom': 'Placed from',
  'order.log.fponNote':
    'Amendment/Cancellation in FairPriceOn Only (BackOffice)',
  'order.log.sapNote': 'Amendment/Cancellation in SAP Only',
  'order.log.payment.changed': 'Payment Changed',
  'order.log.from': 'From',
  'order.log.to': 'To',
  'order.log.address.changed': 'Address Changed',
  'order.log.shipping.charges': 'Shipping Charges',
  'order.log.type.changed': 'Type Changed',
  'order.log.amount.changed': 'Amount Changed',
  'order.log.date.changed': 'Date Changed',
  'order.log.status.changed': 'Order Status Changed',
  'order.log.slot.changed': 'Slot Changed',
  'order.log.invoiceNumber.generated': 'Invoice Number Generated',
  'order.log.return': 'Order return',
  'order.log.transactionId': 'Transaction Id',
  'order.log.payment.initiated': 'Payment initiated',
  'order.log.payment.cancelled': 'Payment cancelled',
  'order.log.payment.completed': 'Payment completed',
  'order.log.refund.initiated': 'Refund initiated',
  'deliveryOrder.details.heading': 'Delivery Order',
  'deliveryOrder.details.action.dispatchOrder': 'Dispatch Order',
  'deliveryOrder.details.action.completeOrder': 'Complete Order',
  'deliveryOrder.details.action.cancelOrder': 'Cancel Order',
  'deliveryOrder.details.packOrder.title': 'Pick Type',
  'deliveryOrder.details.packOrder.zeroPick': 'Zero Pick',
  'deliveryOrder.details.packOrder.fullPick': 'Full Pick',
  'deliveryOrder.details.packOrder.cannotPack':
    'Packlist(s) cannot be packed because one or more packlists are still in picking/packing status.',
  'deliveryOrder.details.action.save': 'Submit',
  'deliveryOrder.details.action.cancel': 'Cancel',
  'deliveryOrder.details.dispatchOrder.title': 'Pick Type',
  'deliveryOrder.details.action.notifyCustomer.title': 'Customer Communication',
  'deliveryOrder.details.action.notifyCustomer.text':
    'Send email and SMS for this status update',
  'deliveryOrder.details.substitutedIndicator': 'Substituted SKU',
  'deliveryOrder.logs.heading': 'Delivery Order Log',
  'deliveryOrder.edit-packed.heading': 'Edit Packed Delivery Order',
  'deliveryOrder.edit-packed.overpicked.error':
    'quantity entered is more than over picking threshold',
  'deliveryOrder.edit-packed.notAllowed.error':
    'editing not allowed, please check order/packlist status',
  'deliveryOrder.deliveryNote.form.customerNote': 'Customer Note:',
  'deliveryOrder.deliveryNote.form.customerNote.placeholder':
    'Enter Customer Note',
  'deliveryOrder.deliveryPreference.heading': 'Delivery Preference',
  'deliveryOrder.deliveryPreference.customerInstruction':
    'Customer Instruction:',
  'deliveryOrder.deliveryPreference.earlyDelivery': 'Early Delivery:',
  'deliveryOrder.deliveryPreference.leaveAtDoor': 'Leave at Door:',
  'deliveryOrder.deliveryNote.form.dispatcherNote': 'Dispatcher Note:',
  'deliveryOrder.deliveryNote.form.dispatcherNote.placeholder':
    'Enter Dispatcher Note',
  'deliveryOrder.deliveryNote.form.customerInstruction': 'Customer Instruction',
  'deliveryOrder.deliveryNote.form.customerInstruction.placeholder':
    'Select Customer Instruction',
  'deliveryOrder.deliveryNote.form.customerInstruction.freeText':
    'Customer Instruction (Others)',
  'deliveryOrder.deliveryNote.form.customerInstruction.freeText.placeholder':
    'Customer Instruction (Others)',
  'deliveryOrder.deliveryNote.earlyDelivery': 'Early Delivery',
  'deliveryOrder.deliveryNote.leaveAtDoor': 'Leave at Door',
  'deliveryOrder.deliveryNote.freeText.value': 'Others',
  'deliveryOrder.deliveryNote.form.submit': 'Submit',
  'deliveryOrder.deliveryNote.form.cancel': 'Cancel',
  'deliveryOrder.packages.print.selectAll': 'Select all',
  'deliveryOrder.packages.print.clearAll': 'Clear all',
  'deliveryOrder.packages.print.selectLabelsToPrint': 'Select labels to print.',
  'deliveryOrder.packages.print.noLabel': 'No label(s)',
  'deliveryOrder.details.action.assignPicker': 'Assign Picker',
  'deliveryOrder.details.action.selectPicker': 'Select Picker',
  'deliveryOrder.details.action.assign': 'Assign',
  'deliveryOrder.details.error.manualAssignPicker':
    'Selected Picker cannot be manual assigned because of its assigned zones.',
  'deliveryOrder.details.warning.blockManualAssign':
    'This order contains marketplace packlists only. The picker will be automatically assigned.',
  'deliveryOrder.details.warning.pickerCannotBeAssigned':
    'Picker cannot be assigned',
  'deliveryOrder.details.deliverySlot': 'Delivery slot',
  'deliveryOrder.details.redirectToSaleOrder':
    'Refer to sale order page for payment details',
  'deliveryOrder.bulkOperations.reset': 'Reset',

  'saleOrder.table.header.saleOrderNumber': 'Sale Order no.',
  'saleOrder.table.header.customer': 'Customer',
  'saleOrder.table.header.status': 'Status',
  'saleOrder.table.header.amount': 'Amount',
  'saleOrder.table.header.placedTime': 'Placed Time',
  'saleOrder.table.header.completedTime': 'Completed Time',
  'saleOrder.filters.saleOrder.label': 'Sale Order number',
  'saleOrder.filters.saleOrder.placeholder': 'Enter a sale order number here',
  'saleOrder.table.header.actions': 'Actions',
  'saleOrder.listing.emptyPage': 'There is no sale order here at the moment',
  'deliveryOrder.details.verificationStatus': 'Verification Status',
  'saleOrder.details.heading': 'Sale Order',
  'saleOrder.details.mkpLabel': 'MKP',
  'saleOrder.details.subtotal': 'Subtotal',
  'saleOrder.details.total': 'Total',
  'saleOrder.details.payment.amount': 'Amount',
  'saleOrder.details.payment.mode': 'Mode',
  'saleOrder.details.payment.card': 'Card',
  'saleOrder.details.payment.status': 'Status',
  'saleOrder.details.payment.details': 'Details',
  'saleOrder.details.payment.heading': 'Payment Details',
  'saleOrder.details.summary.serviceFee': 'Service Fee',
  'saleOrder.details.summary.vouchers': 'Vouchers',
  'saleOrder.details.summary.offers': 'Offers',
  'saleOrder.details.status': 'Status',
  'saleOrder.details.placedFrom': 'Placed From',
  'saleOrder.details.firstAmendment': '1st Amendment',
  'saleOrder.details.secondAmendment': '2nd Amendment',
  'customer.heading': 'Customers',
  'customer.helpItems.message':
    'You will see your customers’ details here. Get customers now.',
  'customer.helpItems.title': 'How to get Customers?',
  'customer.helpItems.iec.title': 'Import your existing customers',
  'customer.helpItems.iec.description':
    'You will be able to target your customers using email and SMS after the import, which will potentially increase your revenue.',
  'customer.helpItems.rpo.title': 'Run a promotional offer',
  'customer.helpItems.rpo.description':
    'You can create a discount coupon for your customers and promote the online purchase.',
  'customer.helpItems.om.title': 'Offline Marketing',
  'customer.helpItems.om.description':
    'You can put up a banner at a prominent place in your physical store. Include the promotional offer to create buzz.',
  'customer.import.text': 'Import Customers',
  'customer.modal.import.text': 'Import Customers',
  'customer.import.successText':
    'CSV File Uploaded Successfully! Customers will be added and appear after 15 minutes',
  'customer.tags.import.successText':
    'CSV File Uploaded Successfully! Tags will be added and appear after 15 minutes',
  'customer.table.header.image': 'Image',
  'customer.table.header.name': 'Name',
  'customer.table.header.contact': 'Contact',
  'customer.table.header.joinDate': 'Join Date',
  'customer.table.header.lastPurchased': 'Last Purchased',
  'customer.table.header.orders': 'Orders',
  'customer.table.header.actions': 'Actions',
  'customer.table.header.status': 'Status',
  'customer.table.actions.enable': 'Enable',
  'customer.table.actions.disable': 'Disable',
  'customer.table.actions.enable.information':
    'You want to enable this customer ?',
  'customer.table.actions.disable.information':
    'You want to disable this customer ?',
  'customer.details.header.joined': 'Joined',
  'customer.details.header.lastPurchase': 'Last Purchase',
  'customer.table.header.joinDateLastPurchase': 'Join Date / Last Purchase',
  'customer.enterCustomerEmail': "Enter Customer's Email",
  'customer.searchByEmail': 'Search By Email',
  'customer.searchByClientId': 'Search By Id',
  'customer.enterCustomerPhone': "Enter Customer's Phone",
  'customer.enterClientId': "Enter Client's Id",
  'customer.searchByPhone': 'Search By Phone',
  'customer.enterCustomerUid': "Enter Customer's UID",
  'customer.searchByUid': 'Search By UID (Requires vpn)',
  'customer.enterJwcCardNumber': 'Enter JWC card number',
  'customer.searchByJwcCardNumber': 'Search By Jwc card (Requires vpn)',
  'customer.enterAssocName': 'Enter associate name',
  'customer.searchByAssocName': 'Associate name (eg: linkplus)',
  'customer.enterAssocValue': 'Enter associate value',
  'customer.searchByAssocValue':
    'Associate value (eg: 8109xxx), (Requires vpn)',
  'customer.filters.submittext': 'Search',
  'customer.search.submittext': 'Search by association',
  'customer.filters.clearFiltersText': 'Clear All',
  'customer.details.pages.basicinfo': 'Basic Info',
  'customer.details.pages.address': 'Address',
  'customer.details.pages.phoneemail': 'Phone / Email',
  'customer.details.pages.otherdetails': 'Other Details',
  'customer.details.pages.loyaltyJwc': 'Jwc',
  'customer.details.pages.loyaltyDc': 'Digital Club',
  'customer.details.pages.developer': 'Developer',
  'customer.details.purchase.emptyText':
    'Currently there are no orders for this customer',
  'customer.joinedOn': 'Joined On',
  'cutomer.joinedOn.placeholder': 'Date',
  'customer.updatedAt': 'Updated On',
  'customer.details.pages.basicinfo.totalorders': 'Total Orders',
  'customer.details.pages.basicinfo.purchasesworth': 'Purchase worth',
  'customer.details.pages.basicinfo.joined': 'Joined On',
  'customer.details.pages.basicinfo.lastPurchase': 'Last Purchase',
  'customer.details.pages.address.addAddress': 'Add Address',
  'customer.details.pages.address.addresseeName': 'Contact Name',
  'customer.details.pages.address.phoneNumber': 'Contact Number',
  'customer.details.pages.address.floorNumber': 'Floor Number',
  'customer.details.pages.address.unitNumber': 'Unit Number',
  'customer.details.pages.phonemail.addPhoneNumber': 'Add Number',
  'customer.details.pages.phonemail.mailId': 'Email Id',
  'customer.details.pages.phonemail.addEmailId': 'Add Email ID',
  'customer.details.pages.address.viewOnMap': 'View On Map',
  'customer.details.delete': 'Delete',
  'customer.details.address.deleted': 'Address has been successfully deleted',
  'customer.details.phone.deleted':
    'Phone number has been successfully deleted',
  'customer.details.order.status': 'Order Status',
  'customer.details.email.send':
    'An email will be sent to the customer shortly.',
  'customer.details.call.call': 'A call will be made to the customer shortly',
  'customer.details.sms.send': 'An SMS will shortly be sent to the customer.',
  'customer.details.email.added': 'Email added successfully',
  'customer.details.sendMessage': 'Send Message',
  'customer.details.sendEmail': 'Send email',
  'customer.details.call': 'Call',
  'customer.details.address.added': 'Address added successfully',
  'customer.details.phone.added': 'Phone number added successfully',
  'customer.details.email.deleted': 'Email id has been successfully deleted',
  'customer.details.order.purchase.history': 'Purchase History',
  'customer.details.address.addAddress': 'Add Address',
  'customer.details.address.landmark': 'Landmark',
  'customer.details.address.pincode': 'Pincode',
  'customer.details.address.postalCode': 'Postal Code',
  'customer.details.address.buildingName': 'Building Name',
  'customer.details.address.city': 'City',
  'customer.details.submitText.submit': 'Submit',
  'customer.details.email.email': 'Email',
  'customer.details.phone.phoneNumber': 'Phone Number',
  'customer.details.call.option.lateDelivery': 'Late Delivery',
  'customer.details.call.option.orderEdit': 'Order Edit',
  'customer.details.call.option.paymentReminder': 'Payment Reminder',
  'customer.details.call.option.rescheduling': 'Rescheduling',
  'customer.details.call.option.orderConfirmation': 'Order Confirmation',
  'customer.details.call.option.missedCallback': 'Missed Callback',
  'customer.details.call.option.addressClarification': 'Address Clarification',
  'customer.details.call.option.callToInternalEmployees':
    'Call To Internal Employees',
  'customer.details.call.option.tripEnquiry': 'Trip Enquiry',
  'customer.details.call.option.surveyOnceShopped': 'Survey - Once Shopped',
  'customer.details.call.option.retention': 'Rentention',
  'customer.details.call.submitText.call': 'Call',
  'customer.details.call.option.other': 'Other',
  'customer.details.mail.subject': 'Subject',
  'customer.details.mail.body': 'Body',
  'customer.details.mail.submitText.send': 'Send',
  'customer.details.sms.message': 'Message',
  'customer.details.sms.submitText.send': 'Send',

  'customer.details.rewards': 'Rewards',
  'customer.details.rewards.serverError':
    'Something went wrong! please try again later.',
  'customer.details.rewards.luckyDraws': 'Lucky Draws',
  'customer.details.rewards.campaignLabel': 'Campaign',
  'customer.details.rewards.campaignPlaceholder': 'Please select',
  'customer.details.luckyDraws.chancesEarned': 'Chances earned',
  'customer.details.luckyDraws.prizesWon': 'Prizes won',
  'customer.details.luckyDraws.emptState': 'No data yet',
  'customer.details.luckyDraws.optIn': 'LuckyDrawOptIn',
  'customer.details.luckyDraws.optOut': 'LuckyDrawOptOutAt',

  'customer.details.challenge': 'Challenges',
  'customer.details.challenge.acceptedat': 'Accepted at',
  'customer.details.challenge.completedat': 'Completed at',
  'customer.details.challenge.orderDetails': 'View details',
  'customer.details.challenge.noOrders': 'Customer has not placed any orders.',

  'customer.details.rewards.rewardsCatalogue': 'Rewards Catalogue',
  'customer.details.rewards.refund': 'Refund',
  'customer.details.rewards.refundConfirmation': 'Confirmation: Refund',
  'customer.details.rewards.applicableToReward': 'Applicable to Reward',
  'customer.details.rewards.errorMsg.unSupportedRewardsType':
    'Not refundable due to unsupported reward type',
  'customer.details.rewards.refundSuccessMessage':
    'Refund processed successfully!',
  'customer.details.rewards.refund.validationMsg': 'This field is required',
  'customer.details.rewards.refundInfo':
    'This will remove the reward from user`s account and the Linkpoints will be refunded accordingly. You will not be able to undo this action.',

  'customer.address.delete.prompt.title': 'Remove this address?',
  'customer.phone.delete.prompt.title': 'Are you sure?',
  'customer.phone.delete.prompt.message': 'This action will remove this number',
  'customer.email.delete.prompt.title': 'Are you sure?',
  'customer.email.delete.prompt.message':
    'This action will remove this email ID',
  'customer.address.modal.heading': 'Address',
  'customer.cancel.message': 'No',
  'customer.confirm.message': 'Yes',
  'customer.address.success': 'Success',
  'customer.address.saved': 'Address updated',
  'customer.address.okay': 'Okay',
  'customer.action.reset.password': 'Reset Password',
  'customer.save.success.title': 'Password successfully changed!',
  'customer.save.success.closeText': 'Okay',
  'customer.reset.password.title': 'Are you sure ?',
  'customer.reset.password.message': "You want to reset customer's password ?",
  'customer.reset.password.cancelText': 'No, Cancel',
  'customer.reset.password.confirmText': 'Yes, Do it!',
  'customer.details.page.address.placeholder': 'Enter address',
  'customer.details.address.landmark.placeholder': 'Enter landmark',
  'customer.details.address.pincode.placeholder': 'Enter pincode',
  'customer.details.address.city.placeholder': 'Enter city',
  'customer.details.address.postalCode.placeholder': 'Enter postal code',
  'customer.details.address.unitNumber.placeholder':
    'Enter unit number (optional)',
  'customer.details.address.floorNumber.placeholder':
    'Enter floor number (optional)',
  'customer.details.page.addresseeName.placeholder': 'Enter contact name',
  'customer.details.page.phoneNumber.placeholder': 'Enter contact number',
  'customer.details.address.buildingName.placeholder': 'Enter building name',

  'customer.emailWidget.form.title': 'Send email to',
  'customer.emailWidget.form.subject.heading': 'Subject',
  'customer.emailWidget.form.subject.placeholder': '',
  'customer.emailWidget.form.body.heading': 'Body',
  'customer.emailWidget.form.body.placeholder': '',
  'customer.emailWidget.form.submitText': 'Submit',
  'customer.callWidget.form.title': 'Call',
  'customer.smsWidget.form.title': 'Send sms to',
  'customer.callWidget.form.reason.heading': 'Reason',
  'customer.callWidget.form.reason.placeholder': 'Please select an option',
  'customer.callWidget.form.reason.lateDelivery': 'Late delivery',
  'customer.callWidget.form.reason.orderEdit': 'Order edit',
  'customer.callWidget.form.reason.paymentReminder': 'Payment reminder',
  'customer.callWidget.form.reason.rescheduling': 'Rescheduling',
  'customer.callWidget.form.reason.orderConfirmation': 'Order confirmation',
  'customer.callWidget.form.reason.missedCallback': 'Missed call back',
  'customer.callWidget.form.reason.addressClarifiation': 'Address clarifiation',
  'customer.callWidget.form.reason.internalCall': 'Call to internal employees',
  'customer.callWidget.form.reason.tripEnquiry': 'Trip Enquiry Call',
  'customer.callWidget.form.reason.survey': 'Survey - Once Shopped',
  'customer.callWidget.form.reason.retention': 'Retention',
  'customer.callWidget.form.reason.other': 'Other',
  'customer.callWidget.form.otherReason.heading': 'Other reason',
  'customer.callWidget.form.otherReason.placeholder': '',
  'customer.callWidget.form.submitText': 'Submit',
  'customer.entityMetaData.successTitle': 'Success',
  'customer.entityMetaData.successInformation':
    'Your settings have been successfully saved',
  'customer.entityMetaData.ok': 'Okay',
  'customer.entityMetaData.errorTitle': 'Error',
  'customer.entityMetaData.heading': 'Custom Fields',
  'customer.entityMetaData.enter': 'Enter',
  'customer.entityMetaData.submit': 'Save',
  'customer.entityMetaData.cancel': 'Cancel',
  'customer.contact.details.default': 'Default',
  'customer.contact.details.set.default': 'Set as default',
  'customer.contact.details.delete': 'Delete',
  'customer.orderCleanup.bulkDispatch.csvUploadLabel':
    'Drag and drop CSV file here',
  'customer.orderCleanup.bulkDispatch.csvUploadLabel.or': 'or',
  'customer.orderCleanup.bulkDispatch.csvUploadLabel.chooseFileFromPC':
    'Choose file from computer',
  'customer.orderCleanup.bulkDispatch.invalidCsv': 'Invalid CSV',
  'customer.entityMetaData.segmentIo.error':
    'Unable to retrieve SegmentIo Tag Names.',
  'customer.entityMetaData.segmentIo.header': 'Segment IO Tag Names',
  'customer.details.jwc.jwcoperations.fromuid.inputlabel': 'From uid',
  'customer.details.jwc.jwcoperations.touid.inputlabel': 'To uid',
  'customer.details.jwc.jwcoperations.fromuid.placeholder':
    'Uid of account you want to shift from',
  'customer.details.jwc.jwcoperations.touid.placeholder':
    'Uid of account you want to shift to',
  'customer.details.jwc.jwcoperations.shiftButton': 'Shift membership',

  'operations.dashboard.heading': 'Operations dashboard',
  'operations.dashboard.sales': 'Sales',
  'operations.dashboard.customerAcquired': 'Customers acquired',
  'operations.dashboard.customersTransacted': 'Customers transacted',
  'operations.dashboard.orders': 'Orders',
  'operations.dashboard.deliveryOrders': 'Delivery Orders',
  'operations.dashboard.saleOrders': 'Sale Orders',
  'operations.dashboard.yesterday': 'Yesterday',
  'operations.dashboard.weeklyAvg': 'Weekly avg.',
  'operations.dashboard.lastWeek': 'Same day last week',
  'operations.dashboard.topProducts.heading': 'Top Products',
  'operations.dashboard.count': '{count, plural, one{1 time} other{# times}}',
  'operations.dashboard.categories.heading': 'Categories',
  'operations.dashboard.orderSlot.heading': 'Orders Today',
  'operations.dashboard.slot': 'Slot',
  'operations.dashboard.counts': 'Count',
  'operations.dashboard.total': 'Total',
  'operations.dashboard.subTotal': 'Subtotal',
  'order.log.editedBy': 'Updated',
  'order.log.customerNA': 'Customer not available',
  'order.log.leftAtDoorStep': 'Order was left at doorstep',
  'operations.store.heading': 'Stores',
  'operations.store.add.text': 'Add store',
  'operations.store.button.manage-store.text': 'Manage Store - S&G',
  'operations.store.button.get-by-coordinate': 'Get Address By Coordinate',
  'operations.store.coord-modal.title': 'Search By Coordinate',
  'operations.store.coord-modal.input.label': 'Enter Coordinate',
  'operations.store.coord-modal.input.placeholder': 'eg 1.345553,23.23456',
  'operations.store.coord-modal.error.address-not-found': 'Address not found',
  'operations.store.coord-modal.error.default': 'Something is wrong',
  'operations.store.table.header.name': 'Name',
  'operations.store.table.header.store_kind': 'Store kind',
  'operations.store.table.header.clientId': 'Client Id',
  'operations.store.table.header.clientSAPId': 'Client Id/ SAP Id',
  'operations.store.table.header.address': 'Address',
  'operations.store.table.header.deliveryHub': 'Has Delivey Hub',
  'operations.store.table.header.deliveryFee': 'Delivery Fee',
  'operations.store.table.header.picking': 'Has Picking',
  'operations.store.table.header.status': 'Status',
  'operations.store.table.row.picking.yes': 'Yes',
  'operations.store.table.row.picking.no': 'No',
  'operations.store.table.header.actions': 'Actions',
  'operations.store.actions.edit': 'Edit',
  'operations.store.actions.delete': 'Delete',
  'operations.store.actions.enable': 'Enable',
  'operations.store.actions.disable': 'Disable',
  'operations.store.default': 'Default',
  'operations.store.form.add.heading': 'Add a store',
  'operations.store.form.edit.heading': 'Edit store',
  'operations.store.form.name.heading': 'Name',
  'operations.store.form.clientId.heading': 'Client Id/SAP Id',
  'operations.store.form.clientId.placeholder': 'Enter client Id',
  'operations.store.form.name.placeholder': "Enter your store's name",
  'operations.store.form.location.heading': 'Location',
  'operations.store.form.location.placeholder': 'Search for your location',
  'operations.store.form.location.requiredMessage':
    "Please set store's location",
  'operations.store.form.address.heading': 'Address',
  'operations.store.form.address.placeholder':
    "Enter your store's complete address",
  'operations.store.form.submitText': 'Submit',
  'operations.store.form.cancelText': 'Cancel',
  'operations.store.form.metaData.heading': 'Custom Fields',
  'operations.store.empty.message': 'There are currently no stores configured',
  'operations.store.form.hasPicking': 'Has Picking',
  'operations.store.form.hasDeliveryHub': 'Has Delivery Hub',
  'operations.store.filters.clientId.heading': 'Client Id',
  'operations.store.filters.clientId.placeholder': 'Search by Client Id',
  'operations.store.form.businessHours.title': 'Business Hours',
  'operations.store.form.businessHours.day': 'Day',
  'operations.store.form.businessHours.alwaysOpen': 'Always Open',
  'operations.store.form.businessHours.openingTime': 'Opening Time',
  'operations.store.form.businessHours.closingTime': 'Closing Time',
  'operations.store.form.sngHours.title': 'SNG Operational Hours',
  'operations.store.form.hasPicking.label': 'is picking done here?',
  'operations.store.form.hasDeliveryHub.label': 'is this a delivery hub?',
  'operations.store.form.hasClickCollect.label': 'is this a collection center?',
  'operations.store.form.hasSelfCheckout.label': 'self checkout',
  'operations.store.form.deliveryFee.label': 'Delivery fee',
  'operations.store.form.deliveryFee.placeholder': 'Select delivery fee',
  'operations.store.form.b2bDeliveryFee.label': 'B2B Delivery Fee (Optional)',
  'operations.store.form.b2bDeliveryFee.placeholder': 'Select B2B delivery fee',
  'operations.store.form.status.label': 'Status',
  'operations.store.form.status.enable.label': 'Enabled',
  'operations.store.form.status.disable.label': 'Disabled',
  'operations.default.fileUploadMessage': 'Click here to upload the file',

  'operations.pickingQueue.header.referenceNumber': 'Order no.',
  'operations.pickingQueue.header.placedTime': 'Placed time',
  'operations.pickingQueue.header.amount': 'Amount',
  'operations.pickingQueue.header.status': 'Status',
  'operations.pickingQueue.itemCount':
    '{count, plural, one{1 Item} other{# Items}}',
  'operations.pickingQueue.heading': 'Picking Queue',
  'operations.pickingQueue.emptyList.message':
    'There are currently no orders for picking',

  'shifts.edit.heading': 'Edit employee',
  'shifts.heading': 'Employees',
  'shifts.edit': 'Edit',
  'shifts.header.name': 'Name',
  'shifts.header.offdays': 'Weekly off',
  'shifts.header.timings': 'Timings',
  'shifts.header.attendance': 'Attendance',
  'shifts.header.actions': 'Actions',
  'shifts.empty.message': 'There are currently no users',
  'shifts.empty.submessage': 'Users can be seen once added',
  'shifts.designation': 'Designation',
  'shifts.select.designation': 'Select designation',
  'shifts.form.name': 'Name',
  'shifts.form.phone': 'Phone',
  'shifts.form.weekly.off': 'Weekly off',
  'shifts.form.startTime': 'Shift Start time',
  'shifts.form.endTime': 'Shift end time',
  'shifts.form.submitText': 'Save',
  'shifts.form.cancel': 'Cancel',
  'shifts.checkout': 'Check Out',
  'shifts.checkin': 'Check In',
  'shifts.designation.placeholder': 'Enter designation',
  'shifts.form.pickers.zones': 'Zones',
  'shifts.form.pickers.zones.placeholder': 'Select picker zones',
  'shifts.filters.submitText': 'Submit',
  'employee.checkedInAt': 'Checked in at',

  'dispute.emptyMessage': 'Currently there are no disputes',
  'dispute.view.heading': 'Disputes for Order',
  'dispute.view': 'View',
  'dispute.heading': 'Disputed Orders',
  'dispute.referenceNumber': 'Reference Number',
  'dispute.orderPlacedOn': 'Placed on',
  'dispute.actions': 'Action',
  'dispute.orderItem': 'Order Item',
  'dispute.filingTime': 'Filing Time',
  'dispute.disputedQuantity': 'Quantity',
  'dispute.reason': 'Reason',
  'dispute.status': 'Status',
  'dispute.approveReject': 'Approve/ Reject',
  'dispute.choose': 'Choose',
  'dispute.approve': 'Approve',
  'dispute.reject': 'Reject',
  'dispute.pickup and refund': 'Pickup and refund',
  'dispute.only deliver': 'Only Deliver',
  'dispute.pickup and replace': 'Pickup and replace',
  'dispute.only refund': 'Only refund',
  'dispute.save': 'Submit',
  'dispute.cancel': 'Cancel',
  'dispute.approved': 'Approved',
  'dispute.pending': 'Pending',
  'dispute.rejected': 'Rejected',
  'dispute.name': 'Product name',
  'dispute.resolution': 'Resolution',
  'dispute.quantity': 'Quantity',
  'dispute.reason.placeholder': 'Select Reason',
  'dispute.resolution.placeholder': 'Select Resolution',
  'order.dispute.heading': 'Dispute for order',

  'return.heading': 'Returns',
  'returns.emptyMessage': 'There are no returns',
  'return.view.heading': 'Returns for order',
  'return.resolution': 'Resolution',
  'return.wastagereason': 'Wastage reason',
  'return.submit': 'Submit',
  'return.toStore': 'Store return',
  'return.markasWastage': 'Wastage',
  'return.wastagereason.placeholder': 'Select wastage reason',
  'return.cancel': 'Cancel',
  'return.name': 'Name',
  'return.item.name': 'Product Name',
  'return.retunquantity': 'Return Quantity',
  'return.totalquantity': 'Total Quantity',
  'return.quantity': 'Quantity',
  'return.settledQuantity': 'Settled Quantity',
  'return.file.heading': 'Return order for',
  'return.filter.referenceNumber': 'Reference Number',
  'return.filter.referenceNumber.placeholder': 'Enter order reference number',
  'return.filter.submit': 'Submit',
  'return.move.order': 'Move order to',
  'return.move.placeholder': 'Select the status',
  'return.pending': 'Pending',
  'return.return': 'Returned',
  'return.completed': 'Completed',
  'return.packed': 'Packed',
  'return.are.you.sure': 'Are you sure?',
  'return.heading.for': 'Returns for',
  'return.reason.header': 'Return reason',
  'return.reasons': 'Select the reason',
  'return.no.items.to.return': 'No items to return for this order',
  'picker.name': 'Name',
  'picker.offDays': 'Off days',
  'picker.timings': 'Timings',
  'picker.timingsAndAttendance': 'Timings / Attendance',
  'picker.status': 'Current Status',
  'picker.attendance': 'Attendance',
  'picker.actions': 'Actions',
  'pickers.title': 'Manage Pickers',
  'picker.edit': 'Edit',
  'picker.editHeading': 'Edit Picker',
  'picker.submit': 'Save',
  'picker.cancel': 'Cancel',
  'picker.weeklyOff': 'Weekly off',
  'picker.phone': 'Phone',
  'pickers.emptyMessage': 'Currently there are no pickers',
  'picker.filter.name': 'Name',
  'picker.filter.name.placeholder': 'Enter name',
  'picker.filter.submit': 'Search',
  'picker.filter.clear': 'Clear All',

  'pickers.today.title': 'Pickers Today',
  'pickers.today.slot': 'Shift : ',
  'pickers.today.order.slot': 'Slot: ',
  'pickers.today.slot.starttime': 'Start: ',
  'pickers.today.slot.estimate.time': 'Est: ',
  'pickers.today.slot.current.time': 'Now: ',
  'pickers.today.picking.item': 'Picking ',
  'pickers.today.packing.item': 'Packing ',
  'pickers.today.checking.item': 'Checking ',
  'pickers.today.packed.item': 'Packed ',
  'pickers.today.picking.item2': ' items',
  'pickers.idle.header': 'Idle Pickers',
  'pickers.active.zone.header': 'Active Zone Pickers',
  'pickers.active.header': 'Active Pickers',
  'pickers.empty': 'Currently there are no pickers available',
  'pickers.today.total.pickers': 'Total Pickers',
  'pickers.today.active.pickers': 'Active',
  'pickers.today.idle.pickers': 'Idle',
  'pickers.today.checkin.time': 'Checkin Time: ',
  'pickers.today.late': 'Late',
  'picker.today.order': 'Order ',
  'picker.dialog.error': 'Error',
  'picker.dialog.okText': 'Okay',
  asapDurationStartText: 'Within',
  asapDurationEndText: 'minutes',
  'picker.today.unassignZonePicker': 'Unassign Zone Picker',
  'picker.today.elapsedTime': 'Elapsed time',
  'picker.today.startTime': 'Start time',
  'picker.today.batch': 'Batch',
  'picker.today.picklistId': 'Picklist ID',

  'cp.title': 'Capacity Planning',
  'cp.edit': 'Edit Rule',
  'cp.add': 'Add Rule',
  delivery: 'Delivery',
  'cp.type': 'Type',
  'cp.area': 'Area',
  'cp.actions': 'Actions',
  pickup: 'Pickup',
  'cp.rb_preorder': 'RB Preorder',
  'type.placeholder': 'Select type',
  'zones.placeholder': 'Select zone',
  'slots.placeholder': 'Select slot',
  'cutoff.time': 'Cutoff time',
  'cutoff.time.placeholder': 'Select cutoff time',
  'capacity.placeholder': 'Enter capacity',
  'rule.for': 'Rule for',
  'day.placeholder': 'Select day',
  'cp.label.slot': 'Slot',
  'cp.label.zone': 'Zone',
  'cp.label.type': 'Type',
  'cp.label.day': 'Day',
  'cp.label.date': 'Date',
  'cp.label.capacity': 'Capacity',
  'cp.empty': 'There are no configurations set',
  'cp.today': 'Capacity Planning Today',
  'cp.today.button': 'Today',
  'cp.today.cutofftime': 'Cutoff Time 1',
  'cp.today.orderTypes': 'Order Type',
  'cp.today.table.slot': 'Slot',
  'cp.today.delivery': 'Delivery',
  'cp.today.pickup': 'Pickup',
  'cp.today.preorder': 'RB Preorder',
  'Thank you for shopping at ': 'Thank you for shopping at {name}',
  'Visit us at': 'Visit us at {domain}',
  'common.stepperForm.submit': 'Submit',
  'common.stepperForm.next': 'Next',
  'common.stepperForm.back': 'Back',

  'operations.timeSlotBlocking.title': 'Block Time Slots',
  'operations.timeSlotBlocking.allRules': 'All Rules',
  'operations.timeSlotBlocking.addRule': 'Add Rule',
  'operations.timeSlotBlocking.add': 'Add',
  'operations.timeSlotBlocking.addRule.cancel': 'Cancel',
  'operations.timeSlotBlocking.byPostalCode': 'By Postal Code',
  'operations.timeSlotBlocking.byMap': 'By Map',
  'operations.timeSlotBlocking.byKmlFile': 'By KML File',
  'operations.timeSlotBlocking.firstStep.postalCode': 'Enter Postal Code',
  'operations.timeSlotBlocking.firstStep.map': 'Draw Area on Map',
  'operations.timeSlotBlocking.firstStep.kml': 'Upload KML File',
  'operations.timeSlotBlocking.secondStep.selectTimeslot': 'Select Timeslot',
  'operations.timeSlotBlocking.thirdStep.ruleName': 'Name the Rule',
  'operations.timeSlotBlocking.addRule.title.postalCode':
    'Add Rule by Postal Code',
  'operations.timeSlotBlocking.addRule.title.map': 'Add Rule by Map',
  'operations.timeSlotBlocking.addRule.title.kmlFile': 'Add Rule by KML File',
  'operations.timeSlotBlocking.addRule.title.store': 'Add Rule by Store',
  'operations.timeSlotBlocking.addRule.kmlInput.label':
    'Upload a KML file to block time slots',
  'operations.timeSlotBlocking.addRule.mapInput.zoneName': 'Name Zone',
  'operations.timeSlotBlocking.addRule.zoneName': 'Zone name',
  'operations.timeSlotBlocking.addRule.dragAndDropKml':
    'Drag and drop a KML file here',
  'operations.timeSlotBlocking.addRule.chooseFileFromCom':
    'Choose file from computer',
  'operations.timeSlotBlocking.addRule.kmlUpload': 'Upload',
  'operations.timeSlotBlocking.addRule.kmlCancel': 'Cancel',
  'operations.timeSlotBlocking.addRule.kmlOr': 'or',
  'operations.timeSlotBlocking.addRule.selectBlockedTimeSlots':
    'Select blocked time slots',
  'operations.timeSlotBlocking.addRule.ruleName.label':
    'Enter a name for the rule',
  'operations.timeSlotBlocking.confirmPopup.leavePage': 'Leave the page?',
  'operations.timeSlotBlocking.confirmPopup.bodyText':
    'All edits and changes made will not be saved.',
  'operations.timeSlotBlocking.error.invalidPostalCodes':
    'Remove invalid postal codes.',
  'operations.timeSlotBlocking.error.duplicaedPostalCodes':
    'Remove duplicated postal codes.',
  'operations.timeSlotBlocking.tip.postalCodeInput':
    'Tip: Add multiple postal codes separated by comma and press Enter.',
  'operations.timeSlotBlocking.label.postalCodeInput': 'Add postal codes',
  'operations.timeSlotBlocking.addRule.success': 'The new rule has been added.',
  'operations.timeSlotBlocking.addRule.error': 'An error has occured.',
  'operations.timeSlotBlocking.kmlInput.multipleZoneError':
    'Upload KML files with single zone only',
  'operations.timeSlotBlocking.noRules':
    "No rules added. Create your first rule by clicking 'Add Rule' button.",
  'operations.timeSlotBlocking.noRules.found': 'No results found.',
  'operations.timeSlotBlocking.kmlInput.invalidFile':
    'Unable to read the kml file.',
  'operations.timeSlotBlocking.title.serverError': 'Something went wrong',
  'operations.timeSlotBlocking.body.serverError':
    'The web server is currently unreachable. Please try again.',
  'operations.timeSlotBlocking.addRule.mapInput.label':
    'Select area by drawing on the map',
  'operations.timeSlotBlocking.editRule.byMap': 'Edit by Map',
  'operations.timeSlotBlocking.editRule.byKmlFile': 'Edit by KML File',
  'operations.timeSlotBlocking.editRule.edit': 'Edit',
  'operations.timeSlotBlocking.deleteRule.delete': 'Delete',
  'operations.timeSlotBlocking.byStore': 'By Store',
  'operations.timeSlotBlocking.zoneCreate.error': 'Unable to create zone',
  'operations.timeSlotBlocking.rulesList.loadMore': 'Load more',
  'operations.timeSlotBlocking.addRule.ruleName.byStore.hint1':
    'Only 1 rule can be created per store',
  'operations.timeSlotBlocking.addRule.ruleName.byStore.hint2':
    'Store name is fixed and auto-populated',
  'operations.timeSlotBlocking.addRule.ruleName.byStore.hint3':
    'Spaces in store names are auto-replaced by an underscore, e.g. Hyper_Changi',
  'operations.timeSlotBlocking.addRule.ruleName.others.hint1':
    'Only alphanumeric and underscore characters are allowed',
  'operations.timeSlotBlocking.addRule.ruleName.others.hint2':
    'Spaces are auto-replaced by an underscore, e.g. Keppel_Bay',
}

export default data
