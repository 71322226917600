import React, { useState } from 'react'
import API from 'lib/api'
import { CAMPAIGN_LABELS_API } from 'config/app'
import ListingPage from '../../../../containers/ListingPage'
import { DropDown, DropDownItem, ICONS } from '../../../../components/DropDown'
import { getMessage } from '../../../../lib/translator'
import { Row, Cell } from '../../../../components/Table'
import { Link } from 'react-router-dom'
import './style.css'
import InfoModal from './InfoModal'
import InfoBanner from './InfoBanner'

const tableProperties = (selectedOption, onDelete) => {
  const header = [
    getMessage('config.preorder.category.id'),
    getMessage('config.preorder.category.startedAt'),
    getMessage('config.preorder.category.finishedAt'),
    getMessage('config.preorder.category.user'),
    getMessage('config.preorder.category.variant'),
    getMessage('config.preorder.category.status'),
    getMessage('product.header.actions'),
  ]
  if (selectedOption === 'preorder-category-configuration') {
    header.splice(1, 0, getMessage('config.preorder.category.campaignId'))
  }
  return {
    headers: header,
    row: ({ id, updatedBy, variant, status, validFrom, validTill, data }) => {
      return (
        /* istanbul ignore next */ <Row key={id}>
          <Cell>
            <Link to={`/catalogue/configurations/${selectedOption}/view/${id}`}>
              <span>{id}</span>
            </Link>
          </Cell>
          {selectedOption === 'preorder-category-configuration' && (
            <Cell>
              {data.data.map((info, index) => (
                <span key={info.campaignId}>
                  {info.campaignId}
                  {index !== data.data.length - 1 ? ', ' : ''}
                </span>
              ))}
            </Cell>
          )}
          <Cell>
            {validFrom && (
              <span>{`${new Date(validFrom).toLocaleDateString('en-CA')} | ${new Date(validFrom).toLocaleTimeString()}`}</span>
            )}
          </Cell>
          <Cell>
            {' '}
            {validTill && (
              <span>{`${new Date(validTill).toLocaleDateString('en-CA')} | ${new Date(validTill).toLocaleTimeString()}`}</span>
            )}
          </Cell>
          <Cell>
            <span>{updatedBy}</span>
          </Cell>
          <Cell>
            <span>{variant}</span>
          </Cell>
          <Cell>
            <span>{status}</span>
          </Cell>
          <Cell className="actions">
            {
              <DropDown
                data-testid={`batch-action-dropdown-${id}`}
                className="batch-action-dropdown"
                icon={<img src={ICONS.VELLIP} alt="⋮" />}
              >
                <DropDownItem>
                  <Link
                    to={`/catalogue/configurations/${selectedOption}/edit/${id}`}
                  >
                    Edit
                  </Link>
                </DropDownItem>
                <DropDownItem
                  onClick={() => {
                    onDelete(id)
                  }}
                  className="hide-action"
                >
                  Delete
                </DropDownItem>
              </DropDown>
            }
          </Cell>
        </Row>
      )
    },
  }
}

function ConfigCardList({ selectedType }) {
  const OFFER_LABEL_SEQUENCE = 'offer-label-sequence'
  const selectedOption =
    selectedType === OFFER_LABEL_SEQUENCE
      ? OFFER_LABEL_SEQUENCE
      : 'campaign-category'
  const tableTitle =
    selectedType === OFFER_LABEL_SEQUENCE
      ? 'Offer label sequence'
      : 'Preorder Category Configuration'
  const [deleteId, setDeleteId] = useState([])
  const [isDeleteButtonPressed, setIsDeleteButtonPressed] = useState(false)
  const [shouldRefetchTableData, setRefetchTableData] = useState(false)
  const [modalInfo, setModalInfo] = useState({})
  const [message, setMessage] = useState({})
  const onDelete = (id) => {
    setDeleteId(id)
    setModalInfo({
      body: 'Are you sure you want to delete the config?',
      title: 'Delete Config',
      confirmBtnText: 'Delete',
      cancelBtnText: 'Cancel',
      onConfirm: onDeleteConfirm,
    })
    setIsDeleteButtonPressed(true)
  }

  const onDeleteConfirm = async () => {
    /* istanbul ignore next */
    if (deleteId) {
      try {
        setIsDeleteButtonPressed(false)
        const api = new API({
          url: `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedOption}/${deleteId}`,
        })
        const response = await api.delete()
        setMessage({ message: response.data, status: 'success' })
      } catch (error) {
        /* istanbul ignore next */
        setMessage({ message: error.message, status: 'error' })
      } finally {
        setRefetchTableData(true)
      }
    }
  }

  const hideModal = () => {
    setModalInfo({})
    setIsDeleteButtonPressed(false)
  }
  return (
    <div>
      <InfoBanner message={message} />
      <InfoModal
        show={isDeleteButtonPressed}
        close={hideModal}
        modalInfo={modalInfo}
        onConfirm={onDeleteConfirm}
      />
      <div>
        <ListingPage
          className="scheduled-jobs-status-page"
          addHeading={tableTitle}
          title={tableTitle}
          refetchTableData={shouldRefetchTableData}
          api={{
            url: `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedOption}`,
            transform: (response) =>
              /* istanbul ignore next */ response && response.data
                ? response.data.configurations
                : [],
          }}
          showLanguageDropDown
          tableProperties={tableProperties(selectedType, onDelete)}
          primaryKey="id"
        />
      </div>
    </div>
  )
}

export default ConfigCardList
