import React from 'react'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import { Link } from 'react-router-dom'
import { getMessage } from 'lib/translator'

import { GIFTING_PAGINATION_SIZE } from 'pages/customer-support/constants'
import { TableProperties } from './TableProperties'
import { transform } from '../../utils/index'
import AddSalesOrder from '../AddSalesOrder'
import PrintFilter from 'pages/gift-card/PrintOrders/PrintFilter'

const EmptyState = {
  message: getMessage('customerSupport.egifting.empty'),
  actions: () => (
    <Link
      to="/gift-card/sales-orders/add"
      className="primary button"
      data-testid="crate-new-sales-orders"
    >
      <span className="text">
        {getMessage('gift.card.sales.orders.create')}
      </span>
    </Link>
  ),
}

const Actions = () => (
  <div className="actions-section">
    <Link to="/gift-card/sales-orders/add" data-testid="crate-new-sales-orders">
      <button className="primary button">
        <span className="text">
          {getMessage('gift.card.sales.orders.create')}
        </span>
      </button>
    </Link>
  </div>
)

const DesignCodes = (props) => {
  return (
    <ListingPageWithRoutes
      testid="sales-orders-listing"
      title="Sales Orders"
      addHeading="Create Salees Orders"
      menu={props.menu}
      className="sales-orders"
      api={{
        url: '/gifting-admin-service/sales-orders',
        params: { ...props.params, page_size: GIFTING_PAGINATION_SIZE },
        transform,
      }}
      form={{
        component: AddSalesOrder,
      }}
      headerActions={() => <Actions />}
      filters={{
        component: PrintFilter,
        options: { type: 'saleOrders' },
      }}
      tableProperties={TableProperties()}
      tableDynamic
      emptyState={EmptyState}
    />
  )
}

export default DesignCodes
