import React from 'react'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import OmniChallengesForm from '../../rewards/OmniChallenges/Form'
import { Link } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import { Row, Cell } from 'components/Table'
import moment from 'moment'
import { DATE_TIME_FORMAT } from 'components/Rewards/utils'
import { getUserRoles } from 'lib/auth'

const tableProperties = () => {
  return {
    headers: ['ID', 'Game Name', 'Type', 'Start Date', 'End Date', 'Status'],
    row: ({ id, name, type, startAt, endAt, isEnabled }) => (
      <Row>
        <Cell>
          <Link to={`/marketing/games/edit/${id}`}>{id}</Link>
        </Cell>
        <Cell>
          <Link to={`/marketing/games/edit/${id}`}>{name}</Link>
        </Cell>
        <Cell>{type}</Cell>
        <Cell>{moment(startAt).format(DATE_TIME_FORMAT)}</Cell>
        <Cell>{moment(endAt).format(DATE_TIME_FORMAT)}</Cell>
        <Cell>
          {isEnabled
            ? getMessage('rewardPage.enabled')
            : getMessage('rewardPage.disabled')}
        </Cell>
      </Row>
    ),
  }
}

const AddGameLinkButton = () => {
  const isReadOnly = getUserRoles().includes('Marketing / Games (readonly)')
  return (
    !isReadOnly && (
      <div className="header-wrap">
        <Link to="/marketing/games/add">
          <button className="primary button">
            <span className="text">+ Add Games</span>
          </button>
        </Link>
      </div>
    )
  )
}
const GameSettings = (props) => {
  return (
    <ListingPageWithRoutes
      testid="game-settings-listing"
      title="Game Settings"
      addHeading="Add Games"
      editHeading="Edit Games"
      menu={props.menu}
      api={{
        url: '/gamification-service/v1/games',
        transform: (response) => {
          if (response && response.data.data.list.length > 0) {
            return response.data.data.list
          }
          return null
        },
      }}
      headerActions={AddGameLinkButton}
      form={{
        component: OmniChallengesForm,
      }}
      tableProperties={tableProperties()}
      viewHeading="Game Details"
      modalClassName="resend-popup"
      tableDynamic
    />
  )
}

export default GameSettings
