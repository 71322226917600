const data = {
  'marketing.blog.add.text': 'Write a new post',
  'marketing.blog.helpItems.message': 'There are no blogs shown here',
  'marketing.blog.heading': 'Blog',
  'marketing.form.add.heading': 'Add blog post',
  'marketing.form.edit.heading': 'Edit blog post',
  'marketing.blog.form.title.heading': 'Post Title',
  'marketing.blog.form.title.placeholder': 'Enter your title here',
  'marketing.blog.form.title.requiredMessage': 'Your blog must have a title',
  'marketing.blog.form.content.placeholder': 'Type here',
  'marketing.blog.form.submitText': 'Publish',
  'marketing.blog.form.cancelText': 'Cancel',
  'marketing.table.heading.title': 'Title',
  'marketing.table.heading.publishTime': 'Published at',
  'marketing.table.heading.author': 'Written by',
  'marketing.table.heading.interaction': 'Interaction',
  'marketing.table.heading.actions': 'Actions',
  'marketing.blog.table.status.draft': 'Draft',
  'marketing.blog.table.action.edit': 'Edit',
  'marketing.blog.table.action.publish': 'Publish',
  'marketing.blog.table.action.unpublish': 'Unpublish',
  'marketing.blog.dialogs.publish.title': 'Are you sure?',
  'marketing.blog.dialogs.unpublish.title': 'Are you sure?',
  'marketing.blog.dialogs.publish.message':
    'This will publish the blog on the website',
  'marketing.blog.dialogs.unpublish.message':
    'This will remove the blog on the website and add it to drafts',
  'marketing.blog.dialogs.publish.cancelText': 'Cancel',
  'marketing.blog.dialogs.publish.okText': 'Publish',
  'marketing.blog.dialogs.unpublish.okText': 'Unpublish',
  'marketing.blog.table.action.delete': 'Delete',
  'marketing.blog.dialogs.delete.title': 'Are you sure?',
  'marketing.blog.dialogs.delete.message':
    'This will publish the blog on the website',
  'marketing.blog.dialogs.delete.cancelText': 'Cancel',
  'marketing.blog.dialogs.delete.okText': 'Publish',

  'websitePage.table.heading': 'Static Pages',
  'websitePage.add.text': 'Add page',
  'websitePage.emptyList.message': 'There are no static pages for your website',
  'websitePage.emptyList.submessage': '',
  'websitePage.status.enabled': 'Enabled',
  'websitePage.status.disabled': 'Disabled',
  'websitePage.table.title': 'Title',
  'websitePage.table.url': 'URL',
  'websitePage.table.status': 'Status',
  'websitePage.table.actions': 'Actions',
  'websitePage.table.action.edit': 'Edit',
  'websitePage.table.action.disable': 'Disable',
  'websitePage.table.action.enable': 'Enable',
  'websitePage.dialogs.disable.title': 'Are you sure?',
  'websitePage.dialogs.enable.title': 'Are you sure?',
  'websitePage.dialogs.disable.message': 'This action will disable this page',
  'websitePage.dialogs.enable.message': 'This action will enable this page',
  'websitePage.dialogs.disable.cancelText': 'Cancel',
  'websitePage.dialogs.disable.okText': 'Confirm',
  'websitePage.form.add.heading': 'Add static page',
  'websitePage.form.edit.heading': 'Edit static page',
  'websitePage.form.title.heading': 'Page Title',
  'websitePage.form.title.placeholder': 'Please enter the title of your page',
  'websitePage.form.url.heading': 'URL',
  'websitePage.form.url.placeholder': 'URL of the page',
  'websitePage.form.title.requiredMessage': 'Your page must have a title',
  'websitePage.form.content.placeholder':
    'What you type here will be shown as the content of this page on your website',
  'websitePage.form.submitText': 'Publish',
  'websitePage.form.cancelText': 'Cancel',
  'websitePage.delete': 'Delete',
  'websitepage.edit': 'Edit',
  'websitepage.form.addLayout': 'Add Layout',
  'websitePage.dialog.title': 'Are you sure?',
  'websitePage.dialog.information': 'This layout will be deleted',
  'websitePage.add': 'Add',
  'websitePage.popup.add': 'Add layout',
  'websitePage.popup.imagewithtext.heading': 'Edit image with text',
  'imagewithtext.title': 'Title',
  'imagewithtext.placeholder': 'Enter title',
  'imagewithtext.image': 'Image',
  'layouts.save': 'Save',
  'websitePage.popup.heading.image': 'Edit Image',
  'image.title': 'Image',

  'offer.all': 'All',
  'offer.product': 'Product',
  'offer.category': 'Category',
  'offer.brand': 'Brand',
  'offer.product.placeholder': 'Select products',
  'offer.category.placeholder': 'Select category',
  'offer.categories.placeholder': 'Select Categories',
  'offer.brand.placeholder': 'Select brand',
  'offer.brands.placeholder': 'Select brands',
  'offer.entity': 'Entity Type',
  'offer.edit': 'Edit',
  'offer.type': 'Type',
  'offer.entity.id': 'Entity ID',
  'offer.entityid.placeholder': 'Enter Entity ID',
  'offer.description': 'Description',
  'offer.costcenter': 'GL Code (Cost Center)',
  'offer.iconType': 'Promo Code Icon',
  'offer.iconType.placeholder': 'Select Promo Code Icon',
  'offer.validFrom': 'Valid From',
  'offer.validTill': 'Valid Till',
  'offer.actions': 'Actions',
  'offers.title': 'Offers',
  'offer.add.text': 'Add offer for OG/S&G',
  'offer.pin.wallet': 'Pin Wallet Offers',
  'offer.pin.wallet.toast.text': 'Offer wallet Pinned.',
  'offer.addfpapp.text': 'Add Offer for Pay via FP App',
  'offer.type.placeholder': 'Select offer type',
  'offer.submit': 'Submit',
  'offer.clear': 'Clear',
  'offer.applicable.to.stores': 'Applicable to store',
  'offer.applicable.to.store.placeholder': 'Select store',
  'offer.enabled': 'Enabled',
  'offer.disabled': 'Disabled',
  'offer.disable': 'Disable',
  'offer.enable': 'Enable',
  'offer.status': 'Status',
  'offer.order.types': 'Order Types',
  'offer.add.heading': 'Add offer',
  'offer.edit.heading': 'Edit offer',
  'offer.cancel': 'Cancel',
  'offer.alert.message': 'Alert Message',
  'offer.alert.message.placeholder': 'Enter alert message',
  'offer.max.redemption': 'order level redemption',
  'offer.max.redemption.placeholder': 'Enter order level redemption',
  'offer.customer.redemption': 'Customer Redemption',
  'offer.customer.redemption.placeholder': 'Enter customer redemption',
  'offer.total.redemption': 'Total Redemption',
  'offer.total.redemption.count': 'Redemption count',
  'offer.total.redemption.placeholder': 'Enter total redemption',
  'offer.ordertype.label': 'Order Type',
  'offer.ordertype.placeholder': 'Select order type',
  'offer.ordertype.options.bulk': 'Bulk',
  'offer.ordertype.options.delivery': 'Delivery',
  'offer.ordertype.options.pickup': 'Pickup',
  'offer.ordertype.options.offline': 'Offline',
  'offer.ordertype.options.b2b': 'B2B',
  'offer.ordertype.options.instore': 'Instore',
  'offer.ordertype.options.preorder': 'Pre-order',
  'offer.ordertype.options.dp.fairprice': 'Fair Price',
  'offer.ordertype.options.dp.cheers': 'Cheers',
  'offer.ordertype.options.dp.kopitiam': 'Kopitiam',
  'offer.image': 'Image',
  'offer.description.placeholder': 'Enter description',
  'offer.howToUseText': 'How to use',
  'offer.howToUseTextInfo':
    'If no custom How To Use is provided, the default value will be used.',
  'offer.tncUrl': 'T&C URL',
  'offer.tncUrlInfo':
    'If no custom T&C url is provided, the default value will be used.',
  'offer.supplier.name': 'Supplier Name',
  'offer.supplier.code': 'Supplier Code',
  'offer.supplier.treatment': 'Treatment',
  'offer.supplier.treatment.placeholder': 'Select treatment',
  'offer.sub.title': 'Sub title',
  'offer.fixed': 'Fixed',
  'offer.percentoff': 'Percent off',
  'offer.absoluteoff': 'Absolute off',
  'offer.all.stores': 'All stores',
  'offer.selected.stores': 'Selected stores',
  'offer.all.except.selected.stores': 'All except selected stores',
  'offer.stores': 'Stores',
  'offer.stores.placeholder': 'Select stores',
  'offer.applies.to': 'Applies to',
  'offer.pay.via.app': 'Pay via app stores',
  'offer.Online.Offline.stores': 'Online/offline stores',
  'offer.applies.to.placeholder': 'Select Stores',
  'offer.quantity': 'Quantity',
  'offer.quantity.placeholder': 'Enter quantity',
  'offer.discounttype': 'Discount type',
  'offer.discounttype.placeholder': 'Select discount type',
  'offer.discountvalue': 'Discount value',
  'offer.maxdiscountvalue': 'Max Discount',
  'offer.discountvalue.placeholder': '00',
  'offer.min.quantity': 'Min quantity',
  'offer.min.quantity.placeholder': 'Enter min quantity',
  'offer.time': 'Valid from time',
  'offer.time.placeholder': 'Select time',
  'offer.validto.time': 'Valid till time',
  'offer.name': 'Name',
  'offer.combo.discount': 'Combo',
  'offer.individual.discount': 'Individual',
  'offer.get': 'Get',
  'offer.main.discount.type': 'Type of discount',
  'offer.cartprice': 'Cart Price',
  'offer.cartprice.placeholder': 'Enter cart price',
  'offer.delete': 'Delete',
  'offer.emptyMessage': 'There are no offers',
  'offer.includeText': 'to include',
  'offer.excludeText': 'to exclude',
  'offer.min.amount.label': 'Min Amount',
  'offer.min.amount.placeholder': 'Enter min amount',
  'offer.select.placeholder': 'Select',
  'offer.includes': 'includes',
  'offer.min.amount': 'Minimum Amount',
  'offer.payment.type': 'Payment type',
  'offer.payment.type.placeholder': 'Enter payment type',
  'offer.buy': 'Buy',
  'offer.filter.clientId': 'Client Id',
  'offer.filter.clientId.placeholder': 'Search by Client Id',
  'offer.filter.sapReference': 'Offer Name',
  'offer.filter.sapReference.placeholder': 'Search by Offer Name',
  'offer.heading.deliveyDate': 'Delivery Date',
  'offer.action.disable': 'Disable',
  'offer.action.enable': 'Enable',
  'offer.dialog.notification.label': 'Notification',
  'offer.dialog.notification.message': 'Promocodes download is in progress.',
  'offer.dialog.notification.close': 'Close',
  'offer.field.hasUniquePromocode.label': 'Has Unique Promocode',
  'offer.field.addToWallet.label': 'Add To Wallet',
  'offer.field.stackable.label': 'Stackable',
  'offer.field.ignoreSegment.label': 'Ignore segment while browsing',
  'offer.filter.searchbytag.placeholder': 'Search by Tag',
  'offer.filter.searchbycategory.placeholder': 'Search by Category',
  'offer.filter.searchbybrand.placeholder': 'Search by Brand',
  'offer.filter.category.label': 'Category',
  'offer.filter.brand.label': 'Brand',
  'offer.filter.tag.label': 'Tag',
  'offer.filter.product.label': 'Product',
  'offer.filters.promoCode.heading': 'Promo Code',
  'offer.filters.promoCode.placeholder': 'Search by Promo Code',
  'offer.dialog.clone.label': 'Warning',
  'offer.dialog.clone.message':
    'This offer will be disabled and a new offer will be created with the remaining redemption',
  'offer.dialog.clone.proceed': 'Continue',
  'offer.dialog.clone.close': 'Cancel',
  'offer.dialog.disable.errorHead': 'Error',
  'offer.dialog.disable.errorText':
    'Error occurred while disabling the offer. Please try agin.',
  'offer.dialog.disable.close': 'Okay',
  'offer.dialog.sapref.errorHead': 'Error',
  'offer.dialog.sapref.errorText': 'Offers has invalid SAP reference.',
  'offer.dialog.sapref.close': 'Okay',
  'offer.dialog.cartprice.errorText': 'Offers has invalid Cart Price.',
  'offer.dialog.minAmount.errorText': 'Offers has invalid Min Amount.',
  'offer.clone.disableNClone': 'Disable and create new',
  'offer.clone.copy': 'Create similar',
  'offer.redeemedCount.placeholder':
    'Redeemed count of original offer: {count}',
  'offer.dialog.sapreference.message':
    'You have entered more than 70 characters. Please reduce the character count.',
  'offer.eligible.items.website': 'Website re-direct link',
  'offer.eligible.items.website.placeholder': 'Enter website re-direct link',
  'offer.eligible.items.mobile': 'Mobile re-direct link',
  'offer.eligible.items.mobile.placeholder': 'Enter mobile re-direct link',
  'offer.eligible.items': 'Eligible Items',
  'offer.promocode.label': 'Promo Code',
  'offer.promocode.placeholder': 'Enter Promo Code',
  'offer.confirmation.title': 'Offer Details',
  'offer.confirmation.warning': 'Note: Discount is greater than 50%',
  'offer.confirmation.tabulation': 'Tabulation',
  'offer.confirmation.summary': 'Offer Summary',
  'offer.confirmation.type': 'Offer Type',
  'offer.confirmation.segments': 'Segments',
  'offer.confirmation.segmentIoAudiences': 'Segment IO Audiences',
  'offer.confirmation.confirm': 'Confirm',
  'offer.confirmation.edit': 'Edit',
  'offer.confirmation.unlimited': 'Unlimited',
  'offer.confirmation.na': 'Not Applicable',
  'offer.form.segmentio.audiences': 'SegmentIO Audiences',
  'offer.form.segmentio.audiences.label': 'Select SegmentIO Audiences',
  'offer.form.segmentio.audiences.selected': 'Selected SegmentIO Audiences',

  // Voucher Center Campaigns string resources
  'offer.campaigns.listing.heading': 'Voucher Center Campaigns',
  'offer.campaigns.listing.add.offerCampaign': 'Add Campaign',
  'offer.campaigns.form.campaignName': 'Campaign Name',
  'offer.campaigns.form.campaignName.placeholder': 'Enter campaign name',
  'offer.campaigns.form.campaignBanner': 'Campaign Banner',
  'offer.campaigns.form.campaignBanner.placeholder': 'Select campaign banner',
  'offer.campaigns.form.startDateTime': 'Start Date Time',
  'offer.campaigns.form.endDateTime': 'End Date Time',
  'offer.campaigns.form.campaignTitle': 'Campaign Title',
  'offer.campaigns.form.campaignTitle.placeholder': 'Enter campaign title',
  'offer.campaigns.form.category': 'Category',
  'offer.campaigns.form.category.placeholder': 'Select category',
  'offer.campaigns.form.campaignDescription':
    'Campaign Sub Header / Description',
  'offer.campaigns.form.campaignDescription.placeholder':
    'Enter campaign sub header / description',
  'offer.campaigns.form.primaryCTA': 'Campaign Primary CTA',
  'offer.campaigns.form.primaryCTA.placeholder': 'Enter campaign primary CTA',
  'offer.campaigns.form.redirectLink': 'Redirect Link (deeplink)',
  'offer.campaigns.form.redirectLink.placeholder': 'Enter redirect link',
  'offer.campaigns.form.offerSchedules.title': 'Offer Schedules',
  'offer.campaigns.form.offerSchedules.startDate': 'Start Date',
  'offer.campaigns.form.offerSchedules.endDate': 'End Date',
  'offer.campaigns.form.offerSchedules.startTime': 'Start Time',
  'offer.campaigns.form.offerSchedules.endTime': 'End Time',
  'offer.campaigns.form.offerSchedules.offerIds': 'Offer IDs (e.g. 1,3,4)',
  'offer.campaigns.form.offerSchedules.offerIds.placeholder': 'Enter offer IDs',
  'offer.campaigns.form.addSchedule': 'Add Schedule',
  'offer.campaigns.form.error': 'Server error',
  'offer.campaigns.form.backgroundColor': 'Background Color',

  // Scan Campaigns string resources
  'offer.scanCampaigns.listing.heading': 'Scan Campaigns',
  'offer.scanCampaigns.listing.add': 'Add Campaign',
  'offer.scanCampaigns.listing.noCampaign':
    'There is no scan campaign at the moment.',
  'offer.scanCampaigns.add.new': 'Add Campaign',
  'offer.scanCampaigns.form.campaignName': 'Campaign Name',
  'offer.scanCampaigns.form.campaignName.placeholder': 'Enter campaign name',
  'offer.scanCampaigns.form.rules.title': 'Rules',
  'offer.scanCampaigns.form.rules.type': 'Type',
  'offer.scanCampaigns.form.rules.type.placeholder': 'Select Type',
  'offer.scanCampaigns.form.addRule': 'Add Rule',
  'offer.scanCampaigns.form.segment.newAppSignUp': 'New App Sign-up',
  'offer.scanCampaigns.form.segment.segmentCustomer': 'Segment Customer',

  'banners.header': 'Manage Banners',
  'banners.select.page.type': 'Page Type',
  'banners.select.page.type.placeholder': 'Select page type',
  'banners.select.bannerImage.type': 'Banner Type',
  'banners.select.bannerImage.placeholder': 'Select banner type',
  'banners.select.search.term': 'search term',
  'banners.select.search.term.placeholder': 'Enter search term',
  'banners.select.category.heading': 'category',
  'banners.select.category.placeholder': 'Enter category name',
  'banners.select.brand.heading': 'brand',
  'banners.select.brand.placeholder': 'Enter brand name',
  'banners.select.tag.heading': 'tag',
  'banners.select.tag.placeholder': 'Enter tag name',
  'banners.radio.label.banners': 'Banners',
  'banners.radio.option.active': 'Active banners',
  'banners.radio.option.expired': 'Expired banners',
  'banners.view.header': 'Manage Banners',
  'banners.view.drag.text': 'Drag to change the order',
  'banners.view.add.button': 'Add Banner',
  'banners.editForm.header': 'Edit Banner',
  'banners.addForm.header': 'Add Banner',
  'banners.editForm.button.save': 'Save',
  'banners.editForm.button.cancel': 'Cancel',
  'banners.editForm.button.delete': 'Delete',
  'banners.editForm.image.label': 'Image',
  'banners.editForm.link.label': 'Link URL',
  'banners.editForm.radio.label': 'Scope',
  'banners.editForm.radio.option.global': 'Global',
  'banners.editForm.radio.option.storeSpecific': 'Store specific',
  'banners.editForm.validity.from': 'Validity from',
  'banners.editForm.validity.to': 'Validity to',
  'banners.editForm.link.placeholder': 'URL to navigate on clicking',
  'banners.popup.banner.selection.heading': 'Choose Banner',
  'banners.action.edit': 'Edit',
  'banners.action.delete': 'Delete',
  'banners.page.type.placeholder': 'Select the page type',
  'banners.startTime.placeholder': 'Start date',
  'banners.endTime.placeholder': 'End date',
  'banners.header.image': 'Image',
  'banners.header.actions': 'Actions',
  'banners.message.invalidDate': 'End date must be after the start date',
  'banners.empty.banner': 'Currently there are no home page banners available',
  'banners.stores': 'Store',
  'banners.stores.placeholder': 'Select the store',
  'banners.client-stores.placeholder':
    'Input client store ids, separated by comma',
  'banners.form.category.heading': 'category',
  'banners.form.brand.heading': 'brand',
  'banners.form.tag.heading': 'tag',
  'banners.form.category.placeholder': 'Enter category name',
  'banners.form.brand.placeholder': 'Enter brand name',
  'banners.form.tag.placeholder': 'Enter tag name',
  'banners.form.sequence.placeholder': 'Enter the sequence',
  'banners.form.sequence.label': 'Sequence',
  'banners.table.view.on.web.url': 'View on web',
  'banners.table.scope': 'Scope:',
  'banners.table.bannerId': 'BannerID:',
  'banners.table.validity': 'Validity:',
  'banners.table.na': ' N/A',
  'banners.form.search.term.label': 'Search Term',
  'banners.form.search.name.label': 'Search By Name',
  'banners.form.search.term.placeholder': 'Enter the search term',
  'banners.form.search.name.placeholder': 'Enter the name',
  'banners.form.startTime.placeholder': 'Enter Start Time',
  'banners.form.endTime.placeholder': 'Enter End Time',
  'banners.success.dialog.message': 'Saved',
  'banners.default.page': 'Home',
  'banners.pageType.home': 'Home',
  'banners.pageType.category': 'Category',
  'banners.pageType.brand': 'Brand',
  'banners.pageType.productTag': 'Product_tag',
  'banners.pageType.o2o_store_selector': 'O2O Store Selector',
  'banners.pageType.search': 'Search',
  'banners.pageType.promotions_all': 'Promotions - All',
  'banners.pageType.promotions_pricedropbuynow':
    'Promotions - Price Drop Buy Now',
  'banners.pageType.promotions_elp': 'Promotions - ELP',
  'banners.pageType.omni_home': 'Omni homepage',
  'banners.scope.global': 'GLOBAL',
  'banners.scope.store': 'STORE',
  'banners.languages': 'Language',
  'banners.languages.placeholder': 'Select the language',
  'banners.pageType.linkpay_invoice': 'Pay via FP App invoice',
  'banners.pageType.sequence.tip1':
    'Sequencing is enabled for Home page and Omni Homepage on Grid view only.',
  'banners.pageType.sequence.tip2': `To sequence, please select Page Type 'Home' or 'Omni Homepage' AND leave Banner Type & Store as 'All'.`,

  'coupon.action.viewAnalytics': 'Analytics',
  'coupon.analytics.heading': 'Coupon Analytics',
  'coupon.analytics.analyticsFor': 'Analytics For',
  'coupon.analytics.uniqueUsers.heading': 'Unique Users',
  'coupon.analytics.activeFor.heading': 'Active For(Days)',
  'coupon.analytics.totalDiscount.heading': 'Total Coupon Discount',
  'coupon.analytics.totalPurchases.heading': 'Total Sales',
  'coupon.analytics.today.heading': 'Today',
  'coupon.analytics.thisWeek.heading': 'This Week',
  'coupon.analytics.paymentDistribution.heading': 'Payment Distribution',
  'coupon.analytics.deviceDistribution.heading': 'Device Distribution',
  'coupon.analytics.dailyDistribution.heading': 'Daily Distribution',
  'coupon.analytics.selectDates.heading': 'Select Dates',
  'coupon.analytics.status.heading': 'Status',
  'campaign.analytics.heading': 'Campaign Analytics',
  'campaign.analytics.subHeading': 'User Engagement',
  'campaign.header.campaign': 'Campaign',
  'campaign.analytics.tab.email': 'Email',
  'campaign.analytics.tab.sms': 'Sms',
  'campaign.action.viewAnalytics': 'View Analytics',
  'campaign.analytics.zopLinkActivity.heading': 'ZopLink Activity',
  'campaign.analytics.zopNowMissyou.message': '[ZopNow] We Miss You !',
  'campaign.analytics.table.heading': 'Last 10 Opened',
  'campaign.analytics.emailTab.table.header.name': 'Name',
  'campaign.analytics.emailTab.table.header.email': 'Email',
  'campaign.analytics.emailTab.table.header.dateTime': 'Date & Time',
  'campaign.analytics.emailTab.table.header.promotional': 'Promotional',
  'campaign.analytics.smsTab.table.header.url': 'Url',
  'campaign.analytics.smsTab.table.header.lastVisit': 'Last visit',
  'campaign.analytics.smsTab.table.header.clicks': 'Clicks (unique)',
  'coupon.analytics.emailTab.basedOnEmailsDelivered':
    'Based on emails delivered',
  'coupon.analytics.emailTab.basedOnEmailsOpened': 'Based on emails opened',
  'coupon.analytics.emailTab.opened': 'Opened',
  'coupon.analytics.emailTab.clickedThrough': 'Clicked through',
  'coupon.analytics.smsTab.clicked': 'Clicked',
  'coupon.analytics.smsTab.basedOnDelivered': 'Based on delivered',
  'coupon.analytics.pieChart.DESKTOP_NA_WEB': 'Desktop',
  'coupon.analytics.pieChart.MOBILE_ANDROID_WEB': 'Android',
  'coupon.analytics.pieChart.MOBILE_IOS_WEB': 'IOS',

  'campaign-labels.title': 'Campaign Labels',
  'campaign-labels.form.tag.heading': 'Tag ID',
  'campaign-labels.add.heading': 'Add Campaign Labels',
  'campaign-labels.form.image.label': 'Campaign Labels Image',
  'campaign-labels.form.campaignName.label': 'Campaign Labels Name',
  'campaign-labels.form.validFrom': 'Valid From',
  'campaign-labels.form.validTo': 'Valid To',
  'campaign-labels.form.startTime': 'Start Time',
  'campaign-labels.form.endTime': 'End Time',
  'campaign-labels.startDate': 'Start Date',
  'campaign-labels.endDate': 'End Date',
  'campaign-labels.list.heading.campaignName': 'Campaign Name',
  'campaign-labels.list.heading.tagId': 'Tag ID',
  'campaign-labels.list.heading.startDateTime': 'Start DateTime',
  'campaign-labels.list.heading.endDateTime': 'End DateTime',
  'campaign-labels.list.heading.campaignLabelImage': 'Campaign Label Image',
  'campaign-labels.list.heading.createdAt': 'Created At',
  'campaign-labels.list.heading.createdBy': 'Created By',
  'campaign-labels.list.heading.action': 'Action',
  'campaign-labels.empty.campaigns':
    'Currently there are no campaign labels available',
  'campaign-labels.filter.type': 'Search Campaign Labels',
  'campaign-labels.filter.type.placeholder': 'Select Campaign Labels Type',

  'search-campaigns.image': 'Image',
  'search-campaigns.id': 'Campaign ID',
  'search-campaigns.name': 'Campaign Name',
  'search-campaigns.type': 'Campaign Type',
  'search-campaigns.status': 'Status',
  'search-campaigns.validFrom': 'Valid From',
  'search-campaigns.validTill': 'Valid Till',
  'search-campaigns.action': 'Action',
  'search-campaigns.title': 'Search Campaigns',
  'search-campaigns.add.heading': 'Add Campaign',
  'search-campaigns.edit.heading': 'Edit Campaign',
  'search-campaigns.view.heading': 'View Campaign',
  'search-campaigns.add.text': 'Add Campaign',
  'search-campaigns.empty.campaigns':
    'Currently there are no campaigns available',
  'search-campaigns.form.image.label': 'Campaign Image',
  'search-campaigns.form.campaignType.label': 'Search Campaign Type',
  'search-campaigns.form.campaignType.placeholder':
    'Select search campaign type',
  'search-campaigns.form.pageType.label': 'Landing Page',
  'search-campaigns.form.pageType.placeholder': 'Select the Landing Page',
  'search-campaigns.form.campaignName.label': 'Search Campaign Name',
  'search-campaigns.form.campaignName.placeholder': 'Enter Campaign Name',
  'search-campaigns.form.defaultText.label': 'Search Default Text',
  'search-campaigns.form.defaultText.placeholder': 'Enter Default Text',
  'search-campaigns.form.keyword.label': 'Add Search Keywords',
  'search-campaigns.form.keyword.placeholder': 'Enter Search Keywords',
  'search-campaigns.form.radio.option.global': 'Global',
  'search-campaigns.form.radio.option.storeSpecific': 'Store specific',
  'search-campaigns.form.scope.radio.label': 'Scope',
  'search-campaigns.form.stores': 'Store',
  'search-campaigns.form.stores.placeholder': 'Select Store',
  'search-campaigns.form.status': 'Status',
  'search-campaigns.form.enabled': 'Enabled',
  'search-campaigns.form.disabled': 'Disabled',
  'search-campaigns.form.cancel': 'Cancel',
  'search-campaigns.form.save': 'Save',
  'search-campaigns.form.validFrom': 'Valid From',
  'search-campaigns.form.validTo': 'Valid To',
  'search-campaigns.filter.type': 'Search campaign type',
  'search-campaigns.filter.type.placeholder': 'Select search campaign type',
  'search-campaigns.filter.defaultText.label': 'Default Text',
  'search-campaigns.filter.defaultText.placeholder': 'Search by Default Text',
  'search-campaigns.filter.keyword.label': 'Keywords',
  'search-campaigns.filter.keyword.placeholder': 'Search by Keywords',
  'search-campaigns.filter.status': 'Status',
  'search-campaigns.filter.all': 'All',
  'search-campaigns.filter.enabled': 'Enabled',
  'search-campaigns.filter.disabled': 'Disabled',
  'search-campaigns.filter.pageType.label': 'Landing Page',
  'search-campaigns.filter.pageType.placeholder': 'Select the Landing Page',
  'search-campaigns.filter.category.heading': 'category',
  'search-campaigns.filter.brand.heading': 'brand',
  'search-campaigns.filter.tag.heading': 'tag',
  'search-campaigns.filter.category.placeholder': 'Enter category name',
  'search-campaigns.filter.brand.placeholder': 'Enter brand name',
  'search-campaigns.filter.tag.placeholder': 'Enter tag name',
  'search-campaigns.filter.name': 'Search campaign name',
  'search-campaigns.filter.name.placeholder': 'Search by Campaign Name',
  'search-campaigns.filter.id': 'Search campaign ID',
  'search-campaigns.filter.id.placeholder': 'Search by Campaign ID',
  'search-campaigns.filter.validFrom': 'Valid from',
  'search-campaigns.filter.validTill': 'Valid Till',
  'search-campaigns.filter.store': 'Store',
  'search-campaigns.filter.store.placeholder': 'Select the store',
  'search-campaigns.filter.searchTerm': 'Search term',
  'search-campaigns.filter.searchTerm.placeholder': 'Search by Search Term',
  'search-campaigns.filter.submit': 'Submit',
  'search-campaigns.filter.clear': 'Clear All',
  'search-campaigns.view': 'View',
  'search-campaigns.edit': 'Edit',
  'search-campaigns.delete': 'Delete',
  'search-campaigns.pageType.category': 'Category',
  'search-campaigns.pageType.brand': 'Brand',
  'search-campaigns.pageType.productTag': 'Tag',
  'search-campaigns.form.category.heading': 'category',
  'search-campaigns.form.brand.heading': 'brand',
  'search-campaigns.form.tag.heading': 'tag',
  'search-campaigns.form.category.placeholder': 'Enter category name',
  'search-campaigns.form.brand.placeholder': 'Enter brand name',
  'search-campaigns.form.tag.placeholder': 'Enter tag name',

  'lucky-draws.time.placeholder': 'Select Time',

  'campaign.startDate': 'Start Date',
  'campaign.endDate': 'End Date',
  'campaign.startTime': 'Start Time',
  'campaign.endTime': 'End Time',
  'campaign.searchByName': 'Search By Name',
  'campaign.enterTheName': 'Enter the name',

  'luckydraw.submit': 'Submit',
  'luckydraw.save': 'Save',
  'luckydraw.next': 'Next',
  'luckydraw.cancel': 'Cancel',
  'luckydraw.tooltip-note': 'Note: ',
  'luckydraw.tooltip-note1': 'Note 1: ',
  'luckydraw.tooltip-note2': 'Note 2: ',
  'luckydraw.draw':
    'Lucky draw entries must fall within/by the cut-off date (before 2359hrs) in order to qualify for the draw, i.e. all entries after this specified cut-off date will be rolled over and considered for the next draw if there are more than one draws (e.g. weekly draws).',
  'luckydraw.draw-1': 'You do not need to specify the draw date(s) here.',
  'luckydraw.baseChance': 'Base Chance',
  'luckydraw.missingField': 'This field is required',
  'luckydraw.drawWinners.button': ' Draw Winners',
  'luckydraw.baseChance-1':
    'Refers to every $X spent in a single transaction = 1 chance',
  'luckydraw.baseChance-2':
    'Amount spent by customer in a single transaction will be rounded down to the nearest dollar based on this (e.g. $1 qualifying spend = 1 chance. If customer spent $1.95 = 1 chance)',
  'luckydraw.bonusChance': 'Bonus or Additional chance (Optional)',
  'luckydraw.bonusChance-1':
    'Bonus/Additional chance(s) will be computed against Base chance, for every transaction',
  'luckydraw.bonusChance-2':
    'If there is more than one Rule set, rules will stack on top of each other',
  'luckydraw.date.header': 'Draw Dates',
  'luckydraw.date.note1':
    'For campaigns with more than one draw date, please add draws.',
  'luckydraw.date.note2':
    ' *Note: lucky draw entries will be rolled over to the subsequent draws if there is more than one draw.',
  'luckydraw.drawWinners.text': 'Draw Winners',
  'luckydraw.lookupWinners.text': 'Lookup Winners',
  'luckydraw.inputWinners.text': 'Input Winner ID e.g. 432423, 3123, 6566',
  'luckydraw.mechanicsTab.header': 'Chance Mechanics',
  'luckydraw.mechanicsTab.text': 'Rules to compute chances.',
  'luckydraw.cutOffDate.label': 'Cut-off Date',
  'luckydraw.noOfwinners.label': 'No. Of Winners',
  'luckydraw.noOfreserveWinners.label': 'No. Of Reserve Winners',
  'luckydraw.addDraw': 'Draw',
  'luckydraw.addDraw.button': '+ Add Draw',
  'luckydraw.campaignName.label': 'Campaign Name',
  'luckydraw.campaignName.placeholder': 'Enter Campaign Name',
  'luckydraw.prizes.label': 'Prizes To Be Won',
  'luckydraw.prizes.placeholder': 'Enter Prizes',
  'luckydraw.participation.label': 'How To Participate',
  'luckydraw.participation.placeholder': 'Enter Participation Process',
  'luckydraw.CampaignPageUrl.label': 'Campaign Page URL (Find Out More)',
  'luckydraw.CampaignPageUrl.placeholder': 'Enter Campaign Page URL',
  'luckydraw.tncsUrl.label': 'T&Cs URL',
  'luckydraw.tncsUrl.placeholder': 'Enter T&Cs URL',
  'luckydraw.winnersListUrl.label': 'Winners list URL',
  'luckydraw.winnersListUrl.placeholder': 'Enter Winners List URL',
  'luckydraw.uploadThumbNail.label': 'Thumbnail (W384 X H384)',
  'luckydraw.uploadThumbNail.placeholder':
    'Click Here To Upload Or Drag Your Thumbnail',
  'luckydraw.uploadBanner.label': 'Banner (W2250 X H1500)',
  'luckydraw.uploadBanner.placeholder':
    'Click Here To Upload Or Drag Your Banner',
  'luckydraw.userSegment':
    'Qualifying memberships. Leave blank if campaign is applicable to all users.',
  'luckydraw.channel-header': 'Channel',
  'luckydraw.channel':
    'Qualifiying channels & spend per chance for each channel.',
  'luckydraw.channel-validate-error':
    'Please select at least one qualifying channel',
  'luckydraw.baseChance-label': 'Qualifying Spend Per Chance',
  'luckydraw.rule': 'Rule',
  'luckydraw.add-rule': '+ Add Rule',
  'luckydraw.condition': 'Condition',
  'luckydraw.add-condition': '+ Add Condition',
  'luckydraw.condition-header': 'Inclusions Or Exclusions',
  'luckydraw.condition-header-2': 'Condition (Optional)',
  'luckydraw.inclusion-mechanics':
    'Amount spent by customer on entity type (Category / Product) to be included or excluded from the computing of qualifying spend.',
  'luckydraw.condition-note':
    'Conditions will apply to orders from the following channels only: Online (FPON), Scan & Go, LinkPay (FP)',
  'luckydraw.mechanics-operator': 'Operator',
  'luckydraw.awardSummary.note':
    'Input this when the challenge awards more than one type of rewards (e.g. linkpoints and offers). This will be displayed to users as an indicator of what they can stand to get when they complete the challenge.',
  'luckydraw.mechanics-select-operator': 'Select Operator',
  'luckydraw.condition-product': 'Product',
  'luckydraw.bulkUploadOfClientIDs': 'Bulk Upload of Client IDs',
  'luckydraw.condition-category': 'Category',
  'luckydraw.condition-entity': 'Entity',
  'luckydraw.rules-subtype': 'Subtype',
  'luckydraw.rules-type': 'Type',
  'luckydraw.rules-value': 'Value',
  'luckydraw.rules-selectsubtype': 'Select Subtype',
  'luckydraw.rules-selecttype': 'Select Type',
  'luckydraw.condition-selectentity': 'Select Entity Type',
  'luckydraw.condition-searchproduct': 'Search Product',
  'luckydraw.condition-searchcategory': 'Search Category Name',
  'luckydraw.verify-form': 'Check To Verify & Confirm',
  'luckydraw.selectDraw.placeholder': 'Select Draw',
  'luckydraw.previewDraw': 'Preview Draw',
  'luckydraw.actualDraw': 'Actual Draw',
  'luckydraw.Confirmation': 'Confirmation',
  'luckydraw.previewDraw.placeholder': 'Enter email address',
  'luckydraw.isManual':
    'Paper-based Draw (select if this is a paper-based draw)',
  'luckydraw.chooseDraw.error': 'Please choose a draw!',
  'luckydraw.participantsTab.email.error': 'Invalid email address',
  'luckydraw.server.error': 'Something went wrong! please try again later.',
  'luckydraw.requiredField.error': 'This field is required',
  'luckydraw.inputField.error': 'Invalid input. Winner ID should be numerical.',
  'luckydraw.drawWinnersPopUp.MainText': 'The System Will Randomly Generate:',
  'luckydraw.drawWinnersPopUp.drawResults.email':
    'Draw results will be sent to:',
  'luckydraw.drawWinnersPopUp.LoadingMsg':
    'Drawing winners in progress. Please wait while loading…',
  'luckydraw.drawWinnersPopUp.SubText':
    'For all qualifying transactions from campaign start date up to (and inclusive of):',
  'luckydraw.drawWinnersPopUp.winners': ' Winners',
  'luckydraw.drawWinnersPopUp.reserveWinners': ' Reserve Winners',
  'luckydraw.drawWinnersPopUp.preview.checkbox':
    'I understand that this only generates results for a preview draw.',
  'luckydraw.drawWinnersPopUp.checkbox':
    ' I understand the results of the draw will be generated and this action cannot be reverted.',
  'luckydraw.analytics': 'Analytics',
  'luckydraw.addLuckyDraw': '+ Add Lucky Draw',
  'luckydraw.drawWinnersList.sendEmail':
    'Email(s) to receive password protected CSV file of winner details',
  'luckydraw.drawWinnersList.inputEmail.placeholder':
    'Enter comma separated list of email addresses',
  'luckydraw.drawWinnersList.sendResults': 'Send Results',
  'luckydraw.drawWinnersList.successModal.headerMessage':
    'The results have been sent.',
  'luckydraw.drawWinnersList.successModal.description':
    'Please check the email inbox for the following:',
  'luckydraw.drawWinnersList.successModal.description.1':
    'Email with password-protected file attachment',
  'luckydraw.drawWinnersList.successModal.description.2': 'Email with password',
  'luckydraw.drawWinnersList.successModal.okay': 'OK, got it',
  'omnichallenge.addChallenge': '+ Add Challenge',
  'omnichallenge.campaignName.label': 'Title',
  'omnichallenge.awardSummary': 'Award Summary',
  'omnichallenge.customerTags': 'Customer Tags',
  'omnichallenge.awardSummary.placeholder': 'Enter award summary liner',
  'omnichallenge.campaignName.placeholder': 'Enter Challenge Title',
  'omnichallenge.uploadThumbNail.label': 'Thumbnail (W384 X H384)',
  'omnichallenge.uploadThumbNail.placeholder':
    'Click here to upload or drag your thumbnail',
  'omnichallenge.uploadBanner.label': 'Banner (W1080 X H720)',
  'omnichallenge.uploadBanner.placeholder':
    'Click here to upload or drag your banner',
  'omnichallenge.details': 'Challenge Details',
  'omnichallenge.tncUrl.label': 'T&Cs URL',
  'omnichallenge.tncUrl.placeholder': 'Enter T&Cs URL',
  'omnichallenge.details.placeholder':
    "Include step-by-step explanation on how customers can participate, how customers can go about completing the tasks and challenge, the T&Cs they can refer to etc \n\nExample:\n• Click 'Accept Challenge' to begin\n• Complete any task listed under the challenge to start earning Linkpoints\n• Use the 'Payment' feature in the FairPrice app to pay for your purchase in-store with an accumulated spend of $40 (can be combined across more than one purchase)\n• To get more information, see T&Cs at https://help.fairprice.com.sg/xxx",
  'omnichallenge.time.placeholder': 'Select Time',
  'omnichallenge.mechanics.header1': 'Tasks',
  'omnichallenge.reward.placeholder': 'Select Reward Type',
  'omnichallenge.addTasks': 'Add Task',
  'omnichallenge.task.isSequential': 'Is Sequential',
  'omnichallenge.task.header': 'Task',
  'omnichallenge.task.title': 'Title (max 85 characters)',
  'omnichallenge.task.placeholder':
    'Enter a description of what this task is about',
  'omnichallenge.task.type': 'Type',
  'omnichallenge.task.redemptionLimitation': 'Redemption Limitation',
  'omnichallenge.task.limitation': 'Limitation',
  'omnichallenge.description': 'Description',
  'omnichallenge.task.offerID': 'Offer ID',
  'omnichallenge.task.rewardsCatalogueID': 'Rewards Catalogue ID',
  'omnichallenge.task.customerTag': 'Customer Tag',
  'omnichallenge.task.reward.type': 'Reward Type',
  'omnichallenge.task.useraction': 'User Action',
  'omnichallenge.task.useraction.placeholder': 'Select User Action',
  'omnichallenge.task.channels': 'Channels Applicable',
  'omnichallenge.task.channels.placeholder': 'Select Channel',
  'omnichallenge.task.storesApplicable': 'Stores Applicable',
  'omnichallenge.task.storesApplicable.placeholder': 'Select Stores',
  'omnichallenge.task.accumulatedspendamount': 'Accumulated Spend Amount',
  'omnichallenge.task.minorder': 'No. Of Orders Required',
  'omnichallenge.task.minspendamount': 'Min. Spend Per Order',
  'omnichallenge.task.reward': 'Task Reward',
  'omnichallenge.task.rewardLimit': 'Quantity Limit (optional)',
  'omnichallenge.task.reward.optional': 'Task Reward (optional)',
  'omnichallenge.sequentialTasks': 'Sequential Task Completion',
  'omnichallenge.task.cardnetwork.VISA': 'Visa',
  'omnichallenge.task.cardnetwork.VISAPLUS': 'Visa Plus',
  'omnichallenge.task.cardnetwork.MasterCard': 'Mastercard',
  'omnichallenge.task.cardnetwork.AMEX': 'Amex',
  'omnichallenge.task.cardnetwork.label': 'Card Network Condition (optional)',
  'omnichallenge.task.cardnetwork.placeholder':
    'Eligible transactions by card network. Leave blank if all is eligible.',
  'omnichallenge.task.condition.label':
    'Category and/or Product Condition (optional)',
  'omnichallenge.task.condition.placeholder':
    'Amount spent by customer on entity type (Category and/or Product) to be included in the computing of qualifying spend.',
  'omnichallenge.task.banknetwork.label': 'Bank Network Condition (optional)',
  'omnichallenge.task.banknetwork.placeholder':
    'Eligible transactions by bank card. Leave blank if all is eligible.',
  'omnichallenge.task.eligibleProductsDeeplink': 'Eligible Products Deeplink',
  'omnichallenge.task.rewardHeader': 'Reward',
  'omnichallenge.task.targetSpend': 'Target Spend',
  'omnichallenge.addReward': 'Add Reward',
  'omnichallenge.value': 'Value',
  'omnichallenge.cancel': 'Cancel',
  'omnichallenge.next': 'Next',
  'omnichallenge.submit': 'Submit',
  'omnichallenge.save': 'Save',
  'omnichallenge.campaignDuration': 'Duration',
  'omnichallenge.createsimilar': 'Create Similar',
  'omnichallenge.rollingType.errorMessage':
    'This challenge can no longer be updated, since a new rolling challenge has been created. If you wish to make any changes, reach out to the technical team for assistance.',
  'rewardPage.dialogs.disable.message': 'This action cannot be reverted',
  'rewardPage.dialogs.disable.title': 'Are you sure?',
  'rewardPage.dialogs.disable.cancelText': 'Cancel',
  'rewardPage.dialogs.disable.okText': 'Okay',
  'rewardPage.edit': 'Edit',
  'rewardPage.enabled': 'Enabled',
  'rewardPage.disabled': 'Disabled',
  'rewardPage.disable': 'Disable',
  'rewardPage.enable': 'Enable',
  'rewardPage.userSegment-header': 'User Segment',
  'eVoucher.filter.entityName': 'Entity Name',
  'eVoucher.filter.FPStores': 'Entity Type',
  'eVoucher.filter.FPStores.options.all.stores': 'All Stores',
  'eVoucher.filter.FPStores.options.Non.FP.stores': 'Non FP Stores',
  'eVoucher.filter.FPStores.options.FP.stores': 'FP Stores',
  'eVoucher.filter.FPStores.placeholder': 'Select',
  'eVoucher.entities.UEN': 'UEN',
  'eVoucher.entities.billing': 'Billing Address',
  'eVoucher.entities.fp.account.emails': 'FP Account Email(s)',
  'eVoucher.entities.is.fp.store': 'Is FP Store',
  'eVoucher.entities.create': 'Create New Entity',
  'eVoucher.entities.customer.email': 'Customer Email(s)',
  'eVoucher.entities.comma.separated': 'Comma separated list of emails',
  'eVoucher.entities.confirm': 'Confirm Entity Details',
  'eVoucher.entities.saved': 'Changes saved.',
  'eVoucher.entities.error':
    ' does not exist. Please create account or remove email.',
  'eVoucher.entities.error.entityName':
    'Entity Name already exists. Unable to create new entity.',
  'eVoucher.entities.date.created': 'Date Created',
  'eVoucher.filter.ordercreatedon': 'Order Created On',
  'eVoucher.update.expiry': 'Update Expiry',
  'eVoucher.resend.email': 'Emails to receive vouchers',
  'eVoucher.resend.password': 'Emails to receive password',
  'eVoucher.update.newexpiry': 'New Expiry Date:',
  'eVoucher.create.invoice': 'Invoice Number',
  'eVoucher.create.allow': 'Allow expiry extension',
  'eVoucher.update.confirm': 'Confirm E-Voucher Expiry',
  'eVoucher.update.content': 'This action will update the expiry date.',
  'eVoucher.update.message': 'Expiry date udpated.',
  'eVoucher.refund.title': 'Refund Details',
  'eVoucher.update.status': 'Enable/Disable Voucher',
  'eVoucher.update.resultDetails':
    'This action will update the expiry date or the status.',
  'eVoucher.add.customer.status': 'Customer Payment Status',
  'evoucher.resend.method': 'Method:',
  'evoucher.resend.receiver': 'Receiver:',
  'evoucher.resend.resend': 'Resend',
  'eVoucher.details.denomination': '($)Denomination',
  'eVoucher.details.denominationDetails':
    'Denomination and Number of E-Vouchers',
  'eVoucher.details.numbers': 'Number of E-Vouchers',
  'eVoucher.disable.message': 'Successfully disabled!',
  'eVoucher.details.denominations': 'Balance Denominations',
  'eVoucher.details.totalVouchers': 'Total number of Vouchers',
  'eVoucher.details.grandTotal': 'Grand total',
  'eVoucher.details.batch': 'Voucher in Batch',
  'eVoucher.details.remarks': 'Remarks',
  'eVoucher.add.cost.centre': 'Cost Center',
  'eVoucher.voucher.receiver': 'Voucher receiver:',
  'eVoucher.password.receiver': 'Password receiver:',
  'games.title.add': 'Add Game',
  'games.title.edit': 'Edit Game',
  'games.title.landingpage': 'Game Settings',
  'games.input.gameName.label': 'Name',
  'games.input.gameName.placeholder': 'Enter Game Name',
  'games.input.gameDeepLink.label': 'Game Deeplink',
  'games.input.gameDeepLink.placeholder': 'Read only field',
  'games.startDate.label': 'Start Date',
  'games.endDate.label': 'End Date',
  'games.startTime.label': 'Start Time',
  'games.endTime.label': 'End Time',
  'games.providers.label': 'Providers Applicable',
  'games.providers.placeholder': 'Select Provider',
  'games.providerGameUrl.label': 'Provider Game URL',
  'games.providerGameUrl.placeholder': 'Enter Provider Game URL',
  'games.ruleCampaignUid.label': 'Rule Campaign Uid',
  'games.ruleCampaignUid.placeholder': 'Enter Rule Campaign Uid',
  'games.maxGameLimit.label': 'Max Game Limit per day',
  'games.maxGameLimit.placeholder': 'Enter Max Game Limit per day',
  'games.button.submit': 'Submit',
}

export default data
