import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import './style.scss'
import { ReactComponent as CloseIcon } from 'icons/close-icon-black.svg'

function ColorPicker({ className, value, onChange, disabled }) {
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [pickedColor, setPickedColor] = useState(null)

  useEffect(() => {
    // if the value prop is undefined or null, set the state to null, else to the value
    setPickedColor(value !== undefined ? value : null)
  }, [value])

  const confirmColor = () => {
    setShowColorPicker(false)
    onChange && onChange(pickedColor)
  }

  const clearColor = () => {
    setPickedColor(null)
    setShowColorPicker(false)
    onChange && onChange(null)
  }

  return (
    <div className={`color-picker-container ${className}`}>
      <div className="color-hex-code">{pickedColor || 'No color selected'}</div>
      <div className="color-display-container">
        <div
          className="selected-color-display"
          style={{ backgroundColor: pickedColor }}
          onClick={() => {
            if (!disabled) {
              setShowColorPicker(true)
            }
          }}
        ></div>
        {/* Display a clear button when color is selected. */}
        {pickedColor && !disabled && (
          <CloseIcon className="clear-color-icon" onClick={clearColor} />
        )}
      </div>
      {showColorPicker && (
        <div className="color-palette-wrapper">
          <SketchPicker
            color={pickedColor || ''} // Pass an empty string when pickedColor is null.
            onChangeComplete={(color) => setPickedColor(color.hex)}
          />
          <button
            className="button primary confirm-button"
            onClick={(e) => {
              e.preventDefault()
              confirmColor()
            }}
          >
            Confirm
          </button>
        </div>
      )}
    </div>
  )
}

export default ColorPicker
