import React from 'react'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import { TableProperties } from './TableProperties'
import Actions from './Actions'
import PrintFilter from '../PrintFilter'
import AddPrintOrder from '../AddPrintOrder'
import { getMessage } from 'lib/translator'
import { emptyState } from './emptyState'
import { GIFT_CARD_PAGINATION_SIZE } from 'pages/gift-card/utils/constants'

export const printOrderTransformResponse = (response) => {
  response.data.offset = 0
  response.data.limit = GIFT_CARD_PAGINATION_SIZE
  response.data.count = response.data.totalSize
  if (response.data.totalSize < GIFT_CARD_PAGINATION_SIZE) {
    // only one page data
    response.data.offset = 0
  } else if (response.data.nextPageToken > 0) {
    response.data.offset =
      (response.data.nextPageToken - 2) * GIFT_CARD_PAGINATION_SIZE
  } else if (response.data.totalSize > 0) {
    response.data.offset =
      (Math.ceil(response.data.totalSize / GIFT_CARD_PAGINATION_SIZE) - 1) *
      GIFT_CARD_PAGINATION_SIZE
  }
  return response.data.records || []
}

const PrintOrdersTable = (props) => {
  return (
    <ListingPageWithRoutes
      testid="print-orders-listing"
      title={getMessage('gift.card.print.orders')}
      addHeading={getMessage('gift.card.print.orders.add')}
      menu={props.menu}
      className="print-order-page"
      api={{
        url: `/gifting-admin-service/print-orders`,
        params: { ...props.params, page_size: GIFT_CARD_PAGINATION_SIZE },
        transform: (response) => printOrderTransformResponse(response),
      }}
      headerActions={() => <Actions />}
      filters={{
        component: PrintFilter,
      }}
      form={{
        component: AddPrintOrder,
      }}
      emptyState={emptyState}
      tableProperties={TableProperties()}
    />
  )
}

export default PrintOrdersTable
