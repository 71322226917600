import React from 'react'
import { BaseForm } from 'components/Form'
import { getMessage } from 'lib/translator'
import FilterBy from '../ScanToWin/FilterBy'
import { OMNI_JOURNEY_MENU } from '../utils'
import { StoresContext } from 'pages/omni-journey/ManageScanToWin'

const TRIGGERS = [
  { text: 'Product', value: 'product' },
  { text: 'Check-in', value: 'check-in' },
]

class Filters extends BaseForm {
  constructor(props) {
    super(props)
  }
  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    const { type } = this.props.options

    return (
      <StoresContext.Consumer>
        {({ stores }) => (
          <Form>
            <div className="filters-section">
              {type === OMNI_JOURNEY_MENU[1] && (
                <>
                  <FilterBy
                    type="trigger"
                    options={TRIGGERS}
                    generateStateMappers={this.generateStateMappers}
                  />
                  <FilterBy
                    type="store"
                    options={stores}
                    generateStateMappers={this.generateStateMappers}
                  />
                </>
              )}
            </div>
            <SubmitButton testid="submit-filter">
              {getMessage('omni-journey.submit-button')}
            </SubmitButton>
          </Form>
        )}
      </StoresContext.Consumer>
    )
  }
}

export default Filters
