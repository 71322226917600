import React from 'react'
import { BaseForm, Input, Select, VALIDATION_TYPES } from 'components/Form'
import { Popup } from 'components/Popup'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import { padCode } from 'pages/gift-card/utils/constants'

import ConfirmationDetails from '../../DesignCodes/ConfirmationDetails'
import { multipleEmailErrorMsg, emailRegex } from 'components/Evoucher/utils'

class AddSalesOrder extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values = {
      showPopup: false,
      isDesignCodeExist: false,
      denominationType: '',
      designCodes: [],
      selectedItem: [],
      stores: [],
    }

    this._validateSalesOrder = this._validateSalesOrder.bind(this)
    this.fetchDesignCodes = this.fetchDesignCodes.bind(this)
    this.fetchStores = this.fetchStores.bind(this)
  }

  async _validateSalesOrder() {
    const {
      type,
      entityId,
      customerEmails,
      paymentMode,
      denomination,
      quantity,
    } = this.state.values
    const { selectedItem } = this.state
    const customerEmailsData = customerEmails
      .split(',')
      .map((item) => item.trim())

    const items = selectedItem?.map(
      ({ text: _text, value: _value, ...rest }) => ({
        ...rest,
        ...{ denomination, quantity },
      })
    )

    const data = {
      type,
      entityId,
      customerEmails: customerEmailsData,
      paymentMode,
      items,
    }
    try {
      const url = `/gifting-admin-service/sales-orders`
      const api = new API({ url: url })
      await api.post(data)
      this.props.onCancel()
    } catch (error) {
      this.setState({
        showPopup: false,
      })
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
      isDesignCodeExist: false,
    })
    const isValid = this.isFormValid()
    if (isValid) {
      this.setState({ showPopup: true })
    }
  }

  async fetchDesignCodes() {
    const api = new API({
      url: '/gifting-admin-service/design-codes',
    })
    try {
      const res = await api.get()
      this.setState({
        designCodes: res.data?.records?.map((item) => ({
          text: `${padCode(item.id)} - ${item?.denominationType === 'FLEXI' ? getMessage('order.personal') : getMessage('offer.ordertype.options.b2b')}`,
          value: item.id,
          designCode: item.id,
          denomination: item.denomination || 0,
          quantity: item.reservedQty,
        })),
      })
    } catch (error) {
      console.log(error, 'Error fetching Design Codes')
    }
  }

  async fetchStores(isFpStore = false) {
    const api = new API({ url: '/gifting-admin-service/entities' })
    const params = {
      is_fp_store: isFpStore,
    }
    try {
      const response = await api.get(params)
      const raw = response.data.entities || []
      const entityNames = raw.map((element) => {
        return {
          value: element.id,
          text: element.name,
        }
      })
      this.setState({ stores: entityNames })
    } catch (error) {
      console.log(error, 'Error fetching Stores')
    }
  }

  async componentDidMount() {
    this.fetchDesignCodes()
  }

  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons

    return (
      <Form>
        <div className="print-order-details">
          <div className="row">
            <div className="col-6">
              <Select
                required
                name="type"
                testid="type"
                placeholder={getMessage(
                  'gift.card.design.codes.input.denomination.type'
                )}
                label={getMessage(
                  'gift.card.design.codes.input.denomination.type'
                )}
                options={[
                  {
                    text: getMessage('offer.ordertype.options.b2b'),
                    value: 'B2B',
                  },
                  {
                    text: getMessage('order.personal'),
                    value: 'B2C',
                  },
                ]}
                onChange={(e) => {
                  this.setState((prevState) => ({
                    values: {
                      ...prevState.values,
                      type: e,
                    },
                  }))
                  this.fetchStores(e === 'B2C')
                }}
                value={this.state.values?.type}
              />
              <Select
                name="entityId"
                testid="entityId"
                required
                placeholder={getMessage('offer.entity.id')}
                options={this.state.stores}
                label={getMessage('offer.entity.id')}
                {...this.generateStateMappers({
                  stateKeys: ['entityId'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
              <Input
                required
                name="customerEmails"
                testid="customerEmails"
                placeholder={getMessage('gift.card.sales.orders.email')}
                label={getMessage('gift.card.sales.orders.email')}
                pattern={emailRegex}
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ['customerEmails'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={multipleEmailErrorMsg}
              />
              <Select
                required
                name="paymentMode"
                testid="paymentMode"
                placeholder={getMessage('order.filters.payment.heading')}
                label={getMessage('order.filters.payment.heading')}
                options={[
                  {
                    text: getMessage('gift.card.sales.orders.credit.term'),
                    value: 'CREDIT_TERM',
                  },
                  {
                    text: getMessage('gift.card.sales.orders.draw.down'),
                    value: 'DRAW_DOWN',
                  },
                  {
                    text: getMessage('gift.card.sales.orders.post.paid'),
                    value: 'POST_PAID',
                  },
                ]}
                {...this.generateStateMappers({
                  stateKeys: ['paymentMode'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
              <Select
                required
                name="select-status"
                placeholder={getMessage(
                  'gift.card.print.orders.select.placeholder'
                )}
                label={getMessage('gift.card.print.orders.design.code')}
                options={this.state.designCodes}
                type="number"
                testid="design-code-sales-order"
                value={this.state.selectedItem?.map((item) => item.value)}
                onChange={(e) => {
                  this.setState((prevState) => ({
                    selectedItem: prevState.designCodes?.filter(
                      (obj) => obj.value === e
                    ),
                  }))
                }}
              />

              <Input
                name="quantity"
                testid="quantity"
                placeholder={getMessage('gift.card.print.orders.quantity')}
                type="number"
                min={0}
                label={getMessage('gift.card.print.orders.quantity')}
                {...this.generateStateMappers({
                  stateKeys: ['quantity'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
              <Input
                name="denomination"
                testid="denomination"
                placeholder={getMessage(
                  'gift.card.design.codes.input.denomination'
                )}
                type="number"
                min={0}
                label={getMessage('gift.card.design.codes.input.denomination')}
                {...this.generateStateMappers({
                  stateKeys: ['denomination'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
            </div>
          </div>
        </div>
        <SubmitButton>{getMessage('offer.submit')}</SubmitButton>
        <CancelButton testid="cancel-submit">
          {getMessage('offer.cancel')}
        </CancelButton>
        <Popup
          show={this.state.showPopup}
          heading={'Confirm Sales Order Details'}
          className="confirmPopup"
          close={() => this.setState({ showPopup: false })}
          data-testid="confirm-popup"
        >
          <ConfirmationDetails
            isSalesOrder
            {...this.state.values}
            selectedItem={this.state.selectedItem}
            onConfirm={this._validateSalesOrder}
          />
        </Popup>
      </Form>
    )
  }
}

export default AddSalesOrder
