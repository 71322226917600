import { hasPermissions } from 'lib/auth'
import { get } from 'lib/storage'
import SPLIT_FEATURES from 'containers/SplitContext/features'

const flags = JSON.parse(get('SPLIT_FEATURE_FLAGS'))
const useDeliveryOrdersEndpointPermission =
  flags?.[SPLIT_FEATURES.USE_DELIVERY_ORDERS_ENDPOINT_PERMISSION]?.treatment ===
  'on'

const service = 'order'
const endpoint = useDeliveryOrdersEndpointPermission
  ? 'v3/deliveryOrders'
  : 'order'

export const hasOrderGetPermissions = () =>
  hasPermissions(service, endpoint, 'get')
export const hasOrderEditPermissions = () =>
  hasPermissions(service, endpoint, 'put')
export const hasOrderPostPermissions = () =>
  hasPermissions(service, endpoint, 'post')
