import React from 'react'
import { getMessage } from 'lib/translator'
import emptyIcon from './empty.svg'
import Actions from './Actions'

export const emptyState = {
  icon: emptyIcon,
  message: getMessage('gift.card.print.orders.empty.message'),
  actions: () => <Actions />,
}
