export const dropDownOptions = ['Playable']

export const initialGameValue = {
  name: '',
  gameDeepLink: '',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  providers: '',
  providerGameUrl: '',
  ruleCampaignUid: '',
  maxGameLimit: '',
  gameRules: [],
}

export const requiredFields = [
  'name',
  'startDate',
  'startTime',
  'endDate',
  'endTime',
  'providers',
  'providerGameUrl',
  'ruleCampaignUid',
  'maxGameLimit',
]

export const dateAndTimeDeconstructor = (date) => {
  const parseISOT = date.split('T')
  const parseISOZ = parseISOT[1].split('+')
  return {
    date: parseISOT[0],
    time: parseISOZ[0],
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'game_name':
      return { ...state, name: action.value }
    case 'start_date':
      return { ...state, startDate: action.value }
    case 'start_time':
      return { ...state, startTime: action.value }
    case 'end_date':
      return { ...state, endDate: action.value }
    case 'end_time':
      return { ...state, endTime: action.value }
    case 'providers':
      return { ...state, providers: action.value }
    case 'provider_game_url':
      return { ...state, providerGameUrl: action.value }
    case 'rule_campaign_uid':
      return { ...state, ruleCampaignUid: action.value }
    case 'max_game_limit':
      return { ...state, maxGameLimit: action.value }
    case 'fetch_edit':
      return { ...action.value }
    case 'cancel':
      return { ...initialGameValue }
    default:
      return { ...state }
  }
}

export const handleError = ({
  setShowPopup,
  setError,
  setErrorMsg,
  errMessage,
}) => {
  setShowPopup(true)
  setError(true)
  setErrorMsg(errMessage)
}

export const getUpdateGameBody = (body, gameState) => {
  return {
    ...body,
    rules: [
      {
        id: gameState.gameRules[0].id,
        type: 'frequency',
        value: {
          maxPerDay: gameState.maxGameLimit,
        },
      },
    ],
  }
}

export const getAddGameBody = (gameState, startAt, endAt) => {
  return {
    name: gameState.name,
    config: {
      provider: {
        name: gameState.providers,
        url: gameState.providerGameUrl,
      },
      ruleCampaign: {
        id: gameState.ruleCampaignUid,
      },
    },
    rules: [
      {
        type: 'frequency',
        value: {
          maxPerDay: gameState.maxGameLimit,
        },
      },
    ],
    type: 'Generic',
    startAt,
    endAt,
  }
}

export const copyGameDeepLink = () => {
  var copyText = document.getElementById('game-deeplink')

  // Select the text field
  copyText.select()
  copyText.setSelectionRange(0, 99999) // For mobile devices
  // Copy the text inside the text field
  navigator.clipboard.writeText(copyText.value)
  alert('Copied to clipboard')
}

export const mockTestGameObject = {
  data: {
    name: 'game name',
    startAt: '2025-01-24T00:00:00.729+08:00',
    endAt: '2030-01-24T00:00:00.729+08:00',
    uuid: 'abc',
    config: {
      provider: {
        name: 'Playable',
        url: 'provider url',
      },
      ruleCampaign: {
        id: 'provider id',
      },
    },
    rules: [{ value: { maxPerDay: 2 } }],
  },
}
