import React from 'react'
import { getMessage } from 'lib/translator'
import { Link } from 'react-router-dom'
import ListingPage from 'containers/ListingPage'
import ScanCampaignTableProperties from './ScanCampaignTableProperties'
import emptyIcon from 'icons/no-orders.svg'

const AddNewScanCampaignButton = () => (
  <Link to="/marketing/scan-campaigns/new">
    <button className="primary button">
      {`+ ${getMessage('offer.campaigns.listing.add.offerCampaign')}`}
    </button>
  </Link>
)

const ScanCampaignsTable = ({ menu }) => {
  return (
    <ListingPage
      testid="offer-scan-campaign-listing"
      menu={menu}
      className="offer-scan-campaigns-page"
      title={getMessage('offer.scanCampaigns.listing.heading')}
      api={{
        url: '/scan-campaign-service/campaigns',
        transform: (response) => response.data.campaign,
      }}
      tableDynamic
      primaryKey="id"
      tableProperties={ScanCampaignTableProperties()}
      headerActions={AddNewScanCampaignButton}
      emptyState={{
        icon: emptyIcon,
        message: getMessage('offer.scanCampaigns.listing.noCampaign'),
        actions: AddNewScanCampaignButton,
      }}
      nextPrevButtonsEnabled
      dontSavePagination
    />
  )
}

export default ScanCampaignsTable
