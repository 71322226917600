import React from 'react'
import {
  cloneMutables,
  getNestedState,
  updateStateRecursively,
} from '../../lib/stateManagement/'

import {
  Input as _Input,
  Select as _Select,
  Upload as _Upload,
  ImageUpload as _ImageUpload,
  Textarea as _Textarea,
  Phone as _Phone,
  Checkbox as _Checkbox,
  Radio as _Radio,
  Searchable as _Searchable,
  CheckboxGroup as _CheckboxGroup,
  DateRangePicker as _DateRangePicker,
  SingleDatePicker as _SingleDatePicker,
  MultiLevelCheckbox as _MultiLevelCheckbox,
  MultiSelect as _MultiSelect,
  MultiTextInput as _MultiTextInput,
  Toggle as _Toggle,
  SelectSearch as _SelectSearch,
  ProductSearch as _ProductSearch,
  BrandSearch as _BrandSearch,
  DateTime as _DateTime,
  CategorySearch as _CategorySearch,
  PaymentSearch as _PaymentSearch,
  CustomerTagSearch as _CustomerTagSearch,
  TimePicker as _TimePicker,
  StoreSearch as _StoreSearch,
  CampaignSearch as _CampaignSearch,
  ColorPicker as _ColorPicker,
} from './Inputs'

import infoIcon from './info-icon.svg'

import './form.scss'
import classNames from 'classnames'
import { _PlainInput } from './Inputs/PlainInput'
import { _PlainSelect } from './Inputs/PlainSelect'

const VALIDATION_TYPES = {
  ALWAYS: 'ALWAYS',
  ONCHANGE: 'ONCHANGE',
  ONBLUR: 'ONBLUR',
  ONSUBMIT: 'ONSUBMIT',
}

// A helper method to easily register input events like change, blur, etc.
// NOTE: Context of this method should be bound to the respective form
function generateStateMappers({
  formatValue = (formatValueInput) => formatValueInput,
  formatChange = (formatChangeInput) => formatChangeInput,
  defaultValue,
  validationType = VALIDATION_TYPES.ONSUBMIT,
  stateKeys,
  loseEmphasisOnFill = false,
  beforeChange = (beforeChangeInput) => beforeChangeInput,
  onChange,
}) {
  let showErrors = false
  if (validationType) {
    switch (validationType) {
      case VALIDATION_TYPES.ALWAYS:
        showErrors = true
        break
      case VALIDATION_TYPES.ONCHANGE:
        showErrors = this.validationScenarios.validateOnChange(stateKeys)
        break
      case VALIDATION_TYPES.ONBLUR:
        showErrors = this.validationScenarios.validateOnBlur(stateKeys)
        break
      case VALIDATION_TYPES.ONSUBMIT:
        showErrors = this.validationScenarios.validateOnSubmit()
        break
      default:
        break
    }
  }
  let emphasize = true
  if (loseEmphasisOnFill) {
    // emphasize = false only if value is non-empty
    const stateKeysValue = this.getState(stateKeys)
    if (stateKeysValue !== undefined && stateKeysValue !== null) {
      const type = typeof stateKeysValue
      switch (type) {
        case 'boolean':
          emphasize = false
          break
        case 'object':
          if (
            (Array.isArray(stateKeysValue) && stateKeysValue.length) ||
            Object.keys(stateKeysValue).length
          ) {
            emphasize = false
          }
          break
        case 'number':
          if (stateKeysValue || stateKeysValue === 0) {
            emphasize = false
          }
          break
        default:
          if (stateKeysValue) {
            emphasize = false
          }
          break
      }
    }
  }
  let value = this.getState(stateKeys)
  if (value !== 0 && !value) {
    value = defaultValue
  }

  return {
    value: formatValue(value),
    onChange: (updatedValue) => {
      onChange && onChange(updatedValue)
      updatedValue = formatChange(updatedValue)
      this.updateState(stateKeys, updatedValue)
      updateStateRecursively.call(this, ['blurred', ...stateKeys], false)
    },
    onValidation: (error) => {
      this.registerValidation(stateKeys, error)
    },
    onBlur: () => {
      value = beforeChange(value)
      this.updateState(stateKeys, value)
      updateStateRecursively.call(this, ['blurred', ...stateKeys], true)
    },
    // Whether the input should be emphasized (with bold label, etc)
    emphasize,
    showErrors,
  }
}

export default function Form(props) {
  return <form {...props} />
}

class BaseForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      values: props.value ? cloneMutables(props.value) : {}, // Use immutable objects instead
      touched: {},
      blurred: {},
      validations: {},
      submitting: props.submitting || false,
      pressedSubmitWithCurrentData: false,
    }
    this.getState = this.getState.bind(this)
    this.addKeys = this.addKeys.bind(this)
    this.deleteKeys = this.deleteKeys.bind(this)
    this._submitHandler = this._submitHandler.bind(this)
    this.generateStateMappers = generateStateMappers.bind(this)
    this.beforeSubmit = this.beforeSubmit.bind(this)
    this.afterSubmit = this.afterSubmit.bind(this)
    this.buttons = {
      SubmitButton: (submitButtonProps) => (
        <input
          className="primary"
          type="submit"
          value={this.state.submitting ? '...' : submitButtonProps.children}
          disabled={submitButtonProps.disabled || this.state.submitting}
          data-testid={submitButtonProps.testid}
          ref={submitButtonProps.buttonRef}
        />
      ),
      CancelButton: (cancelButtonProps) => (
        <button
          className="button"
          type="button"
          disabled={cancelButtonProps.disabled}
          onClick={this.props.onCancel}
          data-testid={cancelButtonProps.testid}
        >
          {cancelButtonProps.children}
        </button>
      ),
      ClearButton: (clearButtonProps) => (
        <button
          className="button"
          type="button"
          disabled={clearButtonProps.disabled}
          onClick={this.props.onClear || clearButtonProps.onClear}
          data-testid={clearButtonProps.testid}
        >
          {clearButtonProps.children}
        </button>
      ),
    }
    this.components = {
      Form: (formProps) => (
        <form
          data-testid={formProps.testid}
          className={formProps.className}
          ref={formProps.formRef}
          onSubmit={this._submitHandler}
          noValidate
        >
          {formProps.children}
        </form>
      ),
    }

    // Some validation helpers
    this.validationScenarios = {
      validateOnChange: (stateKeys) =>
        getNestedState.call(this, ['touched', ...stateKeys]),
      validateOnBlur: (stateKeys) =>
        getNestedState.call(this, ['blurred', ...stateKeys]),
      validateOnSubmit: () => this.state.pressedSubmitWithCurrentData,
    }
  }
  getState(keys) {
    return getNestedState.call(this, ['values', ...keys])
  }
  deleteKeys(keyList) {
    const { values, validations } = this.state
    if (values) {
      keyList.forEach((key) => values[key] && delete values[key])
    }
    keyList.forEach((key) => validations[key] && delete validations[key])
    this.setState({ values, validations })
  }
  addKeys(values) {
    this.setState({ values })
  }
  getCurrentState() {
    return this.state
  }
  updateState(keys, value, registerAsUserInput = true) {
    this.setState({
      pressedSubmitWithCurrentData: false,
    })
    updateStateRecursively.call(this, ['values', ...keys], value)
    if (registerAsUserInput) {
      // False value implies that this value was modified programatically, so don't modify 'touched' value
      updateStateRecursively.call(this, ['touched', ...keys], true)
    }
  }
  registerValidation(keys, error) {
    updateStateRecursively.call(this, ['validations', ...keys], error)
  }
  isFormValid(validationObj) {
    let validations = validationObj || this.state.validations
    if (!validations) {
      validations = {}
    }
    if ('valid' in validations) {
      return validations.valid
    }
    return Object.keys(validations)
      .map((name) =>
        Array.isArray(validations[name])
          ? // If this field has more fields nested in it
            validations[name]
              .map((validation) => this.isFormValid(validation))
              .reduce((value, acc) => value && acc, true)
          : 'valid' in validations[name]
            ? validations[name].valid
            : !(
                  typeof validations[name] === 'object' &&
                  Object.keys(validations[name]).length === 0
                ) &&
                typeof Object.values(Object.values(validations[name])[0])[0] ===
                  'boolean'
              ? this.isFormValid(validations[name])
              : Object.values(validations[name])
                  .map((validation) => this.isFormValid(validation))
                  .reduce((value, acc) => value && acc, true)
      )
      .reduce((value, acc) => value && acc, true)
  }
  beforeSubmit(_data) {
    // Update this method if you want to do something before submission happens
    // E.g. Navigate to an input with an error in the page
  }
  onSubmit(_data) {
    // Form submission logic. This is the function that the user would define
  }
  afterSubmit(_data) {
    // Update this method if you want to do something after submission happens
    // E.g. Do extra API calls to update something else
  }
  _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    if (isValid) {
      if (this.props.onSubmit) {
        const values = cloneMutables(this.state.values) // Use immutable objects instead
        this.props.onSubmit(values)
      } else {
        this.onSubmit(this.state.values)
      }
      this.afterSubmit(this.state.values)
    }
  }
  /* To overwrite this, create UNSAFE_componentWillReceiveProps in the component where BaseForm is extended */
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.value) {
      const values = cloneMutables(newProps.value) // Use immutable objects instead
      this.setState({
        values: values,
        submitting: newProps.submitting,
      })
    }
  }
  render() {
    const { Form: ThisForm } = this.components
    return <ThisForm />
  }
}

// HoC to wrap Inputs, providing them form-related styling and functionality
const wrapInput = (Component) =>
  function InputWrapper({ className, style = {}, ...props }) {
    if (!props.label) {
      return <Component {...props} />
    }
    return (
      <div className={classNames('field', className)} style={style}>
        <span className="labelWrap">
          {props.label ? (
            <label
              htmlFor={props.name}
              className={
                props.emphasize === false || props.readOnly === true
                  ? 'thin'
                  : null
              }
            >
              {props.label}
            </label>
          ) : null}
          {props.secondaryLabel && props.secondaryLabel()}
          {props.tooltip && (
            <div className="label-tooltip">
              <img src={infoIcon} alt="note" />
              <div className="label-tooltiptext">{props.tooltip}</div>
            </div>
          )}
        </span>
        {props.filter && props.filter()}
        <Component {...props} />
        {props.children && <div className="description">{props.children}</div>}
      </div>
    )
  }

const Input = wrapInput(_Input)
const PlainInput = wrapInput(_PlainInput)
const PlainSelect = wrapInput(_PlainSelect)
const Upload = wrapInput(_Upload)
const Select = wrapInput(_Select)
const ImageUpload = wrapInput(_ImageUpload)
const Textarea = wrapInput(_Textarea)
const Phone = wrapInput(_Phone)
const Checkbox = wrapInput(_Checkbox)
const Radio = wrapInput(_Radio)
const Searchable = wrapInput(_Searchable)
const CheckboxGroup = wrapInput(_CheckboxGroup)
const DateRangePicker = wrapInput(_DateRangePicker)
const SingleDatePicker = wrapInput(_SingleDatePicker)
const MultiLevelCheckbox = wrapInput(_MultiLevelCheckbox)
const MultiSelect = wrapInput(_MultiSelect)
const MultiTextInput = wrapInput(_MultiTextInput)
const Toggle = wrapInput(_Toggle)
const SelectSearch = wrapInput(_SelectSearch)
const ProductSearch = wrapInput(_ProductSearch)
const BrandSearch = wrapInput(_BrandSearch)
const CustomerTagSearch = wrapInput(_CustomerTagSearch)
const DateTime = wrapInput(_DateTime)
const CategorySearch = wrapInput(_CategorySearch)
const PaymentSearch = wrapInput(_PaymentSearch)
const TimePicker = wrapInput(_TimePicker)
const StoreSearch = wrapInput(_StoreSearch)
const CampaignSearch = wrapInput(_CampaignSearch)
const ColorPicker = wrapInput(_ColorPicker)

export {
  BaseForm,
  VALIDATION_TYPES,
  Input,
  Select,
  Upload,
  ImageUpload,
  Textarea,
  Phone,
  Checkbox,
  Radio,
  Searchable,
  CheckboxGroup,
  DateRangePicker,
  SingleDatePicker,
  MultiLevelCheckbox,
  MultiSelect,
  MultiTextInput,
  Toggle,
  SelectSearch,
  ProductSearch,
  BrandSearch,
  DateTime,
  CategorySearch,
  PaymentSearch,
  CustomerTagSearch,
  TimePicker,
  StoreSearch,
  CampaignSearch,
  PlainInput,
  PlainSelect,
  ColorPicker,
}
