import React, { Component } from 'react'
import moment from 'moment'
import Table, { Row, Header, Cell } from '../../../components/Table'
import { SingleDatePicker } from '../../../components/Form'
import { Link } from 'react-router-dom'
import { getMinutes, formatTime, formatDate } from '../../../lib/datetime'
import { getMessage } from '../../../lib/translator'
import { get } from '../../../lib/storage'
import { isEqual } from 'lodash'
import ZonesDropdown from './ZonesDropdown'

class SlotOrderTable extends Component {
  constructor() {
    super()
    this.state = {
      total: {},
      asapSlotColumn: [],
    }
    this.generateStateFromProps = this.generateStateFromProps.bind(this)
  }

  generateStateFromProps() {
    const { asapSlots, packlistFixedSlots, packlistStatuses } = this.props.data
    const { fixedSlots: fixedSlotsProp, statuses: statusesProp } =
      this.props.data
    const { storeZone } = this.props

    let fixedSlots = fixedSlotsProp
    let statuses = statusesProp
    if (storeZone) {
      fixedSlots = packlistFixedSlots
      statuses = packlistStatuses
    }
    const total = {}
    statuses &&
      statuses.map((status) => {
        total[status] = 0
        return null
      })

    fixedSlots &&
      fixedSlots.map((object) => {
        const countOb = object.count
        if (countOb) {
          Object.keys(countOb).map((status) => {
            total[status] += Number(countOb[status])
            return null
          })
        }
        return object.slot
      })

    const asapSlotColumn =
      asapSlots &&
      asapSlots.map((object) => {
        const countOb = object.count
        if (countOb) {
          Object.keys(countOb).map((status) => {
            total[status] += Number(countOb[status])
            return null
          })
        }
        return object.slot
      })
    this.setState({ asapSlotColumn, total })
  }

  getLinkForPacklistBreakdown(link, startTime, endTime, packlistStatus) {
    const { storeZone, selectedDate } = this.props
    const linkWithStore = link + get('store')
    const slot = `&slot=${JSON.stringify({ startTime: startTime, endTime: endTime })}`
    const status = packlistStatus
      ? `&${storeZone ? `packlistStatus` : `status`}=${this.getStatus(packlistStatus.toUpperCase(), storeZone)}`
      : ''
    const zone = `${storeZone && storeZone !== 'none' ? `&packlistZone=${storeZone}` : ``}`
    const preferredDate = `&preferredDate=${selectedDate}`
    return `${linkWithStore}${slot}${status}${zone}${preferredDate}`
  }

  componentDidMount() {
    this.generateStateFromProps()
  }

  componentDidUpdate(prevProps) {
    const currData = this.props.data
    const prevData = prevProps.data
    if (
      !isEqual(currData.fixedSlots, currData.fixedSlots) ||
      !isEqual(prevData.fixedSlots, currData.fixedSlots) ||
      !isEqual(currData.packlistFixedSlots, prevData.packlistFixedSlots)
    ) {
      this.generateStateFromProps()
    }
  }

  getRedirectLink() {
    return '/operations/delivery-orders?'
  }

  getStatus(status, storeZone) {
    return storeZone ? status : `DELIVERY_ORDER_STATUS_${status}`
  }

  render() {
    const {
      asapSlots,
      noSlots,
      totalPacklists,
      packlistFixedSlots,
      packlistStatuses,
    } = this.props.data
    const {
      totalOrders: totalOrdersProp,
      fixedSlots: fixedSlotsProp,
      statuses: statusesProp,
    } = this.props.data
    const {
      areOrdersScheduled,
      showZonesDropdown,
      handleStoreZoneChange,
      storeZoneConfig,
      storeZone,
      selectedDate,
      handleDateChange,
    } = this.props
    let link = `${this.getRedirectLink()}storeId=`
    let totalOrders = totalOrdersProp
    let fixedSlots = fixedSlotsProp
    let statuses = statusesProp
    if (storeZone) {
      totalOrders = totalPacklists
      fixedSlots = packlistFixedSlots
      statuses = packlistStatuses
      link = '/operations/delivery-orders?storeId='
    }
    const { asapSlotColumn } = this.state
    const today = moment().format('YYYY-MM-DD')
    return (
      <div className="order-stats">
        <div className="header-container">
          <div className="left">
            <div className="heading">
              {`${storeZone ? 'Packlist Breakdown ' : 'Orders'} ${
                selectedDate === today
                  ? 'Today'
                  : 'On ' + formatDate(selectedDate)
              }`}
              <span className="orders-count">({totalOrders})</span>
            </div>
          </div>
          {showZonesDropdown ? (
            <div className="middle">
              <div className="zonesDropdown">
                <ZonesDropdown
                  onChange={handleStoreZoneChange}
                  storeZoneConfig={storeZoneConfig}
                  isZoneError={false}
                  value={storeZone}
                  disabled={false}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="right">
            <div className="date-select">
              <SingleDatePicker
                allowAllDates={areOrdersScheduled ? true : undefined}
                isOutsideRange={!areOrdersScheduled ? true : undefined}
                displayFormat="YYYY-MM-DD"
                value={selectedDate}
                openDirection="down"
                onChange={handleDateChange}
              />
            </div>
          </div>
        </div>
        {statuses && statuses.length > 0 && (
          <div className="order-table-container">
            <Table className="order-table">
              <Header>
                <Cell className="first-column-header">
                  {noSlots
                    ? getMessage('order.filters.status.heading')
                    : getMessage('operations.dashboard.slot')}
                </Cell>
                {statuses &&
                  statuses.map((status, index) => (
                    <Cell key={`status-${index}`}>
                      <Link
                        to={
                          link +
                          `${get(
                            'store'
                          )}&${storeZone ? `packlistStatus` : `status`}=${this.getStatus(status.toUpperCase(), storeZone)}${storeZone ? `&packlistZone=` + storeZone : ``}`
                        }
                      >
                        {storeZone
                          ? status.substring(16).toUpperCase()
                          : status.toUpperCase()}
                      </Link>
                    </Cell>
                  ))}
              </Header>
              {statuses && asapSlotColumn && asapSlotColumn.length > 0 && (
                <Row className="first-column">
                  {asapSlotColumn.map((slot, index) => (
                    <Row key={`asap-slot-name-${index}`}>
                      <Cell>
                        <Link
                          to={`${this.getRedirectLink()}storeId=${get(
                            'store'
                          )}&slotType=ASAP&slotEndTime=${
                            slot.endTime
                          }&preferredDate=${selectedDate}`}
                        >
                          {`${getMessage('slots.asap')} ${getMinutes(slot.endTime)} ${getMessage('slots.asap.minute')}`}
                        </Link>
                      </Cell>
                    </Row>
                  ))}
                </Row>
              )}
              {statuses &&
                asapSlots &&
                asapSlots.map((slot, index) => (
                  <Row key={`asap-slot-${index}`}>
                    <Cell>
                      <Link
                        to={`${this.getRedirectLink()}storeId=${get(
                          'store'
                        )}&slotType=ASAP&slotEndTime=${
                          slot.slot.endTime
                        }&preferredDate=${selectedDate}`}
                      >
                        {`${getMessage('slots.asap')} ${getMinutes(slot.slot.endTime)} ${getMessage('slots.asap.minute')}`}
                      </Link>
                    </Cell>
                    {statuses &&
                      statuses.map((status, i) => (
                        <Cell key={`asap-slot-${status}-${i}-${index}`}>
                          <Link
                            to={`${this.getRedirectLink()}storeId=${get(
                              'store'
                            )}&slotType=ASAP&slotEndTime=${
                              slot.slot.endTime
                            }&status=${this.getStatus(status.toUpperCase())}&preferredDate=${selectedDate}`}
                          >
                            {slot.count[status] || '-'}
                          </Link>
                        </Cell>
                      ))}
                  </Row>
                ))}
              {statuses &&
                fixedSlots &&
                fixedSlots.map((slot, index) => (
                  <Row key={`std-slot-${index}`}>
                    <Cell>
                      <Link
                        to={this.getLinkForPacklistBreakdown(
                          link,
                          encodeURIComponent(slot.slot.startTime),
                          encodeURIComponent(slot.slot.endTime)
                        )}
                      >
                        {`${formatTime(slot.slot.startTime)} - ${formatTime(slot.slot.endTime)}`}
                      </Link>
                    </Cell>
                    {statuses &&
                      statuses.map((status, i) => (
                        <Cell key={`std-slot-${status}-${index}-${i}`}>
                          <Link
                            to={this.getLinkForPacklistBreakdown(
                              link,
                              encodeURIComponent(slot.slot.startTime),
                              encodeURIComponent(slot.slot.endTime),
                              status
                            )}
                          >
                            {slot.count[status] || '-'}
                          </Link>
                        </Cell>
                      ))}
                  </Row>
                ))}
              {noSlots && (
                <Row className="first-column">
                  <Cell>{getMessage('operations.dashboard.counts')}</Cell>
                </Row>
              )}
              {noSlots && (
                <Row>
                  <Cell>{getMessage('operations.dashboard.counts')}</Cell>
                  {noSlots &&
                    statuses.map((status, index) => (
                      <Cell key={`noslots-${index}`}>
                        <Link
                          to={`${this.getRedirectLink()}storeId=${get(
                            'store'
                          )}&status=${status}&createdAt=${selectedDate}`}
                        >
                          {noSlots.count[status]}
                        </Link>
                      </Cell>
                    ))}
                </Row>
              )}
              {areOrdersScheduled && statuses && (
                <Row>
                  <Cell>{getMessage('operations.dashboard.total')}</Cell>
                  {statuses.map((status, index) => {
                    return (
                      <Cell key={`status-total-${index}`}>
                        {this.state.total[status]}
                      </Cell>
                    )
                  })}
                </Row>
              )}
            </Table>
          </div>
        )}
      </div>
    )
  }
}

export default SlotOrderTable
