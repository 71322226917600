import React from 'react'
import { withRouter } from 'react-router-dom'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'
import { BaseForm, Toggle } from 'components/Form'
import Loader from 'components/Loader'
import Dialog from 'components/Popup/Dialog'
import API from 'lib/api'
import moment from 'moment'
import PrimaryInputs from './PrimaryInputs'
import OfferSchedulesInput from './OfferSchedulesInput'
import './style.scss'

const serverErrorMsg = getMessage('offer.campaigns.form.error')

class VoucherCenterCampaignForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      errorDialog: null,
      isOfferEdit: !!this.props.router.match.params?.id,
      dataLoaded: false,
    }
    this.addOfferSlot = this.addOfferSlot.bind(this)
    this.removeOfferSlotByIndex = this.removeOfferSlotByIndex.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.updateTitle = this.updateTitle.bind(this)
  }

  componentDidMount() {
    if (this.props.router.match.params?.id) {
      /* assign existing campaign values to the form */
      const api = new API({
        url: `/offer-service/campaigns/${this.props.router.match.params.id}`,
      })
      api
        .get()
        .then((res) => {
          if (res?.data) {
            const { data } = res
            const newSchedules = data.schedules.map((schedule) => {
              return {
                ...schedule,
                startDate: moment(schedule.startDate).format('YYYY-MM-DD'),
                endDate: moment(schedule.endDate).format('YYYY-MM-DD'),
                offers: schedule.offers.map((offer) => offer.offerID).join(','),
                offerIds: schedule.offers,
              }
            })
            this.setState((prev) => ({
              dataLoaded: true,
              values: {
                ...prev.values,
                name: data.name,
                image: data.image,
                title: data.title,
                description: data.description,
                cta: data.cta,
                link: data.link,
                validFrom: moment(data.validFrom).format('YYYY-MM-DD HH:mm:ss'),
                validTill: moment(data.validTill).format('YYYY-MM-DD HH:mm:ss'),
                schedules: newSchedules,
                status: data.status,
                category: data.category,
                metaData: {
                  backgroundColor: data.metaData?.backgroundColor,
                },
              },
            }))
          }
        })
        .catch((err) => {
          console.log(err)
          if (err.code === 400 || err.code === 500) {
            this.setState({ errorDialog: err?.message || serverErrorMsg })
          }
        })
    } else {
      const offerSchedule = {
        uid: 1,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        offers: [],
      }
      this.setState((prev) => ({
        values: {
          ...prev.values,
          schedules: [offerSchedule],
        },
      }))
    }
  }

  onSubmit(formData) {
    this.setState({
      submitting: true,
    })
    const validFromDate = new Date(formData.validFrom)
    const validTillDate = new Date(formData.validTill)
    const existingCampaignId = this.props.router.match.params?.id

    // Destructure 'id' to exclude it, spread the rest
    const offerSchedules = formData.schedules.map((schedule) => {
      const offerIdsArr = [...new Set(schedule.offers.split(','))] // remove any duplicate offer ids from user inputs
      const offerIds = offerIdsArr.map((offerId) => {
        const trimmedOfferId = offerId.trim()
        const existingOffer =
          schedule?.offerIds?.length > 0
            ? schedule.offerIds.find(
                (x) => x.offerID === Number(trimmedOfferId)
              )
            : null
        if (existingOffer) {
          return {
            id: existingOffer.id,
            offerID: Number(trimmedOfferId),
          }
        }
        return { offerID: Number(trimmedOfferId) }
      })

      // remove keys not needed in the request payload
      delete schedule.offerIds
      delete schedule.uid

      return {
        ...schedule,
        offers: offerIds,
      }
    })

    const data = Object.assign(
      {},
      {
        ...formData,
        validFrom: validFromDate.toISOString().split('.')[0] + 'Z',
        validTill: validTillDate.toISOString().split('.')[0] + 'Z',
        schedules: offerSchedules,
        status: formData?.status || 'ENABLED',
      }
    )

    if (existingCampaignId) {
      const api = new API({
        url: `/offer-service/campaigns/${existingCampaignId}`,
      })
      api
        .put(data)
        .then((res) => {
          if (res) {
            this.props.history.push(`/marketing/voucher-center-campaigns`)
          }
        })
        .catch((err) => {
          if (err.code === 400 || err.code === 500) {
            this.setState({ errorDialog: err?.message || serverErrorMsg })
          }
        })
        .finally(() => this.setState({ submitting: false }))
    } else {
      const api = new API({ url: '/offer-service/campaigns' })
      api
        .post(data)
        .then((res) => {
          if (res) {
            this.props.history.push(`/marketing/voucher-center-campaigns`)
          }
        })
        .catch((err) => {
          if (err.code === 400 || err.code === 500) {
            this.setState({ errorDialog: err?.message || serverErrorMsg })
          }
        })
        .finally(() => this.setState({ submitting: false }))
    }
  }

  addOfferSlot(e) {
    e.preventDefault()
    this.setState((prev) => ({
      values: {
        ...prev.values,
        schedules: [
          ...prev.values.schedules,
          {
            // uid is used as a unique key to iterate in component rendering
            uid: prev.values.schedules.length + 1,
            offers: null,
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
          },
        ],
      },
    }))
  }

  removeOfferSlotByIndex(index) {
    this.setState((prev) => ({
      validations: {
        ...prev.validations,
        schedules: prev.validations.schedules.filter((_, i) => i !== index),
      },
      values: {
        ...prev.values,
        schedules: prev.values.schedules.filter((_, i) => i !== index),
      },
    }))
  }

  handleToggle() {
    this.setState((prev) => ({
      values: {
        ...prev.values,
        status: prev.values.status === 'ENABLED' ? 'DISABLED' : 'ENABLED',
      },
    }))
  }

  getCampaignStatus(isExpiredCampaign) {
    if (isExpiredCampaign) {
      return 'EXPIRED'
    }
    return this.state.values?.status // ENABLED or DISABLED
  }

  updateTitle(title) {
    this.setState({
      values: {
        ...this.state.values,
        title,
      },
    })
  }

  render() {
    const { menu } = this.props
    const { SubmitButton } = this.buttons
    const { Form } = this.components

    /* if there is an id and dataLoaded is false, it means data is still being fetched. So, show loader. */
    if (this.props.router.match.params?.id && !this.state.dataLoaded) {
      return <Loader />
    }

    const currentDate = moment()
    const isExpiredCampaign = currentDate.isAfter(
      this.state.values?.validTill ?? Infinity
    )

    // check campaign status for only existing campaign (i.e. campaign with id)
    const campaignStatus = this.props.router.match.params?.id
      ? this.getCampaignStatus(isExpiredCampaign)
      : null

    const existingCampaign = !!this.props.router.match.params?.id

    return (
      <AuthenticatedPage
        className="voucher-center-campaign-form-page"
        menu={menu}
      >
        <div className="flex-around">
          <h1 className="title heading">
            <strong>
              {existingCampaign
                ? `Voucher Center Campaign`
                : getMessage('offer.campaigns.listing.add.offerCampaign')}
            </strong>
          </h1>
          {campaignStatus && (
            <div className="offer-campaign-status">
              <span className={`status-text ${campaignStatus.toLowerCase()}`}>
                {campaignStatus}
              </span>
              <Toggle
                name="offerCampaignStatus"
                value={this.state.values.status === 'ENABLED'}
                icons={false}
                onChange={() => this.handleToggle()}
                disabled={isExpiredCampaign}
              />
            </div>
          )}
        </div>
        <Form className="offer-campaign-form">
          <PrimaryInputs
            generateStateMappers={this.generateStateMappers}
            isExpiredCampaign={isExpiredCampaign}
            formValues={this.state.values}
            updateTitle={this.updateTitle}
          />

          <OfferSchedulesInput
            generateStateMappers={this.generateStateMappers}
            formValues={this.state.values}
            addOfferSlot={this.addOfferSlot}
            removeOfferSlotByIndex={this.removeOfferSlotByIndex}
            isExpiredCampaign={isExpiredCampaign}
          />

          {existingCampaign && (
            <ul className="deep-links-container">
              <span>Deep Links</span>
              <li>
                <strong>Omni Popup: </strong>
                fairprice://loyalty/vouchers/flashVoucher?id=
                {this.state.values.name}
                &location=omni-home&subLocation=omni-popup
              </li>
              <li>
                <strong>Omni Banner: </strong>
                fairprice://loyalty/vouchers/flashVoucher?id=
                {this.state.values.name}
                &location=omni-home&subLocation=omni-banner
              </li>
              <li>
                <strong>PNS: </strong>
                fairprice://loyalty/vouchers/flashVoucher?id=
                {this.state.values.name}&location=pns&subLocation=null
              </li>
            </ul>
          )}

          <div className="form-buttons">
            <SubmitButton disabled={isExpiredCampaign}>
              {getMessage('offer.submit')}
            </SubmitButton>
            <button
              className="button secondary"
              onClick={() =>
                this.props.history.push(`/marketing/voucher-center-campaigns`)
              }
            >
              {getMessage('offer.cancel')}
            </button>
          </div>
        </Form>

        {this.state.errorDialog && (
          <Dialog
            className="error-dialog"
            show={!!this.state.errorDialog}
            title="Error"
            information={this.state.errorDialog}
            close={() => this.setState({ errorDialog: null })}
            closeText={getMessage('dialog.okText')}
          />
        )}
      </AuthenticatedPage>
    )
  }
}

export default withRouter(({ match, ...props }) => (
  <VoucherCenterCampaignForm router={{ match }} {...props} />
))
