import React, { Component } from 'react'
import Loader from '../../../../components/Loader'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import API from '../../../../lib/api'
import PlacementDetails from './PlacementDetails'
import EvoucherDeatils from './EvouchersDetails'
import './style.css'
import PaymentDetails from './PaymentDetails/PaymentDetails'
import { getMessage } from 'lib/translator'
import Invoice from './Invoice/Invoice'

export default class Details extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: false,
      failed: false,
      invoiceShown: false,
    }
    this.getData = this.getData.bind(this)
    this.getEvoucherDetailsApi = this.getEvoucherDetailsApi.bind(this)
  }
  getEvoucherDetailsApi() {
    return this.evoucherDetailsApi.get().then(
      (response) => {
        const { data } = response
        this.setState({
          failed: false,
          data: data,
        })
      },
      (error) => {
        this.setState({ failed: true })
        if (error.code === 401) {
          throw error
        }
      }
    )
  }
  getData() {
    const referenceNumber = this.props.router.match.params.id
    this.evoucherDetailsApi = new API({
      url: `/gifting-admin-service/orders/${referenceNumber}`,
    })
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        this.getEvoucherDetailsApi().then(() => {
          this.setState({ loading: false })
        })
      }
    )
  }

  getPrintInvoice() {
    const input = document.getElementById('divToPrint')
    const orderDate = new Date(this.state.data?.createTime)
    const DD = String(orderDate.getDate()).padStart(2, '0')
    const MM = String(orderDate.getMonth() + 1).padStart(2, '0')
    const YYYY = orderDate.getFullYear()
    const orderCreateDate = DD + MM + YYYY
    html2canvas(input)?.then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = jsPDF('p', 'mm', 'a4', true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 30
      pdf.addImage(
        imgData,
        'PNG',
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      )
      pdf.save(`FPG_${this.state.data?.reference}_${orderCreateDate}.pdf`)
    })
  }

  async componentDidMount() {
    this.getData()
  }
  componentWillUnmount() {
    this.evoucherDetailsApi && this.evoucherDetailsApi.cancel()
  }

  render() {
    const contentLoaded =
      !this.state.loading && !this.state.failed && this.state.data
    const isBusiness = this.state.data?.isB2B || 0
    return (
      <>
        <div id="divToPrint">
          <Invoice data={this.state.data} />
        </div>

        <AuthenticatedPage
          storeDependent
          className="evoucher-details"
          menu={this.props.menu}
        >
          <div className="flex-around">
            <h1 className="title heading">
              {getMessage('customerSupport.egifting.details.heading')}
              <strong className="reference-number">
                {this.state.data && ` ${this.state.data.reference}`}
              </strong>
            </h1>
            <div className="order-actions-web-view">
              <button
                data-testid="print-gift-invoice"
                className="invoice-button print-icon"
                onClick={() => {
                  this.getPrintInvoice()
                }}
              >
                {getMessage('order.details.invoice.printButtonText')}
              </button>
            </div>
          </div>

          {this.state.loading ? <Loader /> : null}
          {this.state.failed ? (
            <div className="retry-message text-muted" onClick={this.getData}>
              Something went wrong. Click here to try again.
            </div>
          ) : null}
          {contentLoaded ? (
            <div>
              <div className="evoucher-summary-wrapper">
                <div className="user-details">
                  <PlacementDetails
                    data={{
                      status: this.state.data.status,
                      creationTime: this.state.data.createTime,
                      completionTime: this.state.data.updateTime,
                      isBusiness: isBusiness,
                      invoiceId: this.state.data.invoiceId,
                    }}
                  />
                </div>
                <EvoucherDeatils data={this.state.data} />
                <PaymentDetails data={this.state.data} />
              </div>
            </div>
          ) : null}
        </AuthenticatedPage>
      </>
    )
  }
}
