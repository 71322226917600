import React from 'react'
import { BaseForm, Input, Select, VALIDATION_TYPES } from 'components/Form'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import API from 'lib/api'

import ConfirmationDetails from './ConfirmationDetails'
import './style.css'

class DesignCodesForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state.values = {
      showPopup: false,
      isDesignCodeExist: false,
      denominationType: '',
    }
    this._validateDesignCodes = this._validateDesignCodes.bind(this)
  }

  async _validateDesignCodes() {
    const {
      id,
      denominationType = '',
      denomination,
      sku = '',
    } = this.state.values
    try {
      const url = `/gifting-admin-service/design-codes`
      const api = new API({ url: url })

      const data =
        denominationType === 'FLEXI'
          ? { id: Number(id), denominationType }
          : {
              id: Number(id),
              denominationType,
              denomination: denomination.toString(),
              sku,
            }

      await api.post(data)
      this.props.onCancel()
    } catch (error) {
      this.setState({
        showPopup: false,
      })
      if (error.code === 400) {
        this.setState({ isDesignCodeExist: true })
      }
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
      isDesignCodeExist: false,
    })
    const isValid = this.isFormValid()
    if (isValid) {
      this.setState({ showPopup: true })
    }
  }

  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons
    const isFixed = this.state.values?.denominationType === 'FIXED'

    return (
      <Form>
        <div className="form-fields-entities" id="entityName">
          <div className="row">
            <div className="col-6 no-margin">
              <Input
                required
                testid="designCodes"
                maxLength="4"
                name="id"
                error={this.state.isDesignCodeExist}
                min={0}
                type="text"
                placeholder={getMessage('gift.card.print.orders.design.code')}
                label={getMessage('gift.card.print.orders.design.code')}
                {...this.generateStateMappers({
                  stateKeys: ['id'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
            </div>

            <div className="col-6 no-margin error-msg">
              {this.state.isDesignCodeExist && (
                <span className="input-error-message">
                  {getMessage('gift.card.design.code.error.exist')}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="form-fields-entities">
          <Select
            required
            name="denominationType"
            testid="denominationType"
            placeholder={getMessage(
              'gift.card.design.codes.input.denomination.type'
            )}
            label={getMessage('gift.card.design.codes.input.denomination.type')}
            options={[
              {
                text: getMessage('offer.ordertype.options.b2b'),
                value: 'FIXED',
              },
              {
                text: getMessage('order.personal'),
                value: 'FLEXI',
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ['denominationType'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          {isFixed && (
            <>
              <Input
                name="denomination"
                testid="denomination"
                placeholder={getMessage(
                  'gift.card.design.codes.input.denomination'
                )}
                type="number"
                min={0}
                label={getMessage('gift.card.design.codes.input.denomination')}
                {...this.generateStateMappers({
                  stateKeys: ['denomination'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
              <Input
                name="sku"
                testid="sku"
                placeholder={getMessage('gift.card.design.codes.input.sku')}
                label={getMessage('gift.card.design.codes.input.sku')}
                {...this.generateStateMappers({
                  stateKeys: ['sku'],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
            </>
          )}
        </div>
        <SubmitButton>{getMessage('offer.submit')}</SubmitButton>
        <CancelButton testid="cancel-submit">
          {getMessage('offer.cancel')}
        </CancelButton>
        <Popup
          show={this.state.showPopup}
          heading={'Confirm Design Codes Details'}
          className="confirmPopup"
          close={() => this.setState({ showPopup: false })}
          data-testid="confirm-popup"
        >
          <ConfirmationDetails
            {...this.state.values}
            onConfirm={this._validateDesignCodes}
          />
        </Popup>
      </Form>
    )
  }
}

export default DesignCodesForm
