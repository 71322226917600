import React from 'react'
import { Select } from 'components/Form'
import { getMessage } from 'lib/translator'

const FilterBy = ({ type, options, generateStateMappers }) => {
  return (
    <Select
      required
      name={`${type}-filter`}
      className={`${type}-filter`}
      label={getMessage(`omni-journey.filter.${type}.label`)}
      placeholder={getMessage(`omni-journey.filter.${type}.placeholder`)}
      options={options}
      {...generateStateMappers({
        stateKeys: [type],
        loseEmphasisOnFill: true,
      })}
    />
  )
}

export default FilterBy
