import React from 'react'
import { Link } from 'react-router-dom'
import { DropDown, DropDownItem, ICONS } from '../../../../components/DropDown'
import { Cell } from '../../../../components/Table'
import Row from '../../../../components/Table/Row'
import { TABLE_ACTIONS } from '../../../../containers/ListingPage'
import { getOnlyTime, getPrintableTime } from '../../../../lib/datetime'
import { getMessage } from '../../../../lib/translator'
import { GIFTING_PAGINATION_SIZE } from '../../constants'

export const Actions = ({ id, onAction, notificationStatus, status }) => {
  return (
    <DropDown
      icon={
        <img data-testid="egifting-row-dropdown" src={ICONS.VELLIP} alt="⋮" />
      }
      isDisable={
        ['PENDING-REFUND', 'FAILED'].includes(status) ||
        notificationStatus !== 'ORDER_PLACED'
      }
    >
      <DropDownItem onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}>
        {getMessage('customerSupport.egifting.cancel')}
      </DropDownItem>
    </DropDown>
  )
}

export default function tablePropertie() {
  return {
    headers: [
      getMessage('customerSupport.egifting.header.orderno'),
      getMessage('customerSupport.egifting.header.customer'),
      getMessage('order.invoice.id'),
      getMessage('customerSupport.egifting.header.email'),
      getMessage('order.payment.paymentmode'),
      getMessage('offer.ordertype.label'),
      getMessage('customerSupport.egifting.header.placement-time'),
      getMessage('customerSupport.egifting.header.amount'),
      getMessage('customerSupport.egifting.header.status'),
      getMessage('customerSupport.egifting.header.payment-status'),
      getMessage('customerSupport.egifting.header.completedAt'),
      getMessage('customerSupport.egifting.header.actions'),
    ],
    row: ({
      reference,
      sender,
      invoiceId,
      senderEmail,
      paymentType,
      createTime,
      amount,
      status,
      updateTime,
      id,
      onAction,
      notificationStatus,
      isB2B,
    }) => {
      return (
        <Row>
          <Cell>
            <Link
              className="order-number"
              to={`/customer-support/egifting-orders/${id}`}
            >
              {reference}
            </Link>
          </Cell>
          <Cell>
            <div className="name">
              <span>{sender}</span>
            </div>
          </Cell>
          <Cell>{invoiceId}</Cell>
          <Cell>{senderEmail}</Cell>
          <Cell>
            {paymentType === 'DEPOSIT'
              ? getMessage('customerSupport.egifting.bank')
              : getMessage('order.details.payment.card')}
          </Cell>
          <Cell>
            {isB2B
              ? getMessage('order.business')
              : getMessage('order.personal')}
          </Cell>
          <Cell className="column-creation-time">
            <div>
              {createTime ? getPrintableTime(createTime).split(', ')[0] : null}
            </div>
            <small className="text-muted">
              {createTime ? getOnlyTime(createTime) : null}
            </small>
          </Cell>
          <Cell>{amount}</Cell>
          <Cell>{getMessage(notificationStatus)}</Cell>
          <Cell>{getMessage(status)?.toUpperCase()}</Cell>
          <Cell>
            <div>
              {updateTime ? getPrintableTime(updateTime).split(', ')[0] : null}
            </div>
            <small className="text-muted">
              {updateTime ? getOnlyTime(updateTime) : null}
            </small>
          </Cell>
          <Cell>
            <Actions
              onAction={onAction}
              id={id}
              notificationStatus={notificationStatus}
              status={status}
            />
          </Cell>
        </Row>
      )
    },
  }
}

export const returnFunction = (response) => {
  response.data.count = response.data.totalSize
  response.data.limit = GIFTING_PAGINATION_SIZE
  response.data.offset = 0
  if (response.data.totalSize < GIFTING_PAGINATION_SIZE) {
    // only one page data
    response.data.offset = 0
  } else if (response.data.nextPageToken > 0) {
    // For data from page=1 to page=last-1
    // For first page nextPageToken will be 2
    response.data.offset =
      (response.data.nextPageToken - 2) * GIFTING_PAGINATION_SIZE
  } else if (response.data.totalSize > 0) {
    // For last page, nextPageToken= 0;
    response.data.offset =
      (Math.ceil(response.data.totalSize / GIFTING_PAGINATION_SIZE) - 1) *
      GIFTING_PAGINATION_SIZE
  }

  return response
}

export const transform = (response) => {
  const transactions = response.data.orders || []
  returnFunction(response)
  return transactions
}
