import React, { Component, useContext } from 'react'
import API from '../../../lib/api'
import ActiveZonePickers from './ActiveZonePickers'
import IdlePickers from './IdlePickers'
import TotalPickers from './TotalPickers'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import Loader from '../../../components/Loader'
import { Link } from 'react-router-dom'
import { getMessage } from '../../../lib/translator'
import emptyStateIcon from './svg/picker-empty.svg'
import settingImage from './svg/settings-inactive.svg'
import StoreSelector from '../../../containers/StoreSelector'
import { PICKERS_TODAY_ROLE_IDS } from '../../../config/app'

import './style.css'
import { get } from '../../../lib/storage'
import Toast from '../../../components/Toast'
import { SplitContext } from 'containers/SplitContext'

const EmptyState = () => {
  return (
    <React.Fragment>
      <div className="pickers-empty-state">
        <img src={emptyStateIcon} alt="settings" />
        {getMessage('pickers.empty')}
      </div>
    </React.Fragment>
  )
}

class PickersToday extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pickerLoading: true,
      employeeLoading: true,
      Loading: true,
      storeId: get('store'),
      multiStores: [],
      employees: [],
      pickers: [],
      activeZonePickers: [],
      showUnassignToast: false,
    }
    this.changeStore = this.changeStore.bind(this)
    this.handleUnassignZonePicker = this.handleUnassignZonePicker.bind(this)
  }

  changeStore(storeId) {
    this.setState({ storeId }, this.getEmployees)
  }

  updateStates(employees) {
    this.activeZonePickerApi = new API({
      url: `/picking-service/v3/pickers/${this.state.storeId}`,
    })

    this.activeZonePickerApi
      .get()
      .then(res => {
        this.setState(
          {
            activeZonePickers: res.activePickers,
            pickerLoading: false,
            multiStores: JSON.parse(get('stores')),
          },
          () => {
            this.setState({ employees, employeeLoading: false, Loading: false })
          }
        )
      })
      .catch(error => {
        throw error
      })
  }

  async getEmployees() {
    this.setState({ Loading: true });
    const roleIds = PICKERS_TODAY_ROLE_IDS.split(",");
    const allEmployees = [];

    // Helper function to fetch paginated data for a single roleId
    const fetchEmployeesForRole = async (roleId) => {
      const allRoleEmployees = [];
      let page = 1;
      let hasMoreData = true;

      while (hasMoreData) {
          // Call the API with the current page
          this.employeeApi = new API({ url: '/account-service/employee' })
          const response = await this.employeeApi.get({
              roleId: roleId.trim(),
              storeId: this.state.storeId,
              limit: 100,
              page: page,
          });

          // Process response and add employees to the array
          const employees = response.data.employee || [];
          allRoleEmployees.push(...employees);

          // Calculate whether more calls are needed based on count
          const count = response.data.count;
          hasMoreData = count > page * 100;
          page += 1; // Increment the page number for the next request
      }

      return allRoleEmployees;
    };

    try {
        // Map roleIds to a series of async calls for each role
        const fetchPromises = roleIds.map((roleId) => fetchEmployeesForRole(roleId));

        // Wait for all API calls to complete and collect all employees
        const allRoleEmployeesArrays = await Promise.all(fetchPromises);

        // Flatten results from all roles
        allRoleEmployeesArrays.forEach((employees) => allEmployees.push(...employees));

        // Update state with the collected employees
        this.updateStates(allEmployees);
    } catch(err) {
      console.log(err)
      throw err;
    } finally {
        this.setState({ Loading: false });
    }
  }

  componentWillUnmount() {
    this.employeeApi && this.employeeApi.cancel()
    this.pickerApi && this.pickerApi.cancel()
    this.activeZonePickerApi && this.activeZonePickerApi.cancel()
  }

  componentDidMount() {
    this.getEmployees()
  }

  handleUnassignZonePicker(pickerData) {
    this.setState({
      loading: true,
    })
    const unassignZonePickerAPI = new API({
      url: '/picking-service/v3/picklists/unassign',
    })
    if (pickerData && pickerData.length === 0) {
      throw new Error('Invalid State')
    }

    unassignZonePickerAPI
      .post({
        pickerId: pickerData[0].pickerId,
        picklistId: pickerData[0].picklistId,
      })
      .then(() => {
        this.setState({
          loading: false,
          showUnassignToast: true,
        })
      })
      .catch(error => {
        this.setState({
          loading: false,
          showUnassignToast: false,
        })
        throw error
      })
  }

  render() {
    const {
      employees,
      pickerLoading,
      employeeLoading,
      multiStores,
      storeId,
      Loading,
      activeZonePickers,
    } = this.state
    return (
      <AuthenticatedPage menu={this.props.menu} className="pickers-today">
        {this.state.showUnassignToast && (
          <Toast
            content="Picker unassigned"
            cancelMessage="Ok"
            onClose={() => {
              this.setState({
                showUnassignToast: false,
              })
              this.getEmployees()
            }}
          />
        )}
        <h1 className="pickers-title">
          {getMessage('pickers.today.title')}
          <Link to="/operations/pickers" className="pickers-nav-btn">
            <button className="primary button vendor-btn">
              {' '}
              <img
                src={settingImage}
                className="setting-image"
                alt="settings"
              />{' '}
              {getMessage('pickers.title')}
            </button>
          </Link>
        </h1>
        {!(pickerLoading || employeeLoading) ? (
          employees.length ? (
            Loading ? (
              <Loader />
            ) : (
              <React.Fragment>
                <StoreSelector
                  value={storeId}
                  onChange={this.changeStore}
                  stores={multiStores}
                />
                <TotalPickers {...this.state} />
                {activeZonePickers.length !== 0 ? (
                  <ActiveZonePickers
                    {...this.state}
                    onUnassignZonePicker={this.handleUnassignZonePicker}
                  />
                ) : null}
                <IdlePickers {...this.state} />
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <StoreSelector
                value={storeId}
                onChange={this.changeStore}
                stores={multiStores}
              />
              {Loading ? <Loader /> : <EmptyState />}
            </React.Fragment>
          )
        ) : (
          <Loader />
        )}
      </AuthenticatedPage>
    )
  }
}

const WrappedPickerToday = (props) => {
  const { splits } = useContext(SplitContext)
  return <PickersToday splits={splits} {...props}/>
}

export default WrappedPickerToday
