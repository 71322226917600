import React from 'react'
import { Link } from 'react-router-dom'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'

import { getMessage } from '../../../../lib/translator'
import { GIFTING_PAGINATION_SIZE } from '../../constants'
import tableProperties, { transform } from './tableProperties'
import EnitityFilter from './Filters'
import EntityForm from '../Form'

const AddButton = () => (
  <Link
    to="/customer-support/gifting-entities/add"
    className="primary button"
    data-testid="crate-new-entity"
  >
    <span className="text">{getMessage('eVoucher.entities.create')}</span>
  </Link>
)

const EmptyState = {
  message: getMessage('customerSupport.egifting.empty'),
  actions: () => <AddButton />,
}

export const fpStoreFilter = (formData) => {
  const data = Object.assign({}, formData)
  if (data.is_fp_store) {
    const FPStore = getMessage('eVoucher.filter.FPStores.options.FP.stores')
    const NonFPStore = getMessage(
      'eVoucher.filter.FPStores.options.Non.FP.stores'
    )

    if ([FPStore, NonFPStore].includes(data.is_fp_store)) {
      data.is_fp_store = (data.is_fp_store === FPStore).toString()
    } else {
      delete data.is_fp_store
    }
  }
  return data
}

export default function List(props) {
  return (
    <ListingPageWithRoutes
      testid="e-gifting-entities"
      addHeading={getMessage('eVoucher.entities.create')}
      menu={props.menu}
      className="egifting-entities-page"
      title={getMessage('customerSupport.egifting.entities')}
      api={{
        url: '/gifting-admin-service/entities',
        params: { ...props.params, page_size: GIFTING_PAGINATION_SIZE },
        transform,
      }}
      headerActions={AddButton}
      primaryKey="id"
      filters={{
        component: EnitityFilter,
        forceShow: true,
        transformSubmit: (formData) => fpStoreFilter(formData),
        transformFilter: (formData) => fpStoreFilter(formData),
      }}
      form={{
        component: EntityForm,
      }}
      tableProperties={tableProperties()}
      emptyState={EmptyState}
      statusUpdateOnDelete
    />
  )
}
