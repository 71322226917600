import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './style.css'

function CustomDropdown({ selectedOption, setSelectedOption }) {
  const [isActive, setIsActive] = useState(false)
  const dropdownRef = useRef(null)

  const options = [
    { text: 'offer-label-sequence' },
    { text: 'preorder-category-configuration' },
  ]

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleSelect = (option) => {
    setSelectedOption(option.text)
    setIsActive(false)
  }

  return (
    <div
      className={`select-menu ${isActive ? 'active' : ''}`}
      ref={dropdownRef}
    >
      <div className="select-btn" onClick={() => setIsActive(!isActive)}>
        <span className="sBtn-text">{selectedOption}</span>
      </div>

      {isActive && (
        <ul className="options">
          {options.map((option) => (
            <li
              key={option}
              className="option"
              onClick={() => handleSelect(option)}
            >
              <Link to={`/catalogue/configurations/${option.text}`}>
                <span className="option-text">{option.text}</span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default CustomDropdown
