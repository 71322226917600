import React from 'react'
import moment from 'moment'
import ScanCampaignRow from './ScanCampaignRow'
import 'react-popper-tooltip/dist/styles.css'
import './style.scss'

const tableHeaders = [
  'Campaign ID',
  'Name',
  'Status',
  'Start Date',
  'End Date',
  'Actions',
]

const ScanCampaignTableProperties = () => {
  return {
    headers: tableHeaders,
    row: ({ id, name, status, validFrom, validTill }) => {
      const currentDate = moment()
      const isExpiredCampaign = currentDate.isAfter(validTill ?? Infinity)

      return (
        <ScanCampaignRow
          id={id}
          name={name}
          status={status}
          validFrom={validFrom}
          validTill={validTill}
          isExpiredCampaign={isExpiredCampaign}
        />
      )
    },
  }
}

export default ScanCampaignTableProperties
