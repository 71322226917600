import React from 'react'
import { withRouter } from 'react-router-dom'
import { BaseForm, Input } from 'components/Form'
import { getMessage } from 'lib/translator'
import {
  formatValidity,
  validateAnalytics,
  contentAPIRequestHandler,
} from 'components/AppHome/utils'
import Loader from 'components/Loader'
import _unset from 'lodash/unset'
import { ANALYTIC_SCHEMA } from 'components/OmniJourney/ScanToWin/utils'
import Content from 'components/OmniJourney/ScanToWin/Content'
import PreviewTrigger from 'components/OmniJourney/ScanToWin/PreviewTrigger'
import ContentForm from 'components/OmniJourney/ScanToWin/ContentForm'
import SegmentRankProduct from 'components/OmniJourney/ScanToWin/SegmentRankProduct'
import PopupForm from 'components/OmniJourney/ScanToWin/PopupForm'
import { ErrorDialog, FormActionButtons } from 'components/OmniJourney/utils'
import Validity from 'components/AppHome/Validity'
import './style.scss'

class ScanToWinForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      stores: [],
      loading: false,
      showErrorDialog: false,
      errorMessage: null,
      errorFields: {},
      storeOptions: [],
      preview: false,
      values: {
        startTime: '00:00:00',
        endTime: '00:00:00',
        segments: [],
        texts: {},
        popup: {
          title: "Here's your daily scratch card",
          message:
            "We'll add this to your vouchers. Come back tomorrow for new scratch cards!",
          closeBtnText: 'Okay, got it',
        },
      },
    }

    this.dismissErrors = this.dismissErrors.bind(this)
    this.changeProductTypeHandler = this.changeProductTypeHandler.bind(this)
    this.isAnalyticsValid = this.isAnalyticsValid.bind(this)
  }

  async componentDidMount() {
    const { method } = this.props
    this.setState({ loading: true })

    if (method === 'add') {
      const query = `?contentType=category&key=products`
      const callbacks = {
        onError: (error) =>
          this.setState({
            showErrorDialog: true,
            errorMessage: `${error.message} while retrieving stores.`,
          }),
        onSuccess: (res) => {
          const stores = res.data.items.map((item) => item.text)
          this.setState({ stores })
        },
        onCancel: () => this.setState({ loading: false }),
      }

      contentAPIRequestHandler('GET', {}, callbacks, query)
    }

    if (method === 'edit') {
      const apiData = this.props.apiData.data.items[0]
      const validity = await formatValidity('api', apiData)
      const segments = apiData.segments.map(({ name }) => ({ name }))
      const values = {
        ...apiData,
        ...validity,
        segments,
        popup: {
          ...apiData.popup,
          cta: JSON.stringify(apiData.popup?.cta, null, 2),
          closeBtnCta: JSON.stringify(apiData.popup?.closeBtnCta, null, 2),
          cancelBtnCta: JSON.stringify(apiData.popup?.cancelBtnCta, null, 2),
        },
      }
      this.setState({ values, loading: false })
    }
  }

  isAnalyticsValid(_data, analytics) {
    const parsedAnalytics = {}
    const invalidAnalytics = {}

    for (const key of analytics) {
      if (_data.popup[key]) {
        const { isValid, parsedAnalytic, errors } = validateAnalytics(
          _data.popup[key],
          ANALYTIC_SCHEMA
        )
        if (isValid) {
          parsedAnalytics[key] = parsedAnalytic
        } else {
          invalidAnalytics[key] = errors[0].stack
        }
      } else {
        _unset(_data, `popup.${key}`)
      }
    }
    return { parsedAnalytics, invalidAnalytics }
  }

  async _submitHandler(e, saveAsDraft = false) {
    e && e.preventDefault()
    this.setState({ pressedSubmitWithCurrentData: true })
    const { values, stores } = this.state
    const analytics = ['cta', 'closeBtnCta', 'cancelBtnCta']
    const { parsedAnalytics, invalidAnalytics } = this.isAnalyticsValid(
      values,
      analytics
    )
    if (Object.keys(invalidAnalytics).length > 0) {
      this.setState({
        errorFields: invalidAnalytics,
      })
      return
    }
    if (!this.isFormValid()) {
      return
    }

    this.setState({ loading: true })
    const { method, onCancel, router } = this.props
    const { startTime, endTime } = await formatValidity('form', values)
    for (const key in parsedAnalytics) {
      values.popup[key] = parsedAnalytics[key]
    }
    const callbacks = {
      onCancel: () => this.setState({ loading: false }),
      onError: ({ message }) =>
        this.setState({
          errorMessage: message,
          showErrorDialog: true,
        }),
      onSuccess: onCancel,
    }
    const formPayload = {
      data: {
        ...values,
        endTime,
        startTime,
        isDisabled: saveAsDraft,
        type: router.match.params.type,
      },
      contentType: 'scanwin',
      key: `${router.match.params.type.replace('scan-', '')}#${values.popup.store}`,
    }

    if (method === 'add' && !stores.includes(formPayload.data.popup.store)) {
      const categoryPayload = {
        contentType: 'category',
        key: 'products',
        data: {
          startTime: '0000-01-01T00:00:00+08:00',
          endTime: '9999-01-01T00:00:00+08:00',
          value: `product#${values.popup.store}`,
          text: values.popup.store,
          isDisabled: false,
        },
      }
      callbacks.onSuccess = () =>
        contentAPIRequestHandler('POST', formPayload, {
          ...callbacks,
          onSuccess: onCancel,
        })
      callbacks.onCancel = () => {}
      contentAPIRequestHandler('POST', categoryPayload, callbacks)
    } else {
      const req = method !== 'add' ? 'PUT' : 'POST'
      callbacks.onSuccess = onCancel
      contentAPIRequestHandler(req, formPayload, callbacks)
    }
  }

  dismissErrors() {
    this.setState({
      showErrorDialog: false,
      errorMessage: null,
      errorFields: {},
    })
  }

  changeProductTypeHandler(type) {
    if (type !== 'product') {
      _unset(this.state.values, 'productId')
    }
  }

  render() {
    const { Form } = this.components
    const { loading } = this.state
    const { type: trigger } = this.props.router.match.params

    return (
      <div className="scan-to-win-form">
        {loading && <Loader size="sm" />}
        {!loading && (
          <Form>
            <div className="grid-2-col">
              {trigger === 'scan-product' && (
                <SegmentRankProduct
                  generateStateMappers={this.generateStateMappers}
                  productType={this.state.values?.productType}
                  changeProductTypeHandler={this.changeProductTypeHandler}
                />
              )}

              <Content
                preview={this.state.preview}
                setPreview={() =>
                  this.setState({ preview: !this.state.preview })
                }
              >
                {this.state.preview && (
                  <PreviewTrigger
                    src={this.state.values.image}
                    text1={this.state.values.texts?.text1}
                    text2={this.state.values.texts?.text2}
                  />
                )}
                {!this.state.preview && (
                  <ContentForm
                    generateStateMappers={this.generateStateMappers}
                    trigger={trigger}
                  />
                )}
              </Content>

              <div className="block">
                <h3>{getMessage('omni-journey.validity.header')}</h3>
                <Validity generateStateMappers={this.generateStateMappers} />
              </div>

              <div>
                <h3>{getMessage('omni-journey.offers.header')}</h3>
                <Input
                  name="offerCampaignId"
                  label={getMessage(
                    'omni-journey.offer-campaign-id-field.label'
                  )}
                  placeholder={getMessage(
                    'omni-journey.offer-campaign-id-field.placeholder'
                  )}
                  {...this.generateStateMappers({
                    stateKeys: ['offerCampaignId'],
                    loseEmphasisOnFill: true,
                  })}
                />
              </div>

              <PopupForm
                errorFields={this.state.errorFields}
                dismissErrors={this.dismissErrors}
                generateStateMappers={this.generateStateMappers}
                method={this.props.method}
                trigger={trigger}
              />

              <ErrorDialog
                show={this.state.showErrorDialog}
                message={this.state.errorMessage}
                onClose={() =>
                  this.setState({
                    showErrorDialog: false,
                    errorMessage: null,
                  })
                }
              />

              <div className=" block form-buttons-section">
                <FormActionButtons
                  buttons={this.buttons}
                  onSave={(e) => this._submitHandler(e, true)}
                />
              </div>
            </div>
          </Form>
        )}
      </div>
    )
  }
}

export default withRouter(({ location, history, match, ...props }) => (
  <ScanToWinForm router={{ location, history, match }} {...props} />
))
