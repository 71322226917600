import React from 'react'
import './OfferSequenceLabel/style.css'
import { isCatalogueProductReadOnly } from '../Products/utils'
import { Link } from 'react-router-dom'

function OfferSequence({
  editData: details,
  onDelete,
  selectedOption,
  onCancel,
}) {
  if (!details) {
    return null
  }
  const isDefaultVariant = details?.variant === 'default'
  const startDate = details?.validFrom ? new Date(details?.validFrom) : ''
  const endDate = details?.validTill ? new Date(details?.validTill) : ''
  const data = details?.data.data?.length ? details?.data.data : []
  const OFFER_LABEL_SEQUENCE = 'offer-label-sequence'
  const selectedType =
    selectedOption === OFFER_LABEL_SEQUENCE
      ? OFFER_LABEL_SEQUENCE
      : 'preorder-category-configuration'
  const isReadOnly = isCatalogueProductReadOnly()
  return (
    <div className="ConfigDetails">
      <div className="Config-header">
        <div className="variant-left">
          <div>
            <span className="Config-ID">
              ID - {details?.id} | User - {details?.updatedBy}
            </span>{' '}
            <span className="Config-V">{details?.variant}</span>
            <span
              className={`Config-Status ${details?.status === 'DISABLED' ? 'variant-disabled' : ''}`}
            >
              {details?.status}
            </span>
          </div>
        </div>
        <div className="Config-Footer">
          {!isReadOnly && (
            <Link
              to={`/catalogue/configurations/${selectedType}/edit/${details?.id}`}
            >
              <button className="Edit-btn">Edit</button>
            </Link>
          )}
          {!isDefaultVariant && !isReadOnly && (
            <button
              className="Delete-btn"
              onClick={() => onDelete(details?.id)}
            >
              Delete
            </button>
          )}
          <button className="ConfigCancel" onClick={onCancel}>
            Go Back
          </button>
        </div>
      </div>

      <div className="Config-Content">
        {selectedOption === OFFER_LABEL_SEQUENCE && (
          <div className="ConfigSection">
            <label htmlFor="">Offer Sequence</label>
            <p>
              {data.map((item) => {
                return (
                  <span className="ConfigChips" key={item}>
                    {item}
                  </span>
                )
              })}
            </p>
          </div>
        )}
        {selectedOption !== OFFER_LABEL_SEQUENCE && (
          <div className="ConfigSection">
            <label htmlFor=""> Preorder Category Id</label>
            <div>
              {data.map((item) => {
                return (
                  <div className="configWrap" key={item.campaignId}>
                    <span key={item.campaignId}>
                      campaign id -{' '}
                      <span className="ConfigChips">{item.campaignId}</span>
                    </span>
                    category ids -{' '}
                    {item.campaignCategories.map((category, categoryIndex) => {
                      return (
                        /* eslint-disable-next-line react/no-array-index-key */
                        <span
                          className="ConfigChips"
                          key={`${item.campaignId}-${categoryIndex}`}
                        >
                          {category}
                        </span>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        )}

        <div className="ConfigSection ConfigTimeStamp">
          {startDate && (
            <div>
              <label htmlFor="">Start Date & Time</label>
              <span className="ConfigChips">{`${startDate?.toLocaleDateString('en-CA')} | ${startDate.toLocaleTimeString()}`}</span>
            </div>
          )}

          {endDate && (
            <div className="ConfigEndDate">
              <label htmlFor="">End Date & Time</label>
              <span className="ConfigChips">{`${endDate?.toLocaleDateString('en-CA')} | ${endDate.toLocaleTimeString()}`}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OfferSequence
