import { SingleDatePicker, Select, Input } from 'components/Form'
import { getMessage } from 'lib/translator'
import React, { useState } from 'react'

const PrintFilter = (props) => {
  const { onSubmit, onClear, options } = props
  const [orderId, setOrderId] = useState('')
  const [status, setStatus] = useState('')
  const [createTime, setCreateTime] = useState()

  const handlePrintFilter = () => {
    const addIfNotEmpty = (obj, key, value) => {
      if (value) {
        obj[key] = value
      }
    }
    const searchVal = {}
    addIfNotEmpty(searchVal, 'id', orderId)
    addIfNotEmpty(searchVal, 'status', status)
    addIfNotEmpty(searchVal, 'create-time', createTime)
    onSubmit(searchVal)
  }

  const clearPrintFilter = () => {
    setOrderId('')
    setStatus('')
    setCreateTime()
    onClear()
  }

  const STATUS_OPTIONS = [
    {
      text: getMessage('gift.card.print.orders.status.processing'),
      value: getMessage('gift.card.print.orders.status.processing'),
    },
    {
      text: getMessage('gift.card.print.orders.status.emailing'),
      value: getMessage('gift.card.print.orders.status.emailing'),
    },
    {
      text: getMessage('gift.card.print.orders.status.placed'),
      value: getMessage('gift.card.print.orders.status.placed'),
    },
    {
      text: getMessage('gift.card.print.orders.status.printed'),
      value: getMessage('gift.card.print.orders.status.printed'),
    },
    ...(options?.type === 'saleOrders'
      ? [
          {
            text: getMessage('gift.card.sales.orders.paid'),
            value: getMessage('gift.card.sales.orders.paid'),
          },
          {
            text: getMessage('gift.card.sales.orders.pending.delivery'),
            value: getMessage('gift.card.sales.orders.pending.delivery'),
          },
          {
            text: getMessage('gift.card.sales.orders.delivered'),
            value: getMessage('gift.card.sales.orders.delivered'),
          },
          {
            text: getMessage('gift.card.sales.orders.enabled'),
            value: getMessage('gift.card.sales.orders.enabled'),
          },
          {
            text: getMessage('gift.card.sales.orders.cancelled'),
            value: getMessage('gift.card.sales.orders.cancelled'),
          },
        ]
      : []),
  ]

  return (
    <div className="print-order-details">
      <div className="form-fields">
        <Input
          label={getMessage('gift.card.print.orders.order.id')}
          value={orderId}
          placeholder={getMessage(
            'gift.card.print.orders.order.id.placeholder'
          )}
          testid="order-id"
          type="text"
          onChange={(e) => setOrderId(e)}
        />
        <Select
          name="select-status"
          placeholder={getMessage('gift.card.print.orders.select.placeholder')}
          label={getMessage('gift.card.print.orders.status')}
          options={STATUS_OPTIONS}
          value={status}
          testid="status"
          type="text"
          onChange={(e) => setStatus(e)}
        />
        <SingleDatePicker
          className="w-100"
          label={getMessage('gift.card.print.orders.create.time')}
          placeholder={getMessage(
            'gift.card.print.orders.create.time.placeholder'
          )}
          name="created-date"
          value={createTime}
          isOutsideRange
          onChange={(e) => setCreateTime(e)}
        />
      </div>
      <div className="row">
        <div className="form-actions">
          <button
            type="button"
            disabled={!orderId && !status && !createTime}
            className="primary search-print-order"
            data-testid="search-print-order"
            onClick={handlePrintFilter}
          >
            {getMessage('gift.card.print.orders.search')}
          </button>
          <button
            type="button"
            className="button"
            data-testid="clear-print-order"
            onClick={clearPrintFilter}
          >
            {getMessage('gift.card.print.orders.clear.all')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PrintFilter
