import React from 'react'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'

import { Input, SingleDatePicker, Select } from 'components/Form'
import { Popup } from 'components/Popup'
import { formatISO } from 'date-fns'
import API from 'lib/api'
import './style.scss'
import {
  dropDownOptions,
  requiredFields,
  initialGameValue,
  dateAndTimeDeconstructor,
  reducer,
  handleError,
  getUpdateGameBody,
  getAddGameBody,
  copyGameDeepLink,
} from './utils'
import { withRouter } from 'react-router-dom'
import { getUserRoles } from 'lib/auth'

const GameSettingsForm = (props) => {
  const isEdit = window.location.href.includes('edit')
  const getPageTitle = isEdit
    ? getMessage('games.title.edit')
    : getMessage('games.title.add')
  const [gameState, dispatch] = React.useReducer(reducer, initialGameValue)
  const [showPopup, setShowPopup] = React.useState(false)
  const [error, setError] = React.useState('')
  const [errorMsg, setErrorMsg] = React.useState('')
  const gameId = props.router.match.params.id
  const isReadOnly = getUserRoles().includes('Marketing / Games (readonly)')

  React.useEffect(() => {
    if (isEdit) {
      const url = `/gamification-service/v1/games/${gameId}`
      const getGameByIdApi = new API({ url })
      getGameByIdApi
        .get()
        .then((res) => {
          const { startAt, endAt, name, uuid } = res.data
          const deconstructStartAt = dateAndTimeDeconstructor(startAt)
          const deconstructEndAt = dateAndTimeDeconstructor(endAt)
          const gameData = {
            name: name,
            gameDeepLink: uuid,
            startDate: deconstructStartAt.date,
            startTime: deconstructStartAt.time,
            endDate: deconstructEndAt.date,
            endTime: deconstructEndAt.time,
            providers: res.data.config.provider.name,
            providerGameUrl: res.data.config.provider.url,
            ruleCampaignUid: res.data.config.ruleCampaign.id,
            maxGameLimit: res.data.rules[0]?.value.maxPerDay,
            gameRules: res.data.rules,
          }
          dispatch({ type: 'fetch_edit', value: gameData })
        })
        .catch((err) => {
          handleError({
            setShowPopup,
            setError,
            setErrorMsg,
            errMessage: err.message,
          })
        })
    }
  }, [])

  const handleInputChange = (type, event) => {
    dispatch({ type, value: event })
  }

  const handleClickSubmit = () => {
    const url = `/gamification-service/v1/games`
    const getStartAt = new Date(`${gameState.startDate} ${gameState.startTime}`)
    const getEndAt = new Date(`${gameState.endDate} ${gameState.endTime}`)

    for (let i = 0; i < requiredFields.length; i++) {
      if (
        !gameState[requiredFields[i]] ||
        gameState[requiredFields[i]].length === 0
      ) {
        handleError({
          setShowPopup,
          setError,
          setErrorMsg,
          errMessage: `${requiredFields[i]} is required`,
        })
        return
      }
    }

    if (getStartAt.valueOf() >= getEndAt.valueOf()) {
      handleError({
        setShowPopup,
        setError,
        setErrorMsg,
        errMessage: 'Start date must be earlier than end date',
      })
      return
    }

    const startAt = formatISO(getStartAt)
    const endAt = formatISO(getEndAt)
    const body = getAddGameBody(gameState, startAt, endAt)

    if (isEdit) {
      const updateGameApi = new API({ url: `${url}/${gameId}` })
      const updateBody = getUpdateGameBody(body, gameState)
      updateGameApi
        .patch(updateBody)
        .then((res) => {
          if (res.code === 200) {
            setShowPopup(true)
          }
        })
        .catch((err) => {
          handleError({
            setShowPopup,
            setError,
            setErrorMsg,
            errMessage: err.message,
          })
        })
    } else {
      const createNewGameApi = new API({ url })
      createNewGameApi
        .post(body)
        .then((res) => {
          if (res.code === 201) {
            handleInputChange('cancel')
            setShowPopup(true)
          }
        })
        .catch((err) => {
          handleError({
            setShowPopup,
            setError,
            setErrorMsg,
            errMessage: err.message,
          })
        })
    }
  }

  return (
    <AuthenticatedPage
      menu={props.menu}
      from={props.location && props.location.pathname}
    >
      <h1 className="game-form-title">{getPageTitle}</h1>
      <div className="game-form-fields">
        <div className="field-row">
          <Input
            label={getMessage('games.input.gameName.label')}
            placeholder={getMessage('games.input.gameName.placeholder')}
            value={gameState.name}
            onChange={(event) => handleInputChange('game_name', event)}
            name="game-name"
          />
          {isEdit && (
            <div className="deeplink-row">
              <Input
                label={getMessage('games.input.gameDeepLink.label')}
                placeholder={getMessage('games.input.gameDeepLink.placeholder')}
                value={`fairprice://gamification/start?gameId=${gameState.gameDeepLink}`}
                disabled={true}
                name="game-deeplink"
                id="game-deeplink"
              />
              <div style={{ margin: 'auto' }}>
                <button onClick={copyGameDeepLink}>&#x2398;</button>
              </div>
            </div>
          )}
        </div>
        <div className="field-row">
          <div className="start">
            <SingleDatePicker
              enableToday
              label={getMessage('games.startDate.label')}
              value={gameState.startDate}
              onChange={(event) => handleInputChange('start_date', event)}
              name="start-date"
              style={{ width: '15rem' }}
            />
            <div className="time-wrapper">
              <label for="start-time">
                {getMessage('games.startTime.label')}
              </label>
              <input
                className="time-input"
                value={gameState.startTime}
                type="time"
                id="start-time"
                name="start-time"
                required
                step="1"
                onChange={(event) =>
                  handleInputChange('start_time', event.target.value)
                }
              />
            </div>
          </div>
          <div className="end">
            <SingleDatePicker
              enableToday
              label={getMessage('games.endDate.label')}
              value={gameState.endDate}
              onChange={(event) => handleInputChange('end_date', event)}
              name="end-date"
              style={{ width: '15rem' }}
            />
            <div className="time-wrapper">
              <label for="end-time">{getMessage('games.endTime.label')}</label>
              <input
                className="time-input"
                value={gameState.endTime}
                type="time"
                id="end-time"
                name="end-time"
                required
                step="1"
                onChange={(event) =>
                  handleInputChange('end_time', event.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="field-row">
          <Select
            label={getMessage('games.providers.label')}
            placeholder={getMessage('games.providers.placeholder')}
            options={dropDownOptions}
            value={gameState.providers}
            onChange={(event) => handleInputChange('providers', event)}
            name="provider-name"
          />
        </div>
        <div className="single-input-field">
          <Input
            label={getMessage('games.providerGameUrl.label')}
            placeholder={getMessage('games.providerGameUrl.placeholder')}
            value={gameState.providerGameUrl}
            onChange={(event) => handleInputChange('provider_game_url', event)}
            name="provider-url"
          />
        </div>
        <div className="single-input-field">
          <Input
            label={getMessage('games.ruleCampaignUid.label')}
            placeholder={getMessage('games.ruleCampaignUid.placeholder')}
            value={gameState.ruleCampaignUid}
            onChange={(event) => handleInputChange('rule_campaign_uid', event)}
            name="rule-campaign-uid"
          />
        </div>
        <div className="single-input-field">
          <Input
            label={getMessage('games.maxGameLimit.label')}
            placeholder={getMessage('games.maxGameLimit.placeholder')}
            value={gameState.maxGameLimit}
            onChange={(event) => handleInputChange('max_game_limit', event)}
            type="number"
            name="game-max-limit"
          />
        </div>
        {!isReadOnly && (
          <div className="game-buttons">
            <button className="submit-button" onClick={handleClickSubmit}>
              {getMessage('games.button.submit')}
            </button>
          </div>
        )}
      </div>
      <Popup
        show={showPopup}
        heading={error ? 'Error' : 'Success'}
        close={() => {
          setError(false)
          setShowPopup(false)
          setErrorMsg('')
        }}
      >
        <div>
          {error ? (
            <div style={{ color: 'red' }}>{errorMsg}</div>
          ) : (
            <span className="successCheckmark">Success &#9989;</span>
          )}
        </div>
      </Popup>
    </AuthenticatedPage>
  )
}

export default withRouter(({ location, history, match, ...props }) => (
  <GameSettingsForm router={{ location, history, match }} {...props} />
))
