import React, { useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import './style.css'

const TextEditor = (props) => {
  const { label, html = '' } = props
  const [value, setValue] = useState(html)
  useEffect(() => setValue(html), [html])

  return (
    <div>
      <label>{label}</label>
      <Editor
        initialValue={html}
        value={value}
        onEditorChange={(newValue) => {
          setValue(newValue)
          props.onChange(newValue)
        }}
        init={{
          base_url: '/tinymce', // Root for TinyMCE assets
          suffix: '.min', // Suffix to use when loading assets,
          menubar: true,
          license_key: 'gpl', // This ensures it's using the free version
          plugins:
            'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
          toolbar:
            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog typography | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
        }}
      />
    </div>
  )
}

export default TextEditor
