import React from 'react'
import { Input, Checkbox } from 'components/Form'
import { getMessage } from 'lib/translator'

const PrintOrderConfirmation = ({ vendorEmail, codeQtyArray, onConfirm }) => {
  const [onConfirmClick, setOnConfirmClick] = React.useState(false)
  return (
    <div className="contain">
      <div className="print-order-details">
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.vendor.email')}
              value={vendorEmail}
              testid="vendor-email"
              type="text"
              readonly
            />
          </div>
        </div>
        {codeQtyArray.map((item) => (
          <div className="row" key={item.designCode}>
            <div className="col-6">
              <Input
                label={getMessage('gift.card.print.orders.design.code')}
                value={item.designCode}
                testid="design-code"
                type="text"
                readonly
              />
            </div>
            <div className="col-6">
              <Input
                label={getMessage('gift.card.print.orders.quantity')}
                value={item.quantity}
                testid="quantity"
                type="number"
                className="quantity"
                readonly
              />
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <Checkbox
          label=""
          testid="confirm-id"
          inlineLabel={getMessage('gift.card.print.orders.confirm')}
          name="checkedDetails"
          onChange={(v) => setOnConfirmClick(v)}
        />
      </div>
      <div className="row flex-end">
        <div className="form-actions">
          <button
            type="button"
            className="entity-button primary"
            disabled={!onConfirmClick}
            data-testid="submit"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default PrintOrderConfirmation
