import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Loader from '../../components/Loader'
import { DropDown, DropDownItem } from '../../components/DropDown'
import API from '../../lib/api'
import { isExtensionEnabled, getStores } from '../../lib/auth'
import { get, set } from '../../lib/storage'
import StoreSelectorForm from './Form'

import icon from './drop-down-arrow.svg'
import './style.css'

function makestoreDependentComponent(OriginalComponent) {
  return class storeDependentComponent extends Component {
    constructor(props) {
      super(props)
      this.hasMultiStoreSupport = isExtensionEnabled('MultiStoreSupport')
      if (this.hasMultiStoreSupport) {
        this.state = {
          loading: false,
          error: false,
          stores: null,
        }
      }
    }
    UNSAFE_componentWillMount() {
      if (this.hasMultiStoreSupport) {
        if (get('stores')) {
          this.setState({
            stores: JSON.parse(get('stores')),
          })
        } else {
          if (get('user')) {
            const user = JSON.parse(get('user'))
            if (user.stores && user.stores.length > 0) {
              this.setState({
                stores: user.stores,
              })
            } else {
              const api = new API({ url: '/account-service/store' })
              api.get({ paginate: 'false' }).then(response => {
                this.setState({ stores: response.data.store.reverse() })
              })
            }
          } else {
            const api = new API({ url: '/account-service/store' })
            api.get({ paginate: 'false' }).then(response => {
              this.setState({ stores: response.data.store.reverse() })
            })
          }
        }
      }
    }
    render() {
      if (!this.hasMultiStoreSupport) {
        return <OriginalComponent {...this.props} />
      }
      if (this.state.loading) {
        return <Loader />
      }
      if (this.state.stores) {
        return <OriginalComponent {...this.props} stores={this.state.stores} />
      }
      return null
    }
  }
}

function getDefaultStore(stores) {
  let result = {}
  const orgStores = getStores()
  if (stores && stores.length && get('store')) {
    const store = get('store')
    result = {
      storeId: Number(store),
    }
    return result
  }
  if (stores && stores.length) {
    result = {
      storeId: stores[0].id,
    }
  }
  if (orgStores && orgStores.length) {
    result = {
      storeId: orgStores[0].id,
    }
  }
  return result
}

function updateStore(id, onChange) {
  set('store', id)
  onChange(id)
}

export function getStoreName(store) {
  return store.hasSelfCheckout ? `${store.name} (S&G)` : store.name
}

export default class StoreSelector extends Component {
  UNSAFE_componentWillMount() {
    const allSubmenus = document.querySelectorAll(
      '#menu ul.menu-items li .submenu'
    )
    if (allSubmenus.length) {
      [].forEach.call(allSubmenus, submenu => {
        submenu.style.top = '108px'
      })
    }
    const userHoverSubmenu = document.querySelector('.user-hover .submenu')
    if (userHoverSubmenu) {
      userHoverSubmenu.style.top = '108px'
    }
  }

  render() {
    if (!this.props.stores || !this.props.value) {
      return null
    }
    let stores = this.props.stores || []
    stores = stores
      .filter(store => store.status === "ENABLED")
      .map(item => ({
        ...item,
        name: getStoreName(item),
      }))
    const logistics = window.location.pathname.includes('logistics')
    if (logistics) {
      stores = stores.filter(store => store.hasDeliveryHub)
    }
    const operations = window.location.pathname.includes('operations')
    if (operations) {
      stores = stores.filter(
        store =>
          store.hasPicking || store.hasClickCollect || store.hasSelfCheckout
      )
    }
    const allSubmenus = document.querySelectorAll(
      '#menu ul.menu-items li .submenu'
    )
    if (allSubmenus.length) {
      [].forEach.call(allSubmenus, submenu => {
        submenu.style.top = '108px'
      })
    }
    const userHoverSubmenu = document.querySelector('.user-hover .submenu')
    if (userHoverSubmenu) {
      userHoverSubmenu.style.top = '108px'
    }
    let selectedStore = stores.filter(
      ({ id }) => id === Number(this.props.value)
    )[0]
    if (!selectedStore) {
      selectedStore = stores[0]
      selectedStore && set('store', selectedStore.id)
    }
    if (!selectedStore) {
      selectedStore = {}
    }
    return ReactDOM.createPortal(
      stores && stores.length < 5 ? (
        <div className="store-selector">
          <div className="selected-store" onClick={this.simulateClick}>
            {selectedStore.name}
          </div>
          <DropDown icon={<img src={icon} alt="v" />} drop>
            {stores.map(({ id, name }) => (
              <DropDownItem
                className={
                  parseInt(this.props.value, 10) === id ? 'selected' : ''
                }
                key={id}
                onClick={() => {
                  if (parseInt(this.props.value, 10) === id) {
                    return
                  }
                  updateStore(id, this.props.onChange)
                }}
              >
                {name}
              </DropDownItem>
            ))}
          </DropDown>
        </div>
      ) : (
        <StoreSelectorForm
          selectedStore={selectedStore.name}
          stores={stores || []}
          onChange={this.props.onChange}
          updateStore={updateStore}
        />
      ),
      document.getElementById('store-selector')
    )
  }
}

export { getDefaultStore, makestoreDependentComponent }
