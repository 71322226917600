import React from 'react'
import { Input, Checkbox } from 'components/Form'
import { getMessage } from 'lib/translator'

const ConfirmationDetails = ({
  id,
  denominationType,
  denomination,
  sku = '',
  onConfirm,
  isSalesOrder,
  type,
  entityId,
  customerEmails,
  paymentMode,
  selectedItem,
  quantity,
}) => {
  const [onConfirmClick, setOnConfirmClick] = React.useState(false)
  const [checkedDetails, setCheckedDetails] = React.useState(false)

  return (
    <div className="contain">
      {isSalesOrder ? (
        <>
          <div className="col-12">
            <Input
              label={getMessage(
                'gift.card.design.codes.input.denomination.type'
              )}
              value={type}
              type="text"
              readOnly
            />
          </div>

          <div className="col-12">
            <Input
              label={getMessage('offer.entity.id')}
              value={entityId}
              type="text"
              readOnly
            />
          </div>
          <div className="col-12">
            <Input
              label={getMessage('gift.card.sales.orders.email')}
              value={customerEmails}
              type="text"
              readOnly
            />
          </div>

          <div className="col-12">
            <Input
              label={getMessage('order.filters.payment.heading')}
              value={paymentMode?.replace(/_/g, ' ')}
              type="text"
              readOnly
            />
          </div>
          <div className="col-12">
            <Input
              label={getMessage('gift.card.print.orders.design.code')}
              value={selectedItem?.map((item) => item.text)}
              type="text"
              readOnly
            />
          </div>

          <div className="col-12">
            <Input
              label={getMessage('gift.card.print.orders.quantity')}
              value={quantity}
              type="text"
              readOnly
            />
          </div>
          <div className="col-12">
            <Input
              label={getMessage('gift.card.design.codes.input.denomination')}
              value={denomination}
              type="text"
              readOnly
            />
          </div>
        </>
      ) : (
        <>
          <div className="col-12">
            <Input
              label={getMessage('gift.card.design.codes.title')}
              value={id}
              type="text"
              readOnly
            />
          </div>
          <div className="col-12">
            <Input
              label={getMessage(
                'gift.card.design.codes.input.denomination.type'
              )}
              value={denominationType}
              type="text"
              readOnly
            />
          </div>
          <div className="col-12">
            <Input
              label={getMessage('gift.card.design.codes.input.denomination')}
              value={denomination}
              type="text"
              readOnly
            />
          </div>
          <div className="col-12">
            <Input
              label={getMessage('gift.card.design.codes.input.sku')}
              value={sku}
              type="text"
              readOnly
            />
          </div>
        </>
      )}
      <div className="row">
        <Checkbox
          label=""
          testid="confirm-id"
          inlineLabel="I confirm that all information given above is accurate and complete"
          name="checkedDetails"
          onChange={(v) => setCheckedDetails(v)}
        />
      </div>
      {onConfirmClick && !checkedDetails && (
        <div className="row">
          <div className="input-error-message">This field is required</div>
        </div>
      )}
      <div className="row flex-end">
        <div className="form-actions">
          <button
            type="button"
            className="entity-button primary"
            disabled={!checkedDetails}
            data-testid="submit"
            onClick={() => {
              onConfirm()
              setOnConfirmClick(true)
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationDetails
