import React from 'react'
import { getMessage } from 'lib/translator'
import { Link } from 'react-router-dom'
import ListingPage from 'containers/ListingPage'
import VoucherCampaignTableProperties from './VoucherCampaignTableProperties'
import emptyIcon from 'icons/no-orders.svg'

const AddNewCampaignButton = () => (
  <Link to="/marketing/voucher-center-campaigns/new">
    <button className="primary button">
      {`+ ${getMessage('offer.campaigns.listing.add.offerCampaign')}`}
    </button>
  </Link>
)

const VoucherCenterCampaignsTable = ({ menu }) => {
  return (
    <ListingPage
      testid="voucher-campaign-listing"
      menu={menu}
      className="voucher-center-campaigns-page"
      title={getMessage('offer.campaigns.listing.heading')}
      api={{
        url: '/offer-service/campaigns',
        transform: (response) => response.data.campaign,
      }}
      tableDynamic
      primaryKey="id"
      tableProperties={VoucherCampaignTableProperties()}
      headerActions={AddNewCampaignButton}
      emptyState={{
        icon: emptyIcon,
        message: 'There is no voucher campaign at the moment.',
        actions: AddNewCampaignButton
      }}
      nextPrevButtonsEnabled
      dontSavePagination
    />
  )
}

export default VoucherCenterCampaignsTable
