import React from 'react'
import { BaseForm, VALIDATION_TYPES, Input, Checkbox } from 'components/Form'
import { withRouter } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import { multipleEmailErrorMsg } from 'components/Evoucher/utils'
import API from 'lib/api'

import './style.css'
import ConfirmationDetails from './ConfirmationDetails'

class EntityForm extends BaseForm {
  constructor(props) {
    super(props)

    const inputList =
      this.state.values.inputList && this.state.values.inputList.filter(Boolean)
    if (!inputList) {
      this.state.values = {
        error: '',
        success: false,
        showPopup: false,
        errorMsg: '',
        isExist: false,
        isNoEmail: false,
      }
    }
    this._validateEntityAndEmail = this._validateEntityAndEmail.bind(this)
  }

  async _validateEntityAndEmail() {
    const {
      emails = '',
      entityName = '',
      address = '',
      floor = '',
      buildingName = '',
      postalCode = '',
      uen = '',
      isFPStore = false,
      unitNumber = '',
    } = this.state.values
    try {
      const isEdit = this.props.method === 'edit'
      const url = isEdit
        ? `/gifting-admin-service/entities/${this.props.resourceId}`
        : '/gifting-admin-service/entities'
      const api = new API({ url: url })
      const voucherEmailsArr = Array.isArray(emails)
        ? emails.map((item) => item.trim())
        : emails
            .toString()
            .split(',')
            .map((item) => item.trim())

      const data = {
        name: entityName,
        address: {
          buildingName,
          address: address,
          floor,
          city: '',
          postalCode,
          country: '',
          unitNumber,
        },
        email: voucherEmailsArr,
        uen,
        isFPStore,
        customers: voucherEmailsArr.map((email) => ({ email })),
      }

      if (isEdit) {
        delete data.email
        await api.patch(data)
        this.props.history.push({
          pathname: `/customer-support/gifting-entities/view/${this.props.resourceId}`,
          state: {
            isEdit: true,
          },
        })
      } else {
        await api.post(data)
        this.props.onCancel()
      }
    } catch (error) {
      let newValues
      switch (error.code) {
        case 409:
        case 503: {
          newValues = {
            errorMsg: error.message,
            isExist: true,
          }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          break
        }
        case 400: {
          newValues = {
            errorMsg: error.message,
            isExist: false,
            isNoEmail: true,
          }
        }
      }

      this.setState({
        values: { ...this.state.values, ...newValues },
        showPopup: false,
      })
    }
  }

  async _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit(this.state.values)
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValid = this.isFormValid()
    if (isValid) {
      this.setState({ showPopup: true })
    }
  }

  componentDidMount() {
    if (this.props.method === 'edit') {
      const { data } = this.props.value
      this.setState({
        values: {
          uen: data.uen || '',
          isFPStore: data.isFPStore || false,
          entityName: data?.name || '',
          address: data.address?.address || '',
          unitNumber: data.address?.unitNumber || '',
          floor: data?.address?.floor || '',
          buildingName: data.address?.buildingName || '',
          postalCode: data.address?.postalCode || '',
          emails: Array.isArray(data.customers)
            ? data.customers.map((customer) => customer?.email)
            : [],
        },
      })
    }
  }

  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons

    return (
      <Form>
        <div className="form-fields-entities row no-margin" id="entityName">
          <div className="col-6 no-margin">
            <Input
              required
              label={getMessage('eVoucher.filter.entityName')}
              placeholder={getMessage('eVoucher.filter.entityName')}
              name="entityName"
              type="text"
              error={this.state.values.isExist}
              {...this.generateStateMappers({
                stateKeys: ['entityName'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
          </div>
          <div className="col-6 no-margin error-msg">
            {this.state.values.isExist && (
              <span className="input-error-message">
                {getMessage('eVoucher.entities.error.entityName')}
              </span>
            )}
          </div>
        </div>
        <div className="form-fields-entities uen">
          <Input
            label={getMessage('eVoucher.entities.UEN')}
            placeholder={`${getMessage('eVoucher.entities.UEN')}*`}
            name="uen"
            required
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['uen'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <div className="form-fields-entities margin-top">
          <Input
            label={getMessage('eVoucher.entities.billing')}
            placeholder={`${getMessage('rewards.address')}*`}
            name="address"
            required
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['address'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className="form-fields-entities">
          <Input
            placeholder={getMessage(
              'customer.details.pages.address.unitNumber'
            )}
            className="form-billing"
            name="unitNumber"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['unitNumber'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            placeholder={getMessage('rewards.floor')}
            name="floor"
            type="text"
            className="form-billing"
            {...this.generateStateMappers({
              stateKeys: ['floor'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            placeholder={getMessage('customer.details.address.buildingName')}
            name="buildingName"
            type="text"
            className="form-billing"
            {...this.generateStateMappers({
              stateKeys: ['buildingName'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            placeholder={`${getMessage(
              'customer.details.address.postalCode'
            )}*`}
            name="postalCode"
            type="text"
            maxLength={6}
            minLength={0}
            required
            className="form-billing"
            {...this.generateStateMappers({
              stateKeys: ['postalCode'],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
        </div>
        <div className="form-fields-entities row margin-top" id="entityName">
          <div className="col-6 no-margin">
            <Input
              label={getMessage('eVoucher.entities.fp.account.emails')}
              placeholder={getMessage('eVoucher.entities.comma.separated')}
              name="emails"
              required
              error={this.state.values.isNoEmail}
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['emails'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              validationStrings={multipleEmailErrorMsg}
            />
          </div>
          <div className="col-6 no-margin error-msg">
            {this.state.values.isNoEmail && (
              <span className="input-error-message">
                {`${this.state.values.emails
                  .split(',')
                  .map((item) => item.trim())}${getMessage(
                  'eVoucher.entities.error'
                )}`}
              </span>
            )}
          </div>
        </div>
        <div className="row mt">
          <div className="col-4">
            <Checkbox
              inlineLabel={getMessage('eVoucher.entities.is.fp.store')}
              name="isFPStore"
              {...this.generateStateMappers({
                stateKeys: ['isFPStore'],
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        </div>
        <div className="form-actions-entities margin-top">
          <SubmitButton testid="confirm-submit">Submit</SubmitButton>
          <CancelButton testid="cancel-submit">Cancel</CancelButton>
        </div>
        <Popup
          show={this.state.showPopup}
          heading={getMessage('eVoucher.entities.confirm')}
          close={() => this.setState({ showPopup: false })}
          className="confirmPopup"
          data-testid="confirm-popup"
        >
          <ConfirmationDetails
            entityName={this.state.values.entityName}
            uen={this.state.values.uen}
            billing={this.state.values.address}
            unitNumber={this.state.values.unitNumber}
            floor={this.state.values.floor}
            buildingName={this.state.values.buildingName}
            isFPStore={this.state.values.isFPStore}
            postal={this.state.values.postalCode}
            emails={this.state.values.emails}
            onConfirm={this._validateEntityAndEmail}
          />
        </Popup>
      </Form>
    )
  }
}

export default withRouter(EntityForm)
