import React, { Component } from 'react'
import { Popup } from '../../../../components/Popup'
import Loader from '../../../../components/Loader'
import searchIcon from './icon-search.svg'
import './style.css'
import API from '../../../../lib/api'
import debounce from '../../../../lib/debounce'
import { isEqual } from 'lodash'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import DOMPurify from 'dompurify';

export const textElement = (suggestion, nameKey, searchtext, name) => {
  if (name === 'tag') {
    return (
      <>
        <span
          dangerouslySetInnerHTML={{
            __html: boldTextWrapper(suggestion[nameKey], searchtext),
          }}
        ></span>
        <span
          dangerouslySetInnerHTML={{
            __html: `&nbsp;(${boldTextWrapper(
              String(suggestion['id']),
              String(searchtext)
            )})`,
          }}
        ></span>
        <span>{` - ${suggestion['productsCount']} products`}</span>
      </>
    )
  } else if (
    name === 'category' ||
    name === 'secondary-categories' ||
    name === 'category-search'
  ) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: boldTextWrapper(
            `${suggestion[nameKey]} - ${suggestion['productsCount']} products`,
            searchtext
          ),
        }}
      ></span>
    )
  } else {
    return suggestion[nameKey]
  }
}

export const boldTextWrapper = (str, query) => {
  const n = str.toUpperCase()
  const q = query.toUpperCase()
  const x = n.indexOf(q)
  if (!q || x === -1) {
    return DOMPurify.sanitize(str) // bail early
  }
  const l = q.length
  const result = `${str.substr(0, x)}<b>${str.substr(x, l)}</b>${str.substr(x + l)}`
  return DOMPurify.sanitize(result)
}

function getValidationObj(props, value) {
  let valueMissing = props.required && !value
  if (props.required && props.multiple) {
    valueMissing = valueMissing || !value || !value.length
  }
  return {
    valueMissing,
    valid: !valueMissing,
  }
}

const SortableItem = SortableElement((props) => {
  return (
    <div className="tag-value">
      <span className="tag-value-label">
        <small>
          {props.displaySelectedValue
            ? props.displaySelectedValue(props.value)
            : props.value[props.nameKey]}
        </small>
      </span>
      <button
        type="button"
        className="tag-value-icon"
        onClick={() => {
          props.onSelect(props.value)
        }}
      />
    </div>
  )
})

const SortableList = SortableContainer((props) => {
  const items = props.items || []
  return (
    <ul>
      {items &&
        Array.isArray(items) &&
        items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            nameKey={props.nameKey}
            valueKey={props.valueKey}
            displaySelectedValue={props.displaySelectedValue}
            onSelect={props.onSelect}
          />
        ))}
    </ul>
  )
})

class Searchable extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.runValidation = this.runValidation.bind(this)
    this.state = {
      options: [],
      searchText: '',
      page: 1,
      selectedValue: props.value,
      loading: false,
      creating: false,
      count: 0,
      offset: 0,
      showRestrictedTagRemovePopup: false,
      restrictedTagValue: ''
    }
    this.api = new API({ url: props.searchUrl })
    this.selectSearch = debounce(this.selectSearch.bind(this), 300)
    this.search = this.selectSearch
    this.onSelect = this.onSelect.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(
      ({ selectedValue }) => ({
        selectedValue: arrayMove(selectedValue, oldIndex, newIndex),
      }),
      () => {
        this.props.onChange(this.state.selectedValue)
      }
    )
  }

  runValidation(value) {
    this.props.onValidation &&
      this.props.onValidation(getValidationObj(this.props, value))
  }

  onSelect(option) {
    let selectedValue = option
    if (this.props.multiple) {
      selectedValue = [...(this.state.selectedValue || [])]
      // This should toggle the option from selectedValue
      const index = selectedValue.findIndex(
        (listOption) =>
          listOption[this.props.valueKey] === option[this.props.valueKey]
      )
      if (index > -1) {
        selectedValue.splice(index, 1)
      } else {
        selectedValue = [...selectedValue, option]
      }
    }
    this.setState(
      {
        options: [],
        lastRequest: '',
        searchText: '',
        pressedEnter: false,
      },
      () => {
        this.props.onChange(selectedValue)
        this.runValidation(selectedValue)
      }
    ) // When props.multiple === false
  }

  componentDidMount() {
    this.runValidation(this.state.selectedValue)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.runValidation(this.state.selectedValue)
    }
  }

  addNewItem(name) {
    this.setState({ creating: true }, () => {
      this.api
        .post({ name })
        .then((response) => {
          this.onSelect(
            response.data[this.props.responseKey] || response.data['name']
          )
        })
        .then(() => {
          this.setState({ creating: false })
        })
    })
  }

  selectSearch(api, value, searchkey, searchUrl, fetchMore) {
    const paramsDefault = {}
    paramsDefault[searchkey] = value
    const params = this.props.transformRequest
      ? this.props.transformRequest(this.state.searchText, paramsDefault)
      : paramsDefault
    if (fetchMore) {
      const { page: pageProp } = this.state
      let page = pageProp
      page++
      params['page'] = page
      this.setState({ page, loading: true })
    } else {
      this.setState({ page: 1 })
    }
    if (value !== '') {
      api
        .get(params)
        .then((response) => {
          let data = []
          const { options } = this.state
          if (this.props.asyncTransform) {
            if (this.props.transformResponse) {
              this.props.transformResponse(response).then((resData) => {
                let newData = resData
                if (fetchMore) {
                  newData = options.concat(resData)
                }
                this.setState({
                  options: newData,
                  lastRequest: value,
                  count: response.data.count,
                  offset: response.data.offset,
                  loading: false,
                })
              })
            }
          } else {
            data = this.props.transformResponse
              ? this.props.transformResponse(response)
              : response.data
            if (fetchMore) {
              data = options.concat(data)
            }
            if (
              this.props.selectOnEnter &&
              this.state.pressedEnter &&
              data &&
              data.length > 0
            ) {
              this.onSelect(data[0])
            } else {
              this.setState({
                options: data,
                lastRequest: value,
                count: response.data.count,
                offset: response.data.offset,
                loading: false,
              })
            }
          }
        })
        .catch((error) => {
          if (error.code === 401) {
            throw error
          }
          this.setState({
            error:
              error.message || 'Something went wrong, please try again later',
          })
        })
    }
  }

  onKeyDown(e) {
    if (!this.props.multiple && (e.keyCode === 8 || e.keyCode === 46)) {
      if (this.state.selectedValue) {
        this.onSelect('')
      }
    }
    if (this.props.selectOnEnter && this.state.searchText && e.keyCode === 13) {
      e.preventDefault()
      this.setState({
        pressedEnter: true,
      })
    } else if (this.state.pressedEnter) {
      this.setState({
        pressedEnter: false,
      })
    }
  }

  handleChange(e) {
    e.preventDefault()
    const { searchKey, searchUrl } = this.props
    this.setState({
      searchText: e.target.value,
      error: null,
    })
    const searchkey = searchKey || 'term'
    this.search(this.api, e.target.value, searchkey, searchUrl)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var newValue, oldValue
    if (this.props.multiple) {
      newValue = nextProps.value
      oldValue = this.props.value
    } else {
      newValue = nextProps.value
        ? nextProps.value[this.props.nameKey] || nextProps.value['name'] || ''
        : ''
      oldValue = this.props.value
        ? this.props.value[this.props.nameKey] || this.props.value['name'] || ''
        : ''
    }
    if (oldValue !== newValue) {
      this.setState({
        searchText: '',
        selectedValue: nextProps.value || (this.props.multiple ? [] : ''),
      })
    }
  }

  render() {
    const {
      type,
      name,
      placeholder,
      required,
      valueKey,
      nameKey,
      createButton = false,
      readOnly,
      expandMore,
    } = this.props
    const options = this.state.options || []
    const { count, offset, loading } = this.state
    const selectableValues = options.length
      ? options.map((suggestion, index) => {
          var suggestionAllowed
          if (this.props.multiple) {
            suggestionAllowed =
              this.state.selectedValue && this.state.selectedValue.length
                ? this.state.selectedValue.findIndex(
                    (option) => suggestion[valueKey] === option[valueKey]
                  ) === -1
                : true
          } else {
            suggestionAllowed = this.state.selectedValue
              ? this.state.selectedValue[valueKey] !== suggestion[valueKey]
              : true
          }
          return suggestionAllowed ? (
            this.props.renderListElement ? (
              this.props.renderListElement(
                suggestion,
                valueKey,
                nameKey,
                this.onSelect,
                this.state.searchText
              )
            ) : (
              <li
                className="select-option"
                key={`${suggestion[nameKey]}-${index}`}
                onClick={() => this.onSelect(suggestion)}
              >
                {textElement(suggestion, nameKey, this.state.searchText, name)}
              </li>
            )
          ) : (
            ''
          )
        })
      : ''
    if (
      options.length &&
      options.length >= 20 &&
      offset < count &&
      expandMore
    ) {
      const { searchKey, searchUrl } = this.props
      const { lastRequest } = this.state
      if (loading) {
        selectableValues.push(<Loader size="sm" />)
      } else {
        selectableValues.push(
          <li
            className="select-option-bold"
            key={'add-more-button'}
            onClick={() =>
              this.selectSearch(
                this.api,
                lastRequest,
                searchKey,
                searchUrl,
                true
              )
            }
          >
            + Click to view more
          </li>
        )
      }
    }

    return (
      <div
        className={`Searchable ${this.state.creating ? 'creating' : ''}`.trim()}
      >
        <div className="input">
          <input
            id={name}
            name={name}
            type={type || 'text'}
            value={
              this.state.searchText ||
              (this.state.selectedValue &&
                ((this.props.displaySelectedValue &&
                  this.props.displaySelectedValue(this.state.selectedValue)) ||
                  this.state.selectedValue[nameKey] ||
                  this.state.selectedValue['name'])) ||
              ''
            }
            placeholder={placeholder}
            required={required}
            onChange={this.handleChange}
            autoComplete="off"
            readOnly={readOnly}
            onKeyDown={this.onKeyDown}
            className={readOnly ? 'focus-none' : ''}
          />
          <img src={searchIcon} className="select-field-icon" alt="" />
        </div>
        {this.state.error && (
          <div className="form-error">{this.state.error}</div>
        )}
        {this.state.searchText && (
          <div className="select-field-dropdown-container">
            <ul
              className={`select-field-dropdown ${
                this.state.lastRequest !== this.state.searchText ? 'hidden' : ''
              }`.trim()}
            >
              {createButton &&
              this.state.options.findIndex(
                (option) =>
                  option[nameKey].toLowerCase() ===
                  this.state.searchText.toLowerCase()
              ) === -1 ? (
                <li className="select-option unselectable">
                  {this.state.searchText}
                  {this.state.creating ? (
                    <Loader size="sm" />
                  ) : (
                    <button
                      type="button"
                      className="add-button"
                      onClick={() => {
                        this.addNewItem(this.state.searchText)
                      }}
                    >
                      + {createButton || 'Create'}
                    </button>
                  )}
                </li>
              ) : null}
              {selectableValues}
            </ul>
          </div>
        )}
        {this.props.multiple ? (
          this.props.draggable ? (
            <div className="tag-values draggable-values">
              <SortableList
                items={this.state.selectedValue}
                onSortEnd={this.onSortEnd}
                nameKey={nameKey}
                valueKey={valueKey}
                displaySelectedValue={this.props.displaySelectedValue}
                onSelect={this.onSelect}
                axis={'xy'}
                pressDelay={200}
                helperClass="sortableHelper"
              />
            </div>
          ) : (
            <div className="tag-container">
              {(this.state.selectedValue || []).map((value) => (
                <div className="tag-value" key={value[this.props.valueKey]}>
                  <span className="tag-value-label">
                    <small>
                      {this.props.displaySelectedValue
                        ? this.props.displaySelectedValue(value)
                        : value[nameKey]}
                    </small>
                  </span>
                  {!readOnly && (
                    <button
                      type="button"
                      className="tag-value-icon"
                      onClick={() => {
                        if(value.name === 'Exclusion List'){
                          this.setState({
                            showRestrictedTagRemovePopup: true,
                            restrictedTagValue: value
                          })
                        } else {
                          this.onSelect(value)
                        }
                      }}
                    />
                  )}
                </div>
              ))}
              <Popup
                show={this.state.showRestrictedTagRemovePopup}
                heading={'Remove Exclusion list tag from product'}
                close={() =>
                  this.setState({ showRestrictedTagRemovePopup: false })
                }
              >
                <div>
                  <p>This will make the product eligble for ratings & review and advertisement. Do you wish to proceed?</p>
                  <div className="button-wrapper">
                    <button
                      className="button button-white"
                      type="button"
                      onClick={() => {
                        this.onSelect(this.state.restrictedTagValue)
                        this.setState({
                          showRestrictedTagRemovePopup: false,
                        })
                      }}
                    >
                      Ok
                    </button>
                    <button
                      className="button primary"
                      type="button"
                      onClick={() => {
                        this.setState({
                          showRestrictedTagRemovePopup: false,
                        })
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Popup>
            </div>
          )
        ) : (
          this.state.selectedValue && (
            <div className="tag-value">
              <span className="tag-value-label">
                <small>
                  {this.state.selectedValue && this.state.selectedValue.name}
                </small>
              </span>
            </div>
          )
        )}
      </div>
    )
  }
}

Searchable.defaultProps = {
  nameKey: 'name',
  valueKey: 'value',
  draggable: false,
  expandMore: false,
}

export default Searchable
