import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ScanCampaignForm from './Form'
import ScanCampaignsTable from './Table'

class ScanCampaignsRouter extends Component {
  render() {
    const { router } = this.props
    if (router.match.params.id) {
      return <ScanCampaignForm {...this.props} isEdit />
    }
    return <ScanCampaignsTable {...this.props} />
  }
}

export default withRouter(({ match, ...props }) => (
  <ScanCampaignsRouter router={{ match }} {...props} />
))
