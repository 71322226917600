import { getMessage } from 'lib/translator'
import React from 'react'
import { Link } from 'react-router-dom'

const Actions = () => {
  return (
    <div className="actions-section">
      <Link to="/gift-card/print-orders/add">
        <button className="primary button">
          <span className="text">
            {getMessage('gift.card.print.orders.add')}
          </span>
        </button>
      </Link>
    </div>
  )
}

export default Actions
