import React, { useMemo } from 'react'
import { getMessage } from '../../../lib/translator'
import Select from '../../../components/Form/Inputs/Select'

const JobsDropdown = (props) => {
  const { onChange, value, isJobError, disabled } = props

  const jobsOption = useMemo(() => {
    return Object.keys(props?.jobConfig).map((key) => ({
      text: props?.jobConfig[key].name,
      value: key,
    }))
  }, [props?.jobConfig])

  return (
    <div className="batch-upload-jobs">
      <h1 className="title">{getMessage('catalogue.batch-jobs.heading')}</h1>
      <div className={`dropdown-wrapper ${isJobError && 'hasError'}`}>
        <span className="labelWrap">
          <label htmlFor="BatchUploadDropdown">Jobs</label>
        </span>
        <Select
          id="batch-upload-dropdown"
          name="BatchUploadDropdown"
          placeholder="Select a job"
          value={value}
          options={jobsOption}
          onChange={onChange}
          disabled={disabled}
        />
        {isJobError && <span className="error">Please select a job</span>}
      </div>
    </div>
  )
}

export default JobsDropdown
