import React, { useState } from 'react'
import CustomDropdown from '../OfferSequenceLabel/CustomDropdown'
import ConfigCardList from '../OfferSequenceLabel/ConfigCardList'
import './style.css'
import { Link } from 'react-router-dom'
import { isCatalogueProductReadOnly } from 'pages/catalogue/Products/utils'

function ConfigSetupList(props) {
  const [selectedOption, setSelectedOption] = useState(
    props.type || 'offer-label-sequence'
  )
  const isReadOnly = isCatalogueProductReadOnly()
  return (
    <div>
      <div className="ConfigSetup">
        <h1>Configurations</h1>
        <div className="ConfigAdd">
          <CustomDropdown
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
          />
          {!isReadOnly && (
            <div className="ConfigNew">
              <Link to={`/catalogue/configurations/${selectedOption}/add`}>
                <h5>+ Add New Config</h5>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="ConfigContent">
        <ConfigCardList selectedType={selectedOption} />
      </div>
    </div>
  )
}

export default ConfigSetupList
