import React from 'react'
import {
  Input,
  VALIDATION_TYPES,
  DateTime,
  Upload,
  Textarea,
  Select,
  ColorPicker,
} from 'components/Form'
import { getMessage } from 'lib/translator'
import './style.scss'

const TITLE_CATEGORY_OPTIONS = [
  { text: 'Cart vouchers', value: 1 },
  { text: 'Free shipping vouchers', value: 2 },
  { text: 'Product or category vouchers', value: 3 },
  { text: 'Others', value: 4 },
]

function PrimaryInputs({
  generateStateMappers,
  isExpiredCampaign,
  formValues,
  updateTitle,
}) {
  const isStandardCategory =
    formValues?.category === 1 ||
    formValues?.category === 2 ||
    formValues?.category === 3

  return (
    <>
      <Select
        placeholder={getMessage('offer.campaigns.form.category.placeholder')}
        label={getMessage('offer.campaigns.form.category')}
        name="campaignCategory"
        options={TITLE_CATEGORY_OPTIONS}
        className="title-category-select"
        testid="title-category-select"
        disabled={isExpiredCampaign}
        type="number"
        {...generateStateMappers({
          stateKeys: ['category'],
          loseEmphasisOnFill: true,
          onChange: (val) => {
            if (val !== 4) {
              updateTitle(TITLE_CATEGORY_OPTIONS[val - 1].text)
            }
          },
        })}
      />
      <Input
        type="text"
        label={getMessage('offer.campaigns.form.campaignTitle')}
        name="campaignTitle"
        placeholder={getMessage(
          'offer.campaigns.form.campaignTitle.placeholder'
        )}
        disabled={isExpiredCampaign || isStandardCategory}
        {...generateStateMappers({
          stateKeys: ['title'],
          loseEmphasisOnFill: true,
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
      />

      <Input
        type="text"
        placeholder={getMessage(
          'offer.campaigns.form.campaignName.placeholder'
        )}
        name="campaignName"
        label={getMessage('offer.campaigns.form.campaignName')}
        required
        disabled={isExpiredCampaign}
        {...generateStateMappers({
          stateKeys: ['name'],
          loseEmphasisOnFill: true,
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
      />
      <Textarea
        placeholder={getMessage(
          'offer.campaigns.form.campaignDescription.placeholder'
        )}
        name="campaignDescription"
        label={getMessage('offer.campaigns.form.campaignDescription')}
        disabled={isExpiredCampaign}
        {...generateStateMappers({
          stateKeys: ['description'],
          loseEmphasisOnFill: true,
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
      />
      <ColorPicker
        className="campaign-background-color-picker"
        label={getMessage('offer.campaigns.form.backgroundColor')}
        disabled={isExpiredCampaign}
        {...generateStateMappers({
          stateKeys: ['metaData', 'backgroundColor'],
          loseEmphasisOnFill: true,
        })}
      />
      <Upload
        name="image"
        className="bannerImage"
        placeholder={getMessage(
          'offer.campaigns.form.campaignBanner.placeholder'
        )}
        label={getMessage('offer.campaigns.form.campaignBanner')}
        accept={'image/jpeg,image/jpg,image/png,image/gif'}
        disabled={isExpiredCampaign}
        {...generateStateMappers({
          stateKeys: ['image'],
          loseEmphasisOnFill: true,
        })}
      />
      <div className="datetime-picker-container">
        <DateTime
          name="startDateTime"
          label={getMessage('offer.campaigns.form.startDateTime')}
          allowAllDates
          required
          disabled={isExpiredCampaign}
          useTimePicker
          {...generateStateMappers({
            stateKeys: ['validFrom'],
            loseEmphasisOnFill: true,
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
        />
        <div> - to - </div>
        <DateTime
          name="endDateTime"
          label={getMessage('offer.campaigns.form.endDateTime')}
          allowAllDates
          required
          disabled={isExpiredCampaign}
          useTimePicker
          {...generateStateMappers({
            stateKeys: ['validTill'],
            loseEmphasisOnFill: true,
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
        />
      </div>
      <div className="primary-cta-container">
        <Input
          type="text"
          placeholder={getMessage(
            'offer.campaigns.form.primaryCTA.placeholder'
          )}
          className="primary-cta"
          label={getMessage('offer.campaigns.form.primaryCTA')}
          disabled={isExpiredCampaign}
          {...generateStateMappers({
            stateKeys: ['cta'],
            loseEmphasisOnFill: true,
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
        />
        <Input
          type="text"
          placeholder={getMessage(
            'offer.campaigns.form.redirectLink.placeholder'
          )}
          className="link"
          label={getMessage('offer.campaigns.form.redirectLink')}
          disabled={isExpiredCampaign}
          {...generateStateMappers({
            stateKeys: ['link'],
            loseEmphasisOnFill: true,
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
        />
      </div>
    </>
  )
}

export default PrimaryInputs
