import React from 'react'
import Form, {
  BaseForm,
  Input,
  Select,
  SingleDatePicker,
} from 'components/Form'
import moment from 'moment'
import Button from 'components.new/button/Button'
import { ReactComponent as PlusIcon } from 'icons/plus.svg'
import { ReactComponent as BinIcon } from 'icons/delete.svg'
import './ConfigSetupList/style.css'

const CAMPAIGN_CATEGORY = 'campaign-category'

const Status = [
  {
    text: 'Enabled',
    value: 'ENABLED',
  },
  {
    text: 'Disabled',
    value: 'DISABLED',
  },
]

const getTimes = () => {
  const times = []
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      times.push(`0${i}:00`)
      times.push(`0${i}:30`)
    } else {
      times.push(`${i}:00`)
      times.push(`${i}:30`)
    }
  }
  return times
}

class AddNewConfig extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      status: 'ENABLED',
      offerSequence: '',
      categoryGroup: [{ campaignId: '', campaignCategories: '' }],
      startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      startTime: '00:00',
      endDate: '',
      endTime: '',
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.validateFields = this.validateFields.bind(this)
  }

  // Function to validate state properties
  validateFields = () => {
    const { status, startDate, offerSequence, startTime } = this.state
    let isValid = true
    if (this.props.selectedOption === CAMPAIGN_CATEGORY) {
      if (!status || !startDate || !startTime) {
        isValid = false
      }
    } else {
      if (
        !status ||
        !offerSequence ||
        !startDate ||
        offerSequence.trim() === ''
      ) {
        isValid = false
      }
    }
    return isValid
  }

  addNewField = () => {
    const { categoryGroup } = this.state
    categoryGroup.push({ campaignId: '', campaignCategories: '' })
    this.setState({ categoryGroup })
  }

  componentDidMount() {
    const { selectedOption } = this.props
    if (this.props.editData) {
      const { id, status, data, validFrom, validTill } = this.props.editData

      // assuming this value always available
      const fromDate = new Date(validFrom).toLocaleDateString('en-CA')
      const fromTime = new Date(validFrom).toLocaleTimeString()

      const tillDate = validTill
        ? new Date(validTill).toLocaleDateString('en-CA')
        : undefined
      const tillTime = validTill
        ? new Date(validTill).toLocaleTimeString()
        : undefined

      const info = {
        id: id,
        status: status,
        startDate: fromDate,
        startTime: moment(fromTime, 'HH:mm:ss').format('HH:mm'),
        endDate: tillDate ? tillDate : '',
        endTime: tillTime ? moment(tillTime, 'HH:mm:ss').format('HH:mm') : '',
      }
      if (selectedOption === CAMPAIGN_CATEGORY) {
        const modifiedData = data.data.map((item) => {
          return {
            campaignId: item.campaignId,
            campaignCategories: item.campaignCategories.join(),
          }
        })
        info.categoryGroup = modifiedData
      } else {
        info.offerSequence = data?.data.join(',')
      }
      this.setState(info)
    }
  }

  onSubmit(evt) {
    const { status, categoryGroup, offerSequence } = this.state
    evt.preventDefault()
    if (this.validateFields()) {
      const data = {
        status: status,
        variant: 'standard',
        validFrom: moment(
          `${this.state.startDate?.split(' ')[0]} ${this.state.startTime}`,
          'YYYY-MM-DD HH:mm'
        )
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        validTill:
          this.state?.endDate && this.state?.endTime
            ? moment(
                `${this.state.endDate.split(' ')[0]} ${this.state.endTime}`,
                'YYYY-MM-DD HH:mm'
              )
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]')
            : null,
      }
      if (this.props.selectedOption === CAMPAIGN_CATEGORY) {
        const modifiedCategoryGroup = categoryGroup.map((item) => {
          return {
            campaignId: item.campaignId,
            campaignCategories: item.campaignCategories
              .split(',')
              .map((id) => Number(id)),
          }
        })
        data.data = {
          data: modifiedCategoryGroup,
        }
      } else {
        data.data = {
          data: offerSequence.split(','),
        }
      }
      if (this.props.editData) {
        this.props.onSave(data, this.state.id)
      } else {
        this.props.onSave(data)
      }
    } else {
      return
    }
  }

  render() {
    const { categoryGroup } = this.state
    const ispreOrder = this.props.selectedOption === CAMPAIGN_CATEGORY
    return (
      <div className="ConfigAddNew">
        <div className="AddHeader">
          <h3>
            {this.props.editData
              ? ispreOrder
                ? 'Edit Preorder Campaign Configuration'
                : 'Edit Configuration'
              : ispreOrder
                ? '+ Add Preorder Campaign Configuration'
                : '+ Add Configuration'}
          </h3>

          <div className="ConfigBottom">
            <button
              className={`ConfigSubmit${!this.validateFields() ? ' SaveDisabled' : ''}`}
              onClick={this.onSubmit}
            >
              Save
            </button>
            <button className="ConfigCancel" onClick={this.props.onCancel}>
              Go Back
            </button>
          </div>
        </div>
        <Form>
          <div className="FirstBox">
            <Select
              name="status"
              placeholder="Please select the status"
              label="Status"
              options={Status}
              value={this.state.status}
              onChange={(value) => {
                this.setState({ status: value })
              }}
            />
          </div>
          {!ispreOrder && (
            <Input
              type="text"
              name="sequence"
              label="Offer Sequence"
              placeholder="Enter the offer sequence comma separated - JWC, DC"
              value={this.state.offerSequence}
              onChange={(value) => {
                this.setState({ offerSequence: value })
              }}
            />
          )}
          {ispreOrder &&
            categoryGroup.map((item, index) => {
              return (
                /* eslint-disable-next-line react/no-array-index-key */
                <div className="FirstBox" key={index}>
                  <Input
                    type="number"
                    name="campaign id"
                    label="Campaign ID"
                    placeholder="Enter the campaign id"
                    value={item.campaignId}
                    onChange={(value) => {
                      categoryGroup[index].campaignId = value
                      this.setState({ categoryGroup })
                    }}
                  />
                  <Input
                    type="text"
                    name="category ids"
                    label="Category IDs"
                    placeholder="Enter the comma separated Category IDs"
                    value={item.campaignCategories}
                    onChange={(value) => {
                      categoryGroup[index].campaignCategories = value
                      this.setState({ categoryGroup })
                    }}
                  />
                  <div className="button-wrap">
                    {index !== 0 && (
                      <BinIcon
                        width={16}
                        height={16}
                        onClick={(e) => {
                          e.preventDefault()
                          categoryGroup.splice(index, 1)
                          this.setState({ categoryGroup })
                        }}
                      />
                    )}
                  </div>
                </div>
              )
            })}

          {ispreOrder && (
            <Button
              className="button primary add-new-field-btn"
              onClick={this.addNewField}
            >
              <PlusIcon />
              Add new
            </Button>
          )}
          <div className="ConfigTime">
            <div className="ConfigStartTime">
              <SingleDatePicker
                label="Validity from"
                placeholder="Start Date"
                displayFormat="YYYY-MM-DD"
                name="startDate"
                className="date-section"
                enableToday
                openDirection={'up'}
                value={this.state.startDate}
                onChange={(value) => {
                  this.setState({ startDate: value })
                }}
              />
              <Select
                label="Start Time"
                name="configStartTime"
                type="text"
                className="time-section"
                options={getTimes()}
                value={this.state.startTime}
                onChange={(value) => {
                  this.setState({ startTime: value })
                }}
              />
            </div>
            <div className="ConfigEndTime">
              <SingleDatePicker
                label="Validity To"
                placeholder="End Date"
                displayFormat="YYYY-MM-DD"
                name="endDate"
                className="date-section"
                allowToday
                enableToday
                showError={this.state.endDate < this.state.startDate}
                openDirection={'up'}
                value={this.state.endDate}
                onChange={(value) => {
                  this.setState({ endDate: value })
                }}
              />
              <Select
                label="End Time"
                name="configEndTime"
                type="text"
                className="time-section"
                placeholder={this.state.endTime}
                options={getTimes()}
                value={this.state.endTime}
                onChange={(value) => {
                  this.setState({ endTime: value })
                }}
              />
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export default AddNewConfig
