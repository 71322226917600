import React from 'react'
import { Input, Checkbox } from 'components/Form'
import { getMessage } from 'lib/translator'

const ConfirmationDetails = ({
  entityName,
  uen,
  billing,
  unitNumber = '',
  floor = '',
  buildingName = '',
  postal,
  emails,
  isFPStore,
  onConfirm,
}) => {
  const [submitting, setSubmitting] = React.useState(false)
  const [onConfirmClick, setOnConfirmClick] = React.useState(false)
  const [checkedDetails, setCheckedDetails] = React.useState(false)
  const billingAddress = `${billing} ${floor}-${unitNumber} ${buildingName} ${postal}`
  return (
    <div className="contain">
      <div className="col-12">
        <Input
          label={getMessage('eVoucher.filter.entityName')}
          value={entityName}
          type="text"
          readOnly
        />
      </div>
      <div className="col-12">
        <Input
          label={getMessage('eVoucher.entities.UEN')}
          value={uen}
          type="text"
          readOnly
        />
      </div>
      <div className="col-12">
        <Input
          label={getMessage('eVoucher.entities.billing')}
          value={billingAddress}
          type="text"
          readOnly
        />
      </div>
      <div className="col-12">
        <Input
          label={getMessage('eVoucher.entities.fp.account.emails')}
          value={emails}
          type="text"
          readOnly
        />
      </div>
      <div className="col-12">
        <Checkbox
          inlineLabel={getMessage('eVoucher.entities.is.fp.store')}
          value={isFPStore}
          disabled
        />
      </div>
      <div className="row">
        <Checkbox
          label=""
          testid="confirm-id"
          inlineLabel="I confirm that all information given above is accurate and complete"
          name="checkedDetails"
          onChange={(v) => setCheckedDetails(v)}
        />
      </div>
      {onConfirmClick && !checkedDetails && (
        <div className="row">
          <div className="input-error-message">This field is required</div>
        </div>
      )}
      <div className="row flex-end">
        <div className="form-actions">
          <button
            type="button"
            className="entity-button primary"
            disabled={submitting}
            data-testid="submit"
            onClick={() => {
              onConfirm()
              setOnConfirmClick(true)
              setSubmitting(true)
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationDetails
