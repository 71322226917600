import { Input } from 'components/Form'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'
import React, { useEffect, useState } from 'react'
import Table, { Header, Cell, Row } from 'components/Table'
import { getReadableDateFromISO8601 } from 'lib/datetime'
import { Link } from 'react-router-dom'
import API from 'lib/api'
import { APG_GATEWAY_HOST } from 'config/app'

const errorMessage = getMessage('gift.card.sales.orders.error.message')

export const resendInvoice = async (
  id,
  setSuccessModal,
  setMessage,
  setDialogError
) => {
  try {
    await new API({
      url: `${APG_GATEWAY_HOST}/gifting-admin-service/sales-orders/${id}/resend-invoice`,
    }).post({})
    setMessage(getMessage('gift.card.sales.orders.resend.invoice.message'))
    setSuccessModal(true)
  } catch {
    setDialogError(true)
    setMessage(errorMessage)
    setSuccessModal(true)
  }
}
export const statusChange = async (
  id,
  status,
  setSuccessModal,
  setMessage,
  setDialogError
) => {
  try {
    await new API({
      url: `${APG_GATEWAY_HOST}/gifting-admin-service/sales-orders/${id}`,
    }).patch({ status: status })
    setMessage(`Mark as ${status} successfully!`)
    setSuccessModal(true)
  } catch (error) {
    setDialogError(true)
    setMessage(errorMessage)
    setSuccessModal(true)
  }
}

export const getSalesOrderData = async (
  id,
  setSalesOrder,
  setSuccessModal,
  setMessage,
  setDialogError
) => {
  try {
    const response = await new API({
      url: `${APG_GATEWAY_HOST}/gifting-admin-service/sales-orders/${id}`,
    }).get()
    setSalesOrder(response.data)
  } catch {
    setDialogError(true)
    setMessage(errorMessage)
    setSuccessModal(true)
  }
}

const RenderHeader = (items) => {
  return items.map((item) => <Cell key={item}>{item}</Cell>)
}

const detailHeader = [
  getMessage('gift.card.sales.orders.design.code'),
  getMessage('gift.card.sales.orders.quantity'),
  getMessage('gift.card.sales.orders.serial.number.range'),
  getMessage('gift.card.sales.orders.denomination'),
]

const Detail = (props) => {
  const [salesOrder, setSalesOrder] = useState(null)
  const { id } = props.router.match.params
  const { setSuccessModal, setMessage, setDialogError, menu } = props

  useEffect(() => {
    getSalesOrderData(
      id,
      setSalesOrder,
      setSuccessModal,
      setMessage,
      setDialogError
    )
  }, [id, setSuccessModal, setMessage, setDialogError])

  const renderButtons = () => {
    if (salesOrder.status === 'PLACED') {
      return (
        <>
          <button
            className="primary button"
            data-testid="resend-button"
            onClick={() =>
              resendInvoice(
                salesOrder.id,
                setSuccessModal,
                setMessage,
                setDialogError
              )
            }
          >
            <span className="text">
              {getMessage('gift.card.sales.orders.actions.resend.invoice')}
            </span>
          </button>
          <button
            data-testid="mark-as-paid-button"
            className="primary button"
            onClick={() =>
              statusChange(
                salesOrder.id,
                'PAID',
                setSuccessModal,
                setMessage,
                setDialogError
              )
            }
          >
            <span className="text">
              {getMessage('gift.card.sales.orders.actions.mark.as.paid')}
            </span>
          </button>
          <button
            data-testid="cancel-button"
            className="primary button"
            onClick={() =>
              statusChange(
                salesOrder.id,
                'CANCELLED',
                setSuccessModal,
                setMessage,
                setDialogError
              )
            }
          >
            <span className="text">
              {getMessage('gift.card.sales.orders.actions.cancel.order')}
            </span>
          </button>
        </>
      )
    } else if (salesOrder.status === 'PAID') {
      return (
        <>
          <button
            data-testid="mark-as-pending-delivery-button"
            className="primary button"
            onClick={() =>
              statusChange(
                salesOrder.id,
                'PENDING_DELIVERY',
                setSuccessModal,
                setMessage,
                setDialogError
              )
            }
          >
            <span className="text">
              {getMessage(
                'gift.card.sales.orders.actions.mark.as.pending.delivery'
              )}
            </span>
          </button>
        </>
      )
    } else if (salesOrder.status === 'PENDING_DELIVERY') {
      return (
        <>
          <button
            data-testid="mark-as-delivered-button"
            className="primary button"
            onClick={() =>
              statusChange(
                salesOrder.id,
                'DELIVERED',
                setSuccessModal,
                setMessage,
                setDialogError
              )
            }
          >
            <span className="text">
              {getMessage('gift.card.sales.orders.actions.mark.as.delivered')}
            </span>
          </button>
        </>
      )
    } else if (salesOrder.status === 'DELIVERED') {
      return (
        <>
          <button
            data-testid="mark-as-enabled-button"
            className="primary button"
            onClick={() =>
              statusChange(
                salesOrder.id,
                'ENABLED',
                setSuccessModal,
                setMessage,
                setDialogError
              )
            }
          >
            <span className="text">
              {getMessage('gift.card.sales.orders.actions.mark.as.enabled')}
            </span>
          </button>
        </>
      )
    }

    return null
  }

  if (!salesOrder) {
    return <p>Loading...</p>
  }

  return (
    <AuthenticatedPage menu={menu}>
      <h1>{getMessage('gift.card.sales.orders.details')}</h1>
      <div className="print-order-details">
        <div className="print-order-sales-order-buttons">
          <>{renderButtons()}</>
          <button
            data-testid="extend-unused"
            className="primary button"
            onClick={() =>
              statusChange(
                salesOrder.id,
                'PAID',
                setSuccessModal,
                setMessage,
                setDialogError
              )
            }
          >
            <span className="text">
              {getMessage('gift.card.sales.orders.actions.extend.unused')}
            </span>
          </button>
          <Link to="/gift-card/sales-orders">
            <button className="primary button">
              <span className="text">
                {getMessage('gift.card.sales.orders.back')}
              </span>
            </button>
          </Link>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.sales.orders.order.id')}
              value={salesOrder.id}
              testid="sales-order-order-id"
              type="text"
              readOnly
            />
          </div>
          <div className="col-6">
            <Input
              label={getMessage('gift.card.sales.orders.status')}
              value={salesOrder.status}
              testid="sales-order-status"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.sales.orders.type')}
              value={salesOrder.type}
              testid="sales-order-type"
              type="text"
              readOnly
            />
          </div>
          <div className="col-6">
            <Input
              label={getMessage('gift.card.sales.orders.entity.id')}
              value={salesOrder.entityId}
              testid="sales-order-status"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.sales.orders.email')}
              value={salesOrder.customerEmails.join(', ')}
              testid="sales-order-customer-email"
              type="text"
              readOnly
            />
          </div>
          <div className="col-6">
            <Input
              label={getMessage('gift.card.sales.orders.payment.mode')}
              value={salesOrder.paymentMode}
              testid="sales-order-payment-mode"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.sales.orders.created.by')}
              testid="sales-order-created-by"
              value={salesOrder.createdBy.email}
              type="text"
              readOnly
            />
          </div>

          <div className="col-6">
            <Input
              label={getMessage('gift.card.sales.orders.create.time')}
              testid="sales-order-create-time"
              value={getReadableDateFromISO8601(salesOrder.createTime)}
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.sales.orders.updated.by')}
              testid="sales-order-updated-by"
              value={salesOrder.updatedBy.email}
              type="text"
              readOnly
            />
          </div>
          <div className="col-6">
            <Input
              testid="sales-order-update-time"
              label={getMessage('gift.card.sales.orders.update.time')}
              value={getReadableDateFromISO8601(salesOrder.updateTime)}
              type="text"
              readOnly
            />
          </div>
        </div>
        <b className="other-details">
          {getMessage('gift.card.sales.orders.design.code.details')}
        </b>

        <Table>
          <Header>{RenderHeader(detailHeader)}</Header>
          {salesOrder.items.map((item) => (
            <Row key={item.designCode}>
              <Cell>{item.designCode}</Cell>
              <Cell>{item.quantity}</Cell>
              <Cell>
                {item.leadSerialNo} - {item.leadSerialNo + item.quantity - 1}
              </Cell>
              <Cell>{item.denomination}</Cell>
            </Row>
          ))}
        </Table>
      </div>
    </AuthenticatedPage>
  )
}

export default Detail
