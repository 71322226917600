import React, { useState, useEffect } from 'react'
import {
  Select,
  Input,
  Searchable,
  Toggle,
  Radio,
  TimePicker,
  VALIDATION_TYPES,
} from 'components/Form'
import Button from 'components.new/button/Button'
import { getMessage } from 'lib/translator'
import { useDebounce } from 'lib/hooks'
import { ReactComponent as PlusIcon } from 'icons/plus.svg'
import { ReactComponent as CloseIcon } from 'icons/close-icon-black.svg'
import { ReactComponent as BinIcon } from 'icons/delete.svg'
import { ReactComponent as MoveDownIcon } from 'icons/arrow-down-thick.svg'
import { ReactComponent as MoveUpIcon } from 'icons/arrow-up.svg'
import { ReactComponent as MoveToTopIcon } from 'icons/arrow-up-to-line.svg'
import { ReactComponent as MoveToBottomIcon } from 'icons/arrow-down-to-line.svg'

const RULE_TYPES_OPTIONS = [
  { text: 'Generic', value: 1 },
  { text: 'Golden Ticket', value: 2 },
  { text: 'Segment', value: 3 },
]

const REWARD_VALIDITY_TYPES_OPTIONS = [
  { text: 'Same Day', value: 'SAME_DAY' },
  { text: 'x Days', value: 'X_HOURS' },
  { text: 'Same as offer', value: 'SAME_AS_OFFER' },
]

const SEGMENT_TYPE_SELECTIONS = [
  {
    text: getMessage('offer.scanCampaigns.form.segment.newAppSignUp'),
    value: 'NEW_APP_SIGN_UP',
  },
  {
    text: getMessage('offer.scanCampaigns.form.segment.segmentCustomer'),
    value: 'SEGMENT_CUSTOMER',
  },
]

const RuleActionComponent = ({
  rule,
  numberOfRules,
  removeRule,
  index,
  generateStateMappers,
}) => {
  const existingRule = !!rule?.id
  if (existingRule) {
    return (
      <>
        <Toggle
          name="enableDisableRule"
          className="enable-disable-rule-toggle"
          icons={false}
          disabled={numberOfRules === 1}
          {...generateStateMappers({
            stateKeys: ['rules', index, 'isEnabled'],
            loseEmphasisOnFill: true,
          })}
        />
      </>
    )
  }
  return numberOfRules > 1 ? (
    <button
      className="close-icon"
      onClick={(e) => {
        e.preventDefault()
        removeRule(index)
      }}
    >
      <CloseIcon height={16} width={16} />
    </button>
  ) : null
}

const ElementsToMoveRule = ({ moveRule, index }) => {
  const iconSizeProps = { height: 14, width: 14 }
  const moveRuleFunction = (e, direction) => {
    e.preventDefault()
    moveRule[direction](index)
  }
  return (
    <div className="move-operations-container">
      <button
        data-testid="move-top-btn"
        onClick={(e) => moveRuleFunction(e, 'top')}
      >
        <MoveToTopIcon {...iconSizeProps} />
      </button>
      <button
        data-testid="move-up-btn"
        onClick={(e) => moveRuleFunction(e, 'up')}
      >
        <MoveUpIcon {...iconSizeProps} />
      </button>
      <button
        data-testid="move-down-btn"
        onClick={(e) => moveRuleFunction(e, 'down')}
      >
        <MoveDownIcon {...iconSizeProps} />
      </button>
      <button
        data-testid="move-bottom-btn"
        onClick={(e) => moveRuleFunction(e, 'bottom')}
      >
        <MoveToBottomIcon {...iconSizeProps} />
      </button>
    </div>
  )
}

function SingleRuleInputs({
  rule,
  index,
  moveRule,
  formValues,
  generateStateMappers,
  addCampaignRuleReward,
  removeRule,
  removeReward,
  resetValidityPolicy
}) {
  const [winningOdds, setWinningOdds] = useState(0)
  const percentageInputRef = React.useRef(null)
  const [percentageInput, setPercentageInput] = useState(null)

  const debouncedPercentageInput = useDebounce(percentageInput, 500)

  useEffect(() => {
    calculatePercentage()
    percentageInputRef.current = debouncedPercentageInput
  }, [debouncedPercentageInput, rule.rewards.length])

  const calculatePercentage = () => {
    const allPercentage = rule.rewards.reduce((previous, current) => {
      return previous + (current.percentage || 0)
    }, 0)
    setWinningOdds(allPercentage)
  }

  return (
    <div key={`rule-${rule.uid}`} className="single-rule-container">
      <div className="single-rule-heading">
        <div className="left-header-container">
          <div style={{ marginRight: '0.5rem', marginTop: '0.25rem' }}>
            Rule #{index + 1}
          </div>
          <ElementsToMoveRule moveRule={moveRule} index={index} />
        </div>
        <div className="right-header-container">
          <div className="odds-display-container">
            <div className="winning-odds">Winning Odds: {winningOdds}%</div>
            <div className="nothing-odds">No Rewards: {100 - winningOdds}%</div>
          </div>
          <RuleActionComponent
            rule={rule}
            numberOfRules={formValues.rules.length}
            index={index}
            generateStateMappers={generateStateMappers}
            removeRule={removeRule}
          />
        </div>
      </div>
      <div className="single-rule-inputs-container">
        <div className="row-container">
          <Select
            name="rule-type"
            label={getMessage('offer.scanCampaigns.form.rules.type')}
            placeholder={getMessage(
              'offer.scanCampaigns.form.rules.type.placeholder'
            )}
            options={RULE_TYPES_OPTIONS}
            className="rule-type-select"
            type="number"
            disabled={!!rule.id}
            {...generateStateMappers({
              stateKeys: ['rules', index, 'type'],
              loseEmphasisOnFill: true,
            })}
          />
          {rule.type === 2 && (
            <Input
              type="text"
              placeholder={'Enter Sequence (e.g. 1,2,3)'}
              name="ruleSequence"
              label="Sequence (comma separated)"
              className="sequence-input"
              {...generateStateMappers({
                stateKeys: ['rules', index, 'policy', 'sequences'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
          )}
        </div>
        {rule.type === 3 && (
          <>
            <Radio
              name="segment-type"
              label="Segment Type"
              className="segment-type"
              options={SEGMENT_TYPE_SELECTIONS}
              {...generateStateMappers({
                stateKeys: ['rules', index, 'segmentType'],
                loseEmphasisOnFill: true,
              })}
            />
            {rule?.segmentType === 'SEGMENT_CUSTOMER' && (
              <Searchable
                name="segment-io-audience"
                className="segment-io-audience"
                label="Segment"
                placeholder="Select Segment"
                openDirection="up"
                searchUrl="/segments"
                valueKey="id"
                nameKey="name"
                searchKey="name"
                transformResponse={(res) => res.data}
                {...generateStateMappers({
                  stateKeys: ['rules', index, 'segmentId'],
                  loseEmphasisOnFill: true,
                })}
              />
            )}
          </>
        )}
        {rule.type === 1 && (
          <Toggle
            name="ruleFirstEverScan"
            className="first-ever-scan-toggle"
            togglelabel="First Ever Scan"
            icons={false}
            {...generateStateMappers({
              stateKeys: ['rules', index, 'policy', 'firstEverScan'],
              loseEmphasisOnFill: true,
            })}
          />
        )}
        <div>
          <p className="scan-campaign-reward-title">Rewards (Offer Type)</p>
          {rule?.rewards.map((reward, rewardIndex) => (
            <div
              className="single-reward-container"
              key={`reward-${reward.uid}`}
            >
              <Input
                type="text"
                placeholder="Enter Offer ID"
                name="rule-reward-offer-id"
                label="Offer ID"
                disabled={!!reward.id || reward.rewardType === 4}
                {...generateStateMappers({
                  stateKeys: [
                    'rules',
                    index,
                    'rewards',
                    rewardIndex,
                    'rewardValue',
                  ],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
              />
              <Input
                type="number"
                placeholder={'Enter Percentage'}
                className="rule-reward-percentage"
                label={'Percentage'}
                step={0.1}
                min={0}
                required
                {...generateStateMappers({
                  stateKeys: [
                    'rules',
                    index,
                    'rewards',
                    rewardIndex,
                    'percentage',
                  ],
                  loseEmphasisOnFill: true,
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  onChange: (val) => {
                    setPercentageInput(val)
                  },
                })}
              />
              <Select
                name="rule-reward-expiration-type"
                label="Expiration"
                placeholder="Select Expiration"
                options={REWARD_VALIDITY_TYPES_OPTIONS}
                {...generateStateMappers({
                  stateKeys: [
                    'rules',
                    index,
                    'rewards',
                    rewardIndex,
                    'validityPolicy',
                    'type',
                  ],
                  loseEmphasisOnFill: true,
                  onChange: (val) => resetValidityPolicy(val, index, rewardIndex)
                })}
              />
              {
                reward?.validityPolicy?.type === 'X_HOURS' &&
                <Input
                  type="number"
                  placeholder="Enter days"
                  className="rule-reward-x-days"
                  label="x Days"
                  step={0.1}
                  min={0.1}
                  {...generateStateMappers({
                    stateKeys: [
                      'rules',
                      index,
                      'rewards',
                      rewardIndex,
                      'validityPolicy',
                      'durationInHours',
                    ],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                />
              }
              {
                (reward?.validityPolicy?.type === 'X_HOURS' || reward?.validityPolicy?.type === 'SAME_DAY') &&
                <TimePicker
                  name="reward-cutoff-time"
                  label="Cutoff Time"
                  placeholder="time"
                  {...generateStateMappers({
                    stateKeys: [
                      'rules',
                      index,
                      'rewards',
                      rewardIndex,
                      'validityPolicy',
                      'cutOffTime',
                    ],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                />
              }
              <div className="delete-icon">
                {reward?.id
                  ? null
                  : rule?.rewards.length > 1 && (
                      <BinIcon
                        width={16}
                        height={16}
                        onClick={() => removeReward(index, rewardIndex)}
                      />
                    )}
              </div>
            </div>
          ))}
          <Button
            className="button primary add-reward-btn"
            onClick={(e) => addCampaignRuleReward(index, e)}
          >
            <PlusIcon />
            Add Reward
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SingleRuleInputs
