import React, { Component, useContext } from 'react'
import './style.css'
import { withRouter } from 'react-router-dom'

import API from '../../../../lib/api'
import { parse, format } from 'libphonenumber-js'
import {
  getSession,
  isExtensionEnabled,
  getEntityMetaData,
  getExtensionDetails,
  saveEntityMetaData,
  hasPermissions,
  getUserRoles,
} from '../../../../lib/auth'
import { getNewDisplayAddress } from '../../../../lib/commonlyused'
import { isRewardsUser } from './RewardsTab/rewardsUtils'

import InfoCard from '../../../../components/InfoCard'
import Tabs from '../../../../components/Tabs/index'
import Loader from '../../../../components/Loader'
import CustomerDetailsCard from '../../../../components/CustomerDetailsCard'
import PhoneAndEmailDisplay from '../../../../components/PhoneAndEmailCard'
import AddressCard from './AddressCard'
import Popup from '../../../../components/Popup/Popup'
import Dialog from '../../../../components/Popup/Dialog'

import CallForm from './CallForm'
import MailForm from './MailForm'
import SmsForm from './SmsForm'
import AddEmailForm from './AddEmail'
import AddPhoneForm from './AddPhoneNumber'
import AddAddressForm from './AddAddress'
import MetaDataForm from './MetaDataForm'
import Rewards from './RewardsTab/Rewards'
import OrdersImage from './total-orders.svg'
import PurchasedImage from './purchase-worth.svg'
import JoinedImage from './joined-on.svg'
import LastPurchaseImage from './last-purchase.svg'
import { getMessage } from '../../../../lib/translator/index'
import { getPrintableTime } from '../../../../lib/datetime/index'
import NewListingPage from '../../../../containers/NewListingPage'
import { tabularView } from 'pages/operations/DeliveryOrders/Table/Views'
import { qcTabularView } from '../../../qc/Orders/Table/Views'
import { Provider } from '../../Orders/Table/index'
import { unset } from '../../../../lib/storage'
import purchaseHistory from './purchase-history.svg'
import { Select } from '../../../../components/Form/Inputs'
import Jwc from './MemberhipTabs/JwcTab'
import DigitalClub from './MemberhipTabs/DcTab'
import LinkpassOnlyUser, { Developer } from './LinkpassOnlyUser'
import { SplitContext } from '../../../../containers/SplitContext'
import SPLIT_FEATURES from '../../../../containers/SplitContext/features'
import { useGetUserObject } from './LinkpassOnlyUser/GetUserObjectHook'
import AuthenticatedPage from 'containers/AuthenticatedPage'

const HasFpAccDeveloperTab = ({ uid }) => {
  const userObject = useGetUserObject(uid)
  return (
    <div className="developer-tab">
      <Developer infoProps={userObject} />
    </div>
  )
}

const emptyState = {
  icon: purchaseHistory,
  message: getMessage('customer.details.purchase.emptyText'),
}

const customerAddressText = getMessage('customer.details.pages.address')
const customerOtherDetailsText = getMessage(
  'customer.details.pages.otherdetails'
)
class CustomerDetailsClass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      showLoader: true,
      showCallPopup: false,
      showPopup: false,
      showDialog: false,
      showSmsPopup: false,
      showAddEmailPopup: false,
      showAddPhonePopup: false,
      showAddAddressPopup: false,
      showPhoneDeletePrompt: false,
      showEmailDeletePrompt: false,
      phoneIdToDelete: null,
      emailIdToDelete: null,
      selectedTab: 0,
      packedOrderEdit: false,
      isFromLinkpass: new URLSearchParams(window.location.search).get(
        'isNotFpCustomer'
      ),
      fpIdFromPrivateRoute: props.router.match.params.id,
      hasLinkpassAdminRole: [
        'Customer-Support / Customers (Admin)',
        'Linkpass_Admin_Role',
      ].some((role) => getUserRoles().includes(role)),
    }
    this.pages = [
      getMessage('customer.details.pages.basicinfo'),
      customerAddressText,
    ]
    this.message = ''
    this.getFpId = this.props.resourceId
      ? this.props.resourceId
      : this.state.fpIdFromPrivateRoute
    this.currency = getSession().organization.currency.symbol
    this.showLinkpassFeatures =
      this.props.splits?.[SPLIT_FEATURES.LOYALTY_LINKPASS_FEATURES]
        ?.treatment === 'on'
    this.hideLoader = this.hideLoader.bind(this)
    this.showLoader = this.showLoader.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSmsClose = this.handleSmsClose.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.renderDialog = this.renderDialog.bind(this)
    this.addEmailCallback = this.addEmailCallback.bind(this)
    this.handleAddEmailClose = this.handleAddEmailClose.bind(this)
    this.addPhoneNumberCallback = this.addPhoneNumberCallback.bind(this)
    this.handleAddPhoneClose = this.handleAddPhoneClose.bind(this)
    this.deletePhoneNumberCallback = this.deletePhoneNumberCallback.bind(this)
    this.deleteEmailCallback = this.deleteEmailCallback.bind(this)
    this.addAddressCallback = this.addAddressCallback.bind(this)
    this.handleAddAddressClose = this.handleAddAddressClose.bind(this)
    this.deleteAddressCallback = this.deleteAddressCallback.bind(this)
    this.handleCallClose = this.handleCallClose.bind(this)
    this.handlePhoneDeleteCloseDialog =
      this.handlePhoneDeleteCloseDialog.bind(this)
    this.handleEmailDeleteCloseDialog =
      this.handleEmailDeleteCloseDialog.bind(this)
    this.handlePhoneDeleteShowDialog =
      this.handlePhoneDeleteShowDialog.bind(this)
    this.handleEmailDeleteShowDialog =
      this.handleEmailDeleteShowDialog.bind(this)
    this.setDefaultPhoneOrEmail = this.setDefaultPhoneOrEmail.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  showLoader() {
    this.setState({
      showLoader: true,
    })
  }

  hideLoader() {
    this.setState({
      showLoader: false,
    })
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const linkpassProfileWithoutFp = urlParams.get('isNotFpCustomer')
    if (linkpassProfileWithoutFp === 'true') {
      this.setState({ notFairpriceUser: true })
    } else {
      if (isExtensionEnabled('CustomerTags')) {
        this.pages.push(customerOtherDetailsText)
      } else {
        if (isExtensionEnabled('EntityMetaData')) {
          let customerKeys = getEntityMetaData() && getEntityMetaData().customer
          if (!customerKeys) {
            const extentionDetails = getExtensionDetails('EntityMetaData')
            if (extentionDetails) {
              const id = extentionDetails.id
              const api = new API({ url: `/account-service/extension/${id}` })
              api.get().then((response) => {
                const details = response.data.extension.config.globalConfig
                saveEntityMetaData(details)
                customerKeys =
                  getEntityMetaData() && getEntityMetaData().customer
                this.addressKeys = details.address
                if (customerKeys && Object.keys(customerKeys).length > 0) {
                  this.pages.push(customerOtherDetailsText)
                }
              })
            }
          } else {
            if (customerKeys && Object.keys(customerKeys).length > 0) {
              this.pages.push(customerOtherDetailsText)
            }
          }
        }
      }
    }

    if (!linkpassProfileWithoutFp) {
      isRewardsUser() && this.pages.push(getMessage('customer.details.rewards'))
      this.orderSummaryApi = new API({
        url: `/order-service/summary?customerId=${this.getFpId}`,
      })

      hasPermissions('loyalty', 'memberships', 'get', true) &&
        this.pages.push(getMessage('customer.details.pages.loyaltyJwc'))
      hasPermissions('loyalty', 'memberships', 'get', true) &&
        this.pages.push(getMessage('customer.details.pages.loyaltyDc'))
      this.showLinkpassFeatures &&
        this.state.hasLinkpassAdminRole &&
        this.pages.push(getMessage('customer.details.pages.developer'))
      this.customerFullBodiedAPI = new API({
        url: `/ef-customer-core/profile/${this.getFpId}?fullresponse=true`,
      })

      const customerAPIs = [
        this.customerFullBodiedAPI.get(),
        this.orderSummaryApi.get(),
      ]

      Promise.all(customerAPIs).then(
        ([customerResponse, orderSummaryResponse]) => {
          const customer = customerResponse.data
          customer.orderSummary = orderSummaryResponse.data.summary

          Object.keys(localStorage).forEach((key) => {
            if (key.startsWith('customer-details-delivery-orders-page-')) {
              const customerId = key.split('-').pop() // get customerId from localStorage
              if (customerId !== customer.id.toString()) {
                unset(key) // remove the key if customer.id is not the same as the one in localstorage
              }
            }
          })

          this.setState({
            customer: customer,
            showLoader: false,
          })
        },
        (error) => {
          throw error
        }
      )

      this.countryApi = new API({ url: '/account-service/country' })
      this.countryApi.get().then((response) => {
        const isoCode = response.data.country.isoCode
        this.isoCode = isoCode
      })
      this.communicationApi = new API({
        url: `/account-service/config/communication`,
      })
      this.communicationApi
        .get()
        .then((response) => {
          this.setState({ communicationConfig: response.data.config })
        })
        .catch((error) => {
          throw error
        })
      if (isExtensionEnabled('EntityMetaData')) {
        this.configApi = new API({ url: '/config-service/config/customers' })
        this.configApi.get().then((response) => {
          this.addressSequence = response.data.customers.addressSequence
        })
      }
      this.fetchPackedOrderEditstatus()
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
    this.customerFullBodiedAPI && this.customerFullBodiedAPI.cancel()
    this.orderSummaryApi && this.orderSummaryApi.cancel()
    this.countryApi && this.countryApi.cancel()
    this.communicationApi && this.communicationApi.cancel()
    this.configApi && this.configApi.cancel()
  }

  fetchPackedOrderEditstatus() {
    const configServiceAPI = new API({
      url: `/config-service/config/inStoreProcessing.packedOrderEditAllowed`,
    })
    configServiceAPI
      .get()
      .then((res) => {
        if (res.code === 200 && res.status === 'SUCCESS') {
          const isEditAllowed =
            res &&
            res.data &&
            res.data.inStoreProcessing &&
            res.data.inStoreProcessing.packedOrderEditAllowed
          this.setState({ packedOrderEdit: isEditAllowed })
        }
      })
      .catch((err) => {
        if (err.code === 401 || err.code === 403) {
          throw err
        }
      })
  }

  fetchTabData() {
    const page = this.pages[this.state.page]
    if (page === getMessage('customer.details.pages.basicinfo')) {
      return (
        <div className="tab-details">
          <div className="tab-row">
            <InfoCard
              className="total-orders"
              src={OrdersImage}
              title={getMessage('customer.details.pages.basicinfo.totalorders')}
              description={this.state.customer.orderSummary.totalOrders}
            />
            <InfoCard
              className="total-amount"
              src={PurchasedImage}
              title={getMessage(
                'customer.details.pages.basicinfo.purchasesworth'
              )}
              description={
                this.state.customer.orderSummary &&
                this.currency + this.state.customer.orderSummary.totalAmount
              }
            />
          </div>
          <div className="tab-row">
            <InfoCard
              className="joined-on"
              src={JoinedImage}
              title={getMessage('customer.details.pages.basicinfo.joined')}
              description={
                this.state.customer.joinedTime &&
                getPrintableTime(this.state.customer.joinedTime).split(',')[0]
              }
            />
            <InfoCard
              className="last-purchase"
              src={LastPurchaseImage}
              title={getMessage(
                'customer.details.pages.basicinfo.lastPurchase'
              )}
              description={
                this.state.customer.orderSummary.lastOrderedOn
                  ? getPrintableTime(
                      this.state.customer.orderSummary.lastOrderedOn
                    ).split(',')[0]
                  : '-'
              }
            />
          </div>
        </div>
      )
    }
    if (page === customerAddressText) {
      return (
        <div className="tab-details">
          {this.getAddresses()}
          {hasPermissions('customer', 'address', 'post') && (
            <div className="addAddress" onClick={this.addAddressCallback}>
              <div className="addIcon" />
              <div className="addTitle">
                {getMessage('customer.details.address.addAddress')}
              </div>
            </div>
          )}
        </div>
      )
    }

    if (page === customerOtherDetailsText) {
      return (
        <React.Fragment>
          <MetaDataForm customer={this.state.customer} />
        </React.Fragment>
      )
    }
    if (page === getMessage('customer.details.rewards')) {
      return <Rewards uid={this.state.customer?.uid} />
    }

    if (page === getMessage('customer.details.pages.loyaltyJwc')) {
      return <Jwc uid={this.state.customer?.uid} />
    }

    if (page === getMessage('customer.details.pages.loyaltyDc')) {
      return <DigitalClub uid={this.state.customer?.uid} />
    }

    if (page === getMessage('customer.details.pages.developer')) {
      return <HasFpAccDeveloperTab uid={this.state.customer?.uid} />
    }
    return null
  }
  changePage(page) {
    this.setState(
      {
        page: page,
      },
      this.fetchPageData
    )
  }

  getAddresses() {
    const _this = this
    let showDeleteOption = false
    const customerId = this.state.customer.id
    const customerAddresses = this.state.customer.addresses
    if (customerAddresses && customerAddresses.length > 1) {
      showDeleteOption = true
    }
    return (
      customerAddresses &&
      customerAddresses.map(function (address, i) {
        return (
          <AddressCard
            key={address.id}
            customerId={customerId}
            coordinates={{ lat: address.latitude, lng: address.longitude }}
            id={address.id}
            showDeleteOption={showDeleteOption}
            title={`${customerAddressText} ${i + 1}`}
            value={`${getNewDisplayAddress(address)}`}
            viewOnMap={getMessage('customer.details.pages.address.viewOnMap')}
            deleteMessage={getMessage('customer.details.delete')}
            onDelete={_this.deleteAddressCallback}
          />
        )
      })
    )
  }

  getPhoneNumbers() {
    const phone = this.state.customer.phone
    return (
      <PhoneAndEmailDisplay
        value={format(parse(phone), 'International')}
        type="phone"
      />
    )
  }

  setDefaultPhoneOrEmail(_type, _id) {}

  deleteEmailCallback(_id) {}

  deleteAddressCallback(id) {
    const customerId = this.state.customer.id

    const api = new API({
      url: `/ef-customer-core/profile/${customerId}/addresses/${id}`,
    })
    api.delete().then(
      () => {
        this.message = getMessage('customer.details.address.deleted')
        const newDetails = this.state.customer
        const modifiedAddresses = this.state.customer.addresses.filter(
          (address) => address.id !== id
        )
        newDetails.addresses = modifiedAddresses
        this.setState({
          showDialog: true,
          customer: newDetails,
        })
      },
      (error) => {
        this.message = error.message.split(':')[1]
        this.setState({
          showDialog: true,
        })
      }
    )
  }

  deletePhoneNumberCallback(_id) {}

  getEmails() {
    const email = this.state.customer.email
    return <PhoneAndEmailDisplay value={email} type="email" />
  }

  addEmailCallback() {
    this.setState({
      showAddEmailPopup: true,
    })
  }

  addPhoneNumberCallback() {
    this.setState({
      showAddPhonePopup: true,
    })
  }

  addAddressCallback() {
    this.setState({
      showAddAddressPopup: true,
    })
  }

  handleClose() {
    this.setState({
      showPopup: false,
    })
  }

  handleCallClose() {
    this.setState({
      showCallPopup: false,
    })
  }

  handleSmsClose() {
    this.setState({
      showSmsPopup: false,
    })
  }

  handleAddEmailClose() {
    this.setState({
      showAddEmailPopup: false,
    })
  }

  handleAddPhoneClose() {
    this.setState({
      showAddPhonePopup: false,
    })
  }

  handleAddAddressClose() {
    this.setState({
      showAddAddressPopup: false,
    })
  }

  handleCloseDialog() {
    this.setState({
      showDialog: false,
    })
  }

  handlePhoneDeleteCloseDialog() {
    this.setState({
      showPhoneDeletePrompt: false,
    })
  }

  handleEmailDeleteCloseDialog() {
    this.setState({
      showEmailDeletePrompt: false,
    })
  }

  handlePhoneDeleteShowDialog(id) {
    this.setState({
      showPhoneDeletePrompt: true,
      phoneIdToDelete: id,
    })
  }

  handleEmailDeleteShowDialog(id) {
    this.setState({
      showEmailDeletePrompt: true,
      emailIdToDelete: id,
    })
  }

  renderDialog(data, response = {}) {
    if (data === 'render' && !this.state.showLoader && this.state.showPopup) {
      this.message = getMessage('customer.details.email.send')
      this.setState({
        showPopup: false,
        showDialog: true,
      })
    } else if (
      data === 'call' &&
      !this.state.showLoader &&
      this.state.showCallPopup
    ) {
      this.message = getMessage('customer.details.call.call')
      this.setState({
        showCallPopup: false,
        showDialog: true,
      })
    } else if (
      data === 'sms' &&
      !this.state.showLoader &&
      this.state.showSmsPopup
    ) {
      this.message = getMessage('customer.details.sms.send')
      this.setState({
        showSmsPopup: false,
        showDialog: true,
      })
    } else if (
      data === 'addedEmail' &&
      !this.state.showLoader &&
      this.state.showAddEmailPopup &&
      response
    ) {
      this.message = getMessage('customer.details.email.added')
      const newCustomer = this.state.customer
      newCustomer.emails = newCustomer.emails || []
      newCustomer.emails.push(response)
      this.setState({
        showAddEmailPopup: false,
        showDialog: true,
        customer: newCustomer,
      })
    } else if (
      data === 'addedAddress' &&
      !this.state.showLoader &&
      this.state.showAddAddressPopup &&
      response
    ) {
      this.message = getMessage('customer.details.address.added')
      const newCustomer = this.state.customer
      newCustomer.addresses = newCustomer.addresses || []
      newCustomer.addresses.push(response)
      this.setState({
        showAddAddressPopup: false,
        showDialog: true,
        customer: newCustomer,
      })
    } else if (
      data === 'addedPhone' &&
      !this.state.showLoader &&
      this.state.showAddPhonePopup &&
      response
    ) {
      this.message = getMessage('customer.details.phone.added')
      const newCustomer = this.state.customer
      newCustomer.phones = newCustomer.phones || []
      newCustomer.phones.push(response)
      this.setState({
        showAddPhonePopup: false,
        showDialog: true,
        customer: newCustomer,
      })
    }

    if (this.state.showDialog) {
      return (
        <Dialog
          className="success"
          show={this.state.showDialog}
          close={this.handleCloseDialog}
          information={this.message}
          closeText="OK"
        />
      )
    }
    return null
  }

  renderCallPopup() {
    return (
      <Popup
        heading={getMessage('customer.details.call')}
        show={this.state.showCallPopup}
        close={this.handleCallClose}
      >
        <CallForm
          number={
            this.state.customer.phones &&
            this.state.customer.phones.length > 0 &&
            this.state.customer.phones[0].phone
          }
          onSuccess={this.renderDialog}
        />
      </Popup>
    )
  }

  renderMailPopup() {
    if (!this.state.customer.email) {
      return null
    }
    return (
      <Popup
        heading={getMessage('customer.details.sendEmail')}
        show={this.state.showPopup}
        close={this.handleClose}
      >
        <MailForm
          to={this.state.customer.email}
          onSuccess={this.renderDialog}
        />
      </Popup>
    )
  }

  renderSmsPopup() {
    return (
      <Popup
        heading={getMessage('customer.details.sendMessage')}
        show={this.state.showSmsPopup}
        close={this.handleSmsClose}
      >
        <SmsForm
          to={
            this.state.customer.phones &&
            this.state.customer.phones.length > 0 &&
            this.state.customer.phones[0].phone
          }
          onSuccess={this.renderDialog}
        />
      </Popup>
    )
  }

  renderAddEmailPopup() {
    return (
      <Popup
        heading={getMessage('customer.details.pages.phonemail.addEmailId')}
        show={this.state.showAddEmailPopup}
        close={this.handleAddEmailClose}
      >
        <AddEmailForm
          customerId={this.state.customer.id}
          onSuccess={this.renderDialog}
        />
      </Popup>
    )
  }

  renderAddPhonePopup() {
    return (
      <Popup
        heading={getMessage('customer.details.pages.phonemail.addPhoneNumber')}
        show={this.state.showAddPhonePopup}
        close={this.handleAddPhoneClose}
      >
        <AddPhoneForm
          customerId={this.state.customer.id}
          onSuccess={this.renderDialog}
          countryIso={this.isoCode}
        />
      </Popup>
    )
  }

  renderAddAddressPopup() {
    return (
      <Popup
        heading={getMessage('customer.details.address.addAddress')}
        show={this.state.showAddAddressPopup}
        close={this.handleAddAddressClose}
      >
        <AddAddressForm
          customerId={this.state.customer.id}
          onSuccess={this.renderDialog}
          sequence={this.addressSequence}
        />
      </Popup>
    )
  }

  renderPhoneDeleteDialog() {
    return (
      <Dialog
        show={this.state.showPhoneDeletePrompt}
        close={this.handlePhoneDeleteCloseDialog}
        title={getMessage('customer.phone.delete.prompt.title')}
        information={getMessage('customer.phone.delete.prompt.message')}
        closeText={getMessage('customer.cancel.message')}
        onOk={() => {
          this.deletePhoneNumberCallback(this.state.phoneIdToDelete)
        }}
        okText={getMessage('customer.confirm.message')}
      />
    )
  }

  renderEmailDeleteDialog() {
    return (
      <Dialog
        show={this.state.showEmailDeletePrompt}
        close={this.handleEmailDeleteCloseDialog}
        title={getMessage('customer.email.delete.prompt.title')}
        information={getMessage('customer.email.delete.prompt.message')}
        closeText={getMessage('customer.cancel.message')}
        onOk={() => {
          this.deleteEmailCallback(this.state.emailIdToDelete)
        }}
        okText={getMessage('customer.confirm.message')}
      />
    )
  }

  handleTabChange(tabId) {
    this.setState({
      selectedTab: tabId,
    })
  }

  render() {
    return this.state.notFairpriceUser ? (
      <LinkpassOnlyUser
        uid={this.getFpId}
        showDeleteLinkpassButton={this.showLinkpassFeatures}
        hasLinkpassAdminRole={this.state.hasLinkpassAdminRole}
      />
    ) : (
      <div className="customerDetails">
        <h1 className="heading">
          {this.state.customer && this.state.customer.name
            ? this.state.customer.name
            : ''}
        </h1>
        {this.state.showLoader ? (
          <Loader />
        ) : (
          <div>
            {this.state.customer && (
              <div>
                {this.renderCallPopup()}
                {this.renderMailPopup()}
                {this.renderDialog()}
                {this.renderSmsPopup()}
                {this.renderAddEmailPopup()}
                {this.renderAddPhonePopup()}
                {this.renderAddAddressPopup()}
                {this.renderPhoneDeleteDialog()}
                {this.renderEmailDeleteDialog()}
                <div className="tabWithCustomerCard">
                  <div className="tab-container">
                    {window.screen.width >= 480 ? (
                      <Tabs
                        items={this.pages.map((page) => `${page}`)}
                        default={this.state.page}
                        onClick={(index) => {
                          this.changePage(index)
                        }}
                      />
                    ) : (
                      <Select
                        options={this.pages.map((page, index) => {
                          return { text: page, value: index }
                        })}
                        testid="tabs-select-test"
                        value={`${this.state.page}`}
                        onChange={(index) =>
                          this.changePage(Number(index) ? index : '0')
                        }
                      />
                    )}
                    {this.fetchTabData()}
                  </div>
                  <CustomerDetailsCard
                    image={this.state.customer && this.state.customer.image}
                    email={this.state.customer && this.state.customer.email}
                    phone={this.state.customer && this.state.customer.phone}
                    uid={this.state.customer && this.state.customer.uid}
                    name={this.state.customer && this.state.customer.name}
                    id={this.state.customer && this.state.customer.id}
                    showDeleteLinkpassButton={this.showLinkpassFeatures}
                  />
                </div>
              </div>
            )}
            <h3>{getMessage('customer.details.order.purchase.history')}</h3>
            <div className="tab-container">
              <Tabs
                items={['OG & O2O Orders', 'QC Orders']}
                default={this.state.selectedTab}
                onClick={this.handleTabChange}
              />
            </div>
            <div className="tab-details">
              <Provider value={this.state && this.state.communicationConfig}>
                {this.state.selectedTab === 0 ? (
                  <div className="orders-page">
                    <NewListingPage
                      className={`customer-details-delivery-orders-page-${this.state.customer.id}`}
                      api={{
                        url: `/order-service/v3/search/deliveryOrders`,
                        params: {
                          page: 1,
                          pageSize: 5,
                          customerId: this.getFpId,
                        },
                        transform: (response) => response.deliveryOrders,
                      }}
                      emptyState={emptyState}
                      primaryKey="referenceNumber"
                      tableProperties={tabularView(
                        '/customer-support',
                        this.state.packedOrderEdit
                      )}
                      tableDynamic
                    />
                  </div>
                ) : (
                  <div className="tab-details">
                    <div className="orders-page">
                      <NewListingPage
                        className="customer-details-orders-page"
                        api={{
                          url: `/orders`,
                          params: {
                            limit: 5,
                            customerId: this.getFpId,
                          },
                          isPageDisabled: true,
                          transform: (response) => response.data.items,
                        }}
                        emptyState={emptyState}
                        primaryKey="id"
                        tableProperties={qcTabularView(
                          `/${this.props.options && this.props.options.page}`
                        )}
                        tableDynamic
                      />
                    </div>
                  </div>
                )}
              </Provider>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const CustomerDetails = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig

  return <CustomerDetailsClass {...props} splits={splits} />
}

export const AuthenticatedCustomerDetails = withRouter(
  ({ location, history, match, ...props }) => {
    return (
      <AuthenticatedPage menu={props.menu} from={location && location.pathname}>
        <CustomerDetails router={{ location, history, match }} {...props} />
      </AuthenticatedPage>
    )
  }
)

export default CustomerDetails
