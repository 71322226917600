const data = {
  'gift.card.design.codes.create': 'Create new Design Code',
  'gift.card.sales.orders.create': 'Create new Sales Orders',
  'gift.card.design.codes.title': 'Design Codes',
  'gift.card.print.orders.details': 'Print Orders Detail',
  'gift.card.print.orders': 'Print Orders',
  'gift.card.print.orders.add': 'Create Print Order',
  'gift.card.print.orders.order.id': 'Order ID',
  'gift.card.print.orders.order.id.placeholder': 'Enter Order ID',
  'gift.card.print.orders.quantity.placeholder': 'Enter Quantity',
  'gift.card.print.orders.serial.number.range': 'Serial No. Range',
  'gift.card.print.orders.status': 'Status',
  'gift.card.print.orders.status.processing': 'PROCESSING',
  'gift.card.print.orders.status.emailing': 'EMAILING',
  'gift.card.print.orders.status.placed': 'PLACED',
  'gift.card.print.orders.status.printed': 'PRINTED',
  'gift.card.print.orders.select.placeholder': 'Select',
  'gift.card.print.orders.design.codes': 'Design Codes',
  'gift.card.print.orders.design.code': 'Design Code',
  'gift.card.print.orders.quantity': 'Quantity',
  'gift.card.print.orders.vendor.email': 'Vendor Email',
  'gift.card.print.orders.created.by': 'Created By',
  'gift.card.print.orders.create.time': 'Create Time',
  'gift.card.print.orders.create.time.placeholder': 'Select Date',
  'gift.card.print.orders.updated.by': 'Updated By',
  'gift.card.print.orders.update.time': 'Update Time',
  'gift.card.print.orders.actions.resend.order': 'Resend Order',
  'gift.card.print.orders.actions.mark.as.printed': 'Mark as Printed',
  'gift.card.print.orders.add.row': 'Add Row',
  'gift.card.print.orders.search': 'Search',
  'gift.card.print.orders.clear.all': 'Clear All',
  'gift.card.print.orders.submit': 'Submit',
  'gift.card.design.codes.add.text': 'Add Design Code',
  'gift.card.design.codes.input.denomination.type': 'Denomination Type',
  'gift.card.design.codes.input.denomination': 'Denomination',
  'gift.card.design.codes.input.sku': 'SKU',
  'gift.card.design.codes.input.flexi': 'FLEXI',
  'gift.card.design.codes.input.fixed': 'FIXED',
  'gift.card.print.orders.cancel': 'Cancel',
  'gift.card.print.orders.back': 'Back',
  'gift.card.print.orders.ok': 'Ok',
  'gift.card.print.orders.close': 'Close',
  'gift.card.print.orders.design.code.details': 'Print Order Items',
  'gift.card.print.orders.resend.order.message': 'Resend successfully!',
  'gift.card.print.orders.mark.as.printed.message':
    'Mark as printed successfully!',
  'gift.card.print.orders.error.message': 'Something went wrong!',
  'gift.card.design.code.error.exist':
    'Design code already exist! Unable to create new Design Code.',
  'gift.card.print.orders.empty.message': 'No Print Orders yet',
  'gift.card.print.orders.confirm.heading': 'Confirm Add Print Order Details',
  'gift.card.print.orders.confirm':
    'I confirm that all information given above is accurate and complete',
  'gift.card.sales.orders.email': 'Customer Email(s)',
  'gift.card.sales.orders.credit.term': 'Credit Term',
  'gift.card.sales.orders.draw.down': 'Draw Down',
  'gift.card.sales.orders.post.paid': 'Post Paid',
  'gift.card.sales.orders.paid': 'PAID',
  'gift.card.sales.orders.pending.delivery': 'PENDING DELIVERY',
  'gift.card.sales.orders.delivered': 'DELIVERED',
  'gift.card.sales.orders.enabled': 'ENABLED',
  'gift.card.sales.orders.cancelled': 'CANCELLED',
  'gift.card.sales.orders.payment.mode': 'Payment Mode',
  'gift.card.sales.orders.entity.id': 'Entity Id',
  'gift.card.sales.orders.type': 'Type',
  'gift.card.sales.orders.design.code.details': 'Sales Order Items',
  'gift.card.sales.orders.error.message': 'Something went wrong!',
  'gift.card.sales.orders.design.code': 'Design Code',
  'gift.card.sales.orders.quantity': 'Quantity',
  'gift.card.sales.orders.serial.number.range': 'Serial No. Range',
  'gift.card.sales.orders.details': 'Sales Order Detail',
  'gift.card.sales.orders.back': 'Back',
  'gift.card.sales.orders.order.id': 'Order ID',
  'gift.card.sales.orders.status': 'Status',
  'gift.card.sales.orders.created.by': 'Created By',
  'gift.card.sales.orders.create.time': 'Create Time',
  'gift.card.sales.orders.updated.by': 'Updated By',
  'gift.card.sales.orders.update.time': 'Update Time',
  'gift.card.sales.orders.denomination': 'Denomination',
  'gift.card.sales.orders.actions.resend.invoice': 'Resend Invoice',
  'gift.card.sales.orders.actions.mark.as.paid': 'Mark as Paid',
  'gift.card.sales.orders.actions.mark.as.pending.delivery':
    'Mark as Pending Delivery',
  'gift.card.sales.orders.actions.mark.as.delivered': 'Mark as Delivered',
  'gift.card.sales.orders.actions.mark.as.enabled': 'Mark as Enabled',
  'gift.card.sales.orders.actions.cancel.order': 'Cancel Order',
  'gift.card.sales.orders.actions.extend.unused': 'Extend Unused',
  'gift.card.sales.orders.resend.invoice.message': 'Resend successfully!',
  'gift.card.sales.orders.mark.as.paid.message': 'Mark as paid successfully!',
  'gift.card.sales.orders.mark.as.pending.delivery.message':
    'Mark as pending delivery successfully!',
  'gift.card.sales.orders.mark.as.delivered.message':
    'Mark as delivered successfully!',
  'gift.card.sales.orders.mark.as.enabled.message':
    'Mark as enabled successfully!',
  'gift.card.sales.orders.cancel.order.message':
    'Order cancelled successfully!',
  'gift.card.sales.orders.extend.unused.message': 'Extend successfully',
}
export default data
