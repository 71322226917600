import React from 'react'
import StoreSpecificAttributeReview from './ReviewTables/StoreSpecificAttributeReview'
import BulkGlobalAttributesReview from './ReviewTables/BulkGlobalAttributesReview'
import BulkSubstitutionGroupReview from './ReviewTables/BulkSubstitutionGroup'
import BulkDeactivationOfferReview from './ReviewTables/BulkDeactivationOffer'
import BulkVoucherDepositReview from './ReviewTables/BulkVoucherDeposit'
import { getMessage } from '../../../lib/translator'
import BulkSearchOverride from './ReviewTables/BulkSearchOverride'
import { cartLevelConfig, SKULevelConfig } from './BulkOfferUpload/UploadJobConfig'
import { get } from 'lib/storage'
import SPLIT_FEATURES from '../../../containers/SplitContext/features'
import { isUserHaveRole } from 'lib/authorization'
import omit from 'lodash/omit'

const DELETE_ACTION = { DELETE: 'd' }
const ATTRIBUTES_ACTIONS = { ADD: 'a', DELETE: 'd' }
const STORE_CHANNELS = { ONLINE: 'online', OFFLINE: 'offline' }
const SHOW_HIDE_STATUS = { SHOW: 'show', HIDE: 'hide' }
const UNLIMITED_CHECK_OPTIONS = { Y: 'y', N: 'n' }
const PROMO_CODE_LABEL = 'Promo Code'

export const MAX_STORE_LENGTH = 3
export const STORE_GROUPINGS = {
  ALL: 'all',
  FFS: 'ffs',
}
const ADDITIONAL_STORE_GROUPINGS = {
  FFS_HYP: 'ffs_hyp',
  FFS_SUP: 'ffs_sup',
}

const isIncludes = (collectionObj, value) => {
  return Object.values(collectionObj).includes((value || '').toLowerCase())
}

export const isPositiveNumber = value => {
  return value !== '' && !isNaN(value) && value >= 0
}

const isBoolean = val => { const data = val.toLowerCase(); return data === 'y' || data === 'n' ; }

const isValidName = value => {
  return !/[^a-z0-9\s-,&()'+%/.]/gi.test(value)
}

const isCommaSeparatedValues = value => {
  return !/[^a-z,-\s]/gi.test(value)
}

const isGivenLength = (value, length) => {
  return (value || '').length <= length
}

// Why not just !value? Because 0 isn't empty.
export const isEmpty = value =>
  value === null || trim(value) === '' || value === undefined

const trim = value => {
  return typeof value === 'object' ? value : (value || '').replace(/ /g, '')
}

const validateBarCodes = (barcodes = '') => {
  return barcodes
    .split(',')
    .map(barcode => barcode.trim())
    .every(isPositiveNumber)
}

export const snakeToCamel = (str = '') =>
  str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  )

export const buildStoreMap = stores => {
  const storeMap = {}

  stores.forEach(store => {
    if (store.clientId) {
      storeMap[store.clientId] = store.name
    }
  })
  return storeMap
}
export const buildTagMap = stores => {
  const tagMap = {}

  stores.forEach(store => {
    tagMap[store.id] = store.name
  })
  return tagMap
}

export const buildProductNameMap = (products = []) => {
  const productMap = {}

  products.forEach(product => {
    if (product.clientItemId) {
      productMap[product.clientItemId] = {
        name: product.name,
        displayUnit: product.metaData ? product.metaData.DisplayUnit : '',
      }
    }
  })
  return productMap
}

export const buildCategoryMapping = categories => {
  const categoryMap = {}

  categories.forEach(category => {
    categoryMap[category.id] = getCategoryHierarchy(category)
  })
  return categoryMap
}

const getCategoryHierarchy = category => {
  if (!category.parentCategory) {
    return category.name
  }
  return getCategoryHierarchy(category.parentCategory) + ' > ' + category.name
}


export const JobConfig = {
  SKU_STORE_ATTRIBUTE: {
    name: 'Bulk Store-Specific Attributes',
    id: 'sku-store-attribute',
    sampleCsv: 'sample-store-specific_attribute.csv',
    isValidFilename: filename => /^(ffs|mkp|pfc|mst)_.*\.csv$/gi.test(filename),
    csvHeaders: {
      SKU: { header: 'sku', validate: sku => !!sku },
      PRODUCT_NAME: {
        header: '',
        validate: value => !!value,
        skipInTemplate: true,
      },
      STORE_ID: { header: 'store_id', validate: storeId => !!storeId },
      STORE_NAME: {
        header: '',
        validate: (storeId, storeName) =>
          isNaN(storeId)
            ? isIncludes(
                {
                  ...STORE_GROUPINGS,
                  ...ADDITIONAL_STORE_GROUPINGS,
                },
                storeName
              )
            : !!storeName,
        skipInTemplate: true,
      },
      CHANNEL: {
        header: 'channel',
        validate: (storeId, channel) =>
          isNaN(storeId) || isIncludes(STORE_CHANNELS, channel),
      },
      STATUS: {
        header: 'status',
        validate: status => isIncludes(SHOW_HIDE_STATUS, status),
      },
      MAX_PURCHASE_QTY: {
        header: 'max_purchase_quantity',
        validate: isPositiveNumber,
      },
      STORE_BULK: {
        header: 'bulk_order_threshold',
        validate: isPositiveNumber,
      },
      UNLIMITED_CHECK: {
        header: 'unlimited_stock_check',
        validate: value => isIncludes(UNLIMITED_CHECK_OPTIONS, value),
      },
      STOCK_BUFFER: {
        header: 'stock_buffer',
        validate: isPositiveNumber,
      },
    },
    getTableHeaders: () => [
      getMessage('category.bulk-upload.columns.sku'),
      getMessage('category.bulk-upload.columns.productName'),
      getMessage('product.add.showhide.column.storeid'),
      getMessage('product.add.showhide.column.storeName'),
      getMessage('product.add.showhide.column.channel'),
      getMessage('product.add.showhide.column.status'),
      getMessage('product.add.store.column.maxPurchaseQty'),
      getMessage('product.add.store.column.storeBulk'),
      getMessage('product.add.store.column.unlimitedCheck'),
      getMessage('product.add.store.column.stockBuffer'),
    ],
    endpoint: '/catalogue-service/product-attribute-upload',
    ReviewComponent: StoreSpecificAttributeReview,
  },
  SKU_GLOBAL_ATTRIBUTE: {
    name: 'Bulk Global Attributes',
    id: 'bulk_global_attributes',
    sampleCsv: 'sample-bulk-global-attributes.csv',
    isValidFilename: () => true,
    columnSelectable: true,
    csvHeaders: {
      SKU: {
        header: 'sku',
        validate: skuObj => isPositiveNumber(skuObj.id) && skuObj.name,
        displayHeader: getMessage('product.global.column.sku'),
        // eslint-disable-next-line react/display-name
        render: obj => <span className="sku">{obj.id}</span>,
        mandatory: true,
        isEmpty: sku => isEmpty(sku.id),
      },
      BARCODES: {
        header: 'barcodes',
        validate: validateBarCodes,
        displayHeader: getMessage('product.global.column.barcodes'),
      },
      BARCODES_ACTION: {
        header: 'barcodes_action',
        validate: value => !value || isIncludes(ATTRIBUTES_ACTIONS, value),
        displayHeader: getMessage('product.global.column.barcodeAction'),
        render: function AttributeAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      PRODUCT_NAME: {
        header: 'product_name',
        displayHeader: getMessage('product.global.column.productName'),
        skipInTemplate: true,
        // eslint-disable-next-line react/display-name
        render: obj => (
          <React.Fragment>
            <span className="product-name">{obj.name}</span>
            {` `}
            <span className="display-unit muted">{obj.unit}</span>
          </React.Fragment>
        ),
      },
      SELLER_SKU: {
        header: 'seller_sku',
        validate: () => true,
        displayHeader: getMessage('product.global.column.sellerSku'),
      },
      NAME: {
        header: 'name',
        validate: value => isGivenLength(value, 100) && isValidName(value),
        displayHeader: getMessage('product.global.column.name'),
      },
      DISPLAY_UNIT: {
        header: 'display_unit',
        validate: value => isGivenLength(value, 50),
        displayHeader: getMessage('product.global.column.displayUnit'),
      },
      BRAND_ID: {
        header: 'brand_id',
        validate: isPositiveNumber,
        displayHeader: getMessage('product.global.column.brandId'),
      },
      NUTRITIONAL_DATA: {
        header: 'nutritional_data',
        validate: () => true,
        displayHeader: getMessage('product.global.column.nutritionalData'),
      },
      STORAGE_INFORMATION: {
        header: 'storage_information',
        validate: value => isGivenLength(value, 1000),
        displayHeader: getMessage('product.global.column.storageInformation'),
      },
      BULK_ORDER_THRESHOLD: {
        header: 'bulk_order_threshold',
        validate: isPositiveNumber,
        displayHeader: getMessage('product.global.column.globalBulk'),
      },
      PRIMARY_CATEGORY_ID: {
        header: 'primary_category_id',
        validate: cat => !cat.id || !!cat.name,
        displayHeader: getMessage('product.global.column.primaryCategoryId'),
        // eslint-disable-next-line react/display-name
        render: cat => (
          <div>
            <span size="md" className="category-id">
              {cat.id}
            </span>
            {cat.id && ':'}{' '}
            <span size="md" className="category-name">
              {cat.name}
            </span>
          </div>
        ),
        isEmpty: cat => isEmpty(cat.id),
      },
      SECONDARY_CATEGORY_IDS: {
        header: 'secondary_category_ids',
        validate: categories =>
          categories.every(category => !category.id || !!category.name),
        displayHeader: getMessage('product.global.column.secondaryCategoryIds'),
        render: cats =>
          cats.map(cat => {
            return (
              <React.Fragment key={cat.id}>
                <span className="category-id">{cat.id}</span>
                {cat.id && ':'}{' '}
                <span className="category-name">{cat.name}</span> <br />
              </React.Fragment>
            )
          }),
        isEmpty: cats => cats.every(cat => isEmpty(cat.id)),
      },
      PREPARATION: {
        header: 'preparation',
        validate: value => isGivenLength(value, 1000),
        displayHeader: getMessage('product.global.column.preparation'),
      },
      SECONDARY_CATEGORY_IDS_ACTION: {
        header: 'secondary_category_ids_action',
        validate: action => !action || isIncludes(ATTRIBUTES_ACTIONS, action),
        displayHeader: getMessage(
          'category.bulk-upload.columns.category.secondary.action'
        ),
        render: function CategoryAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      TAG_ID: {
        header: 'tag_id',
        validate: obj => isPositiveNumber(obj.id) && obj.name,
        render: obj => <span className="tag">{obj.id}</span>,
        displayHeader: getMessage('product.global.column.tagId'),
      },
      TAG_ID_NAME: {
        header: 'tag_id_name',
        validate: () => true,
        displayHeader: getMessage('product.global.column.tagName'),
        skipInTemplate: true,
      },
      TAG_ID_ACTION: {
        header: 'tag_id_action',
        validate: value => !value || isIncludes(ATTRIBUTES_ACTIONS, value),
        displayHeader: getMessage('product.global.column.tagAction'),
        render: function AttributesAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      KEY_INFORMATION: {
        header: 'key_information',
        validate: value => isGivenLength(value, 1000),
        displayHeader: getMessage('product.global.column.keyInformation'),
      },
      KEY_INFORMATION_ACTION: {
        header: 'key_information_action',
        validate: value => !value || isIncludes(DELETE_ACTION, value),
        displayHeader: getMessage('product.global.column.keyInformationAction'),
        render: function CategoryAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      ADDITIONAL_INFORMATION: {
        header: 'additional_information',
        validate: value => isGivenLength(value, 20000),
        displayHeader: getMessage(
          'product.global.column.additionalInformation'
        ),
      },
      ADDITIONAL_INFORMATION_ACTION: {
        header: 'additional_information_action',
        validate: value => !value || isIncludes(DELETE_ACTION, value),
        displayHeader: getMessage(
          'product.global.column.additionalInformationAction'
        ),
        render: function CategoryAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      INGREDIENTS: {
        header: 'ingredients',
        validate: value => isGivenLength(value, 10000),
        displayHeader: getMessage('product.global.column.ingredients'),
      },
      INGREDIENTS_ACTION: {
        header: 'ingredients_action',
        validate: value => !value || isIncludes(DELETE_ACTION, value),
        displayHeader: getMessage('product.global.column.ingredientsAction'),
        render: function CategoryAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      DIETARY_ATTRIBUTES: {
        header: 'dietary_attributes',
        validate: isCommaSeparatedValues,
        displayHeader: getMessage('product.global.column.dietaryAttributes'),
      },
      DIETARY_ATTRIBUTES_ACTION: {
        header: 'dietary_attributes_action',
        validate: value => {
           /* istanbul ignore next */
          return !value || isIncludes(ATTRIBUTES_ACTIONS, value)
        },
        displayHeader: getMessage(
          'product.global.column.dietaryAttributesAction'
        ),
        render: function DietaryAction(value) {
          /* istanbul ignore next */
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      COUNTRY_OF_ORIGIN: {
        header: 'country_of_origin',
        validate: value => isGivenLength(value, 50),
        displayHeader: getMessage('product.global.column.countryOfOrigin'),
      },
      VENDOR_CODE: {
        header: 'vendor_code',
        validate: isPositiveNumber,
        displayHeader: getMessage('product.global.column.vendorCode'),
      },
      VENDOR_CODE_ACTION: {
        header: 'vendor_code_action',
        validate: value => !value || isIncludes(ATTRIBUTES_ACTIONS, value),
        displayHeader: getMessage('product.global.column.vendorCodeAction'),
        render: function AttributeAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      HANDLING_DAYS: {
        header: 'handling_days',
        validate: isPositiveNumber,
        displayHeader: getMessage('product.global.column.handlingDays'),
      },
      STATUS: {
        header: 'status',
        validate: status => isIncludes(SHOW_HIDE_STATUS, status),
        displayHeader: 'Status',
      },
      IS_PREORDER_ONLY: {
        header: 'is_preorder_only',
        validate: (value) => isIncludes(UNLIMITED_CHECK_OPTIONS, value),
        displayHeader: getMessage('product.global.column.isPreorderOnly'),
      },
      PACK_TYPE: {
        header: 'pack_type',
        validate: (value) => isGivenLength(value, 50),
        displayHeader: getMessage('product.global.column.packType'),
      },
      PACK_TYPE_ACTION: {
        header: 'pack_type_action',
        validate: value => !value || isIncludes(ATTRIBUTES_ACTIONS, value),
        displayHeader: getMessage(
          'product.global.column.packTypeAction'
        ),
        render: function PackTypeAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      PRODUCT_TYPE: {
        header: 'product_type',
        validate: (value) => isGivenLength(value, 50),
        displayHeader: getMessage('product.global.column.productType'),
      },
      PRODUCT_TYPE_ACTION: {
        header: 'product_type_action',
        validate: value => !value || isIncludes(ATTRIBUTES_ACTIONS, value),
        displayHeader: getMessage(
          'product.global.column.productTypeAction'
        ),
        render: function ProductTypeAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      PRODUCT_VOLUME: {
        header: 'product_volume',
        validate: (value) => isGivenLength(value, 50),
        displayHeader: getMessage('product.global.column.productVolume'),
      },
      PRODUCT_VOLUME_ACTION: {
        header: 'product_volume_action',
        validate: value => !value || isIncludes(ATTRIBUTES_ACTIONS, value),
        displayHeader: getMessage(
          'product.global.column.productVolumeAction'
        ),
        render: function ProductVolumeAction(value) {
          return (
            <span className={`attribute-action ${(value || '').toUpperCase()}`}>
              {value}
            </span>
          )
        },
      },
      IS_OG_NON_COMPLAINT: {
        header: 'is_og_non_complaint',
       validate: value => !value || isBoolean(value),
        displayHeader: getMessage('product.global.column.isOgNonComplaint'),
      },
      IS_NOT_ADVERTISABLE: {
        header: 'is_not_advertisable',
       validate: value => !value || isBoolean(value),
        displayHeader: getMessage('product.global.column.isNotAdvertisable'),
      },
    },
    endpoint: '/catalogue-service/product-attribute-upload',
    ReviewComponent: BulkGlobalAttributesReview,
  },
  SUBSTITUTION_GROUP: {
    name: 'Bulk Substitution Group',
    id: 'bulk-substitution-group',
    sampleCsv: 'sample-bulk-substitution-group.csv',
    isValidFilename: () => true,
    csvHeaders: {
      PRIMARY_SKU: {
        header: 'sku',
        validate: skuObj => isPositiveNumber(skuObj.id) && skuObj.name,
        displayHeader: 'Primary SKU',
        // eslint-disable-next-line react/display-name
        render: obj => <span className="sku">{obj.id}</span>,
        mandatory: true,
      },
      PRODUCT_NAME: {
        header: '',
        validate: value => !!value,
        skipInTemplate: true,
      },
      SUBSTITUTE_SKU: {
        header: 'substitute_skus',
        validate: () => true,
        displayHeader: 'Substitute SKU',
      },
    },
    getTableHeaders: () => ['Primary SKU', 'Product Name', 'Substitute SKU'],
    endpoint: '/catalogue-service/product-attribute-upload',
    ReviewComponent: BulkSubstitutionGroupReview,
  },
  BULK_OFFER_DEACTIVATION_BY_OFFER: {
    name: 'Bulk Offer Deactivation By Offer',
    id: 'bulk-offer-deactivation_by_offer',
    sampleCsv: 'sample-bulk-offer-deactivation_by_offer.csv',
    isValidFilename: () => true,
    csvHeaders: {
      OFFER_ID: {
        header: 'offer_id',
        validate: isPositiveNumber,
        displayHeader: 'Offer Id',
      },
    },
    getTableHeaders: () => ['Offer Id', 'Sku', 'Store Id'],
    endpoint: '/offer-service/offers',
    ReviewComponent: BulkDeactivationOfferReview,
  },
  BULK_OFFER_DEACTIVATION_BY_SKU: {
    name: 'Bulk Offer Deactivation By Sku',
    id: 'bulk-offer-deactivation_by_sku',
    sampleCsv: 'sample-bulk-offer-deactivation_by_sku.csv',
    isValidFilename: () => true,
    csvHeaders: {
      SKU: {
        header: 'sku',
        validate: skuObj => isPositiveNumber(skuObj.id) && skuObj.name,
        displayHeader: 'SKU',
        // eslint-disable-next-line react/display-name
        render: obj => <span className="sku">{obj.id}</span>,
        mandatory: true,
      },
      STORE_ID: {
        header: 'store_id',
        validate: isPositiveNumber,
        displayHeader: 'Store Id',
      },
    },
    getTableHeaders: () => ['Offer Id', 'Sku', 'Store Id'],
    endpoint: '/offer-service/offers',
    ReviewComponent: BulkDeactivationOfferReview,
  },
  BULK_VOUCHER_DEPOSIT: {
    name: 'Bulk Voucher Deposit',
    id: 'bulk-voucher-deposit',
    sampleCsv: 'sample-bulk-voucher-deposit',
    isValidFilename: () => true,
    csvHeaders: {
      CUSTOMER_ID: {
        header: 'customer_id',
        validate: isPositiveNumber,
        displayHeader: 'Customer Id',
      },
      PROMO_CODE: {
        header: 'promo_code',
        validate: () => true,
        displayHeader: PROMO_CODE_LABEL,
      },
    },
    getTableHeaders: () => ['Customer Id', PROMO_CODE_LABEL, 'Error'],
    endpoint: '/offer-service/offer-wallet/bulk',
    ReviewComponent: BulkVoucherDepositReview,
  },
  BULK_OFFER_UPLOAD_CART: cartLevelConfig,
  BULK_OFFER_UPLOAD_SKU: SKULevelConfig,
  BULK_SEARCH_OVERRIDES_UPLOAD: {
    name: 'Bulk Search Overrides Upload',
    id: 'bulk-search-overrides-upload',
    sampleCsv: 'sample-search-overrides-offer',
    isValidFilename: () => true,
    csvHeaders: {
      OVERRIDE_TYPE: {
        header: 'searchoverride_type',
        validate: () => true,
        displayHeader: 'searchoverride_type',
        valueKey: 'searchoverrideType',
      },
      OVERRIDE_VALUE: {
        header: 'searchoverride_value',
        validate: () => true,
        displayHeader: 'searchoverride_value',
        valueKey: 'searchoverrideValue',
      },
      START_DATE: {
        header: 'start date',
        validate: () => true,
        displayHeader: 'start date',
        valueKey: 'startDate',
      },
      END_DATE: {
        header: 'end date',
        validate: () => true,
        displayHeader: 'end date',
        valueKey: 'endDate',
      },
      PRODUCT_SKU_ID: {
        header: 'product sku id',
        validate: () => true,
        displayHeader: 'product sku id',
        valueKey: 'productSkuId',
      },
      POSITION: {
        header: 'Position',
        validate: () => true,
        displayHeader: 'position',
        valueKey: 'position',
      },
    },
    endpoint: '/catalogue-service/search-override-upload/bulk',
    ReviewComponent: BulkSearchOverride,
  },
}

const BATCH_UPLOAD_ROLE = "Catalogue / Batch Upload Jobs"
const BATCH_UPLOAD_PRODUCT_ONLY_ROLE = "Catalogue / Batch Upload Jobs (Product-only)"

export const PRODUCT_ONLY_ROLE_JOBS_CONFIG_KEYNAME = [
  'SKU_STORE_ATTRIBUTE',
  'SKU_GLOBAL_ATTRIBUTE',
  'COMPLEX_ATTRIBUTE'
]

export const filterPermittedJobConfigs = (jobConfigs) => {
  const flags = JSON.parse(get('SPLIT_FEATURE_FLAGS'))
  const isReadOnlyAccessControlEnabled =
    flags?.[SPLIT_FEATURES.READ_ONLY_ACCESS_CONTROL]?.treatment === 'on'

  if (!isReadOnlyAccessControlEnabled) {
    return jobConfigs
  }

  if (isUserHaveRole(BATCH_UPLOAD_PRODUCT_ONLY_ROLE)) {
    return jobConfigs
  } 
  
  if (isUserHaveRole(BATCH_UPLOAD_ROLE)) {
    return omit(jobConfigs, PRODUCT_ONLY_ROLE_JOBS_CONFIG_KEYNAME)
  }

  return {}
}