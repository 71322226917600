import React from 'react'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import { Link } from 'react-router-dom'
import { getMessage } from 'lib/translator'

import { LinkButtons, tableProperties, transform } from '../utils'
import DesignCodesForm from './DesignCodesForm'
import { GIFTING_PAGINATION_SIZE } from 'pages/customer-support/constants'

const EmptyState = {
  message: getMessage('customerSupport.egifting.empty'),
  actions: () => (
    <Link
      to="/gift-card/design-codes/add"
      className="primary button"
      data-testid="crate-new-design-code"
    >
      <span className="text">
        {getMessage('gift.card.design.codes.create')}
      </span>
    </Link>
  ),
}

const DesignCodes = (props) => {
  return (
    <ListingPageWithRoutes
      testid="design-codes-listing"
      title="Design Codes"
      addHeading="Create Design Codes"
      menu={props.menu}
      className="design-codes"
      api={{
        url: '/gifting-admin-service/design-codes',
        params: { ...props.params, page_size: GIFTING_PAGINATION_SIZE },
        transform,
      }}
      form={{
        component: DesignCodesForm,
      }}
      tableProperties={tableProperties()}
      tableDynamic
      headerActions={LinkButtons}
      emptyState={EmptyState}
    />
  )
}

export default DesignCodes
