import React from 'react'
import { Cell, Row } from 'components/Table'
import { getReadableDateFromISO8601 } from 'lib/datetime'
import { Link } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import { padCode } from 'pages/gift-card/utils/constants'

export const TableProperties = () => {
  return {
    headers: [
      getMessage('gift.card.print.orders.order.id'),
      getMessage('gift.card.print.orders.status'),
      getMessage('calls.table.heading.type'),
      getMessage('offer.entity.id'),
      getMessage('gift.card.sales.orders.email'),
      getMessage('order.filters.payment.heading'),
      getMessage('gift.card.design.codes.title'),
      getMessage('gift.card.print.orders.created.by'),
      getMessage('gift.card.print.orders.create.time'),
    ],
    row: ({
      id,
      status,
      type,
      entityId,
      customerEmails,
      paymentMode,
      items,
      updatedBy,
      createTime,
    }) => (
      <Row>
        <Cell>
          <Link to={`/gift-card/sales-orders/view/${id}`}>{id}</Link>
        </Cell>
        <Cell>{status}</Cell>
        <Cell>{type}</Cell>
        <Cell>{entityId}</Cell>
        <Cell>{customerEmails.join(', ')}</Cell>
        <Cell>{paymentMode?.replace(/_/g, ' ')}</Cell>
        <Cell>
          {items.map((item) => (
            <>
              <small>
                <strong>
                  {getMessage('gift.card.print.orders.design.code')}:
                </strong>{' '}
                {padCode(item?.designCode)}
                <p>
                  <strong>
                    {getMessage('gift.card.design.codes.input.denomination')}:
                  </strong>{' '}
                  {item?.denomination}
                </p>
                <p>
                  <strong>
                    {getMessage('gift.card.print.orders.quantity')}:
                  </strong>{' '}
                  {item?.quantity}
                </p>
              </small>
            </>
          ))}
        </Cell>
        <Cell>{updatedBy.email}</Cell>
        <Cell>{getReadableDateFromISO8601(createTime)}</Cell>
      </Row>
    ),
  }
}
