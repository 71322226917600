import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PrintOrdersDetails from './Details'
import PrintOrderTable from './Table'
import { Dialog } from 'components/Popup'
import './style.css'
import { getMessage } from 'lib/translator'

const PrintOrders = (props) => {
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState('')
  const [dialogError, setDialogError] = useState(false)
  const { router, history } = props
  const details = { ...props, url: '/print-orders' }

  const handleDialogClose = () => setSuccessModal(false)
  const handleOk = () => {
    handleDialogClose()
    history.push('/gift-card/print-orders/')
  }

  return (
    <>
      <Dialog
        data-testid="print-detail-success"
        className={dialogError ? 'error' : 'success'}
        show={successModal}
        close={handleDialogClose}
        message={message}
        closeText={getMessage('gift.card.print.orders.close')}
        onOk={handleOk}
        okText={getMessage('gift.card.print.orders.ok')}
      />
      {router.match.params.id ? (
        <PrintOrdersDetails
          {...details}
          setSuccessModal={setSuccessModal}
          setMessage={setMessage}
          setDialogError={setDialogError}
        />
      ) : (
        <PrintOrderTable
          {...details}
          setSuccessModal={setSuccessModal}
          setMessage={setMessage}
          setDialogError={setDialogError}
        />
      )}
    </>
  )
}

export default withRouter(({ match, ...props }) => (
  <PrintOrders router={{ match }} {...props} />
))
