import React from 'react'
import flatten from 'lodash/flatten'
import _ from 'lodash'
import {
  BaseForm,
  CategorySearch,
  Input,
  Textarea,
  ImageUpload,
  Checkbox,
  Searchable,
  MultiTextInput,
  BrandSearch,
  VALIDATION_TYPES,
  SingleDatePicker,
  Toggle,
} from '../../../../components/Form'
import MetaDataFormFields from '../../../../components/MetaDataFormFields'
import Tabs from '../../../../components/Tabs'
import Loader from '../../../../components/Loader'
import { Dialog } from '../../../../components/Popup'
import Toast from '../../../../components/Toast'
import { getMessage } from '../../../../lib/translator'
import {
  getSession,
  isExtensionEnabled,
  getStores,
  hasPermissions,
} from '../../../../lib/auth'
import API from '../../../../lib/api'
import { get } from '../../../../lib/storage'
import {
  getDefaultStore,
  getStoreName,
} from '../../../../containers/StoreSelector'
import deleteIcon from './icon-dustbin.svg'
import shopIcon from './shop.svg'
import editIcon from './edit.svg'
import infoIcon from './info.svg'
import './style.scss'
import { StoreGroup, defaultStoreId } from './StoreGroup'
import { When } from 'components/When'
import isEmpty from 'lodash.isempty'
import { isCatalogueProductReadOnly } from '../utils'

const isMarketplaceSku = clientItemId => clientItemId >= 90000000
const isZASKU = skuType => skuType === 'ZA'

export default class ProductForm extends BaseForm {
  constructor(props) {
    super(props)
    this.state.selectedVariant = 0
    this.state.variantName = ''
    this.state.editVariant = false
    this.state.variants = []
    this.state.variantToggle = false
    this.state.selectedStore = getDefaultStore().storeId
    this.state.allStoresLoaded = false
    this.state.apiError = null
    this.state.showErrorDialog = false
    this.state.variance = null
    this.state.buffer = null
    this.state.reservation = null
    this.state.sapStock = null
    this.state.zsStock = null
    this.state.dailyPreset = null
    this.state.onlineStock = null
    this.state.tempStoreId = null
    this.state.showToolTip = false
    this.state.status = isExtensionEnabled('MultiStoreSupport')
    this.state.isFfsOpen = false
    this.state.isSngOpen = false
    this.state.showToast = false
    this.state.toastMessage = ''
    this.state.bulkReservation = ''

    this.addVariant = this.addVariant.bind(this)
    this.removeVariant = this.removeVariant.bind(this)
    this.showProductDeleteConfirmation = this.showProductDeleteConfirmation.bind(
      this
    )
    this.hideProductDeleteConfirmation = this.hideProductDeleteConfirmation.bind(
      this
    )
    this.showVariantDeleteConfirmation = this.showVariantDeleteConfirmation.bind(
      this
    )
    this.hideVariantDeleteConfirmation = this.hideVariantDeleteConfirmation.bind(
      this
    )
    this.deleteProduct = this.deleteProduct.bind(this)
    this.getStores = this.getStores.bind(this)
    this.getEntityMetaData = this.getEntityMetaData.bind(this)
    this.getInventoryData = this.getInventoryData.bind(this)
    this.onSelectStore = this.onSelectStore.bind(this)
    this.hideApiErrorDialog = this.hideApiErrorDialog.bind(this)
    this.onToggleChange = this.onToggleChange.bind(this)
    this.showAllVariantsDeleteConfirmation = this.showAllVariantsDeleteConfirmation.bind(
      this
    )
    this.hideAllVariantsDeleteConfirmation = this.hideAllVariantsDeleteConfirmation.bind(
      this
    )
    this.removeAllVariant = this.removeAllVariant.bind(this)
    this.showInventoryToolTip = this.showInventoryToolTip.bind(this)
    this.getStoreRows = this.getStoreRows.bind(this)
    this.updateStoreStatus = this.updateStoreStatus.bind(this)
    this.saveLocation = this.saveLocation.bind(this)
    this.callInventoryAPI = this.callInventoryAPI.bind(this)
    this.saveStock = this.saveStock.bind(this)
    this.saveLocationToPLS = this.saveLocationToPLS.bind(this)
  }
  getStoreRows(
    group = [],
    variantIndex,
    booleanStock,
    hasInstoreProcessing,
    updateStoreStatus,
    saveLocation,
    saveStock
  ) {
    const isReadOnly = isCatalogueProductReadOnly()
    return group.map((store, storeIndex) => (
      <div key={storeIndex} className="store-details-row form-row">
        {store && store.name ? (
          <div className="store-name-container">
            <img src={shopIcon} alt="shop-icon" />
            <span className="store-name">{getStoreName(store)} | StoreID - ({store.id}) | SAP ID - ({store.clientId})</span>
            <span className="store-toggle">
              <Toggle
                disabled={isReadOnly}
                className={'product-status'}
                name={
                  this.getState([
                    'variants',
                    variantIndex,
                    'storeSpecificData',
                    store.id,
                    'status',
                  ]) === 'ENABLED'
                    ? 'ENABLED'
                    : 'HIDDEN'
                }
                icons={false}
                value={
                  this.getState([
                    'variants',
                    variantIndex,
                    'storeSpecificData',
                    store.id,
                    'status',
                  ]) === 'ENABLED' ||
                  (!isExtensionEnabled('MultiStoreSupport') &&
                    !this.getState([
                      'variants',
                      variantIndex,
                      'storeSpecificData',
                      store.id,
                      'status',
                    ]))
                    ? true
                    : undefined
                }
                onChange={() => {
                  const status =
                    this.getState([
                      'variants',
                      variantIndex,
                      'storeSpecificData',
                      store.id,
                      'status',
                    ]) === 'ENABLED' ||
                    (!isExtensionEnabled('MultiStoreSupport') &&
                      !this.getState([
                        'variants',
                        variantIndex,
                        'storeSpecificData',
                        store.id,
                        'status',
                      ]))
                  this.setState({ status: !status }, () => {
                    this.updateState(
                      [
                        'variants',
                        variantIndex,
                        'storeSpecificData',
                        store.id,
                        'status',
                      ],
                      this.state.status ? 'ENABLED' : 'HIDDEN'
                    )
                  })
                  updateStoreStatus(!status, store.id, variantIndex)
                }}
              />
            </span>
          </div>
        ) : null}
        <div className="store-specific-details price-related-details">
          <div className="heading">
            {getMessage('product.form.variant.price.header')}
          </div>
          <div className="details">
            <Input
              label={getMessage('product.form.variant.price.heading')}
              placeholder={getMessage('product.form.variant.price.placeholder')}
              name={`variant-${variantIndex}-store-${storeIndex}-price`}
              type="number"
              step="0.01"
              min={0}
              // required
              prefix={getSession().organization.currency.symbol}
              {...this.generateStateMappers({
                stateKeys: [
                  'variants',
                  variantIndex,
                  'storeSpecificData',
                  store.id,
                  'mrp',
                ],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
              disabled
            />
            <Input
              label={getMessage('product.form.variant.discount.heading')}
              placeholder={getMessage(
                'product.form.variant.discount.placeholder'
              )}
              name={`variant-${variantIndex}-store-${storeIndex}-discount`}
              type="number"
              step="0.01"
              min={0}
              prefix={getSession().organization.currency.symbol}
              {...this.generateStateMappers({
                stateKeys: [
                  'variants',
                  variantIndex,
                  'storeSpecificData',
                  store.id,
                  'discount',
                ],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              validationStrings={{
                valueMissing: getMessage('input.requiredMessage'),
              }}
              disabled
            />
          </div>
        </div>
        {getSession().organization.currency.name === 'INR' && (
          <div className="store-specific-details tax-related-details">
            <div className="heading">
              {getMessage('product.form.variant.tax.header')}
            </div>
            <div className="details">
              <Input
                label={getMessage('product.form.variant.cgst.heading')}
                placeholder={getMessage(
                  'product.form.variant.cgst.placeholder'
                )}
                name={`variant-${variantIndex}-store-${storeIndex}-cgst`}
                type="number"
                step="0.01"
                min={0}
                max={100}
                className="tax-field"
                suffix="%"
                {...this.generateStateMappers({
                  stateKeys: [
                    'variants',
                    variantIndex,
                    'storeSpecificData',
                    store.id,
                    'tax',
                    'cgst',
                  ],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('product.form.variant.sgst.heading')}
                placeholder={getMessage(
                  'product.form.variant.sgst.placeholder'
                )}
                name={`variant-${variantIndex}-store-${storeIndex}-sgst`}
                type="number"
                step="0.01"
                min={0}
                max={100}
                className="tax-field"
                suffix="%"
                {...this.generateStateMappers({
                  stateKeys: [
                    'variants',
                    variantIndex,
                    'storeSpecificData',
                    store.id,
                    'tax',
                    'sgst',
                  ],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                label={getMessage('product.form.variant.igst.heading')}
                placeholder={getMessage(
                  'product.form.variant.igst.placeholder'
                )}
                name={`variant-${variantIndex}-store-${storeIndex}-igst`}
                type="number"
                step="0.01"
                min={0}
                max={100}
                className="tax-field"
                suffix="%"
                {...this.generateStateMappers({
                  stateKeys: [
                    'variants',
                    variantIndex,
                    'storeSpecificData',
                    store.id,
                    'tax',
                    'igst',
                  ],
                  loseEmphasisOnFill: true,
                })}
              />
            </div>
          </div>
        )}
        {getSession().organization.currency.name !== 'INR' &&
          this.state.taxArray &&
          this.state.taxArray.length > 0 && (
            <div className="store-specific-details tax-related-details">
              <div className="heading">
                {getMessage('product.form.variant.tax.header')}
              </div>
              <div className="details">
                {this.state.taxArray.map(tax => {
                  return (
                    <Input
                      label={tax.toUpperCase()}
                      placeholder={getMessage(
                        'product.form.variant.sgst.placeholder'
                      )}
                      name={`variant-${variantIndex}-store-${storeIndex}-${tax}`}
                      key={`variant-${variantIndex}-store-${storeIndex}-${tax}`}
                      type="number"
                      step="0.01"
                      min={0}
                      max={100}
                      className="tax-field"
                      suffix="%"
                      {...this.generateStateMappers({
                        stateKeys: [
                          'variants',
                          variantIndex,
                          'storeSpecificData',
                          store.id,
                          'tax',
                          tax,
                        ],
                        loseEmphasisOnFill: true,
                      })}
                    />
                  )
                })}
              </div>
            </div>
          )}
        {hasInstoreProcessing && (
          <div className="store-specific-details product-location-details">
            <div className="heading">
              {getMessage('product.form.variant.location.header')}
            </div>
            <div className="details location-details">
              <Input
                label={getMessage('product.form.variant.primary.heading')}
                placeholder={getMessage('product.form.variant.primary.placeholder')}
                name={`variant-${variantIndex}-store-${storeIndex}-primary primary-input`}
                type="text"
                {...this.generateStateMappers({
                  stateKeys: [
                    'variants',
                    variantIndex,
                    'storeSpecificData',
                    store.id,
                    'primaryLocation',
                  ],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <div className='secondary-input-wrapper'>
                <Input
                  label={getMessage('product.form.variant.secondary.heading')}
                  placeholder={getMessage('product.form.variant.secondary.placeholder')}
                  name={`variant-${variantIndex}-${storeIndex}-secondary`}
                  type="text"
                  {...this.generateStateMappers({
                    stateKeys: [
                      'variants',
                      variantIndex,
                      'storeSpecificData',
                      store.id,
                      'secondaryLocation',
                    ],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
                <SingleDatePicker
                  label={getMessage('product.form.variant.secondaryExpiry.heading')}
                  placeholder={getMessage('product.form.variant.secondaryExpiry.placeholder')}
                  name="secondary-expiry-date"
                  openDirection={'up'}
                  allowToday
                  enableToday
                  {...this.generateStateMappers({
                    stateKeys: [
                      'variants',
                      variantIndex,
                      'storeSpecificData',
                      store.id,
                      'secondaryLocationExpiry',
                    ],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
                <button
                  type="button"
                  disabled={isReadOnly}
                  className="button primary btnSaveLocation"
                  onClick={() => this.saveLocationToPLS(store.id, variantIndex, store.name)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="store-specific-details stock-related-details">
          <div className="heading">
            <div className="stock-tooltip-button">
              {getMessage('product.form.variant.stock.header')}
              <img
                src={infoIcon}
                className="product-info-icon"
                alt="X"
                onClick={() => {
                  if (store.id !== this.state.tempStoreId) {
                    this.setState(
                      {
                        tempStoreId: store.id,
                      },
                      this.showInventoryToolTip
                    )
                  }
                }}
              />
            </div>
            {this.state.tempStoreId === store.id && this.state.showToolTip && (
              <div className="stock-tooltip">
                <div className="stock-tooltip-content">
                  <p className="inventory-info">STOCK INFO</p>
                  <span
                    onClick={() => {
                      if (store.id === this.state.tempStoreId) {
                        this.setState({
                          tempStoreId: null,
                          showToolTip: false
                        })
                      }
                    }}
                  >
                    X
                  </span>
                  <table>
                    <tbody>
                      <tr>
                        <td>Store Routing: {this.state.bulkOrderThreshold}</td>
                        <td>
                          Max Purchase Qty: {this.state.maxPurchasableStock}
                        </td>
                      </tr>
                      <tr>
                        <td>Variance: {this.state.variance}</td>
                        <td>Buffer: {this.state.buffer}</td>
                      </tr>
                      <tr>
                        <td>SAP SOH: {this.state.sapStock}</td>
                        <td>Online SOH: {this.state.onlineStock}</td>
                      </tr>
                      <tr>
                        <td>Reservation: {this.state.reservation}</td>
                        <td>ZS SOH: {this.state.zsStock}</td>
                      </tr>
                      <tr>
                        <td>Bulk Resv: {this.state.bulkReservation}</td>
                        <td>Daily Preset: {this.state.dailyPreset}</td>
                      </tr>
                      <tr>
                        <td>Store Bulk: {this.state.bulkThreshold}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="details">
            {booleanStock ? (
              <Checkbox
                disabled={isReadOnly}
                label={getMessage('product.form.variant.availability.heading')}
                inlineLabel={getMessage(
                  'product.form.variant.availability.label'
                )}
                name={`variant-${variantIndex}-store-${storeIndex}-availability`}
                {...this.generateStateMappers({
                  stateKeys: [
                    'variants',
                    variantIndex,
                    'storeSpecificData',
                    store.id,
                    'inStoreStock',
                  ],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                controlled
              />
            ) : (
              <React.Fragment>
                <Input
                  className="fresh-stock"
                  label={getMessage(
                    'product.form.variant.stock.quantity.heading'
                  )}
                  placeholder={
                    this.props.value && this.props.value.soldByWeight
                      ? getMessage(
                          'product.form.variant.availability.fresh.placeholder'
                        )
                      : getMessage(
                          'product.form.variant.availability.placeholder'
                        )
                  }
                  name={`variant-${variantIndex}-store-${storeIndex}-availability`}
                  type="number"
                  step={
                    this.props.value && this.props.value.soldByWeight
                      ? 0.001
                      : ''
                  }
                  // required
                  min={
                    this.props.value && this.props.value.soldByWeight ? 0.0 : 0
                  }
                  suffix={
                    this.props.value && this.props.value.soldByWeight
                      ? 'KG'
                      : ''
                  }
                  {...this.generateStateMappers({
                    stateKeys: [
                      'variants',
                      variantIndex,
                      'storeSpecificData',
                      store.id,
                      'inStoreStock',
                    ],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                    isSoldByWeight:
                      this.props.value && this.props.value.soldByWeight,
                  })}
                  validationStrings={{
                    valueMissing: getMessage('input.requiredMessage'),
                  }}
                  readOnly={
                    this.getState([
                      'variants',
                      variantIndex,
                      'storeSpecificData',
                      store.id,
                      'unlimitedStock',
                    ]) ||
                    isMarketplaceSku(
                      this.getState(['variants', variantIndex, 'clientItemId'])
                    ) ||
                    isZASKU(
                      this.getState([
                        'variants',
                        variantIndex,
                        'storeSpecificData',
                        store.id,
                        'skuType',
                      ])
                    )
                  }
                />
                <button
                  type="button"
                  disabled={isReadOnly}
                  className="button primary btnSaveLocation"
                  onClick={() => {
                    saveStock(store.id, variantIndex)
                  }}
                >
                  Save
                </button>
                <Checkbox
                  disabled={isReadOnly}
                  label={getMessage(
                    'product.form.variant.unlimitedAvailable.label'
                  )}
                  className="unlimited-stock"
                  name={`variant-${variantIndex}-store-${storeIndex}-unlimitedAvailable`}
                  {...this.generateStateMappers({
                    stateKeys: [
                      'variants',
                      variantIndex,
                      'storeSpecificData',
                      store.id,
                      'unlimitedStock',
                    ],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  onChange={event => {
                    this.updateState(
                      [
                        'variants',
                        variantIndex,
                        'storeSpecificData',
                        store.id,
                        'unlimitedStock',
                      ],
                      event
                    )
                  }}
                  controlled
                  value={
                    this.getState([
                      'variants',
                      variantIndex,
                      'storeSpecificData',
                      store.id,
                      'unlimitedStock',
                    ]) || false
                  }
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    ))
  }
  addVariant() {
    if (!this.state.variantName) {
      return
    }
    this.setState(prevState => {
      const newState = Object.assign({}, prevState)
      if (Array.isArray(newState.values.variants)) {
        if (newState.values.variants.length) {
          if (newState.values.variants.length === 1) {
            if (!newState.values.variants[0].name) {
              newState.values.variants[0].name = this.state.variantName
              newState.variantName = ''
            } else {
              newState.values.variants.push({
                name: this.state.variantName,
              })
              newState.variantName = ''
            }
          } else {
            newState.values.variants.push({
              name: this.state.variantName,
            })
            newState.variantName = ''
          }
        }
      } else {
        newState.values.variants = [
          {
            name: this.state.variantName,
          },
        ]
        newState.variantName = ''
      }
      return newState
    })
  }
  removeVariant(index) {
    this.setState(prevState => {
      const newState = Object.assign({}, prevState)
      newState.values.variants.splice(index, 1)
      newState.validations.variants.splice(index, 1)
      if (newState.values.variants.length === 1) {
        // Remove validation of 'variant name' field as it is no longer shown
        delete newState.validations.variants[0].name
      } else if (!newState.values.variants.length) {
        newState.values.variants.push({})
      }
      newState.selectedVariant = Math.max(prevState.selectedVariant - 1, 0)
      return newState
    }, this.hideVariantDeleteConfirmation)
  }

  onToggleChange() {
    if (
      this.state.values &&
      this.state.values.variants &&
      this.state.values.variants.length > 1
    ) {
      this.setState({ deleteAllVariantsDialogShown: true })
    } else {
      this.setState({ variantToggle: !this.state.variantToggle })
    }
  }
  showAllVariantsDeleteConfirmation() {
    this.setState({
      deleteAllVariantsDialogShown: true,
    })
  }
  hideAllVariantsDeleteConfirmation() {
    this.setState({
      deleteAllVariantsDialogShown: false,
    })
  }

  showInventoryToolTip() {
    if (this.state.tempStoreId) {
      const { id } = this.state.values
      Promise.all([
        this.getInventoryDetails(this.state.tempStoreId, id),
        this.getStockOverrideDetails(this.state.tempStoreId, id),
      ]).catch(error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
        this.setState({
          apiError: error.message,
          showErrorDialog: true,
        })
      })
    }
  }

  getInventoryDetails(storeId, productId) {
    const inventoryGetApi = new API({
      url: `/inventory-service/controller/items?productId=${productId}&storeId=${storeId}`,
    })

    return inventoryGetApi.get().then(response => {
      if (response.data[0] && response.data[0].inventory) {
        const inventoryObj = response.data[0].inventory
        this.setState({
          showToolTip: true,
          variance: inventoryObj.stockVariance
            ? inventoryObj.stockVariance.toFixed(3)
            : 0,
          buffer: inventoryObj.buffer,
          reservation: inventoryObj.reservation,
          bulkReservation: inventoryObj.bulkReservation,
          bulkThreshold: inventoryObj?.bulkRoutingThreshold.bulkThreshold,
          sapStock: inventoryObj.sapStock,
          onlineStock: inventoryObj.onlineStock,
          zsStock: inventoryObj.stock ? inventoryObj.stock.toFixed(3) : 0,
          dailyPreset: inventoryObj.dailyPreset ? inventoryObj.dailyPreset.toFixed(2) : 0
        })
      }
    })
  }

  getStockOverrideDetails(storeId, entityId) {
    // clear store specific details
    this.setState({
      bulkOrderThreshold: null,
      maxPurchasableStock: null,
    })
    const stockOverrideApi = new API({
      url: `/catalogue-service/stock-override?type=PRODUCT&storeId=${storeId}&entityId=${entityId}`,
    })

    return stockOverrideApi.get().then(response => {
      if (
        response.data &&
        response.data.stockOverride &&
        response.data.stockOverride[0]
      ) {
        const {
          bulkOrderThreshold,
          maxPurchasableStock,
        } = response.data.stockOverride[0]
        this.setState({
          bulkOrderThreshold,
          maxPurchasableStock,
        })
      }
    })
  }

  removeAllVariant() {
    this.setState(
      prevState => {
        const newState = Object.assign({}, prevState)
        newState.values.variants.splice(1)
        newState.validations.variants.splice(1)
        if (this.props.method === 'add') {
          if (
            newState.values.variants &&
            newState.values.variants.length === 1
          ) {
            delete newState.values.variants[0].name
          }
        } else {
          if (
            newState.values.variants &&
            newState.values.variants.length === 1
          ) {
            if (!newState.values.variants[0].productId) {
              delete newState.values.variants[0].name
            } else {
              delete newState.values.variants[0].name
              delete newState.values.variants[0].fullName
              delete newState.values.variants[0].id
              delete newState.values.variants[0].productId
              delete newState.values.variants[0].status
            }
          }
        }
        return newState
      },
      () => {
        this.setState({ variantToggle: false, selectedVariant: 0 })
        this.hideAllVariantsDeleteConfirmation()
      }
    )
  }
  showProductDeleteConfirmation() {
    this.setState({
      deleteProductDialogShown: true,
    })
  }
  hideProductDeleteConfirmation() {
    this.setState({
      deleteProductDialogShown: false,
    })
  }
  showVariantDeleteConfirmation() {
    this.setState({
      deleteVariantDialogShown: true,
    })
  }
  hideVariantDeleteConfirmation() {
    this.setState({
      deleteVariantDialogShown: false,
    })
  }
  hideApiErrorDialog() {
    this.setState({
      showErrorDialog: false,
    })
  }
  deleteProduct() {
    this.props.onDelete && this.props.onDelete()
  }
  beforeSubmit() {
    // Since variants are tabbed, when errors are there, navigate to the first tab with errors
    let index = -1
    if (
      this.state.validations &&
      this.state.validations.variants &&
      this.state.validations.variants.length
    ) {
      index = this.state.validations.variants.findIndex(
        variant => !this.isFormValid(variant)
      )
    }
    if (index > -1) {
      this.setState({ selectedVariant: index })
    }
  }

  changeParamsDataType(param) {
    const params = Object.assign({}, param)
    if (params.discount !== undefined) {
      params.discount = parseFloat(params.discount)
    }
    if (params.mrp !== undefined) {
      params.mrp = parseFloat(params.mrp)
    }
    if (params.inStoreStock !== undefined) {
      params.inStoreStock = Number(params.inStoreStock)
    }
    if (!params.inStoreStock) {
      params.inStoreStock = 0
    }
    if (params.unlimitedStock !== undefined && params.unlimitedStock) {
      params.inStoreStock = 0
    }
    if (params.status === undefined) {
      params.status = isExtensionEnabled('MultiStoreSupport')
        ? 'HIDDEN'
        : 'ENABLED'
    }
    return params
  }

  transformSubmit(formData) {
    const brandsEnabled = isExtensionEnabled('MultiBrandSupport')
    const entityMetaDataEnabled = isExtensionEnabled('EntityMetaData')
    // return null
    const modifiedData = Object.assign({}, formData)
    if (formData.primaryCategory) {
      modifiedData.primaryCategoryId = formData.primaryCategory.id
    }
    delete modifiedData.primaryCategory
    if (formData.secondaryCategories) {
      modifiedData.categoryIds = formData.secondaryCategories.map(
        category => category.id
      )
      if (modifiedData.primaryCategoryId) {
        modifiedData.categoryIds.splice(0, 0, modifiedData.primaryCategoryId)
      }
    }
    delete modifiedData.secondaryCategories
    if (
      !this.state.variantToggle &&
      modifiedData.variants &&
      modifiedData.variants.length > 0
    ) {
      delete modifiedData.variants[0].name
      delete modifiedData.variants[0].fullName
      delete modifiedData.variants[0].clientItemId
      Object.assign(modifiedData, modifiedData.variants[0])
      // delete modifiedData.variants
      modifiedData.variants = []
      if (modifiedData.hasOwnProperty('hasVariants')) {
        modifiedData.hasVariants = 0
      }
    }

    if (
      this.state.variantToggle &&
      this.props.method !== 'add' &&
      modifiedData.variants &&
      modifiedData.variants.length > 0
    ) {
      if (modifiedData.madeUp) {
        delete modifiedData.variants[0].clientItemId
      }
    }

    if (brandsEnabled && modifiedData.brand) {
      if (modifiedData.brand.name === modifiedData.brandName) {
        modifiedData.brandId = modifiedData.brand.id
        delete modifiedData.brandName
      }
      modifiedData.brandId = modifiedData.brand.id
    }
    if (brandsEnabled && !modifiedData.brand) {
      modifiedData.brandId = null
    }
    delete modifiedData.brand
    if (modifiedData.tags) {
      modifiedData.tagIds = formData.tags.map(tag => tag.id)
      delete modifiedData.tags
    }
    if (modifiedData.metaData) {
      if (!entityMetaDataEnabled) {
        delete modifiedData.metaData
      } else {
        let metaData = Object.assign({}, modifiedData.metaData)
        if (Object.keys(metaData)[0] === '0') {
          metaData = metaData['0']
        }
        Object.keys(metaData).forEach(key => {
          if (metaData[key] === '') {
            delete metaData[key]
          }
        })
        if (Object.keys(metaData).length) {
          modifiedData.metaData = metaData
        } else {
          delete modifiedData.metaData
        }
      }
    }
    modifiedData.soldByWeight = modifiedData.soldByWeight ? 1 : 0
    modifiedData.handlingDays = modifiedData.handlingDays || 0
    modifiedData.bulkOrderThreshold = modifiedData.bulkOrderThreshold || 0
    delete modifiedData.madeUp
    return modifiedData
  }

  onSubmit(values, storeData) {
    // this.props.onSubmit(values)
    const putApi = new API({ url: `/catalogue-service/product/${values.id}` })
    const postApi = new API({ url: `/catalogue-service/product` })
    const inventoryApi = new API({ url: `/inventory-service/item` })
    const param = this.transformSubmit(values)
    if (this.props.method === 'add') {
      postApi.post(param).then(
        response => {
          if (storeData.length === 1 && storeData[0].product) {
            const data = storeData[0].product
            Object.keys(data).forEach(storeId => {
              let params = data[storeId]
              params = this.changeParamsDataType(params)
              if (
                (params.mrp !== 0 && !params.mrp) ||
                (params.inStoreStock !== 0 && !params.inStoreStock)
              ) {
                return false
              }
              params.productId = response.data.product.id
              params.storeId = Number(storeId)
              inventoryApi
                .post(params)
                .then(() => {})
                .catch(error => {
                  if (error.code === 401 || error.code === 403) {
                    throw error
                  }
                  this.setState({
                    apiError: error.message,
                    showErrorDialog: true,
                  })
                })
            })
          } else {
            storeData.forEach(variant => {
              Object.keys(variant).forEach(variantName => {
                if (!variantName) {
                  return
                }
                variant[variantName] &&
                  Object.keys(variant[variantName]).forEach(storeId => {
                    let params = variant[variantName][storeId]
                    params.productId = response.data.product.variants.find(
                      v => {
                        return v.name === variantName
                      }
                    ).id
                    params.storeId = Number(storeId)
                    params = this.changeParamsDataType(params)
                    if (
                      (params.mrp !== 0 && !params.mrp) ||
                      (params.inStoreStock !== 0 && !params.inStoreStock)
                    ) {
                      return false
                    }
                    inventoryApi
                      .post(params)
                      .then(() => {})
                      .catch(error => {
                        if (error.code === 401 || error.code === 403) {
                          throw error
                        }
                        this.setState({
                          apiError: error.message,
                          showErrorDialog: true,
                        })
                      })
                  })
              })
            })
          }
          this.props.onCancel()
        },
        error => {
          if (error.code === 401 || error.code === 403) {
            throw error
          }
          this.setState({
            apiError: error.message,
            showErrorDialog: true,
            submitting: false,
          })
        }
      )
    } else {
      param.variants &&
        param.variants.forEach(variant => {
          if (variant.id && !variant.productId) {
            delete variant.id
          }
        })
      putApi.put(param).then(
        response => {
          storeData.forEach(variant => {
            Object.keys(variant).forEach(variantName => {
              variant[variantName] &&
                Object.keys(variant[variantName]).forEach(storeId => {
                  let params = variant[variantName][storeId]
                  if (params.productId) {
                    params.productId = variant.id
                  } else if (
                    !response.data.product.variants ||
                    (response.data.product.variants &&
                      response.data.product.variants.length === 0)
                  ) {
                    params.productId = response.data.product.id
                  } else {
                    params.productId = response.data.product.variants.find(
                      v => {
                        return v.name === variantName
                      }
                    ).id
                  }
                  params.storeId = Number(storeId)
                  params = this.changeParamsDataType(params)
                  if (
                    (params.mrp !== 0 && !params.mrp) ||
                    (params.inStoreStock !== 0 && !params.inStoreStock)
                  ) {
                    return false
                  }
                })
            })
          })
          this.setState({ submitting: false })
          this.props.onCancel()
        },
        error => {
          if (error.code === 401 || error.code === 403) {
            throw error
          }
          this.setState({
            apiError: error.message,
            showErrorDialog: true,
            submitting: false,
          })
        }
      )
    }
  }

  _submitHandler(e) {
    e && e.preventDefault()
    this.beforeSubmit()
    this.setState({
      pressedSubmitWithCurrentData: true,
      submitting: true,
    })
    const isValid = this.isFormValid()
    const storeData = []

    if (isValid) {
      const values = JSON.parse(JSON.stringify(this.state.values))
      values.variants &&
        values.variants.forEach(variant => {
          variant.name &&
            storeData.push({ [variant.name]: variant.storeSpecificData })
          !variant.name &&
            storeData.push({ product: variant.storeSpecificData })
          delete variant.storeSpecificData
        })
      this.onSubmit(values, storeData)
    } else {
      this.setState({ submitting: false })
    }
  }

  getStores() {
    if (this.props.options && this.props.options.multipleStores) {
      this.setState({
        loading: true,
        failedLoadingStores: false,
      })
      const defaultStore = {
        id: getDefaultStore().storeId,
      }
      this.props.options
        .getStores()
        .then(stores => {
          this.setState(prevState => {
            const newState = Object.assign({}, prevState, {
              stores: stores || [defaultStore],
              loading: false,
              failedLoadingStores: false,
            })
            return newState
          })
        })
        .catch(() => {
          this.setState({
            loading: false,
            failedLoadingStores: true,
          })
        })
    } else {
      this.setState(
        prevState => {
          const newState = Object.assign({}, prevState, {
            stores: getStores(),
          })
          return newState
        },
        () => {
          if (this.props.method === 'add') {
            return null
          }
          this.getInventoryData(get('store'))
        }
      )
    }
  }

  getDefaultStore() {}

  onSelectStore(storeId) {
    this.setState({ selectedStore: storeId })
  }

  getEntityMetaData() {
    const { props } = this
    if (
      props.options &&
      props.options.entityMetaData &&
      props.options.getProductMetaData
    ) {
      this.setState({
        loadingProductMetaData: true,
      })
      props.options.getProductMetaData().then(productMetaData => {
        this.productMetaDataWithType = productMetaData
        const values = Object.assign({}, this.state.values)
        let metaData = values.metaData
        if (!metaData) {
          metaData = {}
          productMetaData &&
            Object.keys(productMetaData).forEach(mt => {
              metaData[mt] = ''
            })
          values.metaData = metaData
        } else {
          const newMetaData = {}
          productMetaData &&
            Object.keys(productMetaData).forEach(mt => {
              newMetaData[mt] = metaData[mt] || ''
            })
          // metaData = []
          // metaData[0] = newMetaData
          metaData = newMetaData
          values.metaData = metaData
        }
        this.setState({
          values,
          loadingProductMetaData: false,
        })
      })
    }
  }

  getInventoryData(storeId) {
    const variants = Object.assign({}, this.state.values.variants)
    const ids = []
    Object.values(variants).forEach(variant => {
      ids.push(variant.id)
    })
    return this.props.options.getInventoryData(storeId, ids).then(
      response => {
        const newValues =
          this.state.values && JSON.parse(JSON.stringify(this.state.values))
        const variants = newValues.variants
        variants &&
          variants.length === response.data.length &&
          variants.forEach((variant, index) => {
            variant.storeSpecificData = variant.storeSpecificData || {}
            variant.storeSpecificData[storeId] = response.data[index] || {
              location: {},
              tax: {},
            }
          })
        this.setState({ values: newValues })
      },
      error => {
        this.setState({ error })
      }
    )
  }

  /* istanbul ignore next */
  fetchMultipleInventoryData = (storesToFetch, onSuccess) => {
    const variants = Object.assign({}, this.state.values.variants)

    /* Retrieve data from inventory-service */
    const getInventoryDataPromises = storesToFetch.map(store => {
      const ids = []
      Object.values(variants).forEach(variant => {
        ids.push(variant.id)
      })
      return this.props.options.getInventoryData(store.id, ids).then(
        response => ({ storeId: store.id, res: response }),
        () => {
          return {
            storeId: store.id,
            res: {},
          }
        }
      )
    })

    Promise.all(getInventoryDataPromises).then(responses => {
      const newValues =
        this.state.values && JSON.parse(JSON.stringify(this.state.values))
      const variants = newValues.variants
      responses
        .filter(response => !isEmpty(response.res))
        .forEach(item => {
          const { res: response, storeId } = item
          variants &&
            variants.length === response.data.length &&
            variants.forEach((variant, index) => {
              variant.storeSpecificData = variant.storeSpecificData || {}
              variant.storeSpecificData[storeId] = response.data[index] || {
                location: {},
                tax: {},
              }
            })
        })
      this.setState({ values: newValues }, () => {
        onSuccess()
      })
    })

    /* Retrieve data from product-location-service */
    const storeIds = storesToFetch.map(store => store.id);
    const variantSkus = Object.values(variants).map(variant => variant.clientItemId);
    this.setState({ loading: true });
    const getProductLocationData = this.props.options.getProductLocationData(storeIds, variantSkus)
      .then(
        response => ({ storeIds: storeIds, res: response }),
        () => ({ storeIds: storeIds, res: {} })
      )

    getProductLocationData.then(response => {
      this.setState({ loading: false });

      const { data } = response.res;
      const newVariants = _.cloneDeep(this.state.values.variants);

      data.forEach(product => {
        const { storeId, productSku } = product;
        const variantIndex = newVariants.findIndex(x => x.clientItemId === productSku)

        product.locations.forEach(location => {
          const { type, name, expiryDate, createdAt } = location;

          newVariants[variantIndex].storeSpecificData[storeId] = type === 'primary' ?
            {
              ...newVariants[variantIndex].storeSpecificData[storeId],
              primaryLocation: name,
              primaryLocationId: createdAt
            } :
            {
              ...newVariants[variantIndex].storeSpecificData[storeId],
              secondaryLocation: name,
              secondaryLocationExpiry: expiryDate,
              secondaryLocationId: createdAt
            };
        })
      })

      this.setState((prevState) => ({
        values: {
          ...prevState.values,
          variants: newVariants
        }
      }), () => onSuccess())
    })
    .catch(e => console.error(e))

  }

  componentDidMount() {
    const inventoryConfig = new API({ url: `/config-service/config/inventory` })
    inventoryConfig.get().then(response => {
      const taxArray = response.data.inventory.taxes || []
      this.setState({ taxArray })
    })
    const valueFromProps = this.props.value
    if (
      this.props.method === 'edit' &&
      valueFromProps &&
      valueFromProps.variants
    ) {
      if (
        valueFromProps.variants.length === 1 &&
        valueFromProps.variants[0].name
      ) {
        this.setState({ variantToggle: true })
      }
      if (valueFromProps.variants.length > 1) {
        this.setState({ variantToggle: true })
      }
    }
    this.getStores()
    // this.getStoreDetails()
    this.getEntityMetaData()
    const tabs = {
      description: getMessage('product.form.description.heading'),
      otherDetails: getMessage('product.form.other.details.heading'),
    }
    if (!isExtensionEnabled('EntityMetaData')) {
      delete tabs.otherDetails
    }
    this.setState({ selected: tabs.description, tabs: tabs })
    const supportVariants =
      (this.props.options &&
        this.props.options.variants &&
        this.props.method === 'add') ||
      (this.props.options &&
        this.props.options.variants &&
        this.props.value &&
        this.props.value.variants &&
        this.props.value.variants.length > 0)
    if (
      supportVariants &&
      this.props.method === 'edit' &&
      this.getState(['variants']).length > 1
    ) {
      this.setState({ variantToggle: true })
    }
    // this.getInventoryData(getDefaultStore().storeId)
  }

  callInventoryAPI(body, storeId, productId, action) {
    let url = '/inventory-service/item'
    let method = 'post'
    if (action === 'STOCK' || action === 'UNLIMITED_STOCK') {
      url = `/inventory-service/controller/items/${productId}`
      method = 'put'
    }

    const inventoryItemApi = new API({ url: url })
    inventoryItemApi[method](body)
      .then(data => {
        if (data.code !== 200) {
          this.setState({
            showToast: true,
            toastMessage: `Failed to update store details.`,
          })
        } else {
          let message = ''

          switch (action) {
            case 'LOCATION':
              message = `Location Details saved for ${
                this.state.stores.filter(i => i.id === storeId)[0].name
              }`
              break
            case 'STORE_TOGGLE':
              message = `${
                this.state.stores.filter(i => i.id === storeId)[0].name
              } store ${body.status.toLowerCase()}`
              break
            case 'STOCK':
            case 'UNLIMITED_STOCK':
              message = `Stock Details saved for ${
                this.state.stores.filter(i => i.id === storeId)[0].name
              }`
              break
            default:
              break
          }
          this.setState({
            showToast: true,
            toastMessage: message,
          })
        }
      })
      .catch(error => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
        this.setState({
          apiError: error.message,
          showErrorDialog: true,
        })
      })
  }

  async updateStoreStatus(productIds, status, storeId, variantIndex) {
    if (status) {
      this.setState({
        loading: true,
      })
      try {
        const inventoryItemAPIs = productIds.map(product => {
          return new API({
            url: `/inventory-service/item?ignoreConversion=true&productId=${product}&storeId=${storeId}`,
          })
        })
        const items = await Promise.all(
          inventoryItemAPIs.map(api => api.get())
        ).then(responses => {
          return flatten(responses.map(response => response.data[0]))
        })
        const enabledStore = items.some(item => item.status === 'ENABLED')

        if (!enabledStore) {
          this.toggleStoreStatus(storeId, status)
        } else {
          this.setState({
            apiError:
              'The same barcode tied to another product is already enabled in this store',
            showErrorDialog: true,
          })
          this.setState({ status: false }, () => {
            this.updateState(
              [
                'variants',
                variantIndex,
                'storeSpecificData',
                storeId,
                'status',
              ],
              'HIDDEN'
            )
          })
        }
      } catch (error) {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
        this.setState({
          apiError: error.message,
          showErrorDialog: true,
        })
      } finally {
        this.setState({
          loading: false,
        })
      }
    } else {
      this.toggleStoreStatus(storeId, status)
    }
  }

  toggleStoreStatus(storeId, status) {
    const { values } = this.state
    const body = {
      storeId: storeId,
      productId: values.id,
      status: status ? 'ENABLED' : 'HIDDEN',
    }
    this.callInventoryAPI(body, storeId, values.id, 'STORE_TOGGLE')
  }

  saveLocation(storeId, variantIndex) {
    const { values } = this.state
    const body = {
      storeId: storeId,
      productId: values.id,
      location:
        values.variants[variantIndex].storeSpecificData[storeId].location,
    }
    this.callInventoryAPI(body, storeId, values.id, 'LOCATION')
  }

  async saveLocationToPLS(storeId, variantIndex, storeName) {
    const { values } = this.state;
    const variant = values.variants[variantIndex].storeSpecificData[storeId];
    const locationPromises = []
    const plsApi = new API({ url: `/pls/v1/admin/locations` })
    const commonPayloadData = {
      storeId,
      productSku: values.clientItemId,
    }

    /*
      Upon clicking Save:
      if primaryLocation exists, POST call with enabled status.
      if primaryLocation is empty and has locationId, PATCH call with disabled status
      if primaryLocation is empty and has no locaitonId, do not call anything

      if both secondaryLocation and secondaryExpiry exists, POST call with enabled status.
      if only one of secondaryLocation or secondaryExpiry exists, do not call anything.
      if both secondaryLocation and secondaryExpiry empty and has locationId, PATCH call with disabled status
      if both secondaryLocation and secondaryExpiry empty and has no locationId, do not call anything
    */

    if (variant?.primaryLocation) {
      locationPromises.push(plsApi.post({
        ...commonPayloadData,
        locationType: 'primary',
        status: 'enabled',
        location: {
          name: variant.primaryLocation,
        }
      }))
    } else if (!variant?.primaryLocation && variant?.primaryLocationId) {
      locationPromises.push(plsApi.patch({
        ...commonPayloadData,
        locationType: 'primary',
        status: 'disabled'
      }))
    }

    if (variant?.secondaryLocation && variant?.secondaryLocationExpiry) {
      locationPromises.push(plsApi.post({
        ...commonPayloadData,
        locationType: 'secondary',
        status: 'enabled',
        location: {
          name: variant.secondaryLocation,
          expiryDate: variant.secondaryLocationExpiry
        }
      }))
    } else if (!variant?.secondaryLocation && !variant?.secondaryLocationExpiry && variant?.secondaryLocationId) {
      locationPromises.push(plsApi.patch({
        ...commonPayloadData,
        locationType: 'secondary',
        status: 'disabled'
      }))
    }

    const hasOnlySecondaryExpiry = !variant?.secondaryLocation && variant?.secondaryLocationExpiry;
    const hasOnlySecondaryName = variant?.secondaryLocation && !variant?.secondaryLocationExpiry;
    // if secondaryLocation or secondaryExpiry is empty, show error toast.
    // if both empty, do not show.
    if (hasOnlySecondaryName || hasOnlySecondaryExpiry) {
      this.setState({
        showToast: true,
        toastMessage: `${hasOnlySecondaryName ? 'Secondary location expiry date' : 'Secondary location name'} is mandatory to create secondary location.`,
      })
    } else {
      try {
        const responses = await Promise.all(locationPromises)
        const newVariants = _.cloneDeep(this.state.values.variants);
        responses.forEach(res => {
          const data = JSON.parse(res.config.data);
          newVariants[variantIndex].storeSpecificData[storeId] = data.locationType === 'primary' ?
            {
              ...newVariants[variantIndex].storeSpecificData[storeId],
              primaryLocationId: data.productSku
            } :
            {
              ...newVariants[variantIndex].storeSpecificData[storeId],
              secondaryLocationId: data.productSku
            }
        })
        const isSuccess = responses.some(res => res.status === 204)
        if (isSuccess) {
          // update current state with primary location data
          this.setState(prevState => ({
            values: {
              ...prevState.values,
              variants: newVariants
            }
          }))
          this.setState({
            showToast: true,
            toastMessage: `Location updated successfully for ${storeName}.`,
          })
        }

      } catch(e) {
        console.error(e);
        this.setState({
          apiError: e.message,
          showErrorDialog: true,
        })
      }
    }

  }

  saveStock(storeId, variantIndex) {
    const { values } = this.state
    const body = {
      organizationId: 2,
      storeId: storeId,
      inStoreStock:
        values.variants[variantIndex].storeSpecificData[storeId].inStoreStock,
    }
    this.callInventoryAPI(body, storeId, values.id, 'STOCK')
  }

  render() {
    const { SubmitButton, CancelButton } = this.buttons
    const { Form } = this.components
    // Allow user to create more variants if the product already had variants
    const supportVariants =
      (this.props.options &&
        this.props.options.variants &&
        this.props.method === 'add') ||
      (this.props.options &&
        this.props.options.variants &&
        this.props.value &&
        this.props.value.variants &&
        this.props.value.variants.length > 0)
    const booleanStock =
      this.props.options &&
      this.props.options.stockStrategy === STOCK_STRATEGIES.BOOLEAN
    // const hasMultipleStores = this.props.options && this.props.options.multipleStores
    const hasTags = this.props.options && this.props.options.tags
    const hasInstoreProcessing =
      this.props.options && this.props.options.inStoreProcessing
    const hasSoldByWeight =
      this.props.options && this.props.options.soldByWeight
    const preOrderSupport =
      this.props.options && this.props.options.preOrderSupport
    const bulkOrderSuppport =
      this.props.options && this.props.options.bulkOrderSuppport
    const namepattern = '[a-zA-Z0-9\\s]+([0-9]+\\s+(kg|g|gm))$'
    const variantpattern = '([a-zA-Z0-9]\\s*)*([0-9]+\\s+(kg|g|gm))$'
    const namePattern =
      this.state.values.soldByWeight && !this.state.values.variants
        ? namepattern
        : '.*'
    const variantPattern = this.state.values.soldByWeight
      ? variantpattern
      : '.*'
    const enabledSoldByWeight = this.state.values.soldByWeight
    let stores = this.state.stores && this.state.stores.slice()
    stores =
      stores &&
      stores.filter(store => store.hasPicking || store.hasSelfCheckout)
    const { tabs, selected } = this.state
    const productIds = [this.getState(['variants', 0, 'id'])]
    const hasEditPermission = Boolean(
      hasPermissions('catalogue', 'product', 'put')
    )
    const hasDeletePermission = Boolean(
      hasPermissions('catalogue', 'product', 'delete')
    )
    const isReadOnly = isCatalogueProductReadOnly()
    return (
      <div>
        {this.state.showToast && (
          <Toast
            content={this.state.toastMessage}
            onClose={() => {
              this.setState({
                showToast: false,
              })
            }}
            cancelMessage="Ok"
          />
        )}
        <Dialog
          show={this.state.deleteProductDialogShown}
          title={getMessage('product.form.delete.title')}
          information={getMessage('product.form.delete.message')}
          onOk={this.deleteProduct}
          close={this.hideProductDeleteConfirmation}
          closeText={getMessage('product.form.delete.cancelText')}
          okText={getMessage('product.form.delete.confirmText')}
        />
        <Dialog
          show={this.state.deleteVariantDialogShown}
          title={getMessage('product.form.variant.delete.title')}
          information={getMessage('product.form.variant.delete.message')}
          onOk={() => {
            this.removeVariant(this.state.selectedVariant)
          }}
          close={this.hideVariantDeleteConfirmation}
          closeText={getMessage('product.form.variant.delete.cancelText')}
          okText={getMessage('product.form.variant.delete.confirmText')}
        />
        <Dialog
          show={this.state.deleteAllVariantsDialogShown}
          title={getMessage('product.form.variant.delete.title')}
          information={getMessage('product.form.all.variants.delete.message')}
          onOk={this.removeAllVariant}
          close={this.hideAllVariantsDeleteConfirmation}
          closeText={getMessage('product.form.variant.delete.cancelText')}
          okText={getMessage('product.form.variant.delete.confirmText')}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={this.state.apiError}
          close={this.hideApiErrorDialog}
          closeText={getMessage('vehiclePlanner.error.dialog.okay')}
        />
        <Form className="product-form">
          <div className="basic-section form-row">
            <span className="product-details-header">
              {getMessage('product.form.details.header')}
            </span>
            <div className="flex-around">
              <Input
                className="product-name"
                label={getMessage('product.form.name.heading')}
                placeholder={getMessage('product.form.name.placeholder')}
                name="name"
                type="text"
                required
                pattern={namePattern}
                siblings={
                  this.props.options && this.props.options.brands
                    ? {
                        before: [
                          <BrandSearch
                            key="brand-name"
                            className="brand-name"
                            placeholder={getMessage(
                              'product.form.brand.placeholder'
                            )}
                            name="brand"
                            createButton
                            {...this.generateStateMappers({
                              stateKeys: ['brand'],
                              loseEmphasisOnFill: true,
                            })}
                          />,
                        ],
                      }
                    : null
                }
                {...this.generateStateMappers({
                  stateKeys: ['name'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage('input.requiredMessage'),
                  patternMismatch: getMessage(
                    'product.form.soldByWeight.pattern'
                  ),
                }}
              >
                {enabledSoldByWeight && !this.state.values.variants
                  ? getMessage('product.form.name.description')
                  : ''}
              </Input>
              <CategorySearch
                label={getMessage('product.form.category.heading')}
                placeholder={getMessage('product.form.category.placeholder')}
                name="category"
                required
                {...this.generateStateMappers({
                  stateKeys: ['primaryCategory'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    'product.form.category.requiredMessage'
                  ),
                }}
              />
              <CategorySearch
                label={getMessage('product.form.secondarycategory.heading')}
                placeholder={getMessage(
                  'product.form.secondarycategory.placeholder'
                )}
                name="secondary-categories"
                {...this.generateStateMappers({
                  stateKeys: ['secondaryCategories'],
                  loseEmphasisOnFill: true,
                })}
                multiple
              />
              {hasTags ? (
                <Searchable
                  label={getMessage('product.form.tag.heading')}
                  placeholder={getMessage('product.form.tag.placeholder')}
                  name="tag"
                  searchUrl="/catalogue-service/tag"
                  valueKey="id"
                  responseKey="tag"
                  nameKey="name"
                  searchKey="name"
                  expandMore={true}
                  transformResponse={response => response.data.tag}
                  createButton={getMessage('product.form.tag.addButton')}
                  multiple
                  {...this.generateStateMappers({
                    stateKeys: ['tags'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              ) : null}
              {preOrderSupport && (
                <Input
                  className="handling-days"
                  label={getMessage('product.form.handlingDays.label')}
                  placeholder={getMessage(
                    'product.form.handlingDays.placeholder'
                  )}
                  name="handling-days"
                  type="number"
                  min={0}
                  {...this.generateStateMappers({
                    stateKeys: ['handlingDays'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  validationStrings={{
                    valueMissing: getMessage('input.requiredMessage'),
                  }}
                />
              )}
              {bulkOrderSuppport && (
                <Input
                  className="bulk-threshold"
                  label={getMessage('product.form.bulkThreshold.label')}
                  placeholder={getMessage(
                    'product.form.bulkThreshold.placeholder'
                  )}
                  name="bulk-order-threshold"
                  type="number"
                  min={0}
                  {...this.generateStateMappers({
                    stateKeys: ['bulkOrderThreshold'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  validationStrings={{
                    valueMissing: getMessage('input.requiredMessage'),
                  }}
                />
              )}
              {hasSoldByWeight ? (
                <Checkbox
                  label={getMessage('')}
                  inlineLabel={getMessage('Sold By Weight')}
                  name={`sold-by-weight`}
                  {...this.generateStateMappers({
                    stateKeys: ['soldByWeight'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              ) : null}
            </div>
          </div>
          {tabs && (
            <div className="description-tabs">
              {Object.keys(tabs).map(key => {
                return (
                  <span
                    key={key}
                    className={selected === tabs[key] ? 'selected' : ''}
                    onClick={() => {
                      this.setState({ selected: tabs[key] })
                    }}
                  >
                    {tabs[key]}
                  </span>
                )
              })}
            </div>
          )}
          {tabs && selected === tabs.description && (
            <div className="flex-item">
              <Textarea
                label={''}
                placeholder={getMessage('product.form.description.placeholder')}
                className="product-description"
                name="description"
                {...this.generateStateMappers({
                  stateKeys: ['description'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              {this.props.method !== 'add' && (
                <Input
                  type="text"
                  name={`variant-sap-product-name`}
                  label={'SAP Product Name'}
                  readOnly
                  className="sap-product-name-field"
                  {...this.generateStateMappers({
                    stateKeys: ['metaData', 'SAP Product Name'],
                  })}
                />
              )}
            </div>
          )}
          {tabs && selected === tabs.otherDetails && (
            <MetaDataFormFields
              metaDataWithType={this.productMetaDataWithType}
              className="metadata-section"
              page="product-form"
              productMetadataMigrateEnabled = {this.props.options.productMetadataMigrateEnabled}
              _this={this}
            />
          )}
          <div className={supportVariants ? 'variant-section' : 'no-variants'}>
            <div className={supportVariants ? 'variant-details' : null}>
              {supportVariants && (
                <div className="toggle-section">
                  <span>{getMessage('product.form.variant.heading')}</span>
                  <Toggle
                    name="variant"
                    className="variant-toggle"
                    value={this.state.variantToggle}
                    onChange={this.onToggleChange}
                    icons={false}
                    disabled={isReadOnly}
                  />
                </div>
              )}
              {(this.getState(['variants']) || [{}]).map(
                (variant, variantIndex) => (
                  <div
                    key={`variant-${variant._key || variantIndex}`}
                    className={
                      variantIndex === this.state.selectedVariant
                        ? ''
                        : 'hidden'
                    }
                  >
                    {this.state.variantToggle && (
                      <div className="variant-add-section">
                        {supportVariants ? (
                          <Input
                            className="variant-name"
                            label={getMessage(
                              'product.form.variant.name.heading'
                            )}
                            placeholder={getMessage(
                              'product.form.variant.name.placeholder'
                            )}
                            name={`variant-${variantIndex}-name`}
                            type="text"
                            required
                            pattern={variantPattern}
                            suffix={
                              supportVariants ? (
                                <button
                                  type="button"
                                  className="add-variant-link"
                                  onClick={this.addVariant}
                                >
                                  <span>+</span>{' '}
                                  {getMessage(
                                    'product.form.variant.addButtonText'
                                  )}
                                </button>
                              ) : null
                            }
                            value={this.state.variantName}
                            readOnly={this.state.editVariant}
                            onChange={value =>
                              this.setState({ variantName: value })
                            }
                            onKeyPress={event => {
                              if (event.key === 'Enter') {
                                event.preventDefault()
                                this.addVariant()
                              }
                            }}
                            validationStrings={{
                              valueMissing: getMessage('input.requiredMessage'),
                            }}
                          >
                            {enabledSoldByWeight
                              ? getMessage('product.form.variant.pattern')
                              : ''}
                          </Input>
                        ) : null}
                        <div className="edit-variant-container">
                          {this.state.editVariant && (
                            <div className="edit-variant">
                              <div>
                                {Array.isArray(this.state.values.variants)
                                  ? this.state.values.variants.map(
                                      (variant, index) => {
                                        return (
                                          <Input
                                            key={variant.name + index}
                                            value={
                                              this.state.variants[index].name
                                            }
                                            onChange={value => {
                                              this.setState(prevState => {
                                                const newState = JSON.parse(
                                                  JSON.stringify(prevState)
                                                )
                                                newState.variants[
                                                  index
                                                ].name = value
                                                return newState
                                              })
                                            }}
                                            onKeyPress={event => {
                                              if (event.key === 'Enter') {
                                                event.preventDefault()
                                              }
                                            }}
                                          />
                                        )
                                      }
                                    )
                                  : null}
                              </div>
                              <button
                                type="button"
                                className="primary variant-save"
                                onClick={() => {
                                  this.setState(
                                    prevState => {
                                      const newState = Object.assign(
                                        {},
                                        prevState
                                      )
                                      newState.values.variants = this.state.variants
                                      return prevState
                                    },
                                    () =>
                                      this.setState({
                                        editVariant: !this.state.editVariant,
                                      })
                                  )
                                }}
                              >
                                {getMessage('product.form.variant.saveButton')}
                              </button>
                              <button
                                type="button"
                                className="button variant-cancel"
                                onClick={() => {
                                  this.setState({
                                    variants: this.getState(['variants']),
                                    editVariant: !this.state.editVariant,
                                  })
                                }}
                              >
                                {getMessage(
                                  'product.form.variant.cancelButton'
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                        {!this.state.editVariant && (
                          <div className="flex-around variants-tab">
                            {supportVariants &&
                            ((this.props.method === 'add' &&
                              (this.getState(['variants']) || []).length > 0) ||
                              (this.props.method === 'edit' &&
                                ((this.getState(['variants']) || []).length >
                                  1 ||
                                  ((this.getState(['variants']) || [])
                                    .length === 1 &&
                                    this.getState(['variants'])[0].name)))) ? (
                              <React.Fragment>
                                <Tabs
                                  items={(
                                    this.getState(['variants']) || []
                                  ).map((variant, index, arr) => (
                                    <span key={index}>
                                      {variant.name || '*'}
                                      {arr.length > 1 &&
                                      this.state.selectedVariant === index ? (
                                        <span
                                          alt="x"
                                          onClick={
                                            this.showVariantDeleteConfirmation
                                          }
                                          className="variant-delete-icon"
                                        >
                                          &times;
                                        </span>
                                      ) : null}
                                    </span>
                                  ))}
                                  active={this.state.selectedVariant}
                                  onClick={selectedVariant => {
                                    this.setState({ selectedVariant })
                                  }}
                                />
                                <button
                                  type="button"
                                  className="variant-edit"
                                  onClick={() => {
                                    this.setState({
                                      editVariant: !this.state.editVariant,
                                      variants: this.state.values.variants,
                                    })
                                  }}
                                >
                                  <img src={editIcon} alt="edit-variant" />
                                </button>
                              </React.Fragment>
                            ) : null}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="image-updload-section">
                      {(this.props.method === 'add' ||
                        this.getState([
                          'variants',
                          variantIndex,
                          'clientItemId',
                        ])) && (
                        <Input
                          type="text"
                          name={`variant-client-item.id-${variantIndex}`}
                          label={getMessage('product.form.clientItemId')}
                          placeholder={getMessage(
                            'product.form.clientItemId.placeholder'
                          )}
                          readOnly={this.props.method !== 'add'}
                          className="client-item-id-field"
                          {...this.generateStateMappers({
                            stateKeys: [
                              'variants',
                              variantIndex,
                              'clientItemId',
                            ],
                            loseEmphasisOnFill: true,
                          })}
                        />
                      )}
                      <MultiTextInput
                        label={getMessage(
                          'product.form.variant.barcodes.heading'
                        )}
                        placeholder={getMessage(
                          'product.form.variant.barcodes.heading'
                        )}
                        name="barcodes"
                        className="barcodes-field"
                        {...this.generateStateMappers({
                          stateKeys: ['variants', variantIndex, 'barcodes'],
                          validationType: VALIDATION_TYPES.ONSUBMIT,
                          loseEmphasisOnFill: true,
                        })}
                        readOnly
                      />
                      {this.props.method !== 'add' &&
                        !!this.getState([
                          'variants',
                          variantIndex,
                          'images',
                        ]) && (
                          <ImageUpload
                            className="variant-image"
                            label={getMessage(
                              'product.form.variant.image.heading'
                            )}
                            placeholder={getMessage(
                              'product.form.variant.image.placeholder'
                            )}
                            name={`variant-${variantIndex}-image`}
                            multiple
                            isProductCreatePage
                            {...this.generateStateMappers({
                              stateKeys: ['variants', variantIndex, 'images'],
                              validationType: VALIDATION_TYPES.ONSUBMIT,
                            })}
                            validationStrings={{
                              valueMissing: getMessage('input.requiredMessage'),
                              isUploading: getMessage('input.isUploading'),
                            }}
                          >
                            {getMessage(
                              'product.form.variant.image.description'
                            )}
                          </ImageUpload>
                        )}
                    </div>
                    {!isReadOnly && <div className="form-actions">
                      <When
                        condition={hasDeletePermission && this.state.values.id}
                      >
                        <a
                          className="product-delete-button"
                          onClick={this.showProductDeleteConfirmation}
                        >
                          <img
                            src={deleteIcon}
                            alt="x"
                            className="product-delete-icon"
                          />
                          <span className="product-delete-button-text">
                            {getMessage('product.form.deleteText')}
                          </span>
                        </a>
                      </When>
                      <When condition={hasEditPermission}>
                        <div className="form-buttons">
                          <SubmitButton
                            disabled={
                              this.state.submitting || this.state.loading
                            }
                          >
                            {getMessage('product.form.submitText')}
                          </SubmitButton>
                          <CancelButton>
                            {getMessage('product.form.cancelText')}
                          </CancelButton>
                        </div>
                      </When>
                    </div>}
                    {(this.state.stores || []).length > 0 && (
                      <div className="store-related-details">
                        <h3 className="product-details-header">
                          Store Specific Details
                        </h3>
                        <StoreGroup
                          getInventoryData={this.fetchMultipleInventoryData}
                          stores={stores}
                          defaultStoreId={defaultStoreId}
                          getStoreDetailsEl={filteredStores =>
                            this.getStoreRows(
                              filteredStores,
                              variantIndex,
                              booleanStock,
                              hasInstoreProcessing,
                              this.updateStoreStatus.bind(this, productIds),
                              this.saveLocation,
                              this.saveStock
                            )
                          }
                        />
                        {this.state.loading ? <Loader size="sm" /> : null}
                        {this.state.failedLoadingStores ? (
                          <div
                            className="stores-error-text"
                            onClick={this.getStores}
                          >
                            {getMessage('product.form.stores.fetchError')}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export const STOCK_STRATEGIES = {
  BOOLEAN: 'boolean',
  NUMERIC: 'numeric',
}