import React, { useEffect, useState } from 'react'
import API from 'lib/api'
import { withRouter } from 'react-router-dom'
import AddNewConfig from './AddNewConfig'
import OfferSequence from './OfferSequence'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import { CAMPAIGN_LABELS_API } from 'config/app'
import InfoBanner from './OfferSequenceLabel/InfoBanner'
import InfoModal from './OfferSequenceLabel/InfoModal'
import ConfigSetupList from './ConfigSetupList/index'

const Loader = () => {
  const template = (index) => (
    <div key={index} className="wrapper">
      <div className="card">
        <div className="card__img skeleton"></div>
        <div className="card__body">
          <div className="card__text skeleton"></div>
          <div className="card__text skeleton"></div>
          <div className="card__text skeleton"></div>
          <div className="card__text skeleton"></div>
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {[1, 2, 3, 4, 5].map((_, index) => {
        return template(index)
      })}
    </div>
  )
}

const CampaignAction = (props) => {
  const { router = {} } = props
  const { action, type, id } = router?.match?.params || {}
  const [deleteId, setDeleteId] = useState([])
  const [isDeleteButtonPressed, setIsDeleteButtonPressed] = useState(false)
  const [modalInfo, setModalInfo] = useState({})
  const [editData, setEditData] = useState()
  const [loading, setloading] = useState(false)
  const [message, setMessage] = useState({})
  const OFFER_LABEL_SEQUENCE = 'offer-label-sequence'
  const selectedType =
    type === OFFER_LABEL_SEQUENCE ? OFFER_LABEL_SEQUENCE : 'campaign-category'
  const callAPI = async () => {
    if (id) {
      setloading(true)
      try {
        const api = new API({
          url: `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedType}/${id}`,
        })
        const response = await api.get()
        setloading(false)
        const configurations = response.data
        setEditData(configurations)
      } catch (error) {
        /* istanbul ignore next */
        setMessage({ message: error.message, status: 'error' })
        /* istanbul ignore next */
        setloading(false)
      }
    }
  }
  useEffect(() => {
    callAPI()
  }, [id])

  const resetAPI = async () => {
    setEditData(null)
  }
  useEffect(() => {
    resetAPI()
  }, [type, id])

  const onSave = async (data, itemId) => {
    //in case of create we will not have id but in case of edit we will have
    const method = itemId ? 'put' : 'post'
    const url = itemId
      ? `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedType}/${itemId}`
      : `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedType}`
    try {
      setloading(true)
      const api = new API({
        url: url,
      })

      const response = await api[method](data)
      setMessage({
        message: method === 'post' ? 'created successfully' : response.data,
        status: 'success',
      })
      callAPI()
    } catch (error) {
      /* istanbul ignore next */
      setMessage({ message: error.message, status: 'error' })
    } finally {
      setloading(false)
      onCancel()
    }
  }

  const onCancel = () => {
    window.history.back()
  }
  const onDelete = (itemId) => {
    setDeleteId(itemId)
    setModalInfo({
      body: 'Are you sure you want to delete the config?',
      title: 'Delete Config',
      confirmBtnText: 'Delete',
      cancelBtnText: 'Cancel',
      onConfirm: onDeleteConfirm,
    })
    setIsDeleteButtonPressed(true)
  }

  const onDeleteConfirm = async () => {
    /* istanbul ignore next */
    if (deleteId) {
      try {
        //setloading(true)
        setIsDeleteButtonPressed(false)
        const api = new API({
          url: `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedType}/${deleteId}`,
        })
        const response = await api.delete()
        setMessage({ message: response.data, status: 'success' })
      } catch (error) {
        /* istanbul ignore next */
        setMessage({ message: error.message, status: 'error' })
      } finally {
        props.history.push(`/catalogue/configurations/${type}`)
      }
    }
  }

  const hideModal = () => {
    setModalInfo({})
    setIsDeleteButtonPressed(false)
  }
  let Component = null
  if (loading) {
    Component = Loader
  } else {
    if (!action) {
      Component = ConfigSetupList
    } else {
      if (action === 'add' || action === 'edit') {
        Component = AddNewConfig
      } else {
        Component = OfferSequence
      }
    }
  }
  return (
    <AuthenticatedPage
      menu={props.menu}
      from={props.location && props.location.pathname}
    >
      <InfoModal
        show={isDeleteButtonPressed}
        close={hideModal}
        modalInfo={modalInfo}
        onConfirm={onDeleteConfirm}
      />
      <InfoBanner message={message} />
      <Component
        type={type || OFFER_LABEL_SEQUENCE}
        editData={editData}
        onDelete={onDelete}
        onSave={onSave}
        onCancel={onCancel}
        selectedOption={selectedType}
      />
    </AuthenticatedPage>
  )
}

export default withRouter(({ match, ...props }) => (
  <CampaignAction router={{ match }} {...props} />
))
