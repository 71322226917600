import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'
import { Dialog } from 'components/Popup'
import API from 'lib/api'
import moment from 'moment'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  DateTime,
  Toggle,
} from 'components/Form'
import Loader from 'components/Loader'
import { getSession } from 'lib/auth'
import RuleInputs from './RuleInputs'
import {
  getRulesWithInvalidRewards,
  constructRules,
  convertStringToArray,
  removeIds,
} from '../utils'
import { ReactComponent as CopyIcon } from 'icons/copy-duplicate.svg'
import './style.scss'

const serverErrorMsg = getMessage('offer.campaigns.form.error')

class ScanCampaignForm extends BaseForm {
  constructor(props) {
    super(props)
    this.addCampaignRule = this.addCampaignRule.bind(this)
    this.handleToggleStatus = this.handleToggleStatus.bind(this)
    this.addCampaignRuleReward = this.addCampaignRuleReward.bind(this)
    this.isRewardsInvalid = this.isRewardsInvalid.bind(this)
    this.removeRule = this.removeRule.bind(this)
    this.removeReward = this.removeReward.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.editCurrentCampaign = this.editCurrentCampaign.bind(this)
    this.fetchCampaignData = this.fetchCampaignData.bind(this)
    this.resetValidityPolicy = this.resetValidityPolicy.bind(this)
    this.state = {
      errorDialog: null,
      dataLoaded: false,
      existingCampaignId: this.props.router.match.params?.id,
      confirmDuplicatePopup: false,
      existingNothingRewards: null,
    }
  }

  componentDidMount() {
    this.fetchCampaignData({
      campaignId: this.state.existingCampaignId,
    })
  }

  async fetchCampaignData({ campaignId, isCloneCamapaign = false }) {
    if (campaignId) {
      try {
        const api = new API({
          url: `/scan-campaign-service/campaigns/${campaignId}`,
        })
        const res = await api.get()

        // sort rules by weight value
        res.data.rules.sort((a, b) => b.weight - a.weight)

        const nothingRewardsByRule = {}
        const rules = await Promise.all(
          res.data.rules.map(async (rule, ruleIndex) => {
            const ruleObj = { ...rule }
            const rewards = rule.rewards.map((reward, rewardIndex) => {
              const duraionInHours = reward?.validityPolicy?.durationInHours ?
                reward?.validityPolicy?.durationInHours / 24 : 0;
              return {
                ...reward,
                validityPolicy: {
                  ...reward?.validityPolicy || {},
                  durationInHours: duraionInHours,
                },
                rewardType: reward.type /* rewardType */,
                percentage: reward.percentage / 100 /* percentage */,
                uid: rewardIndex + 1,
              }
            })

            const rewardsWithoutNothing = rewards.filter(
              (reward) => reward.rewardType !== 4
            )
            const nothingReward = rewards.find(
              (reward) => reward.rewardType === 4
            ) // rewardType 4 is NOTHING
            if (nothingReward) {
              nothingRewardsByRule[rule.id] = {
                ...nothingReward,
                ruleId: rule.id, // add the ruleId in this object
              }
            }

            /* segmentId */
            if (rule.segmentId) {
              // Perform API call if segmentId exists. This check will not run for segmentId 0
              try {
                const customerSegmentationServiceApi = new API({
                  url: `/segments`,
                })
                const segmentResponse =
                  await customerSegmentationServiceApi.get({
                    id: rule.segmentId,
                  })
                ruleObj.segmentId = segmentResponse.data[0] // Assign the fetched segmentId directly
              } catch (segmentError) {
                console.error(segmentError)
              }
            }

            /* segmentType */
            if (rule.segmentId !== null) {
              ruleObj.segmentType =
                rule.segmentId === 0 || rule.segmentId.id === 0
                  ? 'NEW_APP_SIGN_UP'
                  : 'SEGMENT_CUSTOMER'
            }

            /* sequences */
            const sequences =
              rule?.policy?.sequences?.length > 0
                ? rule.policy.sequences.join(',')
                : null

            return {
              ...ruleObj,
              uid: ruleIndex + 1,
              rewards: rewardsWithoutNothing,
              isEnabled: rule.weight > 0,
              policy: {
                ...(rule.policy || {}),
                sequences,
              },
            }
          })
        )

        const validFrom = moment(res.data.validFrom).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        const validTill = moment(res.data.validTill).format(
          'YYYY-MM-DD HH:mm:ss'
        )

        if (isCloneCamapaign) {
          const newCampaignWithData = removeIds({
            ...res.data,
            name: `Copy of ${res.data.name}`,
            status: res.data.status === 'ENABLED',
            validFrom: validFrom,
            validTill: validTill,
            rules,
          })
          this.setState({
            values: newCampaignWithData,
            existingNothingRewards: null,
            existingCampaignId: null,
            dataLoaded: true,
          })
        } else {
          this.setState({
            values: {
              ...res.data,
              status: res.data.status === 'ENABLED',
              validFrom: validFrom,
              validTill: validTill,
              rules,
            },
            existingNothingRewards: nothingRewardsByRule,
            dataLoaded: true,
          })
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      const campaignRule = {
        uid: 1,
        type: null,
        weight: 1,
        policy: null,
        rewards: [
          {
            uid: 1,
            rewardType: 1,
            rewardValue: '',
            percentage: 100,
            validityPolicy: {
              type: 'SAME_DAY'
            }
          },
        ],
      }
      this.setState((prev) => ({
        values: {
          ...prev.values,
          status: true,
          rules: [campaignRule],
        },
        dataLoaded: true,
      }))
    }
  }

  getSegmentIdFromAPI(rule) {
    const customerSegmentationServiceApi = new API({ url: `/segments` })
    return customerSegmentationServiceApi.get({ id: rule.segmentId })
  }

  addCampaignRule(e) {
    e.preventDefault()
    const rules = [...this.state.values.rules]
    rules.push({
      uid: rules.length + 1,
      type: null,
      policy: null,
      rewards: [
        {
          uid: 1,
          rewardType: 1,
          rewardValue: '',
          percentage: 100,
          validityPolicy: {}
        },
      ],
    })

    this.setState((prev) => ({
      values: {
        ...prev.values,
        rules: rules,
      },
    }))
  }

  addCampaignRuleReward(ruleIndex, e) {
    e.preventDefault()
    this.setState((prevState) => {
      const newValues = { ...prevState.values }
      newValues.rules = [...prevState.values.rules] // Create a copy of the rules
      newValues.rules[ruleIndex] = { ...newValues.rules[ruleIndex] } // Create a copy of the specific rule object
      newValues.rules[ruleIndex].rewards = [
        ...newValues.rules[ruleIndex].rewards,
        {
          uid:
            newValues.rules[ruleIndex].rewards[
              newValues.rules[ruleIndex].rewards.length - 1
            ].uid + 1,
          rewardType: 1,
          rewardValue: '',
          percentage: 100,
          validityPolicy: {
            type: 'SAME_DAY'
          }
        },
      ]
      return { values: newValues }
    })
  }

  removeRule(index) {
    this.setState((prev) => ({
      values: {
        ...prev.values,
        rules: prev.values.rules.filter((_, i) => i !== index),
      },
    }))
  }

  removeReward(ruleIndex, rewardIndex) {
    this.setState((prevState) => {
      const newValues = { ...prevState.values }
      newValues.rules[ruleIndex].rewards = prevState.values.rules[
        ruleIndex
      ].rewards.filter((_, i) => i !== rewardIndex)
      return { values: newValues }
    })
  }

  isRewardsInvalid(rules) {
    const rulesWithInvalidRewards = getRulesWithInvalidRewards(rules)

    if (rulesWithInvalidRewards.length > 0) {
      const ruleNumbers = rulesWithInvalidRewards
        .map((num) => `#${num}`)
        .join(', ')
      this.setState({
        errorDialog: `Rule ${ruleNumbers} rewards are invalid.
        Ensure the sum of all percentage values do not exceed 100%.`,
        confirmDuplicatePopup: false,
      })
      return true
    }
    return false
  }

  moveRule() {
    return {
      top: (index) => {
        if (index > 0) {
          // Only move if not already at the top
          this.setState((prevState) => {
            const newRules = [...prevState.values.rules]
            const movedRule = newRules.splice(index, 1)[0] // Remove the rule
            newRules.unshift(movedRule) // Add it to the beginning
            return {
              values: {
                ...prevState.values,
                rules: newRules,
              },
            }
          })
        }
      },
      up: (index) => {
        if (index > 0) {
          this.setState((prevState) => {
            const newRules = [...prevState.values.rules]
            ;[newRules[index - 1], newRules[index]] = [
              newRules[index],
              newRules[index - 1],
            ]
            return {
              values: {
                ...prevState.values,
                rules: newRules,
              },
            }
          })
        }
      },
      down: (index) => {
        if (index < this.state.values.rules.length - 1) {
          this.setState((prevState) => {
            const newRules = [...prevState.values.rules]
            ;[newRules[index + 1], newRules[index]] = [
              newRules[index],
              newRules[index + 1],
            ]
            return {
              values: {
                ...prevState.values,
                rules: newRules,
              },
            }
          })
        }
      },
      bottom: (index) => {
        if (index < this.state.values.rules.length - 1) {
          // Only move if not already at the bottom
          this.setState((prevState) => {
            const newRules = [...prevState.values.rules]
            const movedRule = newRules.splice(index, 1)[0] // Remove the rule
            newRules.push(movedRule) // Add it to the end
            return {
              values: {
                ...prevState.values,
                rules: newRules,
              },
            }
          })
        }
      },
    }
  }

  submitForm({ formData, isDuplicate = false }) {
    if (!this.isRewardsInvalid(formData.rules)) {
      const user = getSession().user
      const validFrom = new Date(formData.validFrom)
      const validTill = new Date(formData.validTill)

      // set the weight value of the rules before Submit
      const rulesWithWeights = formData.rules.map((rule, index) => ({
        ...rule,
        weight:
          !('id' in rule) || ('isEnabled' in rule && rule.isEnabled)
            ? formData.rules.length - index
            : 0,
      }))
      const data = Object.assign(
        {},
        {
          ...formData,
          rules: constructRules(
            rulesWithWeights,
            this.state.existingNothingRewards
          ),
          owners: [user.emails?.length > 0 ? user.emails[0].email : null],
          storeIds: convertStringToArray(formData?.storeIds || []),
          validFrom: validFrom.toISOString().split('.')[0] + 'Z',
          validTill: validTill.toISOString().split('.')[0] + 'Z',
          status: formData?.status ? 'ENABLED' : 'DISABLED',
        }
      )

      if (this.state.existingCampaignId && !isDuplicate) {
        const api = new API({
          url: `/scan-campaign-service/campaigns/${this.state.existingCampaignId}`,
        })
        api
          .put(data)
          .then((res) => {
            if (res) {
              this.props.history.push(`/marketing/scan-campaigns`)
            }
          })
          .catch((err) => {
            this.setState({ errorDialog: err?.message || serverErrorMsg })
          })
      } else {
        const api = new API({ url: '/scan-campaign-service/campaigns' })
        api
          .post({
            ...data,
            status: isDuplicate ? 'DISABLED' : data.status,
          })
          .then((res) => {
            if (res) {
              this.props.history.push(`/marketing/scan-campaigns`)
            }
          })
          .catch((err) => {
            this.setState({ errorDialog: err?.message || serverErrorMsg })
            if (isDuplicate) {
              this.setState({ confirmDuplicatePopup: false })
            }
          })
      }
    }
  }

  onSubmit(formData) {
    this.submitForm({ formData })
  }

  getCampaignStatus(isExpiredCampaign) {
    if (isExpiredCampaign) {
      return 'EXPIRED'
    }
    return this.state.values?.status
  }

  handleToggleStatus() {
    this.setState((prev) => ({
      values: {
        ...prev.values,
        status: !prev.values.status,
      },
    }))
  }

  editCurrentCampaign() {
    this.props.history.push(`/marketing/scan-campaigns/new`)
    this.setState({
      confirmDuplicatePopup: false,
    })
    this.fetchCampaignData({
      campaignId: this.state.existingCampaignId,
      isCloneCamapaign: true,
    })
  }

  resetValidityPolicy(selectedExpirationType, ruleIndex, rewardIndex) {
    // reset durationInHours and cutOffTime if type is SAME_AS_OFFER
    if (selectedExpirationType === 'SAME_AS_OFFER') {
      this.setState((prevState) => {
        const newRules = [...prevState.values.rules]
        newRules[ruleIndex].rewards[rewardIndex].validityPolicy = {
          ...newRules[ruleIndex].rewards[rewardIndex].validityPolicy,
          durationInHours: 0,
          cutOffTime: "0",
        }
        return {
          values: {
            ...prevState.values,
            rules: newRules,
          },
        }
      })
    }
    // reset durationInHours if type is SAME_DAY
    if (selectedExpirationType === 'SAME_DAY') {
      this.setState((prevState) => {
        const newRules = [...prevState.values.rules]
        newRules[ruleIndex].rewards[rewardIndex].validityPolicy = {
          ...newRules[ruleIndex].rewards[rewardIndex].validityPolicy,
          durationInHours: 0,
        }
        return {
          values: {
            ...prevState.values,
            rules: newRules,
          },
        }
      })
    }
  }

  render() {
    const { menu } = this.props
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const existingCampaignId = this.state.values?.id
    const statusText = this.state.values?.status ? 'ENABLED' : 'DISABLED'

    if (!this.state.dataLoaded) {
      return <Loader />
    }

    return (
      <AuthenticatedPage className="scan-campaign-form-page" menu={menu}>
        <div className="flex-around">
          <div className="left-heading-container">
            <h1 className="title heading">
              <strong>
                {existingCampaignId
                  ? `Campaign #${existingCampaignId}`
                  : getMessage('offer.scanCampaigns.add.new')}
              </strong>
            </h1>
            {existingCampaignId && (
              <button
                className="copy-campaign-btn button secondary"
                data-testid="copy-campaign-btn"
                onClick={() => this.setState({ confirmDuplicatePopup: true })}
              >
                <CopyIcon height={18} width={18} />
                &nbsp;Copy Campaign
              </button>
            )}
          </div>
          <div className="offer-campaign-status">
            <span className={`status-text ${statusText.toLowerCase()}`}>
              {statusText}
            </span>
            <Toggle
              name="scanCampaignStatus"
              icons={false}
              {...this.generateStateMappers({
                stateKeys: ['status'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          </div>
        </div>
        <Form className="scan-campaign-form">
          {existingCampaignId && (
            <div className="campaign-uid">
              Campaign UID: {this.state.values.uid}
            </div>
          )}
          <div className="inputs-row-container">
            <Input
              type="text"
              placeholder={getMessage(
                'offer.scanCampaigns.form.campaignName.placeholder'
              )}
              name="scanCampaignName"
              className="campaign-name-input"
              label={getMessage('offer.scanCampaigns.form.campaignName')}
              required
              {...this.generateStateMappers({
                stateKeys: ['name'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
            <Input
              type="text"
              placeholder="Enter Store IDs (e.g. 1,2,4)"
              name="scanCampaignStoreIds"
              className="store-ids-input"
              label="Store IDs (comma separated)"
              {...this.generateStateMappers({
                stateKeys: ['storeIds'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
          </div>
          <div className="datetime-picker-container">
            <DateTime
              name="startDateTime"
              label={getMessage('offer.campaigns.form.startDateTime')}
              allowAllDates
              required
              useTimePicker
              {...this.generateStateMappers({
                stateKeys: ['validFrom'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
            <div> - to - </div>
            <DateTime
              name="endDateTime"
              label={getMessage('offer.campaigns.form.endDateTime')}
              allowAllDates
              required
              useTimePicker
              {...this.generateStateMappers({
                stateKeys: ['validTill'],
                loseEmphasisOnFill: true,
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
            />
          </div>

          <RuleInputs
            generateStateMappers={this.generateStateMappers}
            formValues={this.state.values}
            resetValidityPolicy={this.resetValidityPolicy}
            addCampaignRuleReward={this.addCampaignRuleReward}
            removeRule={this.removeRule}
            removeReward={this.removeReward}
            addCampaignRule={this.addCampaignRule}
            moveRule={this.moveRule()}
          />

          <div className="form-buttons">
            <SubmitButton>{getMessage('offer.submit')}</SubmitButton>
            <Link to="/marketing/scan-campaigns">
              <button className="secondary button">
                {getMessage('offer.cancel')}
              </button>
            </Link>
          </div>
        </Form>

        {this.state.errorDialog && (
          <Dialog
            className="error-dialog"
            show={!!this.state.errorDialog}
            title="Error"
            information={this.state.errorDialog}
            close={() => this.setState({ errorDialog: null })}
            closeText={getMessage('dialog.okText')}
          />
        )}

        {this.state.confirmDuplicatePopup && (
          <Dialog
            className="confirm-dialog"
            show={this.state.confirmDuplicatePopup}
            title="Are you sure?"
            information="This will create a new campaign."
            close={() => this.setState({ confirmDuplicatePopup: false })}
            closeText="Cancel"
            okText="Make a copy"
            onOk={this.editCurrentCampaign}
          />
        )}
      </AuthenticatedPage>
    )
  }
}

export default withRouter(({ match, ...props }) => (
  <ScanCampaignForm router={{ match }} {...props} />
))
