import React from 'react'
import { Link } from 'react-router-dom'
import { Cell, Row } from 'components/Table'
import { formatIsoDate } from 'lib/datetime'
import { getMessage } from 'lib/translator'
import { returnFunction } from 'pages/customer-support/EGiftingOrders/List/tableProperties'
import { padCode } from './constants'

export const LinkButtons = () => (
  <div className="header-wrap">
    <Link
      to="/gift-card/design-codes/add"
      data-testid="add-design-code"
      className="primary button add-action"
    >
      {' '}
      <span className="text">
        {getMessage('gift.card.design.codes.add.text')}
      </span>
    </Link>
  </div>
)

export const tableProperties = () => {
  return {
    headers: [
      'Design Code ID',
      'Denomination Type',
      'Denomination',
      'SKU',
      'Balance QTY',
      'Reserved QTY',
      'Created By',
      'Created At',
    ],
    row: ({
      id,
      denominationType,
      denomination,
      sku,
      balanceQty,
      reservedQty,
      createdBy,
      createTime,
    }) => (
      <Row>
        <Cell className="rewards-page-actions column-batch-id">
          {padCode(id)}
        </Cell>
        <Cell className="rewards-page-actions column-entity-name">
          {denominationType === 'FLEXI'
            ? getMessage('order.personal')
            : getMessage('offer.ordertype.options.b2b')}
        </Cell>
        <Cell className="rewards-page-actions column-total-amount">
          {denomination ? `$${denomination?.toLocaleString()}` : '-'}
        </Cell>
        <Cell className="rewards-page-actions column-total-voucher-count">
          {denomination ? `${sku}` : '-'}
        </Cell>
        <Cell className="rewards-page-actions column-status">{balanceQty}</Cell>
        <Cell className="rewards-page-actions column-creation-time">
          {reservedQty}
        </Cell>
        <Cell className="rewards-page-actions column-creation-time">
          {createdBy?.email}
        </Cell>
        <Cell className="rewards-page-actions column-creation-time">
          {formatIsoDate(createTime)}
        </Cell>
      </Row>
    ),
  }
}

export const transform = (response) => {
  const transactions = response.data.records || []

  returnFunction(response)

  return transactions
}
