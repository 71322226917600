import React, { useContext } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
  Select,
} from '../../../components/Form'
import Table, { Header } from '../../../components/Table'
import CustomerTableView from '../../operations/Customers/Tables'
import DeliveryOrdersTable from '../../operations/DeliveryOrders/Table'
import Loader from '../../../components/Loader'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import { LINKPASS_ADMIN_URL, LINK_HOST } from 'config/app'
import { SplitContext } from '../../../containers/SplitContext'
import SPLIT_FEATURES from '../../../containers/SplitContext/features'
import './style.css'

const SearchForLinkpassByAssociation = ({
  associateName,
  generateStateMappers,
}) => {
  const dropDownOptions = [
    'auth0',
    'fairprice',
    'fairprice_business',
    'linkplus',
    'jwc',
  ]

  return (
    <>
      <Select
        label={getMessage('customer.enterAssocName')}
        placeholder={getMessage('customer.searchByAssocName')}
        name="associateName"
        options={dropDownOptions}
        {...generateStateMappers({
          stateKeys: ['associateName'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
      />
      <Input
        label={getMessage('customer.enterAssocValue')}
        placeholder={getMessage('customer.searchByAssocValue')}
        name="associateValue"
        type="text"
        {...generateStateMappers({
          stateKeys: ['associateValue'],
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
        disabled={!associateName || associateName.length === 0}
      />
    </>
  )
}

class CsDashboardClass extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      orderNumber: null,
      orderSearch: false,
      linkpassProfile: {},
      showSearchByUidInput:
        this.props.splits?.[SPLIT_FEATURES.LOYALTY_LINKPASS_FEATURES]
          ?.treatment === 'on',
      useLinkpassAdminServiceApi:
        this.props.splits?.[SPLIT_FEATURES.LOYALTY_ADMIN_SERVICE_TOGGLE]
          ?.treatment === 'on',
      permissionError: '',
      showBackOfficePhase3Feat:
        this.props.splits?.[SPLIT_FEATURES.DYNAMICS_BACKOFFICE_REVAMP_PHASE3]
          ?.treatment === 'on',
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.parseCustObjFromLinkpass = this.parseCustObjFromLinkpass.bind(this)
    this.searchUserByUid = this.searchUserByUid.bind(this)
    this.handleSingleSearchFocus = this.handleSingleSearchFocus.bind(this)
    this.apiErrorCallback = this.apiErrorCallback.bind(this)
    this.searchByAssociation = this.searchByAssociation.bind(this)
    this.searchByJwcCardNumber = this.searchByJwcCardNumber.bind(this)
  }

  apiErrorCallback(err) {
    this.setState({
      customer: [],
      submitting: false,
      ...(err.code === 403 && {
        permissionError: err.message,
      }),
    })
  }

  handleSingleSearchFocus() {
    this.setState({ values: { associateName: '', associateValue: '' } })
  }

  parseCustObjFromLinkpass(linkpassObj) {
    const joinedTime = `${linkpassObj.created_at.split('T')[0]} ${linkpassObj.created_at.split('T')[1].slice(0, -1)}`
    const updatedAt = `${linkpassObj.updated_at.split('T')[0]} ${linkpassObj.updated_at.split('T')[1].slice(0, -1)}`
    return [
      {
        clientId: 0,
        email: linkpassObj.email,
        id: linkpassObj.association.fairprice,
        image: '',
        joinedOn: linkpassObj.created_at.split('T')[0],
        joinedTime,
        name: linkpassObj.name,
        phone: linkpassObj.phone_number,
        status: 'ENABLED',
        uid: linkpassObj.uid,
        updatedAt,
      },
    ]
  }

  searchUserByUid(uid) {
    const linkpassAdminApi = new API({
      url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/user/uid/${uid}`,
    })
    linkpassAdminApi
      .get()
      .then((res) => {
        this.setState({
          customer: this.parseCustObjFromLinkpass(res),
          orderSearch: false,
          orderNumber: null,
          submitting: false,
          linkpassProfile: res,
          permissionError: '',
        })
      })
      .catch((err) => {
        this.apiErrorCallback(err)
      })
  }

  searchByJwcCardNumber() {
    const searchLinkpassByJwcCardApi = new API({
      url: `${LINK_HOST}/laksa/memberships/just_wine_club/${this.state.values?.associateValue}`,
    })
    searchLinkpassByJwcCardApi
      .get()
      .then((res) => {
        this.searchUserByUid(res.uid)
      })
      .catch((err) => {
        this.apiErrorCallback(err)
      })
  }

  searchByAssociation() {
    const searchByAssociation = new API({
      url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/user/assoc/${this.state.values?.associateName}/${this.state.values?.associateValue}`,
    })
    searchByAssociation
      .get()
      .then((res) => {
        this.setState({
          customer: this.parseCustObjFromLinkpass(res),
          orderSearch: false,
          orderNumber: null,
          linkpassProfile: res,
          submitting: false,
          permissionError: '',
        })
      })
      .catch((err) => {
        this.apiErrorCallback(err)
      })
  }

  onSubmit() {
    // Check if the form is empty
    let emptyForm =
      this.state.values &&
      Object.values(this.state.values).filter((value) => Boolean(value))
    emptyForm = emptyForm ? emptyForm.length === 0 : true

    const getValueKeys =
      (this.state?.values && Object.keys(this.state?.values)) || []
    const getValueLength = getValueKeys.map((key) => {
      return this.state?.values[key]?.length
    })
    const isSearchByAssociate =
      this.state.values?.associateName?.length > 0 &&
      this.state.values?.associateValue?.length > 0
    let numberOfFilledFields = 0
    getValueLength.forEach((val) => {
      if (val > 0) {
        numberOfFilledFields += 1
      }
    })

    if (
      (numberOfFilledFields > 1 && !isSearchByAssociate) ||
      numberOfFilledFields >= 3
    ) {
      this.setState({
        customer: null,
        orderSearch: false,
        orderNumber: null,
        permissionError: '',
        onlyOneFieldError: true,
      })
      return
    }

    if (!emptyForm) {
      this.setState({ submitting: true, onlyOneFieldError: false })
      const orderSearch = Boolean(this.state.values.referenceNumber)
      if (orderSearch) {
        this.setState({
          orderSearch: true,
          orderNumber: this.state.values.referenceNumber,
          customer: null,
          submitting: false,
          permissionError: '',
        })
        return
      }
      const api = new API({
        url: '/ef-customer-core/customers',
      })
      const params = {
        email: this.state.values.email,
        phone: this.state.values.phone,
      }

      // parse object from linkpass profile to customer profile
      if (this.state?.values?.uid) {
        const uid = this.state.values.uid
        this.searchUserByUid(uid)
      } else if (
        this.state.values?.email?.length > 0 &&
        this.state.useLinkpassAdminServiceApi
      ) {
        const linkpassAdminApiEmail = new API({
          url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/user/search/email`,
        })
        linkpassAdminApiEmail
          .post({ email: this.state.values?.email })
          .then((res) => {
            this.setState({
              customer: this.parseCustObjFromLinkpass(res),
              orderSearch: false,
              orderNumber: null,
              submitting: false,
              linkpassProfile: res,
              permissionError: '',
            })
          })
          .catch((err) => {
            this.apiErrorCallback(err)
          })
      } else if (this.state.values?.associateName?.length > 0) {
        if (this.state.values?.associateName === 'jwc') {
          this.searchByJwcCardNumber()
        } else {
          this.searchByAssociation()
        }
      } else {
        api.get(params).then((response) => {
          if (response.data) {
            const customers = response.data.customers
            if (customers) {
              this.setState({
                customer: customers,
                orderSearch: false,
                orderNumber: null,
                submitting: false,
                linkpassProfile: {},
                permissionError: '',
              })
            }
          }
        })
      }
    }
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const customerSupport = 'customer-support'
    const CustomerRow = CustomerTableView(customerSupport).row
    const componentProps = { ...this.props }
    componentProps.className = 'cx-search-results'
    componentProps.url = '/customer-support'
    componentProps.emptyState = () => {
      return {
        message: 'No Orders Matched',
      }
    }

    delete componentProps.menu
    if (this.state.orderSearch) {
      componentProps.params = { referenceNumber: this.state.orderNumber }
      componentProps.updateApiParams = (prevParams, newParams) => {
        if (prevParams.referenceNumber !== newParams.referenceNumber) {
          return {
            shouldUpdate: true,
            params: {
              referenceNumber: newParams.referenceNumber,
            },
          }
        }
        return null
      }
    }
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <h1 className="title">Customer Support</h1>
        <Form className="customer-support-home">
          <div className="form-fields">
            <Input
              label={getMessage('customer.enterCustomerEmail')}
              placeholder={getMessage('customer.searchByEmail')}
              name="email"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['email'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              onFocus={this.handleSingleSearchFocus}
            />
            <Input
              label={getMessage('customer.enterCustomerPhone')}
              placeholder={getMessage('customer.searchByPhone')}
              name="phone"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['phone'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              onFocus={this.handleSingleSearchFocus}
            />
            {this.state.showSearchByUidInput && (
              <Input
                label={getMessage('customer.enterCustomerUid')}
                placeholder={getMessage('customer.searchByUid')}
                name="UID"
                type="text"
                {...this.generateStateMappers({
                  stateKeys: ['uid'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                })}
                onFocus={this.handleSingleSearchFocus}
              />
            )}
            <Input
              label={getMessage('order.filters.referenceNumber.heading')}
              placeholder={getMessage(
                'order.filters.referenceNumber.placeholder'
              )}
              name="referenceNumber"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ['referenceNumber'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
              })}
              onFocus={this.handleSingleSearchFocus}
            />
            {this.state.showBackOfficePhase3Feat && (
              <SearchForLinkpassByAssociation
                associateName={this.state.values?.associateName}
                generateStateMappers={this.generateStateMappers}
              />
            )}
          </div>
          <SubmitButton>
            {getMessage('customer.filters.submittext')}
          </SubmitButton>
        </Form>
        {this.state.onlyOneFieldError && (
          <div>Only one field is allowed at a time.</div>
        )}
        {this.state.permissionError.length > 0 && (
          <div style={{ marginBottom: '1rem' }}>
            {this.state.permissionError}
          </div>
        )}
        {this.state.submitting ? (
          <Loader />
        ) : (
          <div className="table-container">
            {this.state.customer ? (
              this.state.customer.length > 0 ? (
                <div className="customers-page">
                  <Table tableDynamic>
                    {CustomerTableView(customerSupport).headers ? (
                      <Header
                        items={CustomerTableView(customerSupport).headers}
                      />
                    ) : null}
                    {this.state.customer.map((row, index) => (
                      <CustomerRow
                        {...row}
                        uidOfNonFpUser={
                          !this.state.linkpassProfile.association?.fairprice &&
                          this.state.linkpassProfile.uid
                        }
                        key={'cs-cumtomer' + index}
                      />
                    ))}
                  </Table>
                </div>
              ) : (
                <div className="text-muted text-center">
                  {getMessage('customerSupport.search.noCustomer')}
                </div>
              )
            ) : null}
            {this.state.orderSearch ? (
              <DeliveryOrdersTable {...componentProps} />
            ) : null}
          </div>
        )}
      </AuthenticatedPage>
    )
  }
}

const CsDashboard = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig

  return <CsDashboardClass {...props} splits={splits} />
}

export default CsDashboard
