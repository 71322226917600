import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getMessage } from '../../lib/translator'
import WebsiteLink from './WebsiteLink'
import classNames from '../../lib/utils/classNames'

const SELECTOR_SUBMENU = '.submenu ul'
const SELECTOR_HIDDEN_MENU = `#menu #hidden-menu ${SELECTOR_SUBMENU}`
const CSS_CLASS_USER_HOVER = 'user-hover'

function createSlugFromName(name) {
  return name.replace(/\s+/g, '-').toLowerCase()
}

function mouseEventListener(event) {
  const receivedElementName = event.target.nodeName
  const hiddenSubmenuUlOffsetHeight = document.querySelector(
    SELECTOR_HIDDEN_MENU
  )
    ? parseInt(document.querySelector(SELECTOR_HIDDEN_MENU).offsetHeight, 10)
    : 0
  const hiddenSubmenuUl = document.querySelector(SELECTOR_HIDDEN_MENU)
  const hiddenSubmenu = document.querySelector('#menu #hidden-menu .submenu')
  if (receivedElementName === 'LI') {
    if (event.target.querySelector(SELECTOR_SUBMENU)) {
      const hoverSubmenuHeight = event.target.querySelector(SELECTOR_SUBMENU)
        ? parseInt(
            event.target.querySelector(SELECTOR_SUBMENU).offsetHeight,
            10
          )
        : 0
      const item = event.target.querySelector(SELECTOR_SUBMENU)
        ? event.target.querySelector(SELECTOR_SUBMENU).cloneNode(true)
        : null
      if (item && hiddenSubmenuUlOffsetHeight < hoverSubmenuHeight) {
        hiddenSubmenuUl.remove()
        hiddenSubmenu.appendChild(item)
      }
    }
  } else if (receivedElementName === 'A') {
    const parent = event.target.parentElement
    if (parent) {
      const hoverSubmenuHeight = parent.querySelector(SELECTOR_SUBMENU)
        ? parseInt(parent.querySelector(SELECTOR_SUBMENU).offsetHeight, 10)
        : 0
      const item = parent.querySelector(SELECTOR_SUBMENU)
        ? parent.querySelector(SELECTOR_SUBMENU).cloneNode(true)
        : null
      if (item && hiddenSubmenuUlOffsetHeight < hoverSubmenuHeight) {
        hiddenSubmenuUl.remove()
        hiddenSubmenu.appendChild(item)
      }
    }
  }
}

export default class MenuItem extends Component {
  /*
        TODO - It may be easier to use NavLink instead of Link, if we can refactor the html/css to use markup
        such that active class is added on links instead of list. If we can do this, then we can remove the higher
        order component WithRouter from Menu, as that is used just for active links calculation - NavLink directly
        provides this method.

     */
  constructor(props) {
    super(props)
    this.getSubmenu = this.getSubmenu.bind(this)
  }

  getSubmenu(activeText) {
    const { props } = this
    const active = props.active.split('/')
    const isActive = (text) =>
      props.text === CSS_CLASS_USER_HOVER || activeText === 'home'
        ? createSlugFromName(text) === active[2]
        : createSlugFromName(props.text) === active[1] &&
          createSlugFromName(text) === active[2]

    return (
      <div className="submenu">
        {props.info && <props.info />}
        {
          <ul>
            {props.subMenu &&
              props.subMenu.map((text) => {
                return (
                  <li className={isActive(text) ? 'active' : ''} key={text}>
                    <Link
                      to={`/${
                        activeText === CSS_CLASS_USER_HOVER
                          ? active[1]
                          : createSlugFromName(props.text)
                      }/${createSlugFromName(text)}`}
                    >
                      {getMessage(`menu.item.${text}`)}
                    </Link>
                  </li>
                )
              })}
            <li className="website">
              <WebsiteLink />
            </li>
          </ul>
        }
      </div>
    )
  }

  render() {
    const { props } = this
    let text = props.text
    if (!text) {
      text = 'home'
    }
    const className = 'icon-' + createSlugFromName(text)
    const active = props.active.split('/')
    const submenu = this.getSubmenu(text)

    return (
      <li
        onMouseEnter={mouseEventListener}
        className={classNames([
          text === CSS_CLASS_USER_HOVER ? CSS_CLASS_USER_HOVER : '',
          text === 'home' ? 'home-menu' : '',
          createSlugFromName(props.text) === active[1] ? 'active' : '',
          createSlugFromName(props.text) === 'user' ? 'user' : '',
          text !== CSS_CLASS_USER_HOVER &&
          text !== 'home' &&
          createSlugFromName(props.text) !== 'user'
            ? `${createSlugFromName(text)}`
            : '',
        ])}
      >
        <Link className={className} to={'/' + createSlugFromName(props.text)}>
          {props.text ? getMessage(`menu.item.${props.text}`) : 'home'}
        </Link>
        {submenu}
      </li>
    )
  }
}
