import React from 'react'
import { Cell, Row } from 'components/Table'
import { getReadableDateFromISO8601 } from 'lib/datetime'
import { Link } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import { padCode } from 'pages/gift-card/utils/constants'

export const TableProperties = () => {
  return {
    headers: [
      getMessage('gift.card.print.orders.order.id'),
      getMessage('gift.card.print.orders.status'),
      getMessage('gift.card.print.orders.vendor.email'),
      getMessage('gift.card.print.orders.design.codes'),
      getMessage('gift.card.print.orders.created.by'),
      getMessage('gift.card.print.orders.create.time'),
    ],
    row: ({ id, status, items, vendorEmail, createdBy, createTime }) => {
      return (
        <Row>
          <Cell>
            <Link to={`/gift-card/print-orders/view/${id}`}>{id}</Link>
          </Cell>
          <Cell>{status}</Cell>
          <Cell>{vendorEmail}</Cell>
          <Cell>
            {items?.length > 0
              ? items.map((a) => padCode(a?.designCode)).join(', ')
              : ''}
          </Cell>
          <Cell>{createdBy.email}</Cell>
          <Cell>{getReadableDateFromISO8601(createTime)}</Cell>
        </Row>
      )
    },
  }
}
