import { Input, Select } from 'components/Form'
import { getMessage } from 'lib/translator'
import React, { useEffect, useState } from 'react'
import deleteSvg from '../../../../icons/delete.svg'
import API from 'lib/api'
import { Popup } from 'components/Popup'
import { padCode } from 'pages/gift-card/utils/constants'

import PrintOrderConfirmation from './PrintOrderConfirmation'

const AddPrintOrder = (props) => {
  const { onSubmit, onCancel } = props
  const [vendorEmail, setVendorEmail] = useState('')
  const emptyCodeQty = {
    designCode: '',
    quantity: '',
  }
  const [codeQtyArray, setCodeQtyArray] = useState([{ ...emptyCodeQty }])
  const [designCodeOptions, setDesignCodeOptions] = useState([])
  const [showPopup, setShowPopup] = useState(false)

  const handleAddPrintOrder = () => {
    onSubmit({ vendorEmail, items: codeQtyArray })
  }

  const handleConfirm = () => {
    setShowPopup(true)
  }

  const VENDOR_EMAIL_OPTIONS = [
    'test@email.com',
    'admin@email.com',
    'abc@email.com',
  ]

  useEffect(() => {
    new API({
      url: '/gifting-admin-service/design-codes',
    })
      .get()
      .then((res) => {
        setDesignCodeOptions(
          res.data.records.map((item) => ({
            text: `${padCode(item.id)} - ${item?.denominationType === 'FLEXI' ? getMessage('order.personal') : getMessage('offer.ordertype.options.b2b')}`,
            value: item.id,
            disabled: codeQtyArray.map((a) => a.designCode).includes(item.id),
          }))
        )
      })
  }, [codeQtyArray])

  return (
    <div className="print-order-details">
      <div className="row">
        <div className="col-6">
          <Select
            name="select-status"
            placeholder={getMessage(
              'gift.card.print.orders.select.placeholder'
            )}
            label={getMessage('gift.card.print.orders.vendor.email')}
            options={VENDOR_EMAIL_OPTIONS}
            value={vendorEmail}
            testid="vendor-email"
            type="text"
            onChange={(e) => setVendorEmail(e)}
          />
        </div>
      </div>
      {codeQtyArray.map((item, index) => (
        <div className="row" key={item.designCode}>
          <div className="col-6">
            <Select
              name="select-status"
              placeholder={getMessage(
                'gift.card.print.orders.select.placeholder'
              )}
              label={getMessage('gift.card.print.orders.design.code')}
              options={designCodeOptions}
              value={item.designCode}
              type="number"
              testid="design-code"
              onChange={(e) =>
                setCodeQtyArray((prev) =>
                  prev.map((obj, idx) =>
                    idx === index ? { ...obj, designCode: e } : obj
                  )
                )
              }
            />
          </div>
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.quantity')}
              placeholder={getMessage(
                'gift.card.print.orders.quantity.placeholder'
              )}
              value={item.quantity}
              testid="quantity"
              type="number"
              className="quantity"
              onChange={(e) => {
                if (e === '') {
                  setCodeQtyArray((prev) =>
                    prev.map((obj, idx) =>
                      idx === index ? { ...obj, quantity: '' } : obj
                    )
                  )
                  return
                }
                const value = Math.max(1, Number(e))
                setCodeQtyArray((prev) =>
                  prev.map((obj, idx) =>
                    idx === index ? { ...obj, quantity: value } : obj
                  )
                )
              }}
            />
          </div>
          <div className="col-6 mt-2">
            {codeQtyArray.length > 1 && index !== 0 && (
              <img
                className="delete-code-qty"
                src={deleteSvg}
                onClick={() =>
                  setCodeQtyArray((prev) =>
                    prev.filter((_, idx) => idx !== index)
                  )
                }
                alt="Delete Code Quantity"
              />
            )}
            {index === codeQtyArray.length - 1 && (
              <button
                type="button"
                className="primary"
                onClick={() =>
                  setCodeQtyArray((prev) => [...prev, { ...emptyCodeQty }])
                }
              >
                {getMessage('gift.card.print.orders.add.row')}
              </button>
            )}
          </div>
        </div>
      ))}
      <div className="row flex-end">
        <div className="form-actions">
          <button
            type="button"
            className="primary"
            data-testid="add-print-order"
            disabled={
              !vendorEmail ||
              codeQtyArray.some((item) => item.designCode === '') ||
              codeQtyArray.some((item) => item.quantity === '')
            }
            onClick={handleConfirm}
          >
            {getMessage('gift.card.print.orders.submit')}
          </button>

          <button
            className="print-order-cancel-button button"
            onClick={onCancel}
            data-testid="cancel-print-order"
          >
            <span className="text">
              {getMessage('gift.card.print.orders.cancel')}
            </span>
          </button>
        </div>
      </div>
      <Popup
        show={showPopup}
        heading={getMessage('gift.card.print.orders.confirm.heading')}
        close={() => setShowPopup(false)}
        className="confirmPopup"
        data-testid="confirm-popup"
      >
        <PrintOrderConfirmation
          vendorEmail={vendorEmail}
          codeQtyArray={codeQtyArray}
          onConfirm={handleAddPrintOrder}
        />
      </Popup>
    </div>
  )
}

export default AddPrintOrder
