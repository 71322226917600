import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import SalesOrderDetails from './Details'
import SalesOrderTable from './Table'
import { Dialog } from 'components/Popup'
import { getMessage } from 'lib/translator'

const SalesOrders = (props) => {
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState('')
  const [dialogError, setDialogError] = useState(false)
  const { router, history } = props
  const details = { ...props, url: '/sales-orders' }

  const handleDialogClose = () => setSuccessModal(false)
  const handleOk = () => {
    handleDialogClose()
    history.push('/gift-card/sales-orders/')
  }

  return (
    <>
      <Dialog
        data-testid="sales-detail-success"
        className={dialogError ? 'error' : 'success'}
        show={successModal}
        close={handleDialogClose}
        message={message}
        closeText={getMessage('gift.card.print.orders.close')}
        onOk={handleOk}
        okText={getMessage('gift.card.print.orders.ok')}
      />
      {router.match.params.id ? (
        <SalesOrderDetails
          {...details}
          setSuccessModal={setSuccessModal}
          setMessage={setMessage}
          setDialogError={setDialogError}
        />
      ) : (
        <SalesOrderTable
          {...details}
          setSuccessModal={setSuccessModal}
          setMessage={setMessage}
          setDialogError={setDialogError}
        />
      )}
    </>
  )
}

export default withRouter(({ match, ...props }) => (
  <SalesOrders router={{ match }} {...props} />
))
