import React, { useState } from 'react'
import moment from 'moment'
import { Row, Cell } from 'components/Table'
import { DropDown, DropDownItem, ICONS } from 'components/DropDown'
import Dialog from 'components/Popup/Dialog'
import { getMessage } from 'lib/translator'
import { Link } from 'react-router-dom'
import './style.scss'

function ScanCampaignRow({
  isExpiredCampaign,
  id,
  name,
  status,
  validFrom,
  validTill,
}) {
  const [errorDialog, setErrorDialog] = useState(null)

  const getTime = (dateTime) => dateTime && moment(dateTime).format('hh:mm A')
  const getDate = (dateTime) =>
    dateTime && moment(dateTime).format('D MMM YYYY')

  return (
    <>
      {errorDialog && (
        <Dialog
          className="error-dialog"
          show={!!errorDialog}
          title="Error"
          information={errorDialog}
          close={() => setErrorDialog({ errorDialog: null })}
          closeText={getMessage('dialog.okText')}
        />
      )}
      <Row className="scan-campaigns-table-row" key={`row-${id}`}>
        <Cell className="campaign-id">
          <Link to={`/marketing/scan-campaigns/${id}`}>
            <div>{id}</div>
          </Link>
        </Cell>

        <Cell className="scan-campaign-name">
          <Link to={`/marketing/scan-campaigns/${id}`}>
            <div>{name}</div>
          </Link>
        </Cell>

        <Cell className="scan-campaign-status">
          <div className="status text-muted">
            {isExpiredCampaign ? 'EXPIRED' : status}
          </div>
        </Cell>

        <Cell className="scan-campaign-start-time">
          <div>{getDate(validFrom)}</div>
          <div className="time text-muted">{getTime(validFrom)}</div>
        </Cell>

        <Cell className="scan-campaign-end-time">
          <div>{getDate(validTill)}</div>
          <div className="time text-muted">{getTime(validTill)}</div>
        </Cell>

        <Cell>
          <DropDown
            isDisable={isExpiredCampaign}
            icon={<img src={ICONS.VELLIP} alt="⋮" />}
          >
            <DropDownItem>
              <Link to={`scan-campaigns/${id}`}>Edit</Link>
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    </>
  )
}

export default ScanCampaignRow
