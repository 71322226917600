import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import tagIcon from '../../icons/tag.svg'
import deleteIcon from '../../icons/delete.svg'
import AutocompleInput from '../autocomplete/AutocompleteInput'
import { ENVIRONMENT } from 'config/app'
import prodStoresList from './stores_list.json'
import uatStoresList from './stores_list_uat.json'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import Loader from 'components/Loader'
import API from 'lib/api'
import './CategorizedStoreSelector.css'

const KOPITIAM = 'kopitiam'
const FAIRPRICE = 'fairprice'
const UNITY = 'unity'
const CHEERS = 'cheers'

export default function StoreGroupingsSelector({ onUpdate, values = [] }) {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  const useDynamicFpPayStoresFlag =
    splits?.[SPLIT_FEATURES.USE_DYNAMIC_FP_PAY_STORES]?.treatment === 'on'

  const FpPayStores = ENVIRONMENT === 'UAT' ? uatStoresList : prodStoresList

  const [storesList, setStoresList] = useState([])

  useEffect(() => {
    async function fetchStores() {
      try {
        const storesApi = new API({
          url: '/offer-service/stores',
        })
        const storesResponse = await storesApi.get()
        setStoresList(storesResponse.data)
      } catch (e) {
        console.log(e)
      }
    }

    if (useDynamicFpPayStoresFlag) {
      fetchStores()
    } else {
      setStoresList(FpPayStores)
    }
  }, [])

  const isKopitiamDrinkStall = (clientStoreId) => clientStoreId.endsWith('3DF')

  const categorizedStores = [
    {
      category: 'Fairprice',
      label: 'fairprice',
      stores: storesList
        .filter((store) => store.storeType === FAIRPRICE)
        .map((store) => store.clientStoreId),
    },
    {
      category: 'Kopitiam tenant stalls',
      label: 'kopitiam tenant stalls',
      stores: storesList
        .filter(
          (store) =>
            store.storeType === KOPITIAM &&
            !isKopitiamDrinkStall(store.clientStoreId)
        )
        .map((store) => store.clientStoreId),
    },
    {
      category: 'Kopitiam drink stalls',
      label: 'kopitiam drink stalls',
      stores: storesList
        .filter(
          (store) =>
            store.storeType === KOPITIAM &&
            isKopitiamDrinkStall(store.clientStoreId)
        )
        .map((store) => store.clientStoreId),
    },
    {
      category: 'Unity',
      label: 'unity',
      stores: storesList
        .filter((store) => store.storeType === UNITY)
        .map((store) => store.clientStoreId),
    },
    {
      category: 'Cheers',
      label: 'cheers',
      stores: storesList
        .filter((store) => store.storeType === CHEERS)
        .map((store) => store.clientStoreId),
    },
  ]

  const selectedStores = storesList.filter((store) =>
    values.some((value) => value === store.clientStoreId)
  )

  const onAutoCompleteSelect = (option) => {
    if (!values.some((v) => v === option.value)) {
      onUpdate([...values, option.value])
    }
  }

  const addStoresFromCategory = (e, category) => {
    e.preventDefault()
    const newStoresToAdd = category.stores.filter(
      (store) => !values.some((existing) => existing === store)
    )
    onUpdate([...values, ...newStoresToAdd])
  }

  const getDisplayName = ({ name, storeType, clientStoreId }) => {
    if (storeType !== KOPITIAM) {
      return name
    }

    if (isKopitiamDrinkStall(clientStoreId)) {
      return `${name} (drink stalls)`
    }

    return `${name} (tenant stalls)`
  }

  if (storesList?.length === 0) {
    return <Loader size="sm" />
  }

  return (
    <div className="store-grouping-container">
      <div className="store-grouping-list">
        {categorizedStores.map((store) => {
          return (
            <button
              className="store-grouping-btn"
              key={store.category}
              onClick={(e) => addStoresFromCategory(e, store)}
            >
              + {store.label}
            </button>
          )
        })}
      </div>
      <AutocompleInput
        options={storesList.map((opt) => ({
          label: getDisplayName(opt),
          value: opt.clientStoreId,
        }))}
        placeholder="Select the store"
        onSelect={onAutoCompleteSelect}
      />
      {selectedStores.length ? (
        <SelectedList
          selectedStores={selectedStores}
          isKopitiamDrinkStall={isKopitiamDrinkStall}
          onDelete={(id) => {
            onUpdate(values.filter((value) => value !== id))
          }}
        />
      ) : null}
      {values.length > 0 ? (
        <div
          role="button"
          className="remove-all-btn"
          onClick={() => onUpdate([])}
        >
          Remove All ({selectedStores.length}) stores
        </div>
      ) : null}
    </div>
  )
}

function SelectedList({ selectedStores, onDelete, isKopitiamDrinkStall }) {
  const getStoreType = ({ storeType, clientStoreId }) => {
    if (storeType !== KOPITIAM) {
      return storeType
    }
    if (isKopitiamDrinkStall(clientStoreId)) {
      return 'kopitiam drink stalls'
    }
    return 'kopitiam tenant stalls'
  }

  return (
    <ul
      style={{ display: 'flex', flexDirection: 'column' }}
      className="selected-list"
    >
      {selectedStores.map((store) => {
        return (
          <li key={store.clientStoreId} className="selected-list__item">
            <div className="selected-list__label">
              <img src={tagIcon} alt="store-type-icon" className="tag-icon" />
              <small>{store.name}</small>
              <span className="store-grouping-tag">{getStoreType(store)}</span>
            </div>
            <div className="delete-icon__container">
              <img
                src={deleteIcon}
                alt={`Delete ${store.name} icon`}
                className="delete-icon"
                onClick={() => onDelete(store.clientStoreId)}
              />
            </div>
          </li>
        )
      })}
    </ul>
  )
}

SelectedList.propTypes = {
  items: PropTypes.array,
  onDelete: PropTypes.func,
}

StoreGroupingsSelector.propTypes = {
  onUpdate: PropTypes.func,
  values: PropTypes.array,
}
