import Loader from 'components/Loader'
import { Popup } from 'components/Popup'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { SplitContext } from 'containers/SplitContext'
import API from 'lib/api'
import { getSession } from 'lib/auth'
import { getEndpointToPrintInvoice } from 'lib/commonlyused'
import { getMessage } from 'lib/translator'
import React, { Component, useContext } from 'react'
import { Link } from 'react-router-dom'
import ShowSaleOrderDetails from './ShowSaleOrderDetails'
import './style.scss'

class SaleOrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saleOrderData: null,
      loading: false,
      failed: false,
      communicationConfig: null,
      orderConfig: null,
      error: null,
    }
    this.organization = getSession().organization
    this.getSaleOrder = this.getSaleOrder.bind(this)
    this.printInvoice = this.printInvoice.bind(this)
  }

  onError(error) {
    if (error && error.message) {
      this.setState({
        error: error.message.split(':').slice(1).join(':'),
      })
    }
    throw error
  }

  async getSaleOrder() {
    this.setState({ loading: true, failed: false })
    const referenceNumber = this.props.router.match.params.id
    this.getSaleOrderApi = new API({
      url: `/order-service/v3/salesOrders/${referenceNumber}`,
    })
    this.getSaleOrderApi
      .get()
      .then(
        (response) => {
          this.setState({ saleOrderData: response.salesOrder })
        },
        (error) => {
          this.setState({ failed: true })
          if (error.code === 401) {
            throw error
          }
        }
      )
      .finally(() => this.setState({ loading: false }))
  }

  getCommunicationConfig() {
    this.communicationApi = new API({ url: `/account-service/config` })
    this.communicationApi
      .get()
      .then((response) => {
        this.setState({
          communicationConfig: response.data.config.communication,
          orderConfig: response.data.config.order,
        })
      })
      .catch((error) => {
        throw error
      })
  }

  async componentDidMount() {
    this.getSaleOrder()
    this.getCommunicationConfig()
  }

  componentWillUnmount() {
    this.communicationApi && this.communicationApi.cancel()
    this.getSaleOrderApi && this.getSaleOrderApi.cancel()
  }

  async printInvoice() {
    const referenceNumber = this.props.router.match.params.id
    const { type, customer, status } = this.state.saleOrderData

    if (status === 'COMPLETED' && this.state.saleOrderData.invoiceId) {
      this.setState({ loading: true })
      const apiUrl = getEndpointToPrintInvoice({
        customerId: customer.id,
        type,
        referenceNumber,
        invoiceId: this.state.saleOrderData.invoiceId,
      })

      this.invoice = new API({
        url: apiUrl,
      })

      this.invoice
        .fileFetch()
        .then((response) => {
          if (response) {
            const blob = new Blob([response], { type: 'application/pdf' })
            const blobURL = URL.createObjectURL(blob)
            window.open(blobURL)
          }
        })
        .catch((e) => {
          const error = {
            status: true,
            message: 'Invoice not ready',
            details: `Service is unable to generate invoice, Error: ${e?.message || 'Unknown error'}`,
          }
          this.setState({ error })
        })

      this.setState({ loading: false })
    } else {
      this.setState({
        error: {
          status: true,
          message: 'Order not completed',
          details: 'Wait for order to complete, before generating invoice',
        },
      })
    }
  }

  render() {
    const referenceNumber = this.props.router.match.params.id
    const contentLoaded =
      !this.state.loading && !this.state.failed && this.state.saleOrderData

    return (
      <AuthenticatedPage className="sale-order-details" menu={this.props.menu}>
        <div className="flex-around">
          <h1 className="title heading">
            {getMessage('saleOrder.details.heading')}&nbsp;
            <Link to={`/customer-support/sale-orders/${referenceNumber}`}>
              <strong className="reference-number">{referenceNumber}</strong>
            </Link>
          </h1>
          {contentLoaded && (
            <div className="order-actions">
              <div className="order-actions-web-view">
                <button
                  className="invoice-button print-icon"
                  onClick={this.printInvoice}
                >
                  {getMessage('order.details.invoice.printButtonText')}
                </button>
              </div>
            </div>
          )}
        </div>

        {this.state.loading ? <Loader /> : null}
        {this.state.failed && (
          <div className="retry-message text-muted">
            {getMessage('order.details.errors.onLoad')}
          </div>
        )}

        {contentLoaded && (
          <ShowSaleOrderDetails
            saleOrderData={this.state.saleOrderData}
            url={this.props.url}
            currency={this.organization.currency}
          />
        )}

        {this.state.error && this.state.error.status && (
          <Popup
            show={this.state.error.status}
            referenceNumber={referenceNumber}
            close={() => this.setState({ error: null })}
            heading={`#${referenceNumber} ${this.state.error.message}`}
          >
            {this.state.error.details}
          </Popup>
        )}
      </AuthenticatedPage>
    )
  }
}

const SaleOrderDetailsWrapper = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return <SaleOrderDetails splits={splits} {...props} />
}

export default SaleOrderDetailsWrapper
