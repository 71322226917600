import SPLIT_FEATURES from 'containers/SplitContext/features'

/**
 * Use this mapping to map each root menu name or submenu name to its corresponding
 * feature flag name, (if any)
 * Make sure to use full path for submenu as there could be similar submenu name in different menu items.
 * Key in below object is important as it is a connection between split flag and actual menu
 *
 * ToDo:
 * this function will only hide menus and it doesn't block user from accessing the page by typing url manually.
 * We can explore on how we can block user from accessing the page manually as well.
 */
const MenusFeatureFlagMapping = {
  'hr/designation-roles': SPLIT_FEATURES.DESIGNATION_ROLE,
  'admin/preorder': SPLIT_FEATURES.PREORDER_STAFF,
  'marketing/campaign-labels': SPLIT_FEATURES.BACK_DIS_CAMPAIGN_LABEL,
  'customer-support/dashboard': SPLIT_FEATURES.LOYALTY_LINKPASS_FEATURES,
  'catalogue/product-cache-clear': SPLIT_FEATURES.BACK_DIS_CACHE_CLEAR,
  'marketing/games': SPLIT_FEATURES.DYNAMICS_BACKOFFICE_GAME_SETTINGS,
}

/**
 * This function will remove menus which have feature flag mapping to it and
 * treatmetns are turned off.
 * @param {*} splits
 * @param {*} menu
 */
export const checkForFeatureFlags = (splits, menu) => {
  try {
    const entries = Object.entries(menu)
    for (const entry of entries) {
      const rootMenu = entry[0]
      const subMenus = entry[1]

      checkMenuItems(menu, rootMenu, splits)
      checkSubMenuItems(menu, rootMenu, subMenus, splits)
    }
  } catch (err) {
    console.error(err)
  }
  return menu
}

/*
  Technical note:
  objects and arrays passed to function are reference to original object and array.
  Changing the value inside the function will change the referenced object and array
*/
// Check root menu items
function checkMenuItems(menu, rootMenu, splits) {
  const menuFlag = MenusFeatureFlagMapping[rootMenu]
  if (menuFlag) {
    const targetFlag = splits[menuFlag]
    if (targetFlag && targetFlag.treatment === 'off') {
      delete menu[rootMenu]
    }
  }
}

// Check sub menu items
function checkSubMenuItems(menu, rootMenu, subMenus, splits) {
  subMenus
    .map((subpath) => `${rootMenu}/${subpath}`)
    .forEach((subMenuPath) => {
      const subMenuFlag = MenusFeatureFlagMapping[subMenuPath]
      if (subMenuFlag) {
        const targetFlag = splits[subMenuFlag]
        if (
          (targetFlag &&
            targetFlag.treatment === 'off' &&
            subMenuPath !== 'customer-support/dashboard') ||
          (subMenuPath === 'customer-support/dashboard' &&
            targetFlag.treatment === 'on')
        ) {
          const subMenuPathName = subMenuPath.split('/')[1]
          const index = menu[rootMenu].indexOf(subMenuPathName)
          menu[rootMenu].splice(index, 1)
        }
      }
    })
}
