import React, { Component } from 'react'
import moment from 'moment'
import { getMessage } from '../../../../../lib/translator'
import { get } from 'lib/storage'
import PlacementDetails from '../PlacementDetails'
import PacklistViewNew from '../PacklistView'
import OrderItemsViewNew from '../OrderItemsView'
import Tabs from 'components/Tabs'
import DeliveryNote from '../DeliveryNote'
import PacklistPackingDetails from '../PackingDetails'
import PackingDetailsNoPacklist from '../PackingDetails/NoPacklist'
import AddressAndTimeSlot from '../AddressAndTimeSlot'
import PaymentDetails from '../PaymentDetails'
import CustomFields from '../CustomFields'
import {
  getAmendmentItemsFromDeliveryOrder,
  isOperationPage,
} from '../helpers.utils'
import './style.scss'
import { hasOrderEditPermissions } from '../../permissions'

const deliveryDetailsEditableStatus = [
  'PENDING',
  'RETURNED',
  'PACKING',
  'PACKED',
  'PROCESSING',
  'PICKING',
  'PARTIALLY-PICKED',
  'PICKED',
  'CHECKING',
  'CHECKED',
]

const deliveryOrderEditableStatuses = [
  'DELIVERY_ORDER_STATUS_PENDING',
  'DELIVERY_ORDER_STATUS_PROCESSING',
  'DELIVERY_ORDER_STATUS_PACKING',
  'DELIVERY_ORDER_STATUS_PACKED',
  'DELIVERY_ORDER_STATUS_PICKING',
  'DELIVERY_ORDER_STATUS_PARTIALLY_PICKED',
  'DELIVERY_ORDER_STATUS_PICKED',
  'DELIVERY_ORDER_STATUS_CHECKING',
  'DELIVERY_ORDER_STATUS_CHECKED',
  'DELIVERY_ORDER_STATUS_RETURNED',
]

class ShowDeliveryOrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOperationPage: isOperationPage(this.props.router.match.url),
      pages: ['Order Items', 'Packlists'],
      page: isOperationPage(this.props.router.match.url) ? 1 : 0,
    }
  }

  componentDidMount() {
    const { deliveryOrderData, packlistData } = this.props
    const packlistItemsCount = packlistData.flatMap(
      (packlist) => packlist.items
    ).length
    const deliveryOrderItemsCount =
      deliveryOrderData.items.length + deliveryOrderData.freeItems.length
    this.setState({
      pages: [
        `Order Items (${deliveryOrderItemsCount})`,
        `Packlists (${packlistItemsCount})`,
      ],
    })
  }

  renderMobileView() {
    const { deliveryOrderData, isOrderViewItemCollapsed } = this.props
    return (
      <div className={`collapse-container-order ${isOrderViewItemCollapsed}`}>
        <button type="button" className="component-collapse">
          {isOrderViewItemCollapsed ? '+' : '-'}
        </button>
        &nbsp;
        {isOrderViewItemCollapsed && (
          <h3>
            {getMessage('order.details.itemsTable.title')}
            <span className="text-muted">
              &nbsp;({deliveryOrderData.items.length})
            </span>
          </h3>
        )}
      </div>
    )
  }

  isScanAndGoOrder() {
    return this.props.salesOrderType === 'SALES_ORDER_TYPE_SCAN_AND_GO'
  }

  isDfOrder() {
    return this.props.deliveryOrderData.seller?.type === 'SELLER_TYPE_VENDOR'
  }

  renderOrderItemsTab(isSplitOrder) {
    const { deliveryOrderData } = this.props
    return (
      <OrderItemsViewNew
        deliveryOrderData={getAmendmentItemsFromDeliveryOrder(
          deliveryOrderData
        )}
        isSplitOrder={isSplitOrder}
        isDfOrder={this.isDfOrder()}
        currency={this.props.currency}
      />
    )
  }

  renderPacklistsTab(isSplitOrder) {
    const { deliveryOrderData, packlistData } = this.props
    if (packlistData?.length === 0) {
      return (
        <div className="no-packlist-text">
          {getMessage('order.details.noPacklist')}
        </div>
      )
    }

    return (
      <PacklistViewNew
        data={{
          packlistData: packlistData,
          clickAndCollectCharges: 0,
          orderItems: deliveryOrderData.items,
          shipping: deliveryOrderData.currentDeliveryFee,
          invoiceAmount: deliveryOrderData.invoiceAmount,
          amount: deliveryOrderData.amount,
          pendingAmount: deliveryOrderData.pendingAmount,
          type: deliveryOrderData.fulfilmentType,
          serviceFee: deliveryOrderData.currentServiceFee,
        }}
        isOperationsPage={this.state.isOperationPage}
        isSplitOrder={isSplitOrder}
        currency={this.props.currency}
        status={deliveryOrderData.status} // easier to check DO status, otherwise need to loop all packlists
        isDfOrder={this.isDfOrder()}
      />
    )
  }

  render() {
    const {
      deliveryOrderData,
      packlistData,
      deliveryNoteData,
      isMobileView,
      isDeliveryNoteCollapsed,
    } = this.props
    const doStatus = deliveryOrderData.status.replace(
      'DELIVERY_ORDER_STATUS_',
      ''
    )

    // split order means that the order is a part of sale order which has multiple delivery orders
    const isSplitOrder = deliveryOrderData.salesOrderReferenceNumber
      ? deliveryOrderData.referenceNumber !==
        deliveryOrderData.salesOrderReferenceNumber
      : false
    return (
      <div>
        {deliveryOrderData && (
          <div className="delivery-order-summary-wrapper">
            <div className="user-details">
              <PlacementDetails
                data={{
                  status: doStatus,
                  batchIds: null,
                  storeName: deliveryOrderData.store.name,
                  creationTime: deliveryOrderData.createTime,
                  completionTime: deliveryOrderData.completeTime,
                  device: deliveryOrderData.placedFrom,
                  verificationStatus: deliveryOrderData?.verificationStatus, // use 'UNVERIFIED' to test verify button
                  storeId: deliveryOrderData.seller.code,
                  preorder: deliveryOrderData.preorder,
                  salesOrderReferenceNumber:
                    deliveryOrderData['salesOrderReferenceNumber'],
                  sngSessionId: deliveryOrderData.metadata?.sngSessionID,
                  isScanAndGoOrder: this.isScanAndGoOrder(),
                  orderType: this.props.salesOrderType,
                }}
              />
              {
                // AddressAndTimeSlot has both address and customer displays. So, render the component when either of these are present
                (deliveryOrderData.deliveryAddress ||
                  deliveryOrderData.customer) && (
                  <AddressAndTimeSlot
                    data={{
                      customer: deliveryOrderData.customer,
                      address: deliveryOrderData.deliveryAddress,
                      pickupLocation: deliveryOrderData.pickupLocation,
                      status: deliveryOrderData.status,
                      preferredSlot: {
                        startTime: moment(
                          deliveryOrderData.slotStartTime
                        ).format('HH:mm:ss'),
                        endTime: moment(deliveryOrderData.slotEndTime).format(
                          'HH:mm:ss'
                        ),
                        type: 'STANDARD', // No need to handle ASAP as DO only focus on STANDARD
                      },
                      preferredDate: moment(
                        deliveryOrderData.slotStartTime
                      ).format('YYYY-MM-DD'),
                      storeId: Number(get('store')),
                      url: this.props.url,
                      orderType: this.props.salesOrderType,
                    }}
                    editable={
                      deliveryOrderEditableStatuses.includes(
                        deliveryOrderData.status
                      ) && hasOrderEditPermissions
                    }
                    getOrderDetailsApi={this.props.getOrderDetailsApi}
                    onError={this.props.onError}
                    referenceNumber={this.props.router.match.params.id}
                    isScanAndGoOrder={this.isScanAndGoOrder()}
                  />
                )
              }
            </div>
            {isMobileView && this.renderMobileView()}

            <Tabs
              items={this.state.pages.map((page) => `${page}`)}
              default={this.state.page}
              onClick={(index) => this.setState({ page: index })}
            />
            {this.state.pages[this.state.page].startsWith('Order Items')
              ? this.renderOrderItemsTab(isSplitOrder)
              : this.renderPacklistsTab(isSplitOrder)}
            {(!isMobileView || !isDeliveryNoteCollapsed) &&
              !this.isScanAndGoOrder() && (
                <DeliveryNote
                  deliveryNoteData={deliveryNoteData}
                  storeName={deliveryOrderData.store.name}
                  editable={
                    deliveryDetailsEditableStatus.indexOf(doStatus) > -1 &&
                    hasOrderEditPermissions
                  }
                />
              )}
            {!this.isScanAndGoOrder() && packlistData.length > 0 ? (
              <PacklistPackingDetails
                packlistDetails={packlistData}
                itemEditMode={false}
              />
            ) : (
              <PackingDetailsNoPacklist
                packages={deliveryOrderData.package}
                itemEditMode={false}
              />
            )}
            <CustomFields data={deliveryOrderData.metadata} />
            <PaymentDetails
              payments={deliveryOrderData.payments}
              isSplitOrder={isSplitOrder}
              salesOrderReferenceNumber={
                deliveryOrderData.salesOrderReferenceNumber
              }
              currency={this.props.currency}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ShowDeliveryOrderDetails
