import React from 'react'
import {} from 'components/Form'
import { getMessage } from 'lib/translator'
import Button from 'components.new/button/Button'
import { ReactComponent as PlusIcon } from 'icons/plus.svg'
import SingleRuleInputs from './SingleRuleInputs'

function RuleInputs({
  addCampaignRule,
  formValues,
  generateStateMappers,
  addCampaignRuleReward,
  removeRule,
  removeReward,
  moveRule,
  resetValidityPolicy
}) {
  return (
    <>
      <div className="scan-campaign-rule-container">
        <p className="scan-campaign-rule-title">
          {getMessage('offer.scanCampaigns.form.rules.title')}
        </p>
        <div>
          {formValues?.rules?.map((rule, index) => (
            <SingleRuleInputs
              key={`rule-${rule.uid}`}
              generateStateMappers={generateStateMappers}
              rule={rule}
              index={index}
              moveRule={moveRule}
              formValues={formValues}
              addCampaignRuleReward={addCampaignRuleReward}
              removeRule={removeRule}
              removeReward={removeReward}
              resetValidityPolicy={resetValidityPolicy}
            />
          ))}
        </div>
      </div>
      <Button className="button primary add-rule-btn" onClick={addCampaignRule}>
        <PlusIcon />
        {getMessage('offer.scanCampaigns.form.addRule')}
      </Button>
    </>
  )
}

export default RuleInputs
