import React from 'react'
import { Input, Select, Textarea, Upload } from '../../../../../components/Form'
import MetaDataFormFields from '../../../../../components/MetaDataFormFields'
import { getMessage } from '../../../../../lib/translator'
import EligibleItems from './EligibleItems'

export default function OverrideContent({
  isPayViaFpApp,
  iconTypes,
  generateStateMappers,
  hasOfferStarted,
  _this,
  metadataFormTypes,
  addEligibleItems,
  removeEligibleItems,
  eligibleItems,
  treatmentTypes,
  enablePlaceholderValue,
}) {
  const hasSpecificTreatmentType = () => {
    const allowedTreatments = ['DISCOUNTS', 'PARTIAL_PAYMENTS_SUPPLIER']
    return allowedTreatments.includes(_this.state?.values?.metaData?.treatment)
  }
  return (
    <div className="form-fields with-tooltip">
      <Input
        type="text"
        name="title"
        label={getMessage('marketing.table.heading.title')}
        placeholder={getMessage('marketing.table.heading.title')}
        {...generateStateMappers({
          stateKeys: ['promoCodeDetail', 'title'],
          loseEmphasisOnFill: true,
        })}
      />
      <Input
        type="text"
        name="subTitle"
        label={getMessage('offer.sub.title')}
        placeholder={getMessage('offer.sub.title')}
        {...generateStateMappers({
          stateKeys: ['promoCodeDetail', 'subTitle'],
          loseEmphasisOnFill: true,
        })}
      />

      <Textarea
        name="description"
        label={getMessage('offer.description')}
        placeholder={getMessage('offer.description.placeholder')}
        {...generateStateMappers({
          stateKeys: ['description'],
          loseEmphasisOnFill: true,
        })}
      />
      <Textarea
        name="costCenter"
        label={getMessage('offer.costcenter')}
        placeholder={getMessage('offer.costcenter')}
        required={
          _this.state?.values?.orderType?.some((item) => item.includes('DP')) ||
          _this.state?.values?.metaData?.treatment ===
            'PARTIAL_PAYMENTS_INTERNAL'
        }
        {...generateStateMappers({
          stateKeys: ['costCenter'],
          loseEmphasisOnFill: true,
        })}
        disabled={hasOfferStarted || hasSpecificTreatmentType()}
      />
      {!isPayViaFpApp && (
        <Upload
          name="offer-image"
          key="image"
          label={getMessage('offer.image')}
          {...generateStateMappers({
            stateKeys: ['imageUrl'],
            loseEmphasisOnFill: true,
          })}
        />
      )}
      <Select
        name="iconType"
        label={getMessage('offer.iconType')}
        placeholder={getMessage('offer.iconType.placeholder')}
        options={iconTypes}
        {...generateStateMappers({
          stateKeys: ['metaData', 'iconType'],
          loseEmphasisOnFill: true,
        })}
      />
      <div className="field-row">
        <div className="form-fields">
          <Textarea
            name="howToUseText"
            label={getMessage('offer.howToUseText')}
            placeholder={getMessage('offer.howToUseText')}
            tooltip={getMessage('offer.howToUseTextInfo')}
            {...generateStateMappers({
              stateKeys: ['promoCodeDetail', 'howToUseText'],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            type="text"
            name="tncUrl"
            label={getMessage('offer.tncUrl')}
            placeholder={getMessage('offer.tncUrl')}
            tooltip={getMessage('offer.tncUrlInfo')}
            {...generateStateMappers({
              stateKeys: ['promoCodeDetail', 'tncUrl'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
      </div>
      <div className="field-row">
        <h3 data-testid="offer-supplier-info">Supplier Info</h3>
        <div className="form-fields">
          <Input
            type="text"
            name="supplierName"
            label={getMessage('offer.supplier.name')}
            placeholder={getMessage('offer.supplier.name')}
            {...generateStateMappers({
              stateKeys: ['metaData', 'supplierName'],
              loseEmphasisOnFill: true,
            })}
          />

          <Input
            type="text"
            name="supplierName"
            label={getMessage('offer.supplier.code')}
            placeholder={getMessage('offer.supplier.code')}
            {...generateStateMappers({
              stateKeys: ['metaData', 'supplierCode'],
              loseEmphasisOnFill: true,
            })}
          />

          <Select
            name="treatment"
            label={getMessage('offer.supplier.treatment')}
            placeholder={getMessage('offer.supplier.treatment.placeholder')}
            options={treatmentTypes}
            disabled={hasOfferStarted}
            {...generateStateMappers({
              stateKeys: ['metaData', 'treatment'],
              loseEmphasisOnFill: true,
              onChange: (val) => {
                if (val === 'DISCOUNTS') {
                  _this.setState((prev) => ({
                    values: {
                      ...prev.values,
                      costCenter: '5101',
                    },
                  }))
                } else if (val === 'PARTIAL_PAYMENTS_SUPPLIER') {
                  _this.setState((prev) => ({
                    values: {
                      ...prev.values,
                      costCenter: '4314',
                    },
                  }))
                }
              },
            })}
            enablePlaceholderValue={enablePlaceholderValue}
          />
        </div>
      </div>
      <EligibleItems
        addEligibleItems={addEligibleItems}
        removeEligibleItems={removeEligibleItems}
        generateStateMappers={generateStateMappers}
        items={eligibleItems || []}
      />
      {!isPayViaFpApp && (
        <div className="field-row">
          <h3>{getMessage('Custom fields')}</h3>
          <MetaDataFormFields
            metaDataWithType={metadataFormTypes}
            _this={_this}
            page="offer-form"
            className="metadata-section"
            onChange={(key, value) => {
              //this condition is applied for SAP reference field in customised fields only
              const maximumSapReferenceLength = 70
              if (
                key === 'sapReference' &&
                value.length > maximumSapReferenceLength
              ) {
                _this.setState({ overSapReferenceLengthPopup: true })
                return
              }

              const currentState = generateStateMappers({
                stateKeys: ['metaData', key],
                loseEmphasisOnFill: true,
              })

              currentState.onChange && currentState.onChange(value)
            }}
          />
        </div>
      )}
    </div>
  )
}
