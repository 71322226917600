import { Input } from 'components/Form'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'
import React, { useEffect, useState } from 'react'
import Table, { Header, Cell, Row } from 'components/Table'
import { getReadableDateFromISO8601 } from 'lib/datetime'
import { Link } from 'react-router-dom'
import API from 'lib/api'
import { APG_GATEWAY_HOST } from 'config/app'

const errorMessage = getMessage('gift.card.print.orders.error.message')

export const resendOrder = async (
  id,
  setSuccessModal,
  setMessage,
  setDialogError
) => {
  try {
    await new API({
      url: `${APG_GATEWAY_HOST}/gifting-admin-service/print-orders/${id}/resend`,
    }).post({})
    setMessage(getMessage('gift.card.print.orders.resend.order.message'))
    setSuccessModal(true)
  } catch {
    setDialogError(true)
    setMessage(errorMessage)
    setSuccessModal(true)
  }
}
export const markAsPrinted = async (
  id,
  setSuccessModal,
  setMessage,
  setDialogError
) => {
  try {
    await new API({
      url: `${APG_GATEWAY_HOST}/gifting-admin-service/print-orders/${id}`,
    }).patch({ status: 'PRINTED' })
    setMessage(getMessage('gift.card.print.orders.mark.as.printed.message'))
    setSuccessModal(true)
  } catch (error) {
    setDialogError(true)
    setMessage(errorMessage)
    setSuccessModal(true)
  }
}

export const getPrintOrderData = async (
  id,
  setOrderDetail,
  setSuccessModal,
  setMessage,
  setDialogError
) => {
  try {
    const response = await new API({
      url: `${APG_GATEWAY_HOST}/gifting-admin-service/print-orders/${id}`,
    }).get()
    setOrderDetail(response.data)
  } catch {
    setDialogError(true)
    setMessage(errorMessage)
    setSuccessModal(true)
  }
}

const RenderHeader = (items) => {
  return items.map((item) => <Cell key={item}>{item}</Cell>)
}

const detailHeader = [
  getMessage('gift.card.print.orders.design.code'),
  getMessage('gift.card.print.orders.quantity'),
  getMessage('gift.card.print.orders.serial.number.range'),
]

const Detail = (props) => {
  const [orderDetail, setOrderDetail] = useState(null)
  const { id } = props.router.match.params
  const { setSuccessModal, setMessage, setDialogError, menu } = props

  useEffect(() => {
    getPrintOrderData(
      id,
      setOrderDetail,
      setSuccessModal,
      setMessage,
      setDialogError
    )
  }, [id, setSuccessModal, setMessage, setDialogError])

  const renderButtons = () => {
    if (orderDetail.status === 'PLACED' || orderDetail.status === 'EMAILING') {
      return (
        <>
          <button
            className="primary button"
            data-testid="resend-button"
            onClick={() =>
              resendOrder(
                orderDetail.id,
                setSuccessModal,
                setMessage,
                setDialogError
              )
            }
          >
            <span className="text">
              {getMessage('gift.card.print.orders.actions.resend.order')}
            </span>
          </button>

          {orderDetail.status === 'PLACED' && (
            <button
              data-testid="mark-as-printed-button"
              className="primary button"
              onClick={() =>
                markAsPrinted(
                  orderDetail.id,
                  setSuccessModal,
                  setMessage,
                  setDialogError
                )
              }
            >
              <span className="text">
                {getMessage('gift.card.print.orders.actions.mark.as.printed')}
              </span>
            </button>
          )}
        </>
      )
    }

    return null
  }

  if (!orderDetail) {
    return <p>Loading...</p>
  }

  return (
    <AuthenticatedPage menu={menu}>
      <h1>{getMessage('gift.card.print.orders.details')}</h1>
      <div className="print-order-details">
        <div className="print-order-detail-buttons">
          <>{renderButtons()}</>
          <Link to="/gift-card/print-orders">
            <button className="primary button">
              <span className="text">
                {getMessage('gift.card.print.orders.back')}
              </span>
            </button>
          </Link>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.order.id')}
              value={orderDetail.id}
              testid="detail-order-id"
              type="text"
              readOnly
            />
          </div>
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.status')}
              value={orderDetail.status}
              testid="detail-status"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.vendor.email')}
              value={orderDetail.vendorEmail}
              testid="detail-email"
              className="vendor-email"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.created.by')}
              testid="detail-created-by"
              value={orderDetail.createdBy.email}
              type="text"
              readOnly
            />
          </div>

          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.create.time')}
              testid="detail-create-time"
              value={getReadableDateFromISO8601(orderDetail.createTime)}
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.updated.by')}
              testid="detail-updated-by"
              value={orderDetail.updatedBy.email}
              type="text"
              readOnly
            />
          </div>
          <div className="col-6">
            <Input
              testid="detail-update-time"
              label={getMessage('gift.card.print.orders.update.time')}
              value={getReadableDateFromISO8601(orderDetail.updateTime)}
              type="text"
              readOnly
            />
          </div>
        </div>
        <b className="other-details">
          {getMessage('gift.card.print.orders.design.code.details')}
        </b>

        <Table>
          <Header>{RenderHeader(detailHeader)}</Header>
          {orderDetail.items.map((item) => (
            <Row key={item.designCode}>
              <Cell>{item.designCode}</Cell>
              <Cell>{item.quantity}</Cell>
              <Cell>
                {item.leadSerialNo} - {item.leadSerialNo + item.quantity - 1}
              </Cell>
            </Row>
          ))}
        </Table>
      </div>
    </AuthenticatedPage>
  )
}

export default Detail
