/* remove all the id values from an object */
function removeIds(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj // Base case: not an object, or null, return as is
  }

  if (Array.isArray(obj)) {
    // If it's an array, recursively process each element
    return obj.map((item) => removeIds(item))
  }

  // If it's an object, process its properties
  const newObj = {}
  for (const key in obj) {
    if (key !== 'id') {
      newObj[key] = removeIds(obj[key]) // Recursive call for nested objects/arrays
    }
  }
  return newObj
}

/* remove rules that has rewards not setup to sum to 100 exactly */
function getRulesWithInvalidRewards(rules) {
  const rulesWithInvalidRewards = []
  rules.forEach((rule, index) => {
    let totalPercentage = 0
    rule.rewards.map((reward) => {
      totalPercentage += reward.percentage
    })
    if (totalPercentage > 100) {
      rulesWithInvalidRewards.push(index + 1)
    }
  })
  return rulesWithInvalidRewards
}

function convertStringToArray(value) {
  // replace(/\s/g, "") remove all white spaces from the string
  return Array.isArray(value) ? value : value.replace(/\s/g, '').split(',')
}

function constructRules(rules, existingNothingRewards) {
  return rules.map((rule) => {
    let updatedRule = Object.assign({}, rule)
    if (rule.type === 3) {
      updatedRule = {
        ...updatedRule,
        segmentId:
          rule.segmentType === 'NEW_APP_SIGN_UP' ? 0 : rule?.segmentId?.id,
      }
    }

    if (rule?.policy?.sequences) {
      const sequencesArr = convertStringToArray(rule.policy.sequences)
      updatedRule.policy = {
        ...updatedRule.policy,
        sequences: sequencesArr.map((seq) => Number(seq)),
      }
    } else if (rule.policy) {
      updatedRule.policy.sequences = null
    }

    // add NOTHING option for rules that does not meet 100%
    const totalPercentage = rule.rewards.reduce(
      (acc, reward) => acc + reward.percentage,
      0
    )
    let nothingReward = {}

    // If id exists in rule and rule.id exists in existingNothingRewards, process. Else, continue
    if ('id' in rule && existingNothingRewards[rule.id]) {
      nothingReward = {
        ...existingNothingRewards[rule.id],
        percentage: 100 - totalPercentage,
      }
    }

    const newRewards =
      totalPercentage < 100 || Object.keys(nothingReward).length !== 0
        ? [
            ...rule.rewards,
            {
              ...nothingReward,
              rewardType: 4,
              rewardValue: '0',
              percentage: 100 - totalPercentage,
            },
          ]
        : rule.rewards // Keep the original array if totalPercentage = 100

    // deep copy rewards array before modifying
    updatedRule.rewards = [
      ...newRewards.map((reward) => {
        const updatedReward = {
          ...reward,
          percentage: reward.percentage * 100, // multiply by 100 for API payload
        };

        // skip to add validityPolicy for NOTHING reward
        if (reward.rewardType !== 4) {
          const newValidityPolicy = {
            type: reward.validityPolicy?.type,
            cutOffTime: "0",
            durationInHours: 0
          }
          if (reward?.validityPolicy?.cutOffTime && reward.validityPolicy.cutOffTime !== "0") {
            const timeParts = reward.validityPolicy.cutOffTime.split(':');
            newValidityPolicy.cutOffTime = `${timeParts[0]}:${timeParts[1]}`;
          }
          if (reward?.validityPolicy?.durationInHours) {
            newValidityPolicy.durationInHours = reward.validityPolicy.durationInHours * 24;
          }
          updatedReward.validityPolicy = newValidityPolicy;
        }

        return updatedReward;
      }),
    ]

    // cleanup keys for POST/UPDATE call
    delete updatedRule.uid
    delete updatedRule.segmentType
    delete updatedRule.isEnabled
    updatedRule.rewards.forEach((reward) => {
      delete reward.uid
    })

    return updatedRule
  })
}

function removeNothingRewards(rewards) {
  return {
    rewardsWithoutNothing: rewards.filter((reward) => reward.rewardType !== 4),
    nothingReward: rewards.find((reward) => reward.rewardType === 4),
  }
}

export {
  removeIds,
  getRulesWithInvalidRewards,
  convertStringToArray,
  constructRules,
  removeNothingRewards,
}
