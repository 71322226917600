import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  DropDown,
  DropDownItem,
  ICONS,
} from '../../../../../../components/DropDown'
import { getMessage } from '../../../../../../lib/translator'
import { isExtensionEnabled } from '../../../../../../lib/auth'
import {
  hasOrderEditPermissions,
  hasOrderPostPermissions,
} from '../../../permissions'

export default class DropDownActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  canBePendingToPacked(status) {
    return (
      hasOrderEditPermissions &&
      isExtensionEnabled('LogisticsSupport') &&
      !isExtensionEnabled('InStoreProcessing') &&
      status === 'PENDING'
    )
  }
  canBeCompleted(status, type) {
    let canComplete =
      hasOrderEditPermissions && isExtensionEnabled('InStoreProcessing')
        ? isExtensionEnabled('LogisticsSupport')
          ? status === 'DISPATCHED'
          : status === 'PACKED'
        : isExtensionEnabled('LogisticsSupport')
          ? status === 'DISPATCHED'
          : status === 'PENDING'
    if (type && type.name === 'PICKUP' && status === 'PACKED') {
      canComplete = true
    }
    return canComplete
  }
  canBeReturned(status, returnableStatuses) {
    return (
      hasOrderPostPermissions &&
      isExtensionEnabled('OrderReturns') &&
      returnableStatuses.indexOf(status) > -1
    )
  }
  canBeDisputed(status) {
    return isExtensionEnabled('OrderDisputes') && status === 'COMPLETED'
  }
  registerPayments(paymentStatus, type) {
    return (
      paymentStatus === 'PENDING' &&
      (!isExtensionEnabled('LogisticsSupport') ||
        (type && type.name === 'PICKUP'))
    )
  }
  showDialog() {
    if (isExtensionEnabled('OrderReturns')) {
      this.setState({ showPickupPending: true })
    } else {
      this.setState({ showCompleteDialog: true })
    }
  }
  isStatusPacked(status) {
    return status === 'PACKED' || false
  }
  fromOperations(url) {
    return url === '/operations'
  }
  allowEditPackedOrder(status, packedOrderEdit, url) {
    return (
      this.isStatusPacked(status) && packedOrderEdit && this.fromOperations(url)
    )
  }

  render() {
    const {
      clientId,
      packedOrderEdit,
      paymentStatus,
      pendingAmount,
      referenceNumber,
      returnableStatuses,
      status,
      type,
      url,
      printInvoice,
    } = this.props

    return (
      !this.state.loading && (
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`${url}/delivery-orders/${referenceNumber}`}>
              {getMessage('order.action.viewDetails')}
            </Link>
          </DropDownItem>
          {this.canBeDisputed(status) && (
            <DropDownItem>
              <Link to={`${url}/orders/dispute/${referenceNumber}`}>
                {getMessage('order.details.dispute.optionText')}
              </Link>
            </DropDownItem>
          )}
          <DropDownItem onClick={printInvoice}>
            {getMessage('order.action.printInvoice')}
          </DropDownItem>
          {this.canBePendingToPacked(status) && (
            <DropDownItem
              onClick={() => {
                this.setState({
                  showMovePendingToPacked: true,
                })
              }}
            >
              {getMessage('order.action.packed')}
            </DropDownItem>
          )}
          {this.canBeCompleted(status, type) &&
            (!this.registerPayments(paymentStatus, type) ? (
              <DropDownItem
                onClick={() => {
                  this.showDialog()
                }}
              >
                {getMessage('order.action.complete')}
              </DropDownItem>
            ) : (
              <DropDownItem>
                <Link
                  to={`${url}/orders/register-payment/${referenceNumber}?pendingAmount=${pendingAmount}&clientId=${clientId}`}
                >
                  {getMessage('order.action.complete')}
                </Link>
              </DropDownItem>
            ))}
          {this.canBeReturned(status, returnableStatuses) && (
            <DropDownItem>
              <Link
                to={`${url}/delivery-orders/file-for-return/${referenceNumber}`}
              >
                {getMessage('order.action.return')}
              </Link>
            </DropDownItem>
          )}
          <DropDownItem>
            <Link to={`${url}/delivery-orders/order-log/${referenceNumber}`}>
              {getMessage('order.actions.logs')}
            </Link>
          </DropDownItem>
          {this.allowEditPackedOrder(status, packedOrderEdit, url) && (
            <DropDownItem>
              <Link to={`delivery-orders/edit-packed/${referenceNumber}`}>
                {getMessage('order.action.editPackedOrder')}
              </Link>
            </DropDownItem>
          )}
        </DropDown>
      )
    )
  }
}
