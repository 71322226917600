import React from 'react'
import { BaseForm, Input, Select } from 'components/Form'

import { getMessage } from 'lib/translator'

const FP_STORES_OPTIONS = [
  getMessage('eVoucher.filter.FPStores.options.all.stores'),
  getMessage('eVoucher.filter.FPStores.options.Non.FP.stores'),
  getMessage('eVoucher.filter.FPStores.options.FP.stores'),
]
export default class Filters extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons
    const { Form } = this.components
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage('eVoucher.filter.entityName')}
            placeholder={getMessage('eVoucher.filter.entityName')}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ['name'],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            name="FPStores"
            label={getMessage('eVoucher.filter.FPStores')}
            placeholder={getMessage('eVoucher.filter.FPStores.placeholder')}
            options={FP_STORES_OPTIONS}
            {...this.generateStateMappers({
              stateKeys: ['is_fp_store'],
              loseEmphasisOnFill: true,
            })}
          />
        </div>
        <SubmitButton>{getMessage('order.filters.submitText')}</SubmitButton>
        <ClearButton>
          {getMessage('order.filters.clearFiltersText')}
        </ClearButton>
      </Form>
    )
  }
}
